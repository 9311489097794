<template>
  <div>
    <div class="debugOverlay" id="meetingDebugOverlay">
      <div v-for="(pair, index) in debugData" :key="index">
        {{ index }} {{ pair.key }} : {{ pair.value }} <br />
      </div>

      <div v-if="client">
        <h3>client Stats:</h3>
        <div v-for="stat in stats.clientStatsList" :key="stat.description">
          {{ stat.description }} - {{ stat.value }}
        </div>

        <h3>local Stats:</h3>
        <div v-for="stat in stats.localStatsList" :key="stat.description">
          {{ stat.description }} - {{ stat.value }}
        </div>
        <h3>socket Stats:</h3>
        <div v-for="stat in stats.socketStats" :key="stat.description">
          {{ stat.description }} - {{ stat.value }}
        </div>
        <div class="p-2 flex-fill">
          <div class="d-flex align-items-center">
            <div class="p-0">
              <SlideToggle
                :active="clientHasMobileDevice"
                @toggled="toggleMob"
              />
            </div>
            <div class="p-0 ml10">
              Client -portrait?: {{ clientHasMobileDevice }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { mapActions } from "vuex";
import SlideToggle from "@/components/SlideToggle";
export default {
  name: "DebugOverlay",
  components: {
    SlideToggle,
  },
  data() {
    return {
      debugData: [],
      test: 3,
      statsInterval: null,
      stats: {
        clientStatsList: [],
        localStatsList: [],
        socketStats: [],
      },
    };
  },
  mounted() {
    this.initStats();
  },
  created() {
    this.add("test", this.test);
  },
  computed: {
    ...mapGetters([
      "sessionChannelId",
      "client",
      "isSockeClusterActive",
      "isModerator",
      "clientScreenSize",
      "clientHasMobileDevice",
    ]),
  },

  methods: {
    ...mapActions(["toggleClientRatio"]),
    toggleMob() {
      this.toggleClientRatio(!this.clientHasMobileDevice);
    },
    initStats: function () {
      this.statsInterval = setInterval(this.flushStats, 1000);
    },

    flushStats: function () {
      // get the client stats message
      if (this.client === null) {
        console.log(
          this.$options.name + " -> flushStats : NO CLIENT -- SO NO STATS "
        );
        return;
      }
      const clientStats = this.client.getRTCStats();
      this.stats.clientStatsList = [
        {
          description: "Number of users in channel",
          value: clientStats.UserCount,
          unit: "",
        },
        {
          description: "Duration in channel",
          value: clientStats.Duration,
          unit: "s",
        },
        {
          description: "Bit rate receiving",
          value: clientStats.RecvBitrate,
          unit: "bps",
        },
        {
          description: "Bit rate being sent",
          value: clientStats.SendBitrate,
          unit: "bps",
        },
        {
          description: "Total bytes received",
          value: clientStats.RecvBytes,
          unit: "bytes",
        },
        {
          description: "Total bytes sent",
          value: clientStats.SendBytes,
          unit: "bytes",
        },
        {
          description: "Outgoing available bandwidth",
          value: clientStats.OutgoingAvailableBandwidth.toFixed(3),
          unit: "kbps",
        },
        {
          description: "RTT from SDK to SD-RTN access node",
          value: clientStats.RTT,
          unit: "ms",
        },
      ];

      // get the local track stats message
      const localStats = {
        video: this.client.getLocalVideoStats(),
        audio: this.client.getLocalAudioStats(),
      };
      this.stats.localStatsList = [
        {
          description: "Send audio bit rate",
          value: localStats.audio.sendBitrate,
          unit: "bps",
        },
        {
          description: "Total audio bytes sent",
          value: localStats.audio.sendBytes,
          unit: "bytes",
        },
        {
          description: "Total audio packets sent",
          value: localStats.audio.sendPackets,
          unit: "",
        },
        {
          description: "Total audio packets loss",
          value: localStats.audio.sendPacketsLost,
          unit: "",
        },
        {
          description: "Video capture resolution height",
          value: localStats.video.captureResolutionHeight,
          unit: "",
        },
        {
          description: "Video capture resolution width",
          value: localStats.video.captureResolutionWidth,
          unit: "",
        },
        {
          description: "Video send resolution height",
          value: localStats.video.sendResolutionHeight,
          unit: "",
        },
        {
          description: "Video send resolution width",
          value: localStats.video.sendResolutionWidth,
          unit: "",
        },
        {
          description: "video encode delay",
          value: Number(localStats.video.encodeDelay).toFixed(2),
          unit: "ms",
        },
        {
          description: "Send video bit rate",
          value: localStats.video.sendBitrate,
          unit: "bps",
        },
        {
          description: "Total video bytes sent",
          value: localStats.video.sendBytes,
          unit: "bytes",
        },
        {
          description: "Total video packets sent",
          value: localStats.video.sendPackets,
          unit: "",
        },
        {
          description: "Total video packets loss",
          value: localStats.video.sendPacketsLost,
          unit: "",
        },
        {
          description: "Video duration",
          value: localStats.video.totalDuration,
          unit: "s",
        },
        {
          description: "Total video freeze time",
          value: localStats.video.totalFreezeTime,
          unit: "s",
        },
      ];
      this.stats.socketStats = [
        {
          description: "Socketcluster Moderator",
          value: this.isModerator,
          unit: "",
        },
        {
          description: "Socketcluster running",
          value: this.isSockeClusterActive,
          unit: "s",
        },
        {
          description: "Client Screen width ",
          value: this.clientScreenSize.screenSize.width,
          unit: "s",
        },
        {
          description: "Client Screen height ",
          value: this.clientScreenSize.screenSize.height,
          unit: "s",
        },
      ];
    },

    add: function (_keyString, _value) {
      let temp = {
        key: _keyString,
        value: _value,
      };
      this.debugData.push(temp);
    },
  },

  beforeDestroy() {},
};
</script>

<style scoped>
#meetingDebugOverlay {
  /*font-size: 1.5vh;*/
  position: fixed;
  top: 4vh;
  right: 1vw;
  margin: 1vh;
  backdrop-filter: blur(5px);
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 0;
  padding: 0.7vh 1vw;
  color: rgba(0, 0, 0, 0, 0.7);
  display: block;
  z-index: 9999;
  font-size: 16px;
}

.debugOverlay {
  opacity: 0.8;
}
</style>
