<template>
  <div
    v-wave
    class="p-0 align-items-center h60 headText"
    id="_group_nav_item"
    v-if="isVisible"
    :class="{
      headerBottomBorder: isOpened,
    }"
  >
    <div class="header-touch" @click="actionHeaderTapped"></div>

    <div
      v-if="canClose !== false"
      class="header-touch-close"
      @click="actionToggleTapped"
    ></div>

    <transition-group
      appear
      v-on:before-enter="beforeEnter"
      v-on:enter="enter"
      v-on:leave="leave"
    >
      <div
        v-wave
        class="animatedHeader"
        v-if="baseOverlayParams.activeOverlayIndex < 2"
      >
        <div
          class="p-0 animatedHeaderText align-items-center"
          ref="headerTextItem"
          id="mainTitleTile"
        >
          <div class="headerTitle">
            <div
              class="pl15 headerTitleText"
              v-html="mainHeadline"
              role="heading"
            ></div>
            <div v-if="canClose !== false" class="_mainCloseButton">
              <img
                :src="closeBtnImg"
                class="togglePanelIcon"
                v-bind:class="{
                  togglePanelIconActive:
                    baseOverlayParams.appPanelState != 'CLOSED',
                }"
                aria-label="Close"
              />
            </div>
          </div>
        </div>
      </div>
      <div
        class="animatedHeader"
        v-if="baseOverlayParams.activeOverlayIndex > 1"
      >
        <div
          class="p-0 animatedHeaderText align-items-center"
          ref="headerTextItem"
          id="subTitleTile"
        >
          <div class="headerTitle">
            <div class="pl15 headerTitleText">
              <div class="d-flex p0">
                <div class="p-0">
                  <img
                    :src="accoordionBtnImg"
                    class="close-btn-icon"
                    alt=""
                    aria-label="Back"
                  />
                </div>
                <div
                  class="p-0 ml10"
                  v-html="baseOverlayParams.childHeaderTitle"
                  role="heading"
                ></div>
              </div>
            </div>
            <div v-if="canClose !== false" class="_mainCloseButton">
              <img
                :src="closeBtnImg"
                class="togglePanelIcon"
                v-bind:class="{
                  togglePanelIconActive:
                    baseOverlayParams.appPanelState != 'CLOSED',
                }"
                aria-label="Close"
              />
            </div>
          </div>
        </div>
      </div>
    </transition-group>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { gsap } from "gsap";
import { TweenVars } from "@/utils/tweenTime.js";
export default {
  name: "BaseOverlayHeader",
  props: ["canClose", "headline"],
  data() {
    return {
      accoordionBtnImg: "assets/ui/accordionIcon2.svg",
      closeBtnImg: "assets/ui/closeIconBtn.svg",
    };
  },
  computed: {
    ...mapGetters([
      "canRunMeeting",
      "isProductOverlayVisible",
      "apiDataLoaded",
      "baseOverlayParams",
      "currentProduct",
    ]),
    isVisible() {
      return this.isProductOverlayVisible && this.apiDataLoaded;
    },
    mainHeadline() {
      if (this.headline) {
        return this.headline;
      }

      // for some reason if pass this as a `:headline` argument from the outside
      // it fails tests :/ Ideally it should be passed from the outside.
      return this.baseOverlayParams.mainHeaderTitle;
    },
    isOpened() {
      return (
        this.baseOverlayParams.appPanelState === "OPEN" ||
        this.canClose === false
      );
    },
  },
  methods: {
    ...mapActions([
      "baseOverlayToggleButtonTapped",
      "baseOverlayHeaderTapped",
      "notifyActionHeaderTapped",
      "notifyActionToggleTapped",
    ]),
    actionHeaderTapped() {
      if (this.canRunMeeting) {
        this.notifyActionHeaderTapped();
      }

      this.baseOverlayHeaderTapped();
    },
    actionToggleTapped() {
      if (this.canRunMeeting) {
        this.notifyActionToggleTapped();
      }

      this.baseOverlayToggleButtonTapped();
    },
    beforeEnter: function (el) {
      el.style.opacity = 0;
      el.style.transform = "translateX(-20px)";
    },
    enter: function (el) {
      gsap.to(el, {
        opacity: 1,
        x: 0,
        duration: TweenVars.duration,
        ease: TweenVars.ease,
      });
    },
    leave: function (el) {
      gsap.to(el, {
        translateX: 20,
        opacity: 0,
        duration: TweenVars.duration,
        ease: TweenVars.ease,
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.headerBottomBorder {
  border-bottom: 1px solid rgba(99, 99, 99, 0.25) !important;
}

.animatedHeader {
  position: absolute;
  height: 60px !important;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  z-index: 800 !important;
}
.animatedHeaderText {
  position: relative;
  width: 100%;
  display: flex;
  height: 60px !important;
  align-items: flex-center;
}

.headerTitle {
  width: 100%;
  display: table-cell;
  display: flex;
  vertical-align: middle;
  align-self: center;
  padding-top: 4px;
  max-height: 43px;
  justify-content: space-between;
}

.headerTitleText {
  display: inline-block;
  position: relative;
  width: calc(100% - 60px);
}

.header-touch {
  position: absolute;
  cursor: pointer;
  background-color: deeppink;
  opacity: 0;
  top: 0;
  left: 0;
  height: 60px;
  width: calc(100% - 51px);
  z-index: 896;
}
.header-touch-close {
  position: absolute;
  cursor: pointer;
  background-color: deeppink;
  opacity: 0;
  top: 0;
  right: 0;
  height: 60px;
  width: 50px;
  z-index: 899;
}

._mainCloseButton {
  width: 20px;
  display: inline-block;
  vertical-align: top;
  margin-right: 15px;
}

.togglePanelIcon {
  border-radius: 50%;
  padding: 0px 4px;
  transform: rotate(0);
  transition: all 0.25s ease-in-out;
}
.togglePanelIconActive {
  transform: rotate(135deg);
  transition: all 0.25s ease-in-out;
}
</style>
