<template>
  <div class="deviceOptionListHolder" style="pointer-events: all">
    <div
      class="deviceOptionBar"
      :class="{ fontMobile: isMobile === true, fontDesk: isMobile === false }"
      @click="open"
    >
      <div
        class="d-flex align-items-center justify-content-between p0"
        style="height: 100%"
      >
        <div class="p-0" style="padding-top: 3px !important">
          {{ selectedItem }}
        </div>

        <div
          class="p-0 ml15"
          :class="{ collapseOpen: isOpen, collapseClosed: !isOpen }"
        >
          <div class="collapseIcon _desk" v-if="!isMobile">
            <img src="/assets/ui/ui_select_toggle_desk.svg" alt="" />
          </div>
          <div class="collapseIcon _mobile" v-if="isMobile">
            <img src="/assets/ui/ui_select_toggle_mobile.svg" alt="" />
          </div>
        </div>
      </div>
    </div>
    <ul id="deviceOptionResults" v-show="isOpen" class="deviceOptionResults">
      <li
        v-for="(result, i) in items"
        :key="i"
        @click="setResult(result)"
        class="deviceOptionResult"
        :class="{ 'is-active': i === arrowCounter }"
      >
        {{ result.label }}
      </li>
    </ul>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "DeviceDropDown",
  props: ["items", "selected"],
  data() {
    return {
      isOpen: false,
      arrowCounter: -1,
    };
  },
  computed: {
    ...mapGetters(["isMobile"]),
    selectedItem: function () {
      return this.selected.label;
    },
  },

  mounted() {
    document.addEventListener("click", this.handleClickOutside);
  },
  destroyed() {
    document.removeEventListener("click", this.handleClickOutside);
  },
  methods: {
    open() {
      this.isOpen = !this.isOpen;
    },
    setResult(result) {
      this.isOpen = false;
      this.$emit("changed", result);
    },

    handleClickOutside(event) {
      if (!this.$el.contains(event.target)) {
        this.isOpen = false;
        this.arrowCounter = -1;
      }
    },
  },
};
</script>

<style>
.deviceOptionListHolder {
  position: relative;
}
.deviceOptionBar {
  height: 40px;
  cursor: pointer;
  border-radius: 2px;
  padding-left: 15px;
  padding-right: 15px;
  background-color: rgba(91, 91, 91, 0.1);
}
@media (orientation: landscape) {
  .deviceOptionBar {
    height: 40px;
    cursor: pointer;
  }
}

@media (orientation: portrait) {
  .deviceOptionBar {
    height: 54px;
    cursor: pointer;
  }
}
.deviceOptionResults {
  padding: 0;
  margin: 0;
  margin-left: 0px;
  margin-right: 0px;
  list-style-type: none !important;
  list-style: none !important;
  /* border: 1px solid #eeeeee; */
  height: auto;
  /* height: 100%; */
  max-height: 120px;
  min-height: 40px;

  overflow: auto;
  border-radius: 2px;
  backdrop-filter: blur(2px);
  background-color: rgba(255, 255, 255, 0.85);
  -ms-overflow-style: none;
  scrollbar-width: none;
  position: fixed !important;
  z-index: 1111 !important;
  border-radius: 2px;
  -webkit-box-shadow: 0 1px 8px rgba(91, 91, 91, 0.1);
  -moz-box-shadow: 0 1px 8px rgba(91, 91, 91, 0.1);
  box-shadow: 0 1px 8px rgba(91, 91, 91, 0.1);
}
.deviceOptionResults::-webkit-scrollbar {
  width: 4px;
}
.deviceOptionResults::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(91, 91, 91, 0.45);
}

.deviceOptionResult {
  text-align: left;
  /* padding: 4px 8px; */
  margin-left: 8px;
  margin-right: 6px;
  list-style-type: none !important;
  list-style: none !important;
  cursor: pointer;
  background-color: rgba(91, 91, 91, 0);
  transition: background-color 0.45s ease-out;
}
.deviceOptionResult.is-active,
.deviceOptionResult:hover {
  border-radius: 2px;
  background-color: rgba(91, 91, 91, 0.15);
  transition: background-color 0.45s ease-out;
  /* background-color: #4AAE9B;
    color: white; */
}

@media (orientation: landscape) {
  .placeHolderItem {
    font-family: "Univers LT Std";
    color: rgba(99, 99, 99, 0.4);
    font-weight: 300;
    font-size: 13px;
    line-height: 18px;
  }
  .deviceOptionResult {
    /* padding: 4px 4px; */
    padding-top: 6px;
    padding-bottom: 2px;
  }
  li {
    /* border: 1px solid deeppink!important; */
    font-size: 13px;
    margin-top: 4px;
    margin-bottom: 1px !important;
  }
}

@media (orientation: portrait) {
  .placeHolderItem {
    font-family: "Univers LT Std";
    color: rgba(99, 99, 99, 0.4);
    font-weight: 300;
    font-size: 16px;
    line-height: 22px;
  }
  .deviceOptionResult {
    padding: 4px 8px;
  }
  li {
    font-size: 16px;
    margin-top: 4px;
    padding-top: 8px !important;
    margin-bottom: 1px !important;
  }
}

.collapseOpen {
  transform: rotate(180deg);
  transition: all 0.25s ease-in-out;
}
.collapseClosed {
  transform: rotate(0deg);
  transition: all 0.25s ease-in-out;
}
._desk {
  width: 12px;
  height: 12px;
}
._mobile {
  width: 18px;
  height: 18px;
}

li:last-child {
  /* margin-bottom: 0px!important; */
  padding-bottom: 8px !important;
}
</style>
