<template>
  <div style="pointer-events: all">
    <!--    <div class="p-0 ml15 mr15 mt15 mb10" v-if="!isMobile"  :class="{fontMobile:isMobile === true, fontDesk:isMobile === false}">{{contact_locale.copy}}</div>-->

    <div class="p-0 flex-fill mt15 tfbt" style="position: relative">
      <div class="d-flex p0 justify-content-between captcha align-items-center">
        <div class="p-0 flex-fill p0" v-if="!isMobile">
          <input
            type="text"
            name="matcha"
            v-model="userInputCode"
            @change="showResult"
            @input="inputChanged"
            :placeholder="contact_locale.label5"
          />
        </div>
        <div class="p-0 ml15 mr15 captcha">
          <img :src="matChaImg" class="p0" />
        </div>
        <div class="p-0 mr15" @click="captchaReload" style="cursor: pointer">
          <img src="/assets/ui/contact/recapcha_reload_desktop.svg" />
        </div>
      </div>
    </div>

    <div class="p-0 flex-fill tfbt" style="position: relative">
      <div class="d-flex p0 tfbb captcha align-items-center" v-if="isMobile">
        <div class="p-0 flex-fill p0">
          <input
            type="text"
            name="matcha"
            v-model="userInputCode"
            @change="showResult"
            @input="inputChanged"
            :placeholder="contact_locale.label5"
          />
        </div>
        <!--          <div class="p-0 mr15 captcha" v-if="!isMobile">
            <img :src="matChaImg" class="p0" />
          </div>-->
        <!--          <div class="p-0 mr15 "  @click="captchaReload" style="cursor: pointer;" v-if="!isMobile">
            <img src="/assets/ui/contact/recapcha_reload_desktop.svg"  />
          </div>-->
      </div>
      <div class="emailWarn" v-if="captchaWrong">
        {{ contact_locale.label5_2Error }}
      </div>
      <div class="emailWarn" v-if="captchaEmpty">
        {{ contact_locale.label5_1Error }}
      </div>
    </div>

    <div
      class="p-0 ml15 mr15 mt15 mb10"
      :class="{ fontMobile: isMobile === true, fontDesk: isMobile === false }"
    >
      {{ contact_locale.copy }}
    </div>

    <div class="p-0 flex-fill tfbt">
      <div class="input-holder">
        <input
          type="text"
          name="phone"
          v-model="phone"
          :placeholder="contact_locale.label1"
        />
      </div>
    </div>
    <!--        <div class="p-0 flex-fill "  v-if="!isMobile" >
          <div class="input-holder">
            <input type="text" name="phone" v-model="phone" :placeholder="contact_locale.label1"/>
          </div>
        </div>-->
    <div class="p-0 flex-fill tfbt">
      <div class="input-holder">
        <input
          type="text"
          name="firstName"
          v-model="firstName"
          :placeholder="contact_locale.label2"
        />
      </div>
    </div>
    <div class="p-0 flex-fill tfbt">
      <div class="input-holder">
        <input
          type="text"
          name="lastName"
          v-model="lastName"
          :placeholder="contact_locale.label3"
        />
      </div>
    </div>
    <div class="p-0 flex-fill tfbt tfbb">
      <div class="input-holder">
        <input
          type="text"
          name="companyName"
          v-model="companyName"
          :placeholder="contact_locale.label4"
        />
      </div>
    </div>

    <div class="p-0 d-flex flex-column ml15 mr15 mt20 mb20 justify-content-end">
      <div class="p-0 d-flex flex-fill justify-content-center">
        <div
          v-wave
          class="contact-next-button"
          :class="{ contextNextClickable: true }"
          @click="nextTapped"
        >
          <div class="contact-next-button-text">
            {{ contact_locale.buttonCopy }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { OverlaySteps } from "@/utils/enum.js";
import { mapGetters } from "vuex";
import { mapActions } from "vuex";

export default {
  name: "ContactFormData",
  props: ["next", "resize"],
  data() {
    return {
      matChaImg: "",
      phone: "",
      companyName: "",
      firstName: "",
      lastName: "",
      stepType: OverlaySteps.ADD_FORMDATA,
      userInputCode: "",
      code: "",
      match: false,
      captchaWrong: false,
      captchaEmpty: false,
      captchaResult: false,
    };
  },
  computed: {
    ...mapGetters(["isMobile", "contact_locale"]),
    nextDisabled: function () {
      return true;
    },
  },
  methods: {
    ...mapActions([
      "setGetInTouch_companyName",
      "setGetInTouch_firstName",
      "setGetInTouch_lastName",
      "setGetInTouch_phone",
      "matomoTrackEvent",
    ]),
    fetchMatCha: async function () {
      const difficulty = 1;
      const theme = 0; // Math.floor((Math.random() * 8))
      const size = this.isMobile ? "land" : "port";
      const url =
        "https://artificialrome.com/matcha/matcha.php?size=" +
        size +
        "&difficulty=" +
        difficulty +
        "&theme=t" +
        theme;
      await fetch(url)
        .then((resp) => resp.json())
        .then((data) => {
          this.matChaImg = "data:image/png;base64, " + data.img;
          this.code = data.result;
        });
    },
    nextTapped() {
      this.setGetInTouch_companyName(this.companyName);
      this.setGetInTouch_firstName(this.firstName);
      this.setGetInTouch_lastName(this.lastName);
      this.setGetInTouch_phone(this.phone);

      if (this.captchaResult === true) {
        const matomoList = ["Contact", "Success"];
        this.matomoTrackEvent(matomoList);
        this.next(this.stepType, OverlaySteps.SEND_EMAIL);
      } else {
        if (this.userInputCode === "") {
          this.captchaEmpty = true;
          this.captchaWrong = false;
        } else if (this.userInputCode != "") {
          this.captchaEmpty = false;
          this.captchaWrong = true;
        }
      }
    },
    checkResult: function (_res) {
      // has to be min 1 Char
      if (!_res || _res.length === 0) {
        return false;
      }
      if (_res === this.code) {
        return true;
      } else {
        return false;
      }
    },
    captchaReload() {
      this.fetchMatCha();
    },
    showResult: function () {
      this.captchaResult = this.checkResult(this.userInputCode);
    },
    inputChanged() {
      this.captchaWrong = false;
      this.captchaEmpty = false;
    },
  },
  mounted() {
    setTimeout(() => {
      this.resize();
    }, 1000);
  },
  created() {
    this.fetchMatCha();
  },
};
</script>

<style scoped>
* {
  -webkit-user-select: auto;
  pointer-events: all;
}

@media (orientation: landscape) {
  .captcha {
    height: 42px;
  }
}

@media (orientation: portrait) {
  .captcha {
    height: 54px;
  }
}
.emailWarn {
  position: absolute;
  bottom: -2px;
  left: 15px;
  font-size: 12px;
  color: #ff0000;
}
</style>
