<template>
  <div id="dataProtectionContainer">
    <!-- <div id='blurry'></div> -->

    <div
      id="innerContact"
      v-if="showContent"
      v-bind:class="{ frostedbg: showFrost }"
    >
      <div v-if="!showForm">
        <div
          v-wave
          class="d-flex p0 align-items-center"
          style="background-color: rgba(255, 255, 255, 0.35)"
          :class="{
            deskHighlightHeight: !isMobile,
            mobileHighlightHeight: isMobile,
          }"
        >
          <div class="p-0 ml15 mt3">
            <ContactIconMobile v-if="isMobile" />
            <ContactIcon v-else />
          </div>
          <div
            class="p-0 ml15 mr10 flex-fill"
            :class="{
              highlightLabelDesk: !isMobile,
              highlightLabelMobile: isMobile,
            }"
          >
            {{ baseHeadline }}
          </div>
          <div class="p-0 mr20">
            <div
              class="closeIcon2"
              :class="{ closeIcon2Rotate: closeRotate }"
              @click="close"
            ></div>
          </div>
        </div>

        <div
          class="d-flex flex-column _formHolder ml15 mr15"
          :class="{
            fontMobile: isMobile === true,
            fontDesk: isMobile === false,
          }"
        >
          <div class="p-0 flex-fill mt15">{{ baseCopy }}</div>

          <div class="p-0 d-flex flex-fill mt15 mb20 justify-content-center">
            <div
              class="contact-next-button contextNextClickable"
              @click="submit"
            >
              <div class="contact-next-button-text">{{ baseButtonCopy }}</div>
            </div>
          </div>
        </div>
      </div>

      <div style="overflow: hidden; position: relative">
        <ContactForm
          v-if="showForm"
          :resizeCallback="resizeCallback"
          :innerHeight="innerPannelHeight"
          :closeCallback="close"
          :setScreen="setScreen"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { gsap } from "gsap";
import { EB, EB_ACTION } from "@/utils/EB";
import { TweenVars, ContactResizeTweenVars } from "@/utils/tweenTime.js";
import ContactForm from "@/components/contact/ContactForm.vue";
import ContactIcon from "@/components/icons/ContactIcon.vue";
import ContactIconMobile from "@/components/icons/ContactIconMobile.vue";
import { getCablesVar } from "@/utils/cables";

export default {
  name: "ContactOverlay",
  props: ["initFromProduct", "headline", "copy", "buttonCopy", "link"],
  components: {
    ContactIcon,
    ContactIconMobile,
    ContactForm,
  },
  data() {
    return {
      showContent: false,
      showFrost: false,
      showForm: false,
      nextButtonText: "",
      iconSource: "",
      resizeTimeout: null,
      dev: false,
      panelWidth: 500,
      panelTop: 60,
      closeRotate: false,
      maxDeskHeight: 500,
      innerPannelHeight: 0,
    };
  },

  computed: {
    ...mapGetters(["isMobile", "productOverlayParams"]),
    baseHeadline() {
      return this.headline ?? "Arrange a consultation appointment";
    },
    baseCopy() {
      return (
        this.copy ??
        "Do you have questions about the product, specific applications or our services? Our experts will be happy to advise you."
      );
    },
    baseButtonCopy: function () {
      return this.buttonCopy ?? "Make an appointment now";
    },
  },
  destroyed() {
    EB.$off(EB_ACTION.WIN_RESIZE);
  },
  mounted() {
    if (this.initFromProduct === true || this.dev === true) {
      this.showForm = false;
    } else {
      this.showForm = true;
    }
    EB.$on(EB_ACTION.WIN_RESIZE, () => {
      this.aspects();
    });
    this.init();
  },

  methods: {
    ...mapActions(["setGetInTouch_product"]),

    aspects() {
      if (this.resizeTimeout) {
        clearTimeout(this.resizeTimeout);
      }
      this.resizeTimeout = setTimeout(() => {
        this.resizeOverlay();
      }, 250);
    },
    init() {
      this.showContent = true;
      setTimeout(() => {
        this.fadeIn();
      }, 100);
    },
    submit() {
      if (this.link?.includes("://")) {
        const a = document.createElement("a");
        a.href = this.link;
        a.target = "_blank";
        a.click();
      } else {
        this.setGetInTouch_product(this.productOverlayParams.sectionName);
        this.showForm = true;
        /* this.resizeOverlay() */
        setTimeout(() => {
          this.fadeIn();
        }, 100);
      }
    },
    close() {
      this.closeRotate = true;
      this.showFrost = false;
      gsap.to("#innerContact", {
        top: this.$store.state.constrains.screenHeight / 3,
        opacity: 0,
        delay: 0.3,
        duration: TweenVars.duration,
        ease: TweenVars.ease,
      });
      setTimeout(() => {
        EB.$emit("closeProductContactOverlay", { yes: true });
      }, 800);
    },

    fadeIn() {
      this.maxDeskHeight = this.$store.state.constrains.screenHeight - 200;
      const el = document.getElementById("innerContact");
      let elH = 0;
      if (el) elH = el.offsetHeight;

      if (!this.showForm) {
        this.panelWidth = this.isMobile
          ? this.$store.state.constrains.screenWidth - 30
          : 410;
      } else {
        this.panelWidth = this.isMobile
          ? this.$store.state.constrains.screenWidth - 30
          : 500;
      }
      this.panelTop = this.isMobile
        ? 60
        : this.$store.state.constrains.screenHeight / 2 - elH / 2;

      const _l = this.isMobile
        ? 15
        : this.$store.state.constrains.screenWidth / 2 - this.panelWidth / 2;

      gsap.to("#innerContact", {
        width: this.panelWidth,
        left: _l,
        top: this.panelTop,
        duration: TweenVars.duration,
        ease: TweenVars.ease,
        delay: 0.1,
        opacity: 1,
      });
      if (!this.showFrost) {
        setTimeout(() => {
          this.showFrost = true;
        }, 800);
      }
    },

    resizeOverlay() {
      this.maxDeskHeight = this.$store.state.constrains.screenHeight - 300;
      const _l = this.isMobile
        ? 15
        : this.$store.state.constrains.screenWidth / 2 - this.panelWidth / 2;
      const el = document.getElementById("innerContact");
      let elH = 0;
      if (el) elH = el.offsetHeight;
      const _r = this.isMobile
        ? 60
        : this.$store.state.constrains.screenHeight / 2 - elH / 2;
      gsap.to("#innerContact", {
        width: this.panelWidth,
        left: _l,
        top: _r,
        duration: TweenVars.duration,
        ease: TweenVars.ease,
        delay: 0.1,
      });
    },
    setScreen(newHeight) {
      const __maxHeight = this.isMobile
        ? this.$store.state.constrains.screenHeight - 80
        : this.$store.state.constrains.screenHeight - 300;
      const _newHeight = newHeight > __maxHeight ? __maxHeight : newHeight;
      this.innerPannelHeight = _newHeight;
    },
    resizeCallback(newHeight) {
      const __maxHeight = this.isMobile
        ? this.$store.state.constrains.screenHeight - 80
        : this.$store.state.constrains.screenHeight - 300;
      const _newHeight = newHeight > __maxHeight ? __maxHeight : newHeight;

      const _topPos = this.isMobile
        ? 60
        : this.$store.state.constrains.screenHeight / 2 - _newHeight / 2;
      /* const elH = document.getElementById('innerContact').offsetHeight */
      gsap.to("#innerContact", {
        height: _newHeight,
        top: _topPos,
        duration: ContactResizeTweenVars.duration,
        ease: ContactResizeTweenVars.ease,
        /* delay:0.5 */
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.closeIcon2 {
  width: 24px;
  height: 24px;
  background-image: url(../assets/ui/contactStep1Close.svg);
  background-position: center;
  background-repeat: no-repeat;
  cursor: pointer;
  transform: rotate(90deg);
  transition: all 0.25s ease-in-out;
}
.closeIcon2Rotate {
  /* transform: rotate(225deg);
     transition: all 0.25s ease-in-out; */
}
#dataProtectionContainer {
}

#innerContact {
  /* filter: blur(1px); */
  position: absolute;
  z-index: 600 !important;
  width: 200px;
  height: auto;
  overflow: hidden;
  left: 50%;
  top: 0;
  opacity: 0;
  /* background-color: rgba(255, 255, 255, 0.5); */
  background-color: rgba(255, 255, 255, 0.25);
  color: #636363;
  font-family: "Univers LT Std";
}

._formHolder {
}
</style>
