// TODO: migrate to pinia
import { ContactI } from "@/model/ContactI";
const state = {
  getInTouch: {
    product: "",
    continent: {
      value: "",
      title: "",
    },
    country: {
      value: "",
      title: "",
    },
    email: "",
    message: "",
    responseType: "",
    timezoneOffset: null,
    timezone: null,
    meetingDay: null,
    meetingMonth: null,
    meetingYear: null,
    meetHour: "",
    meetMinutes: "",
    phone: "",
    firstName: "",
    lastName: "",
    companyName: "",
    meetDateTime: null,
    isPm: true,
    /* officeName: '', */
    /* contact_email: '', */
    officeBranch: {
      contact_email: "",
      officeName: "",
    },
  },
  contact_locale: null,
  officeBranchItems: [],
  careerContactOverlay: null,
};

const translate = (currentLocale, list) => {
  return list.find((item) => item.locale == currentLocale)?.value ?? "";
};

// =========================== GETTERS :::::::::::::::::::::::::
const getters = {
  careerContactOverlay(state, getters) {
    if (!state.careerContactOverlay) {
      return null;
    }

    return Object.keys(state.careerContactOverlay).reduce((output, key) => {
      return {
        ...output,
        [key]: translate(
          getters.currentLocale,
          state.careerContactOverlay[key]
        ),
      };
    }, {});
  },

  getInTouch: (state) => {
    return state.getInTouch;
  },
  contact_locale: (state) => {
    return state.contact_locale;
  },
  officeBranchItems: (state) => {
    return state.officeBranchItems;
  },
};

// ================== ACTIONS =========================
const actions = {
  setGetInTouch_product({ commit }, value) {
    commit("set_product", value);
  },
  setGetInTouch_continent({ commit }, value) {
    commit("set_continent", value);
  },
  setGetInTouch_country({ commit }, value) {
    commit("set_country", value);
  },
  setGetInTouch_email({ commit }, value) {
    commit("set_email", value);
  },
  setGetInTouch_message({ commit }, value) {
    commit("set_message", value);
  },
  setGetInTouch_responseType({ commit }, value) {
    commit("set_responseType", value);
  },
  setGetInTouch_timezoneOffset({ commit }, value) {
    commit("set_timezoneOffset", value);
  },
  setGetInTouch_timezone({ commit }, value) {
    commit("set_timezone", value);
  },
  setGetInTouch_meetingDay({ commit }, value) {
    commit("set_meetingDay", value);
  },
  setGetInTouch_meetingMonth({ commit }, value) {
    commit("set_meetingMonth", value);
  },
  setGetInTouch_meetingYear({ commit }, value) {
    commit("set_meetingYear", value);
  },
  setGetInTouch_meetHour({ commit }, value) {
    commit("set_meetHour", value);
  },
  setGetInTouch_meetMinutes({ commit }, value) {
    commit("set_meetMinutes", value);
  },
  setGetInTouch_phone({ commit }, value) {
    commit("set_phone", value);
  },
  setGetInTouch_firstName({ commit }, value) {
    commit("set_firstName", value);
  },
  setGetInTouch_lastName({ commit }, value) {
    commit("set_lastName", value);
  },
  setGetInTouch_companyName({ commit }, value) {
    commit("set_companyName", value);
  },
  setGetInTouch_officeName({ commit }, value) {
    commit("set_officeName", value);
  },
  setGetInTouch_contact_email({ commit }, value) {
    commit("set_contact_email", value);
  },
  setGetInTouch_isPM({ commit }, value) {
    commit("set_isPM", value);
  },
  ///// getInTouch clear stuff
  getInTouch_clearForProductChanged({ commit }, productValue) {
    commit("set_product", productValue);
    commit("set_officeName", "");
    commit("set_contact_email", "");
    commit("set_country", "");
  },
  getInTouch_clearForContinentChanged({ commit }, continentValue) {
    commit("set_continent", continentValue);
    commit("set_officeName", "");
    commit("set_contact_email", "");
    commit("set_country", "");
  },
  getInTouch_clearForCountryChanged({ commit }, countryValue) {
    commit("set_country", {
      title: countryValue.valueEN,
      value: countryValue.value,
    });
    /*  , officeNameValue, contact_email_value*/
    commit("set_officeName", null);
    commit("set_contact_email", null);
  },

  getInTouch_clear({ commit }) {
    commit("set_product", "");
    commit("set_continent", {
      value: "",
      title: "",
    });
    commit("set_country", {
      value: "",
      title: "",
    });
    commit("set_email", "");
    commit("set_message", "");
    commit("set_responseType", "");
    commit("set_timezoneOffset", "");
    commit("set_timezone", "");
    commit("set_meetingDay", "");
    commit("set_meetingMonth", "");
    commit("set_meetingYear", "");
    commit("set_meetHour", "");
    commit("set_meetMinutes", "");
    commit("set_phone", "");
    commit("set_firstName", "");
    commit("set_lastName", "");
    commit("set_companyName", "");
    commit("set_officeName", "");
    commit("set_contact_email", "");
    commit("set_isPM", true);
  },
  ///// contact locale
  async initContactLocale({ commit }, _lang) {
    const _contact = await new ContactI(_lang);
    commit("setContactLocale", _contact);
  },

  async fetchOfficeBranches({ commit }) {
    var __officeBranchItems = [];
    var outPut = "Knitting";
    const index = state.contact_locale.productList.findIndex(
      (item) => item.value == state.getInTouch.product
    );
    if (index != -1) {
      outPut = state.contact_locale.productList[index].title;
    }

    const _section =
      state.currentLocale === "cn" ? outPut : state.getInTouch.product;

    if (_section == null || _section == "") {
      return;
    }

    const _continent =
      state.currentLocale === "cn"
        ? state.getInTouch.continent.title
        : state.getInTouch.continent.value;
    /* console.log("_continent ", _continent)*/
    if (_continent == null || _continent == "") {
      return;
    }

    const _tablePath = state.contact_locale.getCountryDataTable(_continent);
    if (_tablePath == null || _tablePath == "") {
      return;
    }
    const url = `${process.env.VUE_APP_API_URL}/api/gb-content/contact-by-continent/${_tablePath}/${_section}`;
    const items = await fetch(url)
      .then((resp) => resp.json())
      .then((data) => {
        return data;
      });
    __officeBranchItems = [];
    items.forEach((element) => {
      __officeBranchItems.push({
        officeName: element["officeName"],
        product: element["product"],
        country_de: element["country_de"],
        continent_de: element["continent_de"],
        contact_email: element["contact_email"],
      });
    });

    commit("setOfficeBranchItems", __officeBranchItems);
  },

  loadCareerContact({ rootGetters, commit, getters }) {
    if (!getters.careerContactOverlay) {
      return fetch(`${rootGetters.apiPath}section/full/career`)
        .then((resp) => resp.json())
        .then((resp) => {
          const {
            _contact_button_1: button,
            _contact_copy_1: copy,
            _contact_hl_1: headline,
            _contact_link_1: link,
          } = resp.careercontentItems;

          commit("careerContactOverlay", {
            button,
            copy,
            headline,
            link,
          });

          return getters.careerContactOverlay;
        });
    }

    return getters.careerContactOverlay;
  },
};

// ================== MUTATIONS =========================
const mutations = {
  careerContactOverlay(state, data) {
    state.careerContactOverlay = data;
  },
  ///// contact locale
  setContactLocale(state, _contact) {
    state.contact_locale = _contact;
  },

  ///// getInTouch
  set_product(state, value) {
    state.getInTouch.product = value;
  },

  set_continent(state, value) {
    state.getInTouch.continent = value;
  },

  set_country(state, value) {
    state.getInTouch.country = value;
  },

  set_email(state, value) {
    state.getInTouch.email = value;
  },

  set_message(state, value) {
    state.getInTouch.message = value;
  },

  set_responseType(state, value) {
    state.getInTouch.responseType = value;
  },

  set_timezoneOffset(state, value) {
    state.getInTouch.timezoneOffset = value;
  },

  set_timezone(state, value) {
    state.getInTouch.timezone = value;
  },

  set_meetingDay(state, value) {
    state.getInTouch.meetingDay = value;
  },

  set_meetingMonth(state, value) {
    state.getInTouch.meetingMonth = value;
  },

  set_meetingYear(state, value) {
    state.getInTouch.meetingYear = value;
  },

  set_meetHour(state, value) {
    state.getInTouch.meetHour = value;
  },

  set_meetMinutes(state, value) {
    state.getInTouch.meetMinutes = value;
  },

  set_phone(state, value) {
    state.getInTouch.phone = value;
  },

  set_firstName(state, value) {
    state.getInTouch.firstName = value;
  },

  set_lastName(state, value) {
    state.getInTouch.lastName = value;
  },

  set_companyName(state, value) {
    state.getInTouch.companyName = value;
  },

  set_officeName(state, value) {
    state.getInTouch.officeBranch.officeName = value;
  },

  set_contact_email(state, value) {
    state.getInTouch.officeBranch.contact_email = value;
  },

  set_isPM(state, value) {
    state.getInTouch.isPm = value;
  },

  setOfficeBranchItems(state, items) {
    state.officeBranchItems = items;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
