<template>
  <svg viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12.9385 2H12.9394C15.1281 2 16.9028 4.18877 16.9028 5.96343V13.4965C16.9028 15.6853 15.1282 17.4599 12.9394 17.4599H12.9385C10.7497 17.4599 8.97507 15.2711 8.97507 13.4965V5.96343C8.97507 3.77465 10.7496 2 12.9385 2V2Z"
      fill="currentColor"
    />
    <path
      d="M19.2159 9.20264C19.0406 9.20264 18.8722 9.27225 18.7484 9.39599C18.6247 9.51974 18.5551 9.68817 18.5551 9.86348V13.3577C18.5551 16.4583 16.0355 18.9796 12.9384 18.9796C9.84129 18.9796 7.32168 16.4574 7.32168 13.3577V9.86348C7.32168 9.49825 7.0252 9.20264 6.66084 9.20264C6.29561 9.20264 6 9.49827 6 9.86348V13.3577C6 16.9636 8.76027 19.9351 12.2776 20.2695V22.6791H9.63497C9.26974 22.6791 8.97413 22.9747 8.97413 23.3399C8.97413 23.7043 9.26976 23.9999 9.63497 23.9999H16.2416C16.606 23.9999 16.9016 23.7043 16.9016 23.3399C16.9016 22.9747 16.606 22.6791 16.2416 22.6791H13.5991L13.5982 20.2686C17.1146 19.9352 19.8758 16.9627 19.8758 13.3568L19.8766 9.86348C19.8766 9.68817 19.807 9.51974 19.6833 9.39599C19.5586 9.27225 19.3911 9.20264 19.2158 9.20264H19.2159Z"
      fill="currentColor"
    />
  </svg>
</template>
