<template>
  <div class="d-flex flex-column form_content" id="_formHolder">
    <div
      class="p-0 d-flex tfbb align-items-center"
      style="background-color: rgba(255, 255, 255, 0); height: 60px"
      id="_formHeader"
    >
      <div
        class="p-0 ml15 contactHeader flex-fill"
        v-if="contact_locale != null"
      >
        {{ contact_locale.headline }}
      </div>
      <div class="p-0 mr15">
        <div id="closeIcon" @click="close">
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect
              width="20"
              height="1.85154"
              rx="0.92577"
              transform="matrix(6.2438e-08 -1 -1 -6.2438e-08 10.8516 20)"
              fill="#636363"
            />
            <rect
              x="20"
              y="10.8516"
              width="20"
              height="1.85154"
              rx="0.92577"
              transform="rotate(180 20 10.8516)"
              fill="#636363"
            />
          </svg>
        </div>
      </div>
    </div>

    <!-- TABBAR -->
    <div class="p-0" id="_tabbar">
      <div class="d-flex flex-column">
        <div class="p-0 mt15 mb4">
          <div class="d-flex" v-if="currentTab !== 5">
            <div
              class="p-0 tab-item"
              v-for="(item, index) in getTabs"
              :key="item.text"
              :data-index="index"
              @click="tapItemTapped(item)"
              :class="{
                tabItemActive: currentTab === item.index,
                tabItemClickable: item.clickable,
              }"
            >
              <div
                :class="{
                  tabItemTextMobile: isMobile,
                  tabItemTextDesk: !isMobile,
                }"
              >
                {{ item.text }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div id="form_content_inner" ref="formContentInner">
      <div id="contactInnerScrollable">
        <transition-group
          appear
          v-on:before-enter="beforeEnter"
          v-on:enter="enter"
          v-on:leave="leave"
          tag="div"
        >
          <div
            class="p-0"
            v-if="currentTab === 0"
            data-index="0"
            key="contactLocation"
          >
            <ContactLocation
              :resize="resize"
              :next="nextTapped"
              :isMobile="isMobile"
            />
          </div>

          <div
            class="p-0"
            v-if="currentTab === 1"
            data-index="1"
            key="EmailMessage"
          >
            <EmailMessage
              :resize="resize"
              :next="nextTapped"
              :isMobile="isMobile"
            />
          </div>

          <div
            class="p-0"
            v-if="currentTab === 2"
            data-index="2"
            key="ContactRequestType"
          >
            <ContactRequestType
              :next="nextTapped"
              :resize="resize"
              :isMobile="isMobile"
              :requestChanged="onRequestTypeChanged"
            />
          </div>

          <div
            class="d-flex flex-column"
            v-if="currentTab === 3"
            data-index="3"
            key="ContactMeetDateTime"
          >
            <div class="p-0 flex-fill">
              <ContactMeetDateTime
                :resize="resize"
                :isMobile="isMobile"
                :next="nextTapped"
              />
            </div>
          </div>

          <div
            class="d-flex flex-column"
            v-if="currentTab === 4"
            data-index="4"
            key="ContactFormData"
          >
            <div class="p-0 flex-fill">
              <ContactFormData
                :next="nextTapped"
                :isMobile="isMobile"
                :resize="resize"
              />
            </div>
          </div>

          <div
            class="d-flex flex-column justify-content-center align-items-center"
            v-if="currentTab === 5"
            data-index="5"
            key="ContactFormDataSENDING"
          >
            <SendMail
              :isMobile="isMobile"
              :resize="resize"
              :backTapped="backTapped"
            />
          </div>
        </transition-group>
      </div>
    </div>
  </div>
</template>

<script>
import ContactLocation from "@/components/contact/ContactLocation.vue";
import SendMail from "@/components/contact/SendMail.vue";
import EmailMessage from "@/modules/EmailMessage.vue";
import ContactRequestType from "@/components/contact/ContactRequestType.vue";
import ContactMeetDateTime from "@/components/contact/ContactMeetDateTime.vue";
import ContactFormData from "@/components/contact/ContactFormData.vue";
import { gsap } from "gsap";
import { TweenVars } from "@/utils/tweenTime";
import { OverlaySteps } from "@/utils/enum.js";
import { countryContinents } from "@/utils/countryByContinent.js";
import { continentsMin } from "@/utils/continents.js";
import { contactSections } from "@/utils/sections.js";
import { EB } from "@/utils/EB";

import { mapGetters } from "vuex";
import { mapActions } from "vuex";

export default {
  name: "ContactForm",
  props: ["closeCallback", "resizeCallback", "innerHeight", "setScreen"],
  components: {
    ContactLocation,
    EmailMessage,
    ContactRequestType,
    ContactMeetDateTime,
    ContactFormData,
    SendMail,
  },
  data() {
    return {
      currentTab: -1,
      sendAutoPlay: true,
      sparten: null,
      // isMobile:false,
      section: "",
      continents: null,
      country: "",
      continent: "",
      circleRotate: 0,
      countriesFlat: [],
      contactData: null,
      /* contact:null, */
      sendingLottieLoop: true,
      showLoadingLoop: true,
      showCheckLoop: true,
      checkAutoPlay: false,
      isSending: false,
      isSendingComplete: false,
      animationSpeed: 1,
      scrollContainter: null,
      overlaystepTypes: [
        OverlaySteps.LOCATION,
        OverlaySteps.EMAILFORM,
        OverlaySteps.RESPONSETYPE,
        OverlaySteps.DATESELECTION,
        OverlaySteps.ADD_FORMDATA,
        OverlaySteps.SEND_EMAIL,
      ],
      matomoList: [],

      speed: 1,
      tabs: [
        { clickable: false, index: 0, text: "1", visible: true },
        { clickable: false, index: 1, text: "2", visible: true },
        { clickable: false, index: 2, text: "3", visible: true },
        { clickable: false, index: 3, text: "4", visible: true },
        { clickable: false, index: 4, text: "5", visible: true },
      ],
    };
  },
  created() {
    /*  this.initContactLocale(this.currentLocale); */
  },
  methods: {
    ...mapActions([
      "setGetInTouch_responseType",
      "initContactLocale",
      "matomoTrackEvent",
    ]),

    close() {
      this.closeCallback();
    },

    tapItemTapped(item) {
      if (item.clickable) {
        this.currentTab = item.index;
        this.contact_locale.setStep((this.currentTab + 1).toString());
      }
    },

    winHeight() {
      return document.getElementById("contactInnerScrollable").clientHeight;
    },

    tabsIn() {
      let _items = document.getElementsByClassName("tab-item");
      const _holderWidth = document.getElementById("_formHolder").clientWidth;

      const _spacing = 15;
      const _buttonWidth =
        (_holderWidth - _spacing * _items.length) / _items.length;
      for (var i = 0; i < _items.length; i++) {
        gsap.to(_items[i], {
          opacity: 1,
          width: _buttonWidth,
          x: _spacing,
          duration: 0.45,
          //onComplete: done,
          ease: TweenVars.ease,
          delay: i * 0.025,
        });
      }
    },

    resize() {
      const WH = this.winHeight();
      const UH = this.upperElementsHeight;
      if (this.currentTab === 5) {
        this.setScreen(WH + 80);
      } else {
        this.setScreen(WH + UH);
      }

      setTimeout(() => {
        if (this.currentTab === 5) {
          this.resizeCallback(WH + 80);
        } else {
          this.resizeCallback(WH + UH);
        }
      }, 550);
      setTimeout(() => {
        if (this.currentTab === 5) {
          this.setInnerHeight(this.scrollcontainerHeight - 80);
        } else {
          this.setInnerHeight(this.scrollcontainerHeight - UH);
        }
      }, 700);
    },

    nextTapped(fromStep, nextStep) {
      const _lastIndex = this.overlaystepTypes.indexOf(fromStep);
      const _nextIndex = this.overlaystepTypes.indexOf(nextStep);
      switch (nextStep) {
        case OverlaySteps.LOCATION:
          this.matomoList = ["Contact", "Step 1"];
          this.matomoTrackEvent(this.matomoList);
          break;
        case OverlaySteps.EMAILFORM:
          this.matomoList = ["Contact", "Step 2"];
          this.matomoTrackEvent(this.matomoList);
          break;
        case OverlaySteps.RESPONSETYPE:
          this.matomoList = ["Contact", "Step 3"];
          this.matomoTrackEvent(this.matomoList);
          this.tabs[4].visible =
            this.getInTouch.responseType !== "requestEmail";
          break;
        case OverlaySteps.DATESELECTION:
          this.matomoList = ["Contact", "Step 4"];
          this.matomoTrackEvent(this.matomoList);
          break;
        case OverlaySteps.ADD_FORMDATA:
          this.matomoList = ["Contact", "Step 5"];
          this.matomoTrackEvent(this.matomoList);
          break;
      }

      this.tabs[_lastIndex].clickable = true;
      if (fromStep !== OverlaySteps.ADD_FORMDATA) {
        this.tabs[_nextIndex].clickable = true;
      }
      this.contact_locale.setStep((_nextIndex + 1).toString());
      this.currentTab = _nextIndex;

      /* this.tabs[this.currentTab].clickable = true;
        this.currentTab ++ */
    },
    backTapped() {
      this.contact_locale.setStep("5");
      this.currentTab = 4;
    },

    beforeEnter: function (el) {
      el.style.opacity = 0;
      el.style.transform = "translateX(-60px)";
    },
    enter: function (el, done) {
      gsap.to(el, {
        opacity: 1,
        x: 0,
        duration: TweenVars.duration,
        onComplete: done,
        ease: TweenVars.ease,
        delay: 0.5 + el.dataset.index * 0.075,
      });
    },
    leave: function (el, done) {
      gsap.to(el, {
        translateX: 60,
        opacity: 0,
        duration: TweenVars.duration,
        ease: TweenVars.ease,
        onComplete: () => {
          done();
        },
        delay: el.dataset.index * 0.015,
      });
    },
    onRequestTypeChanged(e) {
      // CRIS TODO - Remove
      /* store.state.getInTouch.responseType = e; */
      this.setGetInTouch_responseType(e);
      /* this.getInTouch.responseType = e; */
      if (e === "requestEmail") {
        this.tabs[4].visible = false;
      } else {
        this.tabs[4].visible = true;
      }
      setTimeout(() => {
        this.tabsIn();
      }, 150);
    },

    setInnerHeight(value) {
      gsap.to(this.scrollContainter, {
        height: value,
        duration: TweenVars.duration,
        ease: TweenVars.ease,
      });
    },

    async init() {
      await this.initContactLocale(this.currentLocale);
      this.scrollContainter = this.$refs.formContentInner;
      setTimeout(() => {
        this.tabsIn();
      }, 900);
      setTimeout(() => {
        // this.contact_locale.setStep(4)
        this.currentTab = 0;
        this.matomoList = ["Contact", "Step 1"];
        this.matomoTrackEvent(this.matomoList);
      }, 1000);
    },

    localeChanged() {
      console.log("localeChanged");
      if (this.contact_locale != null) {
        this.contact_locale.setCurrentLocale(this.currentLocale);
      }
    },
  },

  mounted() {
    EB.$on("LOCALE_CHANGED", () => {
      this.localeChanged();
    });
    this.init();
    this.countriesFlat = [];
    this.countriesFlat = countryContinents.map((x) => {
      return x["country"];
    });
    this.sparten = contactSections;
    this.continents = continentsMin;
  },

  destroyed() {
    EB.$off("LOCALE_CHANGED", () => {
      this.localeChanged();
    });
  },
  computed: {
    ...mapGetters([
      "isMobile",
      "getInTouch",
      "currentLocale",
      "contact_locale",
    ]),
    upperElementsHeight: function () {
      const _headerHeight = document.getElementById("_formHeader");
      const _tabbarHeight = document.getElementById("_tabbar");
      /* const _headerHeight = document.getElementById('form_content_inner'); */
      if (_headerHeight != null && _tabbarHeight != null) {
        return _headerHeight.offsetHeight + _tabbarHeight.offsetHeight;
      }
      return 120;
    },

    getTabs: function () {
      return this.tabs.filter((x) => {
        return x.visible == true;
      });
    },
    scrollcontainerHeight: function () {
      return this.innerHeight;
    },
  },
};
</script>

<style scoped lang="scss">
#form_content_inner {
  position: relative;
  overflow-y: auto;
  overflow-x: hidden;
}
#contactInnerScrollable {
  position: relative;
}
#form_content_inner::-webkit-scrollbar {
  width: 4px;
  background-color: rgba(0, 0, 0, 0) !important;
}
#form_content_inner::-webkit-scrollbar-track {
  background-color: rgba(0, 0, 0, 0) !important;
}
#form_content_inner::-webkit-scrollbar-thumb {
  border-radius: 6px;
  background-color: #ffffff;
  background-color: rgba(255, 255, 255, 1) !important;
}

.tab-item {
  font-family: "Univers LT Std";
  position: relative;
  width: 10px;
  opacity: 0;
  text-align: center;
  background-color: rgba(255, 255, 255, 0.25);
  color: rgba(99, 99, 99, 0.25);

  border-radius: 2px;
  transition: all 0.45s ease-in-out;
}
.tab-item:not(:first-child) {
  margin-left: 10px;
}
.tabItemTextMobile {
  /* font-family: 'Univers LT Std'; */
  font-size: 16px;
  padding-top: 10px;
  padding-bottom: 6px;
}
.tabItemTextDesk {
  /* font-family: 'Univers LT Std'; */
  font-size: 13px;
  padding-top: 6px;
  padding-bottom: 2px;
}
.tabItemClickable {
  cursor: pointer;
  color: rgba(99, 99, 99, 1);
  background-color: rgba(255, 255, 255, 0.45);
  transition: all 0.45s ease-in-out;
}
.tabItemClickable:hover {
  color: #fff;
  background-color: rgba(99, 99, 99, 0.6);
  transition: all 0.45s ease-in-out;
}

.tabItemActive {
  font-family: "Univers LT Bold";
  color: #fff;
  background-color: rgba(99, 99, 99, 0.6);
  transition: all 0.45s ease-in-out;
}
.md-field {
  background-color: rgba(255, 255, 255, 0.65);
}
.form_content {
  position: relative;
  padding-bottom: 4px;
}
</style>
