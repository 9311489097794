<template>
  <div
    v-wave
    id="_overlay_button"
    class="d-flex align-items-center overlay_button"
    :class="{
      mobileHeightProductDetails: isMobile === true,
      deskHeightProductDetails: isMobile === false,
    }"
    @click="toggleOverlay"
  >
    <div class="p-0 flex-fill">
      <div class="pt2">{{ currentProduct.productDetailsButton }}</div>
    </div>
    <div class="p-0" style="width: 15px; height: 15px">
      <img
        :src="iconImg"
        class="detailsToggleArrow"
        v-bind:class="{ detailsToggleArrowActive: subMenuListOpen }"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "ProductDetails",
  props: ["data", "subMenuListOpen"],
  data() {
    return {
      iconImg: "assets/ui/accordionIcon3.svg",
    };
  },
  mounted() {},

  computed: {
    ...mapGetters(["isMobile", "currentProduct"]),
  },

  methods: {
    toggleOverlay() {
      this.$emit("componentCall", { event: "toggle_groups_overlay" });
    },
  },
};
</script>

<style scoped>
.mobileHeightProductDetails {
  height: 46px;
  font-size: 16px !important;
}
.deskHeightProductDetails {
  font-size: 13px !important;
}
#_overlay_button {
  padding-left: 16px !important;
  padding-right: 15px !important;
  margin-top: -8px;
  margin-bottom: -8px;
}

.overlay_button {
  font-family: "Univers LT Bold";
  cursor: pointer;
  color: #636363;

  /* line-height: 28px; */
}
.detailsToggleArrow {
  /* border-radius:50%; */
  /* padding:1px 4px; */

  transform: rotate(180deg);
  transition: all 0.45s ease-in-out;
}
.detailsToggleArrowActive {
  transform: rotate(0);
  transition: all 0.45s ease-in-out;
}
</style>
