<template>
  <svg
    width="51"
    height="50"
    viewBox="0 0 51 50"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    name="PromiseIcon3"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M25.998 48C38.7006 48 48.998 37.7025 48.998 25C48.998 12.2975 38.7006 2 25.998 2C13.2955 2 2.99805 12.2975 2.99805 25C2.99805 37.7025 13.2955 48 25.998 48ZM25.998 50C39.8052 50 50.998 38.8071 50.998 25C50.998 11.1929 39.8052 0 25.998 0C12.1909 0 0.998047 11.1929 0.998047 25C0.998047 38.8071 12.1909 50 25.998 50Z"
      fill="url(#paint0_linear_3543_821)"
    />
    <path
      d="M29.2824 26.6754H24.2621L24.7358 26.2018C24.9887 25.9489 24.9887 25.5702 24.7358 25.3173C24.4829 25.0645 24.1043 25.0645 23.8514 25.3173L22.2725 26.8642C22.1467 26.99 22.0825 27.1479 22.0825 27.3057C22.0825 27.4636 22.1454 27.6215 22.2725 27.7473L23.8514 29.2941C23.9772 29.42 24.1351 29.4841 24.293 29.4841C24.4508 29.4841 24.6087 29.4212 24.7346 29.2941C24.9874 29.0413 24.9874 28.6626 24.7346 28.4097L24.2609 27.936H29.2812C29.629 27.936 29.9127 27.6523 29.9127 27.3045C29.914 26.9603 29.6303 26.6754 29.2824 26.6754Z"
      fill="url(#paint1_linear_3543_821)"
    />
    <path
      d="M28.1466 20.7385C27.8937 20.4856 27.515 20.4856 27.2621 20.7385C27.0093 20.9914 27.0093 21.37 27.2621 21.6229L27.7358 22.0966H22.7155C22.3677 22.0966 22.084 22.3803 22.084 22.7281C22.084 23.076 22.3677 23.3597 22.7155 23.3597H27.7358L27.2621 23.8655C27.0093 24.1183 27.0093 24.497 27.2621 24.7499C27.388 24.8757 27.5459 24.9399 27.7037 24.9399C27.8616 24.9399 28.0195 24.877 28.1453 24.7499L29.7242 23.2031C29.85 23.0773 29.9142 22.9194 29.9142 22.7615C29.9142 22.6036 29.8513 22.4457 29.7242 22.3199L28.1466 20.7385Z"
      fill="url(#paint2_linear_3543_821)"
    />
    <path
      d="M16.8412 21.7178H14.5678C12.926 21.7178 11.5358 22.9488 11.3149 24.5919L10.9992 27.2439C10.9671 27.5918 11.1891 27.9076 11.5357 28.0013C12.0094 28.0963 12.5152 28.1913 13.0197 28.2542L13.3675 34.4747C13.3996 34.9805 13.8091 35.3592 14.2828 35.3592H17.1248C17.6305 35.3592 18.0092 34.9805 18.0401 34.4747L18.42 28.2542C18.9257 28.1913 19.4302 28.0963 19.9039 28.0013C20.2517 27.9384 20.4725 27.5905 20.4405 27.2439L20.1247 24.5919C19.9039 22.9488 18.5149 21.7178 16.8412 21.7178Z"
      fill="url(#paint3_linear_3543_821)"
    />
    <path
      d="M18.768 17.8028C18.768 19.494 17.3963 20.8656 15.7039 20.8656C14.0128 20.8656 12.6411 19.4939 12.6411 17.8028C12.6411 16.1104 14.0128 14.7388 15.7039 14.7388C17.3963 14.7388 18.768 16.1105 18.768 17.8028Z"
      fill="url(#paint4_linear_3543_821)"
    />
    <path
      d="M40.6824 24.5921C40.4924 22.9503 39.1035 21.718 37.4295 21.718H35.1562C33.5144 21.718 32.1242 22.9491 31.9033 24.5921L31.5875 27.2442C31.5555 27.592 31.7775 27.9078 32.1241 28.0015C32.5978 28.0965 33.1035 28.1915 33.608 28.2544L33.9559 34.475C33.988 34.9807 34.3975 35.3594 34.8712 35.3594H37.7132C38.2189 35.3594 38.5976 34.9807 38.6284 34.475L38.9763 28.2544C39.482 28.1915 39.9865 28.0965 40.4602 28.0015C40.8081 27.9386 41.0289 27.5908 40.9968 27.2442L40.6824 24.5921Z"
      fill="url(#paint5_linear_3543_821)"
    />
    <path
      d="M39.3568 17.8028C39.3568 19.494 37.9852 20.8656 36.294 20.8656C34.6016 20.8656 33.23 19.4939 33.23 17.8028C33.23 16.1104 34.6017 14.7388 36.294 14.7388C37.9852 14.7388 39.3568 16.1105 39.3568 17.8028Z"
      fill="url(#paint6_linear_3543_821)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_3543_821"
        x1="38.998"
        y1="-1.53422e-06"
        x2="12.498"
        y2="50"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="var(--gradientFrom)" />
        <stop offset="1" stop-color="var(--gradientTo)" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_3543_821"
        x1="38.998"
        y1="-1.53422e-06"
        x2="12.498"
        y2="50"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="var(--gradientFrom)" />
        <stop offset="1" stop-color="var(--gradientTo)" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_3543_821"
        x1="38.998"
        y1="-1.53422e-06"
        x2="12.498"
        y2="50"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="var(--gradientFrom)" />
        <stop offset="1" stop-color="var(--gradientTo)" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_3543_821"
        x1="38.998"
        y1="-1.53422e-06"
        x2="12.498"
        y2="50"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="var(--gradientFrom)" />
        <stop offset="1" stop-color="var(--gradientTo)" />
      </linearGradient>
      <linearGradient
        id="paint4_linear_3543_821"
        x1="38.998"
        y1="-1.53422e-06"
        x2="12.498"
        y2="50"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="var(--gradientFrom)" />
        <stop offset="1" stop-color="var(--gradientTo)" />
      </linearGradient>
      <linearGradient
        id="paint5_linear_3543_821"
        x1="38.998"
        y1="-1.53422e-06"
        x2="12.498"
        y2="50"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="var(--gradientFrom)" />
        <stop offset="1" stop-color="var(--gradientTo)" />
      </linearGradient>
      <linearGradient
        id="paint6_linear_3543_821"
        x1="38.998"
        y1="-1.53422e-06"
        x2="12.498"
        y2="50"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="var(--gradientFrom)" />
        <stop offset="1" stop-color="var(--gradientTo)" />
      </linearGradient>
    </defs>
  </svg>
</template>

<script>
export default {
  name: "PromiseIcon3",
};
</script>
