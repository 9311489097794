<template>
  <div class="p-0 d-flex flex-column" @click="click()">
    <div
      v-wave
      class="text-center _productDetailItem"
      :class="{
        mobileButtonHeight: isMobile === true,
        deskButtonHeight: isMobile === false,
      }"
    >
      <div class="copy pt2">
        {{ copy }}
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "OverlayButton",
  emits: ["click"],
  props: ["copy", "url"],

  computed: {
    ...mapGetters(["isMobile"]),
  },

  methods: {
    click() {
      const { url } = this;
      if (url && url.includes("://")) {
        var a = document.createElement("a");
        a.href = url;
        a.target = "_blank";
        a.click();
      } else {
        // it's possible to have customer strings here instructing
        // that we have to open contacts or else
        this.$emit("click");
      }
    },
  },
};
</script>

<style scoped lang="scss">
._productDetailItem {
  cursor: pointer;
  font-family: "Univers LT Std";

  border: 1px solid rgba(99, 99, 99, 0.45);
  border-radius: 3px;
  text-align: center;
  width: 100%;

  color: #636363;
  font-weight: 400;
  display: table;
  background-color: rgba(0, 0, 0, 0);
  transition: background-color 0.45s ease-in-out;
}
._productDetailItem:hover {
  background-color: rgba(0, 0, 0, 0.05);
  transition: background-color 0.25s ease-in-out;
}
.mobileButtonHeight {
  height: 40px !important;
  font-size: 16px;
}
.deskButtonHeight {
  height: 30px !important;
  font-size: 13px;
  line-height: 18px;
}
.copy {
  height: 100%;
  display: table-cell;
  vertical-align: middle;
}
</style>
