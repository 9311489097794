<template>
  <div id="fallbackWin">
    <div id="topDropShadow"></div>
    <div class="topBar" :class="{ topBarOut: !showFallbackOverlay }">
      <div
        class="d-flex justify-content-between align-items-center"
        style="height: 30px"
      >
        <div class="p-0" v-if="isMobile"></div>
        <div class="p-0" style="margin-left: 56px" v-if="!isMobile"></div>
        <div
          class="p-0"
          :class="{ deskLogoPos: !isMobile, mobileLogoPos: isMobile }"
        >
          <img src="/assets/ui/fallback/logo2.svg" alt="" />
        </div>
        <div class="p-0" v-if="isMobile"></div>
        <div class="p-0 mr15" v-if="!isMobile">
          <div class="d-flex p0">
            <div class="p-0 mr4">
              <div
                class="langToggle"
                @click="changeLang('en')"
                :class="{ langToggleActive: locale == 'en' }"
              >
                <div class="lngText">EN</div>
              </div>
            </div>

            <div class="p-0 mr4">
              <div
                class="langToggle"
                @click="changeLang('de')"
                :class="{ langToggleActive: locale == 'de' }"
              >
                <div class="lngText">DE</div>
              </div>
            </div>

            <div class="p-0">
              <div
                class="langToggleCn"
                @click="changeLang('cn')"
                :class="{ langToggleCnActive: locale == 'cn' }"
              >
                <div class="lngText">中文</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="!isMobile">
      <transition-group
        appear
        v-on:before-enter="videoBeforeEnter"
        v-on:enter="videoEnter"
        v-on:leave="videoLeave"
      >
        <video
          id="videoBG"
          autoplay
          muted
          loop
          key="particleLoop"
          data-index="1"
          v-if="showParticleLoop"
        >
          <source src="/assets/ui/particle_loop.mp4" type="video/mp4" />
          <source src="/assets/ui/particle_loop.webm" type="video/webm" />
        </video>

        <video
          id="videoBG"
          autoplay
          loop
          key="fallbackVideo"
          data-index="2"
          v-if="!showParticleLoop"
        >
          <source
            src="/assets/ui/fallback/Fallback_Video_V11.mp4"
            type="video/mp4"
          />
          <source
            src="/assets/ui/fallback/Fallback_Video_V11.webm"
            type="video/webm"
          />
        </video>
      </transition-group>
    </div>
    <div v-if="isMobile" id="videoBGMobile">
      <video id="videoMobile" muted>
        <source
          src="/assets/ui/fallback/Fallback_Video_V11.mp4"
          type="video/mp4"
        />
        <source
          src="/assets/ui/fallback/Fallback_Video_V11.webm"
          type="video/webm"
        />
      </video>
    </div>

    <!-- MODAL -->

    <transition
      appear
      v-on:before-enter="fallbackInnerBvE"
      v-on:enter="fallbackInnerE"
      v-on:leave="fallbackInnerLv"
    >
      <div id="fallBackInnerOverlay" v-if="showFallbackOverlay">
        <div
          class="p-0 d-flex align-items-center"
          style="background-color: rgba(255, 255, 255, 0); min-height: 60px"
          id="_formHeader"
        >
          <div class="p-0 ml15 contactHeader mt10 mb5 flex-fill mr15">
            {{ fallback_locale.home_fallback_hl_1 }}
          </div>
        </div>

        <div
          v-wave
          class="d-flex p0 align-items-center"
          style="background-color: rgba(255, 255, 255, 0.35); cursor: pointer"
          :class="{
            deskHighlightHeight: !isMobile,
            mobileHighlightHeight: isMobile,
          }"
          @click="hideFallbackVideo"
        >
          <div class="p-0 ml15 mt3">
            <PlayIconMobile v-if="isMobile" />
            <PlayIcon v-else />
          </div>
          <div
            class="p-0 ml15 mr10 flex-fill"
            :class="{
              highlightLabelDesk: !isMobile,
              highlightLabelMobile: isMobile,
            }"
          >
            {{ fallback_locale.home_fallback_button_1 }}
          </div>
        </div>

        <div
          class="d-flex flex-column _formHolder ml15 mr15"
          :class="{
            fontMobile: isMobile === true,
            fontDesk: isMobile === false,
          }"
        >
          <div class="p-0 flex-fill mt15">
            {{ fallback_locale.home_fallback_copy_1 }}
          </div>

          <div class="p-0 d-flex flex-fill mt15 mb20 justify-content-center">
            <div
              class="contact-next-button contextNextClickable"
              @click="openCompanyTapped"
            >
              <div class="contact-next-button-text">
                {{ fallback_locale.home_fallback_button_2 }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>

    <!-- END MODAL -->
    <div
      id="closeButtonHolder"
      @click="showFallbackVideo"
      v-if="!showParticleLoop && !isMobile"
    ></div>
    <div
      id="playButtonHolder"
      v-if="!isMobile"
      :class="{
        playEventsDisabled: playBtnEvents == false,
        playEventsEnabled: playBtnEvents == true,
      }"
      @mouseover="playBtntOver"
      @mouseleave="playBtntOut"
      @click="hideFallbackVideo"
    >
      <div id="playBtnIconPassive"></div>
      <div id="playBtnCopy">
        <div class="d-flex align-items-center" style="height: 100%">
          <div class="p-0 mt3 mr5">
            {{ fallback_locale.home_fallback_hover_1 }}
          </div>
          <div class="p-0 mt5">
            <img src="/assets/ui/fallback/PlayActive.svg" alt="" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { TweenVars, MouseMoveTween } from "@/utils/tweenTime.js";
import { gsap } from "gsap";
import { EB, EB_ACTION } from "@/utils/EB";
import { mapActions } from "vuex";
import { mapGetters } from "vuex";
import PlayIcon from "@/components/icons/PlayIcon.vue";
import PlayIconMobile from "@/components/icons/PlayIconMobile.vue";

export default {
  name: "Fallback",
  components: { PlayIcon, PlayIconMobile },

  data() {
    return {
      moveTimeout: null,
      fadeOutTimeOut: null,
      showParticleLoop: true,
      iconSource: "",
      _x: 0,
      _y: 0,
      viewportHeight: 1,
      viewportWidth: 1,
      playButton: null,
      closeButton: null,

      showFallbackOverlay: false,
      playBtnEvents: true,
      locale: "de",
    };
  },

  created() {
    var userLang = navigator.language || navigator.userLanguage;
    userLang = userLang.split("-")[0];
    let _lang = userLang == "zh" ? "cn" : userLang;
    this.locale = _lang;
    this.updateViewportSize();
    this.getAndSetFallbackLang(this.locale);
  },
  unmounted() {
    document.removeEventListener("mousemove", this.onMouseMove);
    /* window.removeEventListener('resize', this.updateViewportSize) */
    EB.$off(EB_ACTION.WIN_RESIZE);
  },
  mounted() {
    EB.$on(EB_ACTION.WIN_RESIZE, () => {
      this.updateViewportSize();
    });
    const frameDesk = document.getElementById("iframeDesk");
    if (frameDesk) {
      frameDesk.style.display = "none";
    }

    document.addEventListener("mousemove", this.onMouseMove);
    /* window.addEventListener('resize', this.updateViewportSize) */
    /* this.fadeOverlay(true) */
    setTimeout(() => {
      this.showFallbackOverlay = true;
    }, 1000);
  },
  methods: {
    ...mapActions(["getAndSetFallbackLang"]),
    changeLang(lng) {
      if (lng != this.locale) {
        this.locale = lng;
        this.getAndSetFallbackLang(lng);
      }
    },

    openCompanyTapped() {
      let url = this.fallback_locale.home_fallback_button_2_link;
      var a = document.createElement("a");
      a.href = url;
      a.target = "_blank";
      a.click();
    },
    fallbackInnerBvE: function (el) {
      const _width = this.isMobile ? window.innerWidth - 30 : 480;
      const _left = this.isMobile ? 15 : window.innerWidth / 2 - _width / 2;
      el.style.opacity = 0;
      el.style.width = _width + "px";
      el.style.transform = "translate(" + _left + "px, 40px)";
      /* el.style.transform = 'translateY(40px)'; */
    },
    fallbackInnerE: function (el) {
      const _width = this.isMobile ? window.innerWidth - 30 : 480;
      gsap.to(el, {
        opacity: 1, //x: _left,
        y: 0,
        width: _width,
        duration: TweenVars.duration,
        ease: TweenVars.ease,
      });
      /* onComplete: done,  */
    },
    fallbackInnerLv(el) {
      gsap.to(el, {
        opacity: 0, //x: _left,
        y: 40,
        duration: TweenVars.duration,
        ease: TweenVars.ease,
      });
      /* onComplete: () => {  done() }, */
    },
    playBtntOver() {
      if (this.isPhoneOrTabletDevice) {
        return;
      }
      const ele5 = document.getElementById("playButtonHolder");
      if (!ele5) {
        return;
      }
      var _width = 298;
      if (this.locale == "cn") {
        _width = 214;
      } else if (this.locale == "en") {
        _width = 320;
      }
      gsap.to(ele5, {
        // cn = 214
        width: _width, //298,
        //  x:- (149-30),
        x: -(_width / 2 - 30),
        duration: MouseMoveTween.duration,
        ease: MouseMoveTween.ease,
      });
      gsap.to("#playBtnIconPassive", {
        opacity: 0,
        duration: MouseMoveTween.duration,
        ease: MouseMoveTween.ease,
      });
      gsap.to("#playBtnCopy", {
        opacity: 1,
        // delay:0.25,
        duration: MouseMoveTween.duration,
        ease: MouseMoveTween.ease,
      });
    },
    playBtntOut() {
      if (this.isPhoneOrTabletDevice) {
        return;
      }
      const ele5 = document.getElementById("playButtonHolder");
      if (!ele5) {
        return;
      }

      gsap.to(ele5, {
        width: 60,
        x: 0,
        duration: MouseMoveTween.duration,
        ease: MouseMoveTween.ease,
      });
      gsap.to("#playBtnIconPassive", {
        opacity: 1, //delay:0.25,
        duration: MouseMoveTween.duration,
        ease: MouseMoveTween.ease,
      });
      gsap.to("#playBtnCopy", {
        opacity: 0,
        duration: MouseMoveTween.duration,
        ease: MouseMoveTween.ease,
      });
    },
    onMouseMove(ev) {
      this._x = ev.clientX;
      this._y = ev.clientY;
      if (this.showParticleLoop) {
        return;
      }
      if (this.moveTimeout) {
        clearTimeout(this.moveTimeout);
      }
      this.moveTimeout = setTimeout(() => {
        this.updateButtonPostion();
      }, 95);
      if (this.fadeOutTimeOut) {
        clearTimeout(this.fadeOutTimeOut);
      }
      this.fadeOutTimeOut = setTimeout(() => {
        this.buttonFadeOut();
      }, 2000);
    },
    updateButtonPostion() {
      const ele2 = document.getElementById("closeButtonHolder");
      if (!ele2) {
        return;
      }
      gsap.to(ele2, {
        x: this._x - 30,
        y: this._y - 30,
        opacity: 1,
        duration: MouseMoveTween.duration,
        ease: MouseMoveTween.ease,
      });
    },
    buttonFadeOut() {
      const ele3 = document.getElementById("closeButtonHolder");
      if (!ele3) {
        return;
      }
      gsap.to(ele3, {
        opacity: 0,
        delay: 0.25,
        duration: MouseMoveTween.duration,
        ease: MouseMoveTween.ease,
      });
    },
    updateViewportSize() {
      this.viewportHeight = window.innerHeight;
      this.viewportWidth = window.innerWidth;

      const _width = this.isMobile ? window.innerWidth - 30 : 480;
      const _left = this.isMobile ? 15 : window.innerWidth / 2 - _width / 2;
      const __win = document.getElementById("fallBackInnerOverlay");
      if (!__win) {
        return;
      }
      gsap.to(__win, {
        x: _left,
        width: _width,
        duration: TweenVars.duration,
        ease: TweenVars.ease,
      });
    },
    videoBeforeEnter: function (el) {
      el.style.opacity = 0;
      /* el.style.transform = this.isInitial === true ? 'translateX(0px)' : 'translateX(40px)'; */
    },
    videoEnter: function (el, done) {
      gsap.to(el, {
        opacity: 1,
        duration: TweenVars.duration,
        onComplete: done,
        ease: TweenVars.ease,
      });
    },
    videoLeave: function (el, done) {
      gsap.to(el, {
        opacity: 0,
        delay: 0.7,
        duration: TweenVars.duration,
        ease: TweenVars.ease,
        onComplete: () => {
          done();
        },
      });
    },
    showFallbackVideo() {
      this.playBtnEvents = true;
      this.showParticleLoop = true;
      this.showFallbackOverlay = true;
    },
    hideFallbackVideo() {
      if (this.isPhoneOrTabletDevice) {
        document.getElementById("videoMobile").play();
        return;
      }
      gsap.killTweensOf("#playButtonHolder");
      this.playBtnEvents = false;
      setTimeout(() => {
        this.showParticleLoop = false;
      }, 900);
      setTimeout(() => {
        this.closeButton = this.$refs.clBtnRef;
      }, 1000);

      this.showFallbackOverlay = false;
    },
  },
  computed: {
    ...mapGetters(["isMobile", "fallback_locale", "isPhoneOrTabletDevice"]),
  },
};
</script>

<style lang="scss" scoped>
#video-viewport {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: -1;
}
video {
  display: block;
  width: 100%;
  height: auto;
}

.fullsize-video-bg {
  height: 100%;
  overflow: hidden;
}

.fullsize-video-bg .inner {
  display: table;
  width: 100%;
  max-width: 24em;
  height: 100%;
  margin: 0 auto;
  padding: 0;
  position: relative;
  z-index: 2;
  text-shadow: 0 1px 5px rgba(0, 0, 0, 0.5);
}
.fullsize-video-bg .inner > div {
  text-align: center;
  display: table-cell;
  vertical-align: middle;
  padding: 0 2em;
}

h1 {
  font-family: "Raleway", sans-serif;
  font-size: 3em;
  line-height: 0.9;
  font-weight: 900;
  letter-spacing: -0.025em;
  text-transform: uppercase;
  margin-bottom: 0.5em;
}
h1 + p {
  font-family: "Shadows Into Light Two", cursive;
  font-size: 1.5em;
}

#fallbackWin {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}
#videoBG {
  position: absolute;

  z-index: -1;
  top: 0;
  left: 0;

  width: 100%;
  height: 100%;
}
@media (min-aspect-ratio: 16/9) {
  #videoBG {
    width: 100%;
    height: auto;
  }
}
@media (max-aspect-ratio: 16/9) {
  #videoBG {
    width: auto;
    height: 100%;
  }
}
@media (max-width: 767px) {
  #videoBG {
    display: none;
    /* height:100vh;
      width:120vw; */
  }
}
#videoBGMobile {
  position: absolute;
  width: 100%;
  height: 100%;
  background-image: url(../assets/ui/fallback/fallback_mobile_bg.jpg);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
#videoMobile {
  position: absolute;
  left: 0px;
  top: 0px;
  /* right: 0px; */
  /* height: 100%; */
  width: 100%;
  opacity: 0;
}
#videoMobile video {
  object-fit: contain;
}
#bgVideoContainer {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
  z-index: -100;
  border: 1px solid deeppink;
  /* min-width: 100vw;
    min-height: 100vh; */
}
.fullscreen-bg__video {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  min-width: 100vw;
  min-height: 100vh;
  /* width: 100%;
    height: 100%; */
}

#closeButtonHolder {
  position: absolute;
  cursor: pointer;
  width: 60px;
  opacity: 0;
  height: 60px;
  transform: translate(50%, 50%);
  background-color: #000;
  border-radius: 50%;
  background-image: url(../assets/ui/fallback/close.svg);
  background-position: center;
  background-repeat: no-repeat;
}
#fallBackInnerOverlay {
  position: fixed;
  z-index: 999 !important;
  width: 200px;
  height: auto !important;
  left: 0;

  /* margin-top: 60px; */
  opacity: 0;
  background-color: rgba(255, 255, 255, 0.45);
  backdrop-filter: blur(5px);
  color: #636363;
  font-family: "Univers LT Std";
  /* padding: 0 0 1vh 0; */
}
@media (orientation: portrait) {
  #fallBackInnerOverlay {
    bottom: 25px;
  }
}
@media (orientation: landscape) {
  #fallBackInnerOverlay {
    top: 50%;
    margin-top: 60px;
  }
}
.playEventsDisabled {
  pointer-events: none !important;
  opacity: 0;
  cursor: none;
  transition: opacity 0.35s ease-in-out;
}
.playEventsEnabled {
  pointer-events: all !important;
  opacity: 1;
  cursor: pointer;
  transition: opacity 0.35s ease-in-out;
}
#playButtonHolder {
  overflow: hidden;
  position: fixed;
  width: 60px;

  height: 60px;
  top: 50% !important;
  left: 50% !important;
  margin-left: -30px !important;
  margin-top: -30px !important;
  background-color: #636363;
  border-radius: 30px;
}
#playBtnCopy {
  position: absolute;
  width: auto;
  min-width: 320px;
  height: 60px;
  /* padding-top: 22px; */
  padding-left: 15px;
  opacity: 0;
  color: #fff;
  user-select: none;
  pointer-events: none;
}
#playBtnIconPassive {
  position: absolute;
  width: 60px;
  height: 60px;
  opacity: 1;
  background-image: url(../assets/ui/fallback/Play.svg);
  background-position: center;
  background-repeat: no-repeat;
  user-select: none;
  pointer-events: none;
}
#topDropShadow {
  position: fixed;
  width: 100%;
  height: 30px;
  left: 0;
  top: -30px;
  background-color: rgba(255, 0, 255, 0.2);
  -webkit-box-shadow: 0 10px 60px rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 0 10px 60px rgba(0, 0, 0, 0.5);
  box-shadow: 0 10px 60px rgba(0, 0, 0, 0.5);
}
.topBar {
  position: fixed;
  left: 0;
  top: 0px;
  height: 30px;
  width: 100%;
  opacity: 1;
  background-color: rgba(255, 255, 255, 0.3) !important;
  transition: all 0.35s ease-in-out;
  z-index: 9999;
}
.topBarOut {
  position: fixed;
  left: 0;
  opacity: 0;
  top: -90px !important;
  height: 30px;
  width: 100%;

  transition: all 0.35s ease-in-out;
}
.langToggle {
  width: 28px;
  height: 24px !important;
  overflow: hidden;

  cursor: pointer;
  border-radius: 2px;
  background-color: rgba(255, 255, 255, 0);
  transition: all 0.35s ease-in-out;
  color: #fff;
  font-family: "Univers LT Std";
  font-size: 14px;
  line-height: 14px;
  text-align: center;
  /* margin-top: 3px;
  padding-top: 3px;
  margin-bottom: 4px;*/
}
.lngText {
  padding-top: 7px;
  font-size: 14px;
  font-weight: 600 !important;
  line-height: 14px;
}
.langToggle:hover {
  background-color: rgba(255, 255, 255, 0.35);
  transition: all 0.35s ease-in-out;
}
.langToggleActive {
  /*font-family: 'Univers LT Bold';*/
  background-color: rgba(255, 255, 255, 0.35);
  transition: all 0.35s ease-in-out;
}
.langToggleCn {
  width: 40px;
  height: 24px !important;
  overflow: hidden;
  font-size: 14px;
  cursor: pointer;
  border-radius: 2px;
  background-color: rgba(255, 255, 255, 0);
  transition: all 0.35s ease-in-out;
  color: #fff;
  font-family: "Univers LT Std";
  text-align: center;
  /*  margin-top: 3px;
  padding-top: 3px;
  margin-bottom: 4px;*/
}
.langToggleCn:hover {
  background-color: rgba(255, 255, 255, 0.35);
  transition: all 0.35s ease-in-out;
}
.langToggleCnActive {
  /* font-family: 'Univers LT Bold';*/
  background-color: rgba(255, 255, 255, 0.35);
  transition: all 0.35s ease-in-out;
}
.deskLogoPos {
  margin-top: 5px;
  margin-left: 80px;
}
.mobileLogoPos {
  margin-top: 5px;
}
</style>
