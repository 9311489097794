<template>
  <svg
    width="50"
    height="50"
    viewBox="0 0 50 50"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    name="PlayIconMobile"
  >
    <path
      d="M20.5 16.7058L19.75 16.2728V17.1388V31.8612V32.7272L20.5 32.2942L33.25 24.933L34 24.5L33.25 24.067L20.5 16.7058ZM18.75 14.5407L36 24.5L18.75 34.4593L18.75 14.5407ZM49.5 25C49.5 38.531 38.531 49.5 25 49.5C11.469 49.5 0.5 38.531 0.5 25C0.5 11.469 11.469 0.5 25 0.5C38.531 0.5 49.5 11.469 49.5 25ZM25 48.5C37.9787 48.5 48.5 37.9787 48.5 25C48.5 12.0213 37.9787 1.5 25 1.5C12.0213 1.5 1.5 12.0213 1.5 25C1.5 37.9787 12.0213 48.5 25 48.5Z"
      fill="white"
      stroke="url(#paint0_linear)"
    />
    <defs>
      <linearGradient
        id="paint0_linear"
        x1="6.5"
        y1="45"
        x2="42.5"
        y2="6"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="var(--gradientFrom)" />
        <stop offset="1" stop-color="var(--gradientTo)" />
      </linearGradient>
    </defs>
  </svg>
</template>

<script>
export default {
  name: "PlayIconMobile",
};
</script>
