export const timezones_fin = [
  {
    key: "ACDT",
    title: "Australian Central Daylight  Time",
    offset: 10.5,
    offset_formarted: "( UTC +10,5 )",
    display_title: "( UTC +10,5 ) Australian Central Daylight  Time",
  },
  {
    key: "ACST",
    title: "Australian Central Standard Time",
    offset: 9.5,
    offset_formarted: "( UTC +9,5 )",
    display_title: "( UTC +9,5 ) Australian Central Standard Time",
  },
  {
    key: "ACT",
    title: "Acre Time",
    offset: -5,
    offset_formarted: "( UTC -5 )",
    display_title: "( UTC -5 ) Acre Time",
  },
  {
    key: "ADT",
    title: "Atlantic Daylight Time",
    offset: -3,
    offset_formarted: "( UTC -3 )",
    display_title: "( UTC -3 ) Atlantic Daylight Time",
  },
  {
    key: "AEDT",
    title: "Australian Eastern Daylight  Time",
    offset: 11,
    offset_formarted: "( UTC  +11 )",
    display_title: "( UTC  +11 ) Australian Eastern Daylight  Time",
  },
  {
    key: "AEST",
    title: "Australian Eastern Standard Time",
    offset: 10,
    offset_formarted: "( UTC  +10 )",
    display_title: "( UTC  +10 ) Australian Eastern Standard Time",
  },
  {
    key: "AFT",
    title: "Afghanistan Time",
    offset: 4.5,
    offset_formarted: "( UTC  +4,5 )",
    display_title: "( UTC  +4,5 ) Afghanistan Time",
  },
  {
    key: "AKDT",
    title: "Alaska Daylight Time",
    offset: -8,
    offset_formarted: "( UTC  -8 )",
    display_title: "( UTC  -8 ) Alaska Daylight Time",
  },
  {
    key: "AKST",
    title: "Alaska Standard Time",
    offset: -9,
    offset_formarted: "( UTC  -9 )",
    display_title: "( UTC  -9 ) Alaska Standard Time",
  },
  {
    key: "AMST",
    title: "Amazon Summer Time (Brazil)",
    offset: -3,
    offset_formarted: "( UTC  -3 )",
    display_title: "( UTC  -3 ) Amazon Summer Time (Brazil)",
  },
  {
    key: "AMT",
    title: "Amazon Time (Brazil)",
    offset: -4,
    offset_formarted: "( UTC -4 )",
    display_title: "( UTC -4 ) Amazon Time (Brazil)",
  },
  {
    key: "AMT",
    title: "Armenia Time",
    offset: 4,
    offset_formarted: "( UTC  +4 )",
    display_title: "( UTC  +4 ) Armenia Time",
  },
  {
    key: "ART",
    title: "Argentina Time",
    offset: -3,
    offset_formarted: "( UTC  -3 )",
    display_title: "( UTC  -3 ) Argentina Time",
  },
  {
    key: "AST",
    title: "Arabia Standard Time",
    offset: 3,
    offset_formarted: "( UTC  +3 )",
    display_title: "( UTC  +3 ) Arabia Standard Time",
  },
  {
    key: "AST",
    title: "Atlantic Standard Time",
    offset: -4,
    offset_formarted: "( UTC  -4 )",
    display_title: "( UTC  -4 ) Atlantic Standard Time",
  },
  {
    key: "AWST",
    title: "Australian Western Standard Time",
    offset: 8,
    offset_formarted: "( UTC  +8 )",
    display_title: "( UTC  +8 ) Australian Western Standard Time",
  },
  {
    key: "AZOST",
    title: "Azores Summer Time",
    offset: 0,
    offset_formarted: "( UTC )",
    display_title: "( UTC ) Azores Summer Time",
  },
  {
    key: "AZOT",
    title: "Azores Standard Time",
    offset: -1,
    offset_formarted: "( UTC -1 )",
    display_title: "( UTC -1 ) Azores Standard Time",
  },
  {
    key: "AZT",
    title: "Azerbaijan Time",
    offset: 4,
    offset_formarted: "( UTC  +4 )",
    display_title: "( UTC  +4 ) Azerbaijan Time",
  },
  {
    key: "BDT",
    title: "Brunei Time",
    offset: 8,
    offset_formarted: "( UTC +8 )",
    display_title: "( UTC +8 ) Brunei Time",
  },
  {
    key: "BIOT",
    title: "British Indian Ocean Time",
    offset: 6,
    offset_formarted: "( UTC  +6 )",
    display_title: "( UTC  +6 ) British Indian Ocean Time",
  },
  {
    key: "BIT",
    title: "Baker Island Time",
    offset: -12,
    offset_formarted: "( UTC  -12 )",
    display_title: "( UTC  -12 ) Baker Island Time",
  },
  {
    key: "BOT",
    title: "Bolivia Time",
    offset: -4,
    offset_formarted: "( UTC  -4 )",
    display_title: "( UTC  -4 ) Bolivia Time",
  },
  {
    key: "BRST",
    title: "Brasilia Summer Time",
    offset: -2,
    offset_formarted: "( UTC  -2 )",
    display_title: "( UTC  -2 ) Brasilia Summer Time",
  },
  {
    key: "BRT",
    title: "Brasilia Time",
    offset: -3,
    offset_formarted: "( UTC  -3 )",
    display_title: "( UTC  -3 ) Brasilia Time",
  },
  {
    key: "BST",
    title: "Bangladesh Standard Time",
    offset: 6,
    offset_formarted: "( UTC  +6 )",
    display_title: "( UTC  +6 ) Bangladesh Standard Time",
  },
  {
    key: "BST",
    title: "Bougainville Standard Time",
    offset: 11,
    offset_formarted: "( UTC  +11 )",
    display_title: "( UTC  +11 ) Bougainville Standard Time",
  },
  {
    key: "BST",
    title: "British Summer Time",
    offset: 1,
    offset_formarted: "( UTC  +1 )",
    display_title: "( UTC  +1 ) British Summer Time",
  },
  {
    key: "BTT",
    title: "Bhutan Time",
    offset: 6,
    offset_formarted: "( UTC  +6 )",
    display_title: "( UTC  +6 ) Bhutan Time",
  },
  {
    key: "CAT",
    title: "Central Africa Time",
    offset: 2,
    offset_formarted: "( UTC  +2 )",
    display_title: "( UTC  +2 ) Central Africa Time",
  },
  {
    key: "CCT",
    title: "Cocos Islands Time",
    offset: 6.5,
    offset_formarted: "( UTC  +6,5 )",
    display_title: "( UTC  +6,5 ) Cocos Islands Time",
  },
  {
    key: "CDT",
    title: "Central Daylight Time (North America)",
    offset: -5,
    offset_formarted: "( UTC  -5 )",
    display_title: "( UTC  -5 ) Central Daylight Time (North America)",
  },
  {
    key: "CDT",
    title: "Cuba Daylight Time",
    offset: -4,
    offset_formarted: "( UTC  -4 )",
    display_title: "( UTC  -4 ) Cuba Daylight Time",
  },
  {
    key: "CEST",
    title: "Central European Summer Time",
    offset: 2,
    offset_formarted: "( UTC +2 )",
    display_title: "( UTC +2 )  Central European Summer Time",
  },
  {
    key: "CET",
    title: "Central European Time",
    offset: 1,
    offset_formarted: "( UTC +1 )",
    display_title: "( UTC +1 )  Central European Time",
  },

  {
    key: "CHOT",
    title: "Choibalsan Standard Time",
    offset: 8,
    offset_formarted: "( UTC +8 )",
    display_title: "( UTC +8 )  Choibalsan Standard Time",
  },
  {
    key: "CHOST",
    title: "Choibalsan Summer Time",
    offset: 9,
    offset_formarted: "( UTC +9 )",
    display_title: "( UTC +9 )  Choibalsan Summer Time",
  },
  {
    key: "CHST",
    title: "Chamorro Standard Time",
    offset: 10,
    offset_formarted: "( UTC +10 )",
    display_title: "( UTC +10 )  Chamorro Standard Time",
  },
  {
    key: "CHUT",
    title: "Chuuk Time",
    offset: 10,
    offset_formarted: "( UTC +10 )",
    display_title: "( UTC +10 )  Chuuk Time",
  },
  {
    key: "CIST",
    title: "Clipperton Island Standard Time",
    offset: -8,
    offset_formarted: "( UTC -8 )",
    display_title: "( UTC -8 )  Clipperton Island Standard Time",
  },
  {
    key: "CIT",
    title: "Central Indonesia Time",
    offset: 8,
    offset_formarted: "( UTC +8 )",
    display_title: "( UTC +8 )  Central Indonesia Time",
  },
  {
    key: "CKT",
    title: "Cook Island Time",
    offset: -10,
    offset_formarted: "( UTC -10 )",
    display_title: "( UTC -10 )  Cook Island Time",
  },
  {
    key: "CLST",
    title: "Chile Summer Time",
    offset: -3,
    offset_formarted: "( UTC -3 )",
    display_title: "( UTC -3 )  Chile Summer Time",
  },
  {
    key: "CLT",
    title: "Chile Standard Time",
    offset: -4,
    offset_formarted: "( UTC -4 )",
    display_title: "( UTC -4 )  Chile Standard Time",
  },
  {
    key: "COST",
    title: "Colombia Summer Time",
    offset: -4,
    offset_formarted: "( UTC -4 )",
    display_title: "( UTC -4 )  Colombia Summer Time",
  },
  {
    key: "COT",
    title: "Colombia Time",
    offset: -5,
    offset_formarted: "( UTC -5 )",
    display_title: "( UTC -5 )  Colombia Time",
  },
  {
    key: "CST",
    title: "Central Standard Time (North America)",
    offset: -6,
    offset_formarted: "( UTC -6 )",
    display_title: "( UTC -6 )  Central Standard Time (North America)",
  },
  {
    key: "CST",
    title: "China Standard Time",
    offset: 8,
    offset_formarted: "( UTC +8 )",
    display_title: "( UTC +8 )  China Standard Time",
    display_title_cn: "( UTC +8 ) 中国标准时间",
  },
  {
    key: "ACST",
    title: "Central Standard Time (Australia)",
    offset: 9.5,
    offset_formarted: "( UTC +9,5 )",
    display_title: "( UTC +9,5 )  Central Standard Time (Australia)",
  },
  {
    key: "ACDT",
    title: "Central Summer Time (Australia)",
    offset: 10.5,
    offset_formarted: "( UTC +10,5 )",
    display_title: "( UTC +10,5 )  Central Summer Time (Australia)",
  },
  {
    key: "CST",
    title: "Cuba Standard Time",
    offset: -5,
    offset_formarted: "( UTC -5 )",
    display_title: "( UTC -5 )  Cuba Standard Time",
  },
  {
    key: "CT",
    title: "China time",
    offset: 8,
    offset_formarted: "( UTC +8 )",
    display_title: "( UTC +8 )  China time",
    display_title_cn: "( UTC +8 ) 中国时间",
  },
  {
    key: "CVT",
    title: "Cape Verde Time",
    offset: -1,
    offset_formarted: "( UTC -1 )",
    display_title: "( UTC -1 )  Cape Verde Time",
  },
  {
    key: "CXT",
    title: "Christmas Island Time",
    offset: 7,
    offset_formarted: "( UTC +7 )",
    display_title: "( UTC +7 )  Christmas Island Time",
  },
  {
    key: "DAVT",
    title: "Davis Time",
    offset: 7,
    offset_formarted: "( UTC +7 )",
    display_title: "( UTC +7 )  Davis Time",
  },
  {
    key: "DDUT",
    title: "Dumont d'Urville Time",
    offset: 10,
    offset_formarted: "( UTC +10 )",
    display_title: "( UTC +10 )  Dumont d'Urville Time",
  },
  {
    key: "EASST",
    title: "Easter Island Summer Time",
    offset: -5,
    offset_formarted: "( UTC -5 )",
    display_title: "( UTC -5 )  Easter Island Summer Time",
  },
  {
    key: "EAST",
    title: "Easter Island Standard Time",
    offset: -6,
    offset_formarted: "( UTC -6 )",
    display_title: "( UTC -6 )  Easter Island Standard Time",
  },
  {
    key: "EAT",
    title: "East Africa Time",
    offset: 3,
    offset_formarted: "( UTC +3 )",
    display_title: "( UTC +3 )  East Africa Time",
  },
  {
    key: "ECT",
    title: "Eastern Caribbean Time",
    offset: -4,
    offset_formarted: "( UTC -4 )",
    display_title: "( UTC -4 )  Eastern Caribbean Time",
  },
  {
    key: "ECT",
    title: "Ecuador Time",
    offset: -5,
    offset_formarted: "( UTC -5 )",
    display_title: "( UTC -5 )  Ecuador Time",
  },
  {
    key: "EDT",
    title: "Eastern Daylight Time (North America)",
    offset: -4,
    offset_formarted: "( UTC -4 )",
    display_title: "( UTC -4 )  Eastern Daylight Time (North America)",
  },
  {
    key: "AEDT",
    title: "Eastern Summer Time (Australia)",
    offset: 11,
    offset_formarted: "( UTC +11 )",
    display_title: "( UTC +11 )  Eastern Summer Time (Australia)",
  },
  {
    key: "EEST",
    title: "Eastern European Summer Time",
    offset: 3,
    offset_formarted: "( UTC +3 )",
    display_title: "( UTC +3 )  Eastern European Summer Time",
  },
  {
    key: "EET",
    title: "Eastern European Time",
    offset: 2,
    offset_formarted: "( UTC +2 )",
    display_title: "( UTC +2 )  Eastern European Time",
  },
  {
    key: "EGST",
    title: "Eastern Greenland Summer Time",
    offset: 0,
    offset_formarted: "( UTC )",
    display_title: "( UTC )  Eastern Greenland Summer Time",
  },
  {
    key: "EGT",
    title: "Eastern Greenland Time",
    offset: -1,
    offset_formarted: "( UTC -1 )",
    display_title: "( UTC -1 )  Eastern Greenland Time",
  },
  {
    key: "EIT",
    title: "Eastern Indonesian Time",
    offset: 9,
    offset_formarted: "( UTC +9 )",
    display_title: "( UTC +9 )  Eastern Indonesian Time",
  },
  {
    key: "EST",
    title: "Eastern Standard Time (North America)",
    offset: -5,
    offset_formarted: "( UTC -5 )",
    display_title: "( UTC -5 )  Eastern Standard Time (North America)",
  },
  {
    key: "AEST",
    title: "Eastern Standard Time (Australia)",
    offset: 10,
    offset_formarted: "( UTC +10 )",
    display_title: "( UTC +10 )  Eastern Standard Time (Australia)",
  },
  {
    key: "FET",
    title: "Further-eastern European Time",
    offset: 3,
    offset_formarted: "( UTC +3 )",
    display_title: "( UTC +3 )  Further-eastern European Time",
  },
  {
    key: "FJT",
    title: "Fiji Time",
    offset: 12,
    offset_formarted: "( UTC +12 )",
    display_title: "( UTC +12 )  Fiji Time",
  },
  {
    key: "FKST",
    title: "Falkland Islands Summer Time",
    offset: -3,
    offset_formarted: "( UTC -3 )",
    display_title: "( UTC -3 )  Falkland Islands Summer Time",
  },
  {
    key: "FKT",
    title: "Falkland Islands Time",
    offset: -4,
    offset_formarted: "( UTC -4 )",
    display_title: "( UTC -4 )  Falkland Islands Time",
  },

  {
    key: "GALT",
    title: "Galapagos Time",
    offset: -6,
    offset_formarted: "( UTC -6 )",
    display_title: "( UTC -6 )  Galapagos Time",
  },

  {
    key: "GET",
    title: "Georgia Standard Time",
    offset: 4,
    offset_formarted: "( UTC +4 )",
    display_title: "( UTC +4 )  Georgia Standard Time",
  },
  {
    key: "GFT",
    title: "French Guiana Time",
    offset: -3,
    offset_formarted: "( UTC -3 )",
    display_title: "( UTC -3 )  French Guiana Time",
  },
  {
    key: "GILT",
    title: "Gilbert Island Time",
    offset: 12,
    offset_formarted: "( UTC +12 )",
    display_title: "( UTC +12 )  Gilbert Island Time",
  },
  {
    key: "GIT",
    title: "Gambier Island Time",
    offset: -9,
    offset_formarted: "( UTC -9 )",
    display_title: "( UTC -9 )  Gambier Island Time",
  },
  {
    key: "GMT",
    title: "Greenwich Mean Time",
    offset: 0,
    offset_formarted: "( UTC )",
    display_title: "( UTC )  Greenwich Mean Time",
  },
  {
    key: "GST",
    title: "South Georgia and the South Sandwich Islands",
    offset: -2,
    offset_formarted: "( UTC -2 )",
    display_title: "( UTC -2 )  South Georgia and the South Sandwich Islands",
  },
  {
    key: "GST",
    title: "Gulf Standard Time",
    offset: 4,
    offset_formarted: "( UTC +4 )",
    display_title: "( UTC +4 )  Gulf Standard Time",
  },
  {
    key: "GYT",
    title: "Guyana Time",
    offset: -4,
    offset_formarted: "( UTC -4 )",
    display_title: "( UTC -4 )  Guyana Time",
  },
  {
    key: "HADT",
    title: "Hawaii-Aleutian Daylight Time",
    offset: -9,
    offset_formarted: "( UTC -9 )",
    display_title: "( UTC -9 )  Hawaii-Aleutian Daylight Time",
  },
  {
    key: "HAST",
    title: "Hawaii-Aleutian Standard Time",
    offset: -10,
    offset_formarted: "( UTC -10 )",
    display_title: "( UTC -10 )  Hawaii-Aleutian Standard Time",
  },
  {
    key: "HKT",
    title: "Hong Kong Time",
    offset: 8,
    offset_formarted: "( UTC +8 )",
    display_title: "( UTC +8 )  Hong Kong Time",
    display_title_cn: "( UTC +8 ) 香港时间",
  },
  {
    key: "HMT",
    title: "Heard and McDonald Islands Time",
    offset: 5,
    offset_formarted: "( UTC +5 )",
    display_title: "( UTC +5 )  Heard and McDonald Islands Time",
  },
  {
    key: "HOVST",
    title: "Khovd Summer Time",
    offset: 8,
    offset_formarted: "( UTC +8 )",
    display_title: "( UTC +8 )  Khovd Summer Time",
  },
  {
    key: "HOVT",
    title: "Khovd Standard Time",
    offset: 7,
    offset_formarted: "( UTC +7 )",
    display_title: "( UTC +7 )  Khovd Standard Time",
  },
  {
    key: "ICT",
    title: "Indochina Time",
    offset: 7,
    offset_formarted: "( UTC +7 )",
    display_title: "( UTC +7 )  Indochina Time",
  },
  {
    key: "IDT",
    title: "Israel Daylight Time",
    offset: 3,
    offset_formarted: "( UTC +3 )",
    display_title: "( UTC +3 )  Israel Daylight Time",
  },
  {
    key: "IOT",
    title: "Indian Ocean Time",
    offset: 3,
    offset_formarted: "( UTC +3 )",
    display_title: "( UTC +3 )  Indian Ocean Time",
  },
  {
    key: "IRDT",
    title: "Iran Daylight Time",
    offset: 4.5,
    offset_formarted: "( UTC +4,5 )",
    display_title: "( UTC +4,5 )  Iran Daylight Time",
  },
  {
    key: "IRKT",
    title: "Irkutsk Time",
    offset: 8,
    offset_formarted: "( UTC +8 )",
    display_title: "( UTC +8 )  Irkutsk Time",
  },
  {
    key: "IRST",
    title: "Iran Standard Time",
    offset: 3.5,
    offset_formarted: "( UTC +3,5 )",
    display_title: "( UTC +3,5 )  Iran Standard Time",
  },
  {
    key: "IST",
    title: "Indian Standard Time",
    offset: 5.5,
    offset_formarted: "( UTC +5,5 )",
    display_title: "( UTC +5,5 )  Indian Standard Time",
  },
  {
    key: "IST",
    title: "Irish Standard Time",
    offset: 1,
    offset_formarted: "( UTC +1 )",
    display_title: "( UTC +1 )  Irish Standard Time",
  },
  {
    key: "IST",
    title: "Israel Standard Time",
    offset: 2,
    offset_formarted: "( UTC +2 )",
    display_title: "( UTC +2 )  Israel Standard Time",
  },
  {
    key: "JST",
    title: "Japan Standard Time",
    offset: 9,
    offset_formarted: "( UTC +9 )",
    display_title: "( UTC +9 )  Japan Standard Time",
  },
  {
    key: "KGT",
    title: "Kyrgyzstan time",
    offset: 6,
    offset_formarted: "( UTC +6 )",
    display_title: "( UTC +6 )  Kyrgyzstan time",
  },
  {
    key: "KOST",
    title: "Kosrae Time",
    offset: 11,
    offset_formarted: "( UTC +11 )",
    display_title: "( UTC +11 )  Kosrae Time",
  },
  {
    key: "KRAT",
    title: "Krasnoyarsk Time",
    offset: 7,
    offset_formarted: "( UTC +7 )",
    display_title: "( UTC +7 )  Krasnoyarsk Time",
  },
  {
    key: "KST",
    title: "Korea Standard Time",
    offset: 9,
    offset_formarted: "( UTC +9 )",
    display_title: "( UTC +9 )  Korea Standard Time",
  },
  {
    key: "LHST",
    title: "Lord Howe Standard Time",
    offset: 10.5,
    offset_formarted: "( UTC +10,5 )",
    display_title: "( UTC +10,5 )  Lord Howe Standard Time",
  },
  {
    key: "LHST",
    title: "Lord Howe Summer Time",
    offset: 11,
    offset_formarted: "( UTC +11 )",
    display_title: "( UTC +11 )  Lord Howe Summer Time",
  },
  {
    key: "LINT",
    title: "Line Islands Time",
    offset: 14,
    offset_formarted: "( UTC +14 )",
    display_title: "( UTC +14 )  Line Islands Time",
  },
  {
    key: "MAGT",
    title: "Magadan Time",
    offset: 12,
    offset_formarted: "( UTC +12 )",
    display_title: "( UTC +12 )  Magadan Time",
  },

  {
    key: "MDT",
    title: "Mountain Daylight Time (North America)",
    offset: -6,
    offset_formarted: "( UTC -6 )",
    display_title: "( UTC -6 )  Mountain Daylight Time (North America)",
  },
  {
    key: "MET",
    title: "Middle European Time",
    offset: 1,
    offset_formarted: "( UTC +1 )",
    display_title: "( UTC +1 )  Middle European Time",
  },
  {
    key: "MEST",
    title: "Middle European Summer Time",
    offset: 2,
    offset_formarted: "( UTC +2 )",
    display_title: "( UTC +2 )  Middle European Summer Time",
  },
  {
    key: "MHT",
    title: "Marshall Islands",
    offset: 12,
    offset_formarted: "( UTC +12 )",
    display_title: "( UTC +12 )  Marshall Islands",
  },

  {
    key: "MMT",
    title: "Myanmar Standard Time",
    offset: 6.5,
    offset_formarted: "( UTC +6,5 )",
    display_title: "( UTC +6,5 )  Myanmar Standard Time",
  },
  {
    key: "MSK",
    title: "Moscow Time",
    offset: 3,
    offset_formarted: "( UTC +3 )",
    display_title: "( UTC +3 )  Moscow Time",
  },
  {
    key: "MST",
    title: "Malaysia Standard Time",
    offset: 8,
    offset_formarted: "( UTC +8 )",
    display_title: "( UTC +8 )  Malaysia Standard Time",
  },
  {
    key: "MST",
    title: "Mountain Standard Time (North America)",
    offset: -7,
    offset_formarted: "( UTC -7 )",
    display_title: "( UTC -7 )  Mountain Standard Time (North America)",
  },
  {
    key: "MUT",
    title: "Mauritius Time",
    offset: 4,
    offset_formarted: "( UTC +4 )",
    display_title: "( UTC +4 )  Mauritius Time",
  },
  {
    key: "MVT",
    title: "Maldives Time",
    offset: 5,
    offset_formarted: "( UTC +5 )",
    display_title: "( UTC +5 )  Maldives Time",
  },
  {
    key: "MYT",
    title: "Malaysia Time",
    offset: 8,
    offset_formarted: "( UTC +8 )",
    display_title: "( UTC +8 )  Malaysia Time",
  },
  {
    key: "NCT",
    title: "New Caledonia Time",
    offset: 11,
    offset_formarted: "( UTC +11 )",
    display_title: "( UTC +11 )  New Caledonia Time",
  },
  {
    key: "NDT",
    title: "Newfoundland Daylight Time",
    offset: -2.5,
    offset_formarted: "( UTC -2,5 )",
    display_title: "( UTC -2,5 )  Newfoundland Daylight Time",
  },
  {
    key: "NFT",
    title: "Norfolk Time",
    offset: 11,
    offset_formarted: "( UTC +11 )",
    display_title: "( UTC +11 )  Norfolk Time",
  },
  {
    key: "NPT",
    title: "Nepal Time",
    offset: 5.75,
    offset_formarted: "( UTC +5,75 )",
    display_title: "( UTC +5,75 )  Nepal Time",
  },
  {
    key: "NST",
    title: "Newfoundland Standard Time",
    offset: -3.5,
    offset_formarted: "( UTC -3,5 )",
    display_title: "( UTC -3,5 )  Newfoundland Standard Time",
  },
  {
    key: "NT",
    title: "Newfoundland Time",
    offset: -3.5,
    offset_formarted: "( UTC -3,5 )",
    display_title: "( UTC -3,5 )  Newfoundland Time",
  },

  {
    key: "NZDT",
    title: "New Zealand Daylight Time",
    offset: 13,
    offset_formarted: "( UTC +13 )",
    display_title: "( UTC +13 )  New Zealand Daylight Time",
  },
  {
    key: "NZST",
    title: "New Zealand Standard Time",
    offset: 12,
    offset_formarted: "( UTC +12 )",
    display_title: "( UTC +12 )  New Zealand Standard Time",
  },
  {
    key: "OMST",
    title: "Omsk Time",
    offset: 6,
    offset_formarted: "( UTC +6 )",
    display_title: "( UTC +6 )  Omsk Time",
  },
  {
    key: "ORAT",
    title: "Oral Time",
    offset: 5,
    offset_formarted: "( UTC +5 )",
    display_title: "( UTC +5 )  Oral Time",
  },
  {
    key: "PDT",
    title: "Pacific Daylight Time (North America)",
    offset: -7,
    offset_formarted: "( UTC -7 )",
    display_title: "( UTC -7 )  Pacific Daylight Time (North America)",
  },
  {
    key: "PET",
    title: "Peru Time",
    offset: -5,
    offset_formarted: "( UTC -5 )",
    display_title: "( UTC -5 )  Peru Time",
  },
  {
    key: "PETT",
    title: "Kamchatka Time",
    offset: 12,
    offset_formarted: "( UTC +12 )",
    display_title: "( UTC +12 )  Kamchatka Time",
  },
  {
    key: "PGT",
    title: "Papua New Guinea Time",
    offset: 10,
    offset_formarted: "( UTC +10 )",
    display_title: "( UTC +10 )  Papua New Guinea Time",
  },
  {
    key: "PHOT",
    title: "Phoenix Island Time",
    offset: 13,
    offset_formarted: "( UTC +13 )",
    display_title: "( UTC +13 )  Phoenix Island Time",
  },
  {
    key: "PHT",
    title: "Philippine Time",
    offset: 8,
    offset_formarted: "( UTC +8 )",
    display_title: "( UTC +8 )  Philippine Time",
  },
  {
    key: "PKT",
    title: "Pakistan Standard Time",
    offset: 5,
    offset_formarted: "( UTC +5 )",
    display_title: "( UTC +5 )  Pakistan Standard Time",
  },
  {
    key: "PMDT",
    title: "Saint Pierre and Miquelon Daylight time",
    offset: -2,
    offset_formarted: "( UTC -2 )",
    display_title: "( UTC -2 )  Saint Pierre and Miquelon Daylight time",
  },
  {
    key: "PMST",
    title: "Saint Pierre and Miquelon Standard Time",
    offset: -3,
    offset_formarted: "( UTC -3 )",
    display_title: "( UTC -3 )  Saint Pierre and Miquelon Standard Time",
  },
  {
    key: "PONT",
    title: "Pohnpei Standard Time",
    offset: 11,
    offset_formarted: "( UTC +11 )",
    display_title: "( UTC +11 )  Pohnpei Standard Time",
  },
  {
    key: "PST",
    title: "Pacific Standard Time (North America)",
    offset: -8,
    offset_formarted: "( UTC -8 )",
    display_title: "( UTC -8 )  Pacific Standard Time (North America)",
  },
  {
    key: "PST",
    title: "Philippine Standard Time",
    offset: 8,
    offset_formarted: "( UTC +8 )",
    display_title: "( UTC +8 )  Philippine Standard Time",
  },
  {
    key: "PYST",
    title: "Paraguay Summer Time (South America)",
    offset: -3,
    offset_formarted: "( UTC -3 )",
    display_title: "( UTC -3 )  Paraguay Summer Time (South America)",
  },
  {
    key: "PYT",
    title: "Paraguay Time (South America)",
    offset: -4,
    offset_formarted: "( UTC -4 )",
    display_title: "( UTC -4 )  Paraguay Time (South America)",
  },
  {
    key: "RET",
    title: "Réunion Time",
    offset: 4,
    offset_formarted: "( UTC +4 )",
    display_title: "( UTC +4 )  Réunion Time",
  },

  {
    key: "SAKT",
    title: "Sakhalin Island time",
    offset: 11,
    offset_formarted: "( UTC +11 )",
    display_title: "( UTC +11 )  Sakhalin Island time",
  },
  {
    key: "SAMT",
    title: "Samara Time",
    offset: 4,
    offset_formarted: "( UTC +4 )",
    display_title: "( UTC +4 )  Samara Time",
  },
  {
    key: "SAST",
    title: "South African Standard Time",
    offset: 2,
    offset_formarted: "( UTC +2 )",
    display_title: "( UTC +2 )  South African Standard Time",
  },
  {
    key: "SBT",
    title: "Solomon Islands Time",
    offset: 11,
    offset_formarted: "( UTC +11 )",
    display_title: "( UTC +11 )  Solomon Islands Time",
  },
  {
    key: "SCT",
    title: "Seychelles Time",
    offset: 4,
    offset_formarted: "( UTC +4 )",
    display_title: "( UTC +4 )  Seychelles Time",
  },
  {
    key: "SGT",
    title: "Singapore Time",
    offset: 8,
    offset_formarted: "( UTC +8 )",
    display_title: "( UTC +8 )  Singapore Time",
  },
  {
    key: "SLST",
    title: "Sri Lanka Standard Time",
    offset: 5.5,
    offset_formarted: "( UTC +5,5 )",
    display_title: "( UTC +5,5 )  Sri Lanka Standard Time",
  },
  {
    key: "SRET",
    title: "Srednekolymsk Time",
    offset: 11,
    offset_formarted: "( UTC +11 )",
    display_title: "( UTC +11 )  Srednekolymsk Time",
  },
  {
    key: "SRT",
    title: "Suriname Time",
    offset: -3,
    offset_formarted: "( UTC -3 )",
    display_title: "( UTC -3 )  Suriname Time",
  },
  {
    key: "SST",
    title: "Samoa Standard Time",
    offset: -11,
    offset_formarted: "( UTC -11 )",
    display_title: "( UTC -11 )  Samoa Standard Time",
  },
  {
    key: "SST",
    title: "Singapore Standard Time",
    offset: 8,
    offset_formarted: "( UTC +8 )",
    display_title: "( UTC +8 )  Singapore Standard Time",
  },

  {
    key: "TAHT",
    title: "Tahiti Time",
    offset: -10,
    offset_formarted: "( UTC -10 )",
    display_title: "( UTC -10 )  Tahiti Time",
  },
  {
    key: "THA",
    title: "Thailand Standard Time",
    offset: 7,
    offset_formarted: "( UTC +7 )",
    display_title: "( UTC +7 )  Thailand Standard Time",
  },
  {
    key: "TFT",
    title: "Indian/Kerguelen",
    offset: 5,
    offset_formarted: "( UTC +5 )",
    display_title: "( UTC +5 )  Indian/Kerguelen",
  },
  {
    key: "TJT",
    title: "Tajikistan Time",
    offset: 5,
    offset_formarted: "( UTC +5 )",
    display_title: "( UTC +5 )  Tajikistan Time",
  },
  {
    key: "TKT",
    title: "Tokelau Time",
    offset: 13,
    offset_formarted: "( UTC +13 )",
    display_title: "( UTC +13 )  Tokelau Time",
  },
  {
    key: "TLT",
    title: "Timor Leste Time",
    offset: 9,
    offset_formarted: "( UTC +9 )",
    display_title: "( UTC +9 )  Timor Leste Time",
  },
  {
    key: "TMT",
    title: "Turkmenistan Time",
    offset: 5,
    offset_formarted: "( UTC +5 )",
    display_title: "( UTC +5 )  Turkmenistan Time",
  },
  {
    key: "TRT",
    title: "Turkey Time",
    offset: 3,
    offset_formarted: "( UTC +3 )",
    display_title: "( UTC +3 )  Turkey Time",
  },
  {
    key: "TOT",
    title: "Tonga Time",
    offset: 13,
    offset_formarted: "( UTC +13 )",
    display_title: "( UTC +13 )  Tonga Time",
  },
  {
    key: "TVT",
    title: "Tuvalu Time",
    offset: 12,
    offset_formarted: "( UTC +12 )",
    display_title: "( UTC +12 )  Tuvalu Time",
  },
  {
    key: "ULAST",
    title: "Ulaanbaatar Summer Time",
    offset: 9,
    offset_formarted: "( UTC +9 )",
    display_title: "( UTC +9 )  Ulaanbaatar Summer Time",
  },
  {
    key: "ULAT",
    title: "Ulaanbaatar Standard Time",
    offset: 8,
    offset_formarted: "( UTC +8 )",
    display_title: "( UTC +8 )  Ulaanbaatar Standard Time",
  },
  {
    key: "USZ1",
    title: "Kaliningrad Time",
    offset: 2,
    offset_formarted: "( UTC +2 )",
    display_title: "( UTC +2 )  Kaliningrad Time",
  },
  {
    key: "",
    title: "Coordinated Universal Time",
    offset: 0,
    offset_formarted: "( UTC )",
    display_title: "( UTC )  Coordinated Universal Time",
  },
  {
    key: "UYST",
    title: "Uruguay Summer Time",
    offset: -2,
    offset_formarted: "( UTC -2 )",
    display_title: "( UTC -2 )  Uruguay Summer Time",
  },
  {
    key: "UYT",
    title: "Uruguay Standard Time",
    offset: -3,
    offset_formarted: "( UTC -3 )",
    display_title: "( UTC -3 )  Uruguay Standard Time",
  },
  {
    key: "UZT",
    title: "Uzbekistan Time",
    offset: 5,
    offset_formarted: "( UTC +5 )",
    display_title: "( UTC +5 )  Uzbekistan Time",
  },
  {
    key: "VET",
    title: "Venezuelan Standard Time",
    offset: -4,
    offset_formarted: "( UTC -4 )",
    display_title: "( UTC -4 )  Venezuelan Standard Time",
  },
  {
    key: "VLAT",
    title: "Vladivostok Time",
    offset: 10,
    offset_formarted: "( UTC +10 )",
    display_title: "( UTC +10 )  Vladivostok Time",
  },
  {
    key: "VOLT",
    title: "Volgograd Time",
    offset: 4,
    offset_formarted: "( UTC +4 )",
    display_title: "( UTC +4 )  Volgograd Time",
  },

  {
    key: "VUT",
    title: "Vanuatu Time",
    offset: 11,
    offset_formarted: "( UTC +11 )",
    display_title: "( UTC +11 )  Vanuatu Time",
  },

  {
    key: "WAST",
    title: "West Africa Summer Time",
    offset: 2,
    offset_formarted: "( UTC +2 )",
    display_title: "( UTC +2 )  West Africa Summer Time",
  },
  {
    key: "WAT",
    title: "West Africa Time",
    offset: 1,
    offset_formarted: "( UTC +1 )",
    display_title: "( UTC +1 )  West Africa Time",
  },
  {
    key: "WEST",
    title: "Western European Summer Time",
    offset: 1,
    offset_formarted: "( UTC +1 )",
    display_title: "( UTC +1 )  Western European Summer Time",
  },
  {
    key: "WET",
    title: "Western European Time",
    offset: 0,
    offset_formarted: "( UTC )",
    display_title: "( UTC )  Western European Time",
  },
  {
    key: "WIT",
    title: "Western Indonesian Time",
    offset: 7,
    offset_formarted: "( UTC +7 )",
    display_title: "( UTC +7 )  Western Indonesian Time",
  },
  {
    key: "WST",
    title: "Western Standard Time",
    offset: 8,
    offset_formarted: "( UTC +8 )",
    display_title: "( UTC +8 )  Western Standard Time",
  },
  {
    key: "YAKT",
    title: "Yakutsk Time",
    offset: 9,
    offset_formarted: "( UTC +9 )",
    display_title: "( UTC +9 )  Yakutsk Time",
  },
  {
    key: "YEKT",
    title: "Yekaterinburg Time",
    offset: 5,
    offset_formarted: "( UTC +5 )",
    display_title: "( UTC +5 )  Yekaterinburg Time",
  },
];
