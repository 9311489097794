<template>
  <div
    class="copyItemMod"
    :class="{ fontMobile: isMobile === true, fontDesk: isMobile === false }"
  >
    <div v-html="currentProduct.mainCopy"></div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "MainOverlayCopy",
  props: ["data", "item_id"],
  data() {
    return {
      height: 0,
    };
  },

  created() {},

  mounted() {
    this.matchHeight();
  },
  methods: {
    matchHeight() {
      const mainPanel = document.getElementById("_mainPanel");
      if (mainPanel) this.height = mainPanel.clientHeight;
      // EB.$emit('main_overlay_copy_comp_height',this.height)
      this.$emit("heightCallback", {
        item_id: this.item_id,
        height: this.height,
      });
    },
    getHeight() {
      return this.height;
    },
  },

  computed: {
    ...mapGetters(["isMobile", "currentProduct"]),
    // cris todo remove
    /*mainCopy:function(){
        return store.state.product.mainCopy
      }*/
  },
};
</script>

<style scoped>
.copyItemMod {
  padding-left: 15px !important;
  padding-right: 15px !important;
  font-family: "Univers LT Std";
  font-style: normal;
  font-weight: 400;
}
</style>
