<template>
  <div
    class="BaseOverlay d-flex flex-column"
    id="_mainPanel"
    :class="{
      mainPanelMobileStart: startMobile,
      mainPanelDeskStart: !startMobile,
      isMobile,
      isOpened,
    }"
    :data-alignment="horizontalAlignment"
  >
    <BaseOverlayHeader
      :canClose="canClose"
      :headline="currentProduct?.baseHeadline"
    />
    <div
      class="p-0 p0"
      id="overlayHolder"
      ref="overlayHolderRef"
      :class="{ overlayHolderScroll: canScroll }"
    >
      <transition-group :css="false" @enter="enter" @leave="leave">
        <BaseProductOverlay
          v-if="baseOverlayParams.activeOverlayIndex < 2 && isOpened"
          ref="OverlayStartRef"
          data-testid="overlay-body"
          :key="1"
        />

        <ChildProductOverlay
          v-else-if="isOpened"
          :isHighlight="isHighlight"
          :overlayData="subItemData"
          data-testid="overlay-body"
          :key="2"
        />
      </transition-group>
    </div>
  </div>
</template>

<script>
import BaseProductOverlay from "./BaseProductOverlay.vue";
import BaseOverlayHeader from "../modules/BaseOverlayHeader";
import ChildProductOverlay from "./ChildProductOverlay.vue";
import { PanelOpenState, PANELEVENT } from "@/utils/enum.js";
import { gsap, Expo } from "gsap";
import { TweenVars, ScrollTween } from "@/utils/tweenTime.js";
import { EB, EB_ACTION } from "@/utils/EB";
import { mapActions, mapGetters } from "vuex";
// @see: https://greensock.com/forums/topic/29801-getting-error-cannot-use-import-statement-outside-a-module-when-importing-flip/#comment-148771
import Flip from "gsap/dist/Flip.js";

gsap.registerPlugin(Flip);

export default {
  name: "BaseOverlay",
  emits: ["close"],
  props: ["canClose"],
  components: {
    BaseOverlayHeader,
    BaseProductOverlay,
    ChildProductOverlay,
  },
  data() {
    return {
      resizeTimeout: null,
      currentPanelWidth: 330,
      initialized: false,
      viewComponents: [],
      subItemData: null,
      contactItem: null,
      startMobile: false,
      isHighlight: false,
      scrollEnabled: false,
      matomoTimeout: null,
    };
  },

  computed: {
    ...mapGetters([
      "canRunMeeting",
      "productOverlayParams",
      "apiDataLoaded",
      "isLandscape",
      "isMobile",
      "currentProduct",
      "rtcParams",
      "productOverlayAction",
      "baseOverlayParams",
      "overlayAspects",
    ]),

    horizontalAlignment() {
      return (
        this.currentProduct?.data.overlays[0]?.horizontal_alignment ?? "right"
      );
    },

    canScroll: function () {
      return this.scrollEnabled;
    },

    isOpened() {
      if (!this.currentProduct) {
        return false;
      }

      if (this.canClose === false) {
        return true;
      }

      return this.baseOverlayParams.appPanelState === PanelOpenState.OPEN;
    },

    panelHeight() {
      return this.baseOverlayParams.panelHeight;
    },

    currentOverlayIndex() {
      return this.baseOverlayParams.activeOverlayIndex;
    },
    computedComponents() {
      return this.viewComponents.filter((item) => item.visible === true);
    },
    isApiDataLoaded() {
      return this.apiDataLoaded;
    },
    overlayAspectsChanged() {
      return this.overlayAspects;
    },
  },
  watch: {
    productOverlayAction(newValue) {
      this.wxClientOverlayActions(newValue);
    },
    panelHeight(newValue, oldValue) {
      if (newValue != oldValue) {
        this.updateAspects();
      }
    },
    currentOverlayIndex(newValue, oldValue) {
      if (newValue != oldValue) {
        for (const _comp of this.viewComponents) {
          if (_comp.id.toString() == newValue.toString()) {
            _comp.visible = true;
          } else {
            _comp.visible = false;
          }
        }
      }

      if (newValue < 2) {
        this.isHighlight = false;
      }
    },
    isApiDataLoaded(newValue, oldValue) {
      if (newValue == true && oldValue == false) {
        this.initPanel();
      }
    },
    overlayAspectsChanged(newVal, oldValue) {
      if (newVal != oldValue) {
        this.updateAspects();
      }
    },
  },
  destroyed() {
    EB.$off(EB_ACTION.WIN_RESIZE);
    EB.$off("panelEvent");
    EB.$off("SHOW_HIGHLIGHT");
    EB.$off("LOCALE_CHANGED");
  },
  mounted() {
    this.changeProductOverlayFirstTimeOpen(false);
    this.fetchProductData();
  },

  created() {
    this.startMobile = this.isMobile;
    EB.$on(EB_ACTION.WIN_RESIZE, () => {
      this.updateAspects();
    });
    EB.$on("panelEvent", (event) => {
      this.panelEvent(event);
    });
    EB.$on("SHOW_HIGHLIGHT", (event) => {
      this.onShowHighlight(event);
    });
    this.listeners();
  },

  methods: {
    ...mapActions([
      "notifyProductOverlayAction",
      "changeProductOverlayFirstTimeOpen",
      "matomoTrackEvent",
      "product_sub_menu_selected",
      "fetchProductData",
      "refreshProductLocale",
    ]),

    enter(el, done) {
      el.style.height = `0px`;
      el.style.opacity = `0`;
      const state = Flip.getState(
        [
          el,
          el.prevElementSibling,
          el.nextElementSibling,
          el.parentElement,
        ].filter(Boolean)
      );
      el.style.height = `auto`;
      el.style.opacity = ``;

      Flip.from(state, {
        duration: TweenVars.duration,
        ease: Expo.easeOut,
      }).then(done);
    },

    leave(el, done) {
      const state = Flip.getState(
        [
          el,
          el.prevElementSibling,
          el.nextElementSibling,
          el.parentElement,
        ].filter(Boolean)
      );
      el.style.height = `0px`;
      el.style.opacity = `0`;

      Flip.from(state, {
        duration: TweenVars.duration,
        ease: Expo.easeOut,
      }).then(done);
    },

    listeners() {
      EB.$on("LOCALE_CHANGED", (event) => {
        this._changeLocale(event.locale);
      });
    },
    _changeLocale(locale) {
      EB.$off("LOCALE_CHANGED");
      this.refreshProductLocale(locale);
    },

    initPanel() {
      setTimeout(() => {
        gsap.to("#_mainPanel", {
          opacity: 1,
          duration: 0.75,
          ease: "expo.inOut",
          delay: 1,
        });
        this.updateAspects();
      }, 500);
    },

    onShowHighlight() {
      if (this.rtcParams.isClientReady) {
        this.notifyProductOverlayAction({
          name: "showHighlight",
          value: "",
        });
      }

      this.showHighlight();
    },

    showHighlight() {
      const highlight = this.currentProduct.highlight;
      this.matomoTrackEvent([
        this.productOverlayParams.sectionName +
          " - prod_" +
          this.productOverlayParams.productId,
        "- highlight",
      ]);
      if (highlight == null) {
        return;
      }
      this.isHighlight = true;
      setTimeout(() => {
        this.subItemData = "HIGHLIGHT";
        this.$store.commit("SET_BASE_OL_PARAMS", {
          childHeaderTitle: this.currentProduct.highlightHeadline,
          activeOverlayIndex: 2,
        });
      }, 200);
    },
    panelEvent(event) {
      switch (event.type) {
        case "CLOSE_PANEL":
          this.$emit("close");
          break;

        case PANELEVENT.PRODUCT_SELECTED: {
          const child_dataIndex = parseInt(event.selectedSubItem);
          this.isHighlight = false;

          setTimeout(() => {
            this.subItemData = child_dataIndex;
            this.$store.commit("SET_BASE_OL_PARAMS", {
              activeOverlayIndex: child_dataIndex,
            });
          }, 200);
          break;
        }
        case PANELEVENT.SHOW_BASE:
          this.isHighlight = false;
          this.$store.commit("SET_BASE_OL_PARAMS", { activeOverlayIndex: 0 });
          this.changeProductOverlayFirstTimeOpen(true);

          break;

        case "PANEL_SCROLL_TOP": {
          this.scrollEnabled = false;

          const delay = event.delay;
          const sctp = event.scrollTop;
          setTimeout(() => {
            gsap.to(this.$refs.overlayHolderRef, {
              scrollTop: sctp,
              duration: ScrollTween.duration,
              ease: ScrollTween.ease,
            });
          }, delay);
          setTimeout(() => {
            this.scrollEnabled = true;
          }, 2000);

          break;
        }
      }
    },
    _scrollTo(pos, delay) {
      setTimeout(() => {
        gsap.to(this.$refs.overlayHolderRef, {
          scrollTop: pos,
          duration: TweenVars.duration,
          ease: TweenVars.ease,
        });
      }, delay);
    },

    updateAspects() {
      gsap.to("#_mainPanel", {
        width: this.overlayAspects.overlayWidth,
        duration: TweenVars.duration,
        ease: TweenVars.ease,
      });
    },

    wxClientOverlayActions(action) {
      switch (action.name) {
        case "open":
          this.$store.commit("SET_BASE_OL_PARAMS", { activeOverlayIndex: 0 });
          setTimeout(() => {
            this.$store.commit("SET_BASE_OL_PARAMS", {
              appPanelState: PanelOpenState.OPEN,
            });
          }, 50);
          return;

        case "close":
          EB.$emit(PANELEVENT.CLOSE_TAPPED, "close");
          setTimeout(() => {
            this.$emit("close");
          }, 50);
          return;
        case "navBack":
          EB.$emit("NAVIGATE_BACK", "back");
          return;
        case "showHighlight":
          this.showHighlight(action.value);
          return;
        case "childOverlaySelected":
          this.product_sub_menu_selected(action.value);
          return;
      }
    },
    beforeEnter(el) {
      el.style.opacity = 0;
      el.style.transform = "translateX(-20px)";
    },
  },
};
</script>

<style lang="scss">
.BaseOverlay {
  --top: unset;
  --right: unset;
  --bottom: unset;
  --left: unset;
  --centerYFn: translateY(calc(-1 * var(--top)));
  --centerXFn: translateX(calc(-1 * var(--left)));

  &:not(.isMobile) {
    --minPaddingTop: 50px;
    --minPaddingBottom: 150px;
    --minRightSpacing: 55px;
    --top: 50%;

    &[data-alignment="right"] {
      --right: var(--minRightSpacing);
      --centerFn: var(--centerYFn);
    }

    &[data-alignment="left"] {
      --left: var(--minRightSpacing);
      --centerFn: var(--centerYFn);
    }

    &[data-alignment="center"] {
      --left: 50%;
      --centerFn: var(--centerXFn) var(--centerYFn);
    }
  }

  &.isMobile {
    --minPaddingTop: 190px;
    --minPaddingBottom: 0px;
    --minRightSpacing: 15px;
    --bottom: 0;
    --left: 50%;
    --centerFn: var(--centerXFn);
  }

  ol {
    list-style-position: inside;
    padding-left: 0 !important;
  }
}

#_mainPanel {
  opacity: 0;
  position: absolute;
  overflow: hidden;
  min-height: 60px;
  width: 330px;
  backdrop-filter: blur(10px);
  background-color: rgba(255, 255, 255, 0.35);
  max-height: calc(
    100% - (calc(var(--minPaddingTop) + var(--minPaddingBottom)))
  );
  top: var(--top);
  right: var(--right);
  bottom: var(--bottom);
  left: var(--left);
  transform: var(--centerFn);
}

[data-url-path="#/promise/values/detail/2"]:not(.isMobile)
  .BaseOverlay:not(.isOpened) {
  margin-top: 100px;
}
</style>
