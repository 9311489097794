<template>
  <div class="AppLoader">
    <div class="AppLoader-tiles">
      <div
        v-for="index in tilesCount"
        :key="index"
        class="AppLoaderTile p-0 p0"
        :class="{
          'AppLoaderTile--master': index === masterIndex,
        }"
        :style="{
          '--order': calculateOrder(index),
        }"
      ></div>
    </div>
    <div class="percent" v-if="percent">{{ percent }}</div>
    <div class="copy" v-if="copyText"><span v-html="copyText"></span></div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "AppLoader",

  props: ["percent", "copyText"],

  computed: {
    ...mapGetters(["isMobile"]),

    tilesCount() {
      return this.isMobile ? 19 : 49;
    },

    masterIndex() {
      return Math.floor(this.tilesCount / 2 + 1);
    },
  },

  methods: {
    calculateOrder(i) {
      return Math.floor(Math.abs(this.tilesCount / 2 - i));
    },
  },
};
</script>

<style lang="scss" scoped>
@keyframes pulse {
  0% {
    transform: scale(var(--minSize));
  }
  18% {
    transform: scale(var(--minSize));
  }
  33% {
    transform: scale(var(--maxSize));
  }
  93% {
    transform: scale(var(--maxSize));
  }
  100% {
    transform: scale(var(--minSize));
  }
}

@keyframes master-pulse {
  0% {
    transform: scale(var(--maxSize));
  }
  10% {
    transform: scale(var(--maxSize));
  }
  20% {
    transform: scale(var(--minSize));
  }
  65% {
    transform: scale(var(--minSize));
  }
  85% {
    transform: scale(var(--maxSize));
  }
  100% {
    transform: scale(var(--maxSize));
  }
}

.AppLoader {
  --minSize: 0.3, 0.1;
  --maxSize: 1, 1;

  display: flex;
  flex-direction: column;
  gap: 40px;
  border: none;
  width: 100%;
  align-items: center;
}

.percent {
  text-align: center;
  font-size: 26px;
  color: white;
  letter-spacing: 0.25em;
  font-family: Univers LT Std;
}

.copy {
  font-family: Univers LT Std;
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  color: white;
  width: 265px;
}

.AppLoader-tiles {
  display: flex;
  gap: 8px;
  justify-content: center;
  align-items: center;
}

.AppLoaderTile {
  --growMultiplier: calc(var(--order, 0) * 0.04s);

  transform: scale(var(--minSize));
  width: 6px;
  height: 48px;
  background-color: aliceblue;
  border-radius: 8px;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  animation-duration: 2s;
  animation-name: pulse;
  animation-delay: var(--growMultiplier);
}

.AppLoaderTile--master {
  transform: scale(var(--maxSize));
  animation-name: master-pulse;
  animation-delay: 1s;
  animation-duration: 2s;
}
</style>
