<template>
  <svg
    width="46"
    height="46"
    viewBox="0 0 46 46"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    name="PlayIcon"
  >
    <path
      d="M18.9592 15.5521L18.2857 15.1633V15.941V29.1611V29.9387L18.9592 29.5499L30.4082 22.9398L31.0816 22.551L30.4082 22.1622L18.9592 15.5521ZM17.3878 13.608L32.8776 22.551L17.3878 31.4941V13.608ZM45 23C45 35.1503 35.1503 45 23 45C10.8497 45 1 35.1503 1 23C1 10.8497 10.8497 1 23 1C35.1503 1 45 10.8497 45 23ZM23 44.102C34.6543 44.102 44.102 34.6543 44.102 23C44.102 11.3457 34.6543 1.89796 23 1.89796C11.3457 1.89796 1.89796 11.3457 1.89796 23C1.89796 34.6543 11.3457 44.102 23 44.102Z"
      fill="white"
      stroke="url(#paint0_linear)"
    />
    <defs>
      <linearGradient
        id="paint0_linear"
        x1="6.38776"
        y1="40.9592"
        x2="38.7143"
        y2="5.93877"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="var(--gradientFrom)" />
        <stop offset="1" stop-color="var(--gradientTo)" />
      </linearGradient>
    </defs>
  </svg>
</template>

<script>
export default {
  name: "PlayIcon",
};
</script>
