import _ from "lodash";

export class ProductI {
  constructor(data, locale) {
    this._currentLocale = locale;
    this.data = data;
  }
  get currentLocale() {
    return this._currentLocale;
  }
  get _overlays() {
    return this.data.overlays;
  }

  get baseHeadline() {
    const _b = this.baseOverlay.find((item) => item.type == "HL");
    const localeI = _b.value.findIndex(
      (item) => item.locale == this._currentLocale
    );
    return localeI != -1 ? _b.value[localeI].value : "";
    //return _b != null ? _b : "null"
  }

  get mainCopy() {
    const reg = RegExp("<p>", "g");
    const reg2 = RegExp("</p>", "g");
    const _b = this.baseOverlay.find((item) => item.type == "COPY");
    const localeI = _b.value.findIndex(
      (item) => item.locale == this._currentLocale
    );
    /* return localeI != -1 ? _b.value[localeI].value : '' */
    var text = localeI != -1 ? _b.value[localeI].value : "";
    text = text.replace(reg, "");
    text = text.replace(reg2, "");
    return text;
  }
  get baseOverlay() {
    const _ovl = _.find(this._overlays, (item) => {
      return item.id == "1";
    });
    return _ovl != null ? _ovl._items : null;
  }

  get productDetailsButton() {
    const btn = this.baseOverlay.find(
      (item) => item.type == "BUTTON" && !item.url
    );
    return btn?.copy;
  }

  getOverlayByKey(id) {
    const _ovl = _.find(this._overlays, (item) => {
      return item.id == id;
    });
    return _ovl != null ? _ovl._items : null;
  }
  get productDetails() {
    let _ovls = _.filter(this._overlays, (item) => {
      return item.id > "1";
    });
    _ovls = _ovls.sort((a, b) => {
      return a.id - b.id;
    });
    let _products = [];
    for (const _overlay of _ovls) {
      for (const _item of _overlay._items) {
        if (_item.type == "HL") {
          _products.push(_overlay.id);
        }
      }
    }
    return _products;
  }
  getOverlayItemsByOverlayId(id) {
    const _ovl = _.find(this._overlays, (item) => {
      return item.id == id;
    });
    if (_ovl == null || _ovl._items == null) {
      return [];
    }
    return _ovl._items.sort((a, b) => {
      return a.order - b.order;
    });
  }

  getHeadlineByKey(id) {
    const reg = RegExp("<p>", "g");
    const reg2 = RegExp("</p>", "g");
    const ovl = this.getOverlayByKey(id);
    if (ovl != null) {
      const _b = ovl.find((item) => item.type == "HL");
      const localeI = _b.value.findIndex(
        (item) => item.locale == this._currentLocale
      );
      var text = localeI != -1 ? _b.value[localeI].value : id;
      text = text.replace(reg, "");
      text = text.replace(reg2, "");
      return text;
    }
    return id;
  }
  getCopyForKeyAndOverlay(overlay, key) {
    const reg = RegExp("<p>", "g");
    const reg2 = RegExp("</p>", "g");
    const regUL = RegExp("<ul>", "g");
    const regOL = RegExp("<ol>", "g");

    const ovl = this.getOverlayByKey(overlay);
    if (ovl != null) {
      const _b = ovl.find((item) => item.type == "COPY" && item.key == key);
      const localeI = _b.value.findIndex(
        (item) => item.locale == this._currentLocale
      );
      var text = localeI != -1 ? _b.value[localeI].value : key;
      text = text.replace(reg, "");
      text = text.replace(reg2, "");
      text = text.replace(regUL, '<ul style="padding-inline-start: 15px;">');
      text = text.replace(regOL, '<ol style="padding-inline-start: 0px;">');
      return text;
    }

    return key;
  }

  get highlightHeadline() {
    if (this.data.highlight == null || this.data.highlight.length == 0) {
      return "";
    }
    const label = this.data.highlight.find((item) => item.type == "HL");
    const localeI = label.value.findIndex(
      (item) => item.locale == this._currentLocale
    );
    return localeI != -1 ? label.value[localeI].value : "null";
  }
  get highlightLabel() {
    if (this.data.highlight == null || this.data.highlight.length == 0) {
      return "";
    }
    const label = this.data.highlight.find((item) => item.type == "LABEL");
    const localeI = label.value.findIndex(
      (item) => item.locale == this._currentLocale
    );
    return localeI != -1 ? label.value[localeI].value : "null";
  }

  get highlightCopy() {
    if (this.data.highlight == null || this.data.highlight.length == 0) {
      return "";
    }
    const item = this.data.highlight.find((item) => item.type == "COPY");
    const localeI = item.value.findIndex(
      (item) => item.locale == this._currentLocale
    );
    return localeI != -1 ? item.value[localeI].value : "null";
  }

  get highlight() {
    if (this.data.highlight == null || this.data.highlight.length == 0) {
      return null;
    } else {
      return this.data.highlight;
    }
  }

  get contact() {
    return {
      button: this.data.contact_button_1,
      copy: this.data.contact_copy_1,
      label: this.data.contact_label_1,
      headline: this.data.contact_hl_1,
    };
  }

  get contactHeadline() {
    return (
      this.data?.contact_hl_1?.find(
        (item) => item.locale == this._currentLocale
      )?.value ?? null
    );
  }

  get contactCopy() {
    return (
      this.data?.contact_copy_1?.find(
        (item) => item.locale == this._currentLocale
      )?.value ?? null
    );
  }

  get contactButton() {
    return (
      this.data?.contact_button_1?.find(
        (item) => item.locale == this._currentLocale
      )?.value ?? null
    );
  }

  get contactLink() {
    const link = this.data?.contact_link_1;
    if (!link || typeof link === "string") {
      return link;
    }

    return (
      this.data?.contact_link_1?.find(
        (item) => item.locale == this._currentLocale
      )?.value ?? null
    );
  }
  get contactLabel() {
    if (this.data == null || this.data.contact_label_1 == null) {
      return null;
    }
    const label = this.data.contact_label_1;
    const localeI = label.findIndex(
      (item) => item.locale == this._currentLocale
    );
    return localeI != -1 ? label[localeI].value : null;
  }

  setCurrentLocale(val) {
    console.log("PRODUCT LOCALE ", val);
    this._currentLocale = val;
  }
}
