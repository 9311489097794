<template>
  <div id="textMessageOverlay" class="overlay">
    <div id="innerTextmessageOverlay" class="innerOverlay">
      <OverlayHeader
        :title="locale.meeting_hud_presenter_hl_2"
        :closeIcon="true"
        @close="closeOverlay"
      />

      <div class="d-flex flex-column _formHolder">
        <div class="p-0 flex-fill" @click="$emit('change', 3)">
          <div
            class="d-flex ml4 mr4 align-items-center toolBarPopupBtn"
            :class="{ activeSetting: meetingWinStyleNr === 3 }"
          >
            <div id="videoIcon" class="p-0 oIcon ml4">
              <BigVideoIcon />
            </div>
            <div class="p-0 mt2 ml10">
              {{ locale.meeting_hud_presenter_button_2_1 }}
            </div>
          </div>
        </div>
        <div class="p-0 flex-fill" @click="$emit('change', 1)">
          <div
            class="d-flex ml4 mr4 align-items-center toolBarPopupBtn"
            :class="{ activeSetting: meetingWinStyleNr === 1 }"
          >
            <div id="videoIcon" class="p-0 oIcon ml4">
              <SmallVideoIcon />
            </div>
            <div class="p-0 mt2 ml10">
              {{ locale.meeting_hud_presenter_button_2_3 }}
            </div>
          </div>
        </div>
        <div class="p-0 flex-fill" @click="$emit('change', 0)">
          <div
            class="d-flex ml4 mr4 align-items-center toolBarPopupBtn"
            :class="{ activeSetting: meetingWinStyleNr === 0 }"
          >
            <div id="videoIcon" class="p-0 oIcon ml4">
              <HideVideoIcon />
            </div>
            <div class="p-0 mt2 ml10">
              {{ locale.meeting_hud_presenter_button_2_4 }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { gsap } from "gsap";
import { TweenVars } from "@/utils/tweenTime.js";
import OverlayHeader from "@/components/klein/OverlayHeader";
import { mapActions, mapGetters } from "vuex";
import HideVideoIcon from "@/icons/HideVideoIcon";
import SmallVideoIcon from "@/icons/SmallVideoIcon";
import BigVideoIcon from "@/icons/BigVideoIcon";

export default {
  name: "OverlayResizeMeeting",
  components: {
    OverlayHeader,
    HideVideoIcon,
    SmallVideoIcon,
    BigVideoIcon,
  },
  data() {
    return {};
  },
  emits: ["change", "close"],

  mounted() {
    this.fadeIn();
  },

  computed: {
    ...mapGetters(["locale", "meetingWinStyleNr"]),
  },

  methods: {
    ...mapActions(["resetOverlayVisibility"]),

    fadeIn() {
      gsap.to("#innerTextmessageOverlay", {
        bottom: 45,
        opacity: 1,
        duration: TweenVars.duration,
        ease: TweenVars.ease,
      });
    },
    closeOverlay: function () {
      gsap.to("#innerTextmessageOverlay", {
        top: this.$store.state.constrains.screenHeight / 3,
        opacity: 0,
        duration: TweenVars.duration,
        ease: TweenVars.ease,
      });
      setTimeout(() => {
        this.resetOverlayVisibility({ resizeMeetingOverlay: false });
        this.$emit("close");
      }, 500);
    },
  },
};
</script>

<style scoped>
#innerTextmessageOverlay {
  position: absolute;
  width: 200px;
  height: auto !important;
  left: 40%;
  bottom: 0;
  opacity: 0;
  backdrop-filter: blur(5px) !important;
  background-color: rgba(255, 255, 255, 0.65);
}

.oIcon {
  width: 34px;
  height: 28px;
}

.iconUndText {
  display: flex;
}

.activeSetting {
  background-color: rgba(91, 91, 91, 0.1);
}
</style>
