// TODO: migrate to pinia
const state = {
  locale: {},
  login_locale: {},
  fallback_locale: {},
  /** language code : ISO 639-1  */
  lang: "de",
};

// =========================== GETTERS :::::::::::::::::::::::::
const getters = {
  lang(state) {
    return state.lang;
  },

  locale: (state) => {
    // allow to display translation keys for empty translations in dev mode only,
    // in order to make it easier to style components during develpment phase.
    return process.env.NODE_ENV !== "development"
      ? state.locale
      : new Proxy(state.locale, {
          get(target, key) {
            // seems like vuex adds an auxiliary property to the object,
            // so we can't just check if there are any keys defined
            const isPopulated = Object.getOwnPropertyNames(target).length > 1;

            if (isPopulated && typeof target[key] === "undefined") {
              return key;
            }

            return target[key];
          },
        });
  },
  login_locale: (state) => {
    return state.login_locale;
  },
  fallback_locale: (state) => {
    return state.fallback_locale;
  },
};

// ================== ACTIONS =========================
const actions = {
  /** set the language - ISO 639-1
   *  and get the text files  */
  async getAndSetLang({ commit, rootGetters }, _lang) {
    let _path = rootGetters.apiPath + "meeting/";
    _path += _lang;
    commit("setLang", _lang);
    await fetch(_path)
      .then((response) => response.json())
      .then((data) => commit("setLangData", data));
  },

  async getAndSetLoginLang({ commit, rootGetters }, _lang) {
    let _path = rootGetters.apiPath + "login/";
    _path += _lang;
    commit("setLang", _lang);
    await fetch(_path)
      .then((response) => response.json())
      .then((data) => commit("setLoginLangData", data));
  },
  async getAndSetFallbackLang({ commit, rootGetters }, _lang) {
    let _path = rootGetters.apiPath + "fallback/";
    _path += _lang;
    commit("setLang", _lang);
    await fetch(_path)
      .then((response) => response.json())
      .then((data) => commit("setFallbackLangData", data));
  },
};

const mutations = {
  setLang(state, _lang) {
    /*console.log(' X -> setLang ', _lang)*/
    state.lang = _lang;
  },

  setLangData(state, _data) {
    /*console.log(' X -> setLangData ', _data)*/
    state.locale = _data;
  },

  setLoginLangData(state, _data) {
    /*console.log(' X -> setLangData ', _data)*/
    state.login_locale = _data;
  },
  setFallbackLangData(state, _data) {
    /*console.log(' X -> setLangData ', _data)*/
    state.fallback_locale = _data;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
