<template>
  <div class="p-0">
    <div class="d-flex flex-column pl0 pr0">
      <div class="p-0 d-flex flex-column">
        <div
          class="p-0 mr15 ml15 mt10 mb10"
          :class="{
            fontMobile: isMobile === true,
            fontDesk: isMobile === false,
          }"
        >
          {{ contact_locale.copy }}
        </div>

        <div
          class="p-0 flex-fill tfbt pRelative"
          style="position: relative !important"
        >
          <OptionList
            :appoointment="false"
            :placeHolder="contact_locale.productsLabel"
            :resize="pseudoResize"
            :type="productType"
            :change="productChanged"
            :disabled="false"
            :selectedValue="getInTouch.product"
            :items="product_list"
            @open="openOptionList"
            :isMobile="isMobile"
          />
          <div class="productWarn" v-if="showProductWarn">
            {{ contact_locale.options1Error1 }}
          </div>
        </div>

        <div
          class="p-0 flex-fill pRelative"
          style="position: relative !important"
        >
          <OptionList
            @open="openOptionList"
            :appoointment="false"
            :placeHolder="contact_locale.continentLabel"
            :resize="pseudoResize"
            :type="continentT"
            :change="continentChanged"
            :disabled="false"
            :selectedValue="getInTouch.continent"
            :items="continent_list"
            :isMobile="isMobile"
          />
          <div class="productWarn" v-if="showContinentWarn">
            {{ contact_locale.options2Error1 }}
          </div>
        </div>

        <div
          class="p-0 flex-fill pRelative"
          style="position: relative !important"
        >
          <SearchAutocomplete
            :items="countries"
            :placeHolder="contact_locale.countryLabel"
            :resize="pseudoResize"
            :isAsync="false"
            :type="countryT"
            :isMobile="isMobile"
            @open="openAutoCompleteList"
            :selectedValue="getInTouch.country.value"
            :change="countryChanged"
            :disabled="isCountryDisabled"
          />
          <div class="productWarn mt2" v-if="showCountryWarn">
            {{ contact_locale.options3Error1 }}
          </div>
        </div>

        <div class="p-0 flex-fill pRelative" v-if="showBranchList">
          <OptionList
            @open="openOptionList"
            :appoointment="false"
            :placeHolder="contact_locale.branchLabel"
            :resize="pseudoResize"
            :type="officeBranchT"
            :change="officeBranchChanged"
            :disabled="isOfficeBranchDisabled"
            :selectedValue="getInTouch.officeBranch"
            :items="officeBranchList"
            :isMobile="isMobile"
          />
          <!--        <div v-if='showBranchItem'>{{contact_locale.branchLabel}}</div>-->
          <div class="productWarn" v-if="showBranchWarn">
            {{ contact_locale.options4Error1 }}
          </div>
        </div>
      </div>

      <div
        class="p-0 d-flex flex-column ml15 mr15 mt20 mb20 justify-content-end"
      >
        <div class="p-0 d-flex flex-fill justify-content-center">
          <div
            v-wave
            class="contact-next-button"
            :class="{ contextNextClickable: nextDisabled }"
            @click="nextTapped"
          >
            <div class="contact-next-button-text">
              {{ contact_locale.buttonCopy }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { AutoCompleteType, OverlaySteps } from "@/utils/enum.js";
import SearchAutocomplete from "@/components/SearchAutocomplete.vue";
import OptionList from "@/components/OptionList.vue";
import { mapGetters } from "vuex";
import { mapActions } from "vuex";
import _ from "lodash";
export default {
  name: "ContactLocation",
  props: ["next", "resize"],
  components: {
    SearchAutocomplete,
    OptionList,
  },
  data() {
    return {
      // CRIS TODO - Remove
      selectedCountry: "",
      selectedContinent: "",
      selectedProduct: "",
      selectedBranch: "",
      countriesFlat: [],
      // officeBranchItems:[],
      productType: AutoCompleteType.TECH,
      countryT: AutoCompleteType.COUNTRY,
      continentT: AutoCompleteType.CONTINENT,
      officeBranchT: AutoCompleteType.OFFICE_BRANCH,

      showProductWarn: false,
      showContinentWarn: false,
      showCountryWarn: false,
      showBranchWarn: false,
      /*showBranchList:false,*/
      stepType: OverlaySteps.LOCATION,
      product_list: [],
    };
  },

  created() {
    this.product_list = this.contact_locale.productList;

    /* console.log("HIER >>> ", this.continent_list)*/
    /*this.contact_locale.getEnProductFromCN("test")*/
  },
  computed: {
    ...mapGetters([
      "isMobile",
      "getInTouch",
      "currentLocale",
      "contact_locale",
      "officeBranchItems",
    ]),
    continent_list: function () {
      return this.contact_locale.continents;
    },
    isContinentDisabled: function () {
      return false; //this.selectedSection == null || this.selectedSection == ''
    },
    isCountryDisabled: function () {
      return (
        this.getInTouch.continent.value == null ||
        this.getInTouch.continent.value === ""
      );
    },
    isOfficeBranchDisabled: function () {
      return (
        this.getInTouch.country == null ||
        this.getInTouch.country === "" ||
        this.officeBranchList.length == 1
      );
    },
    nextDisabled: function () {
      return (
        this.getInTouch.country !== "" &&
        this.getInTouch.continent.value !== "" &&
        this.getInTouch.product !== ""
      );
    },

    countries: function () {
      if (
        this.getInTouch.continent.value == null ||
        this.getInTouch.continent.value === ""
      ) {
        return [];
      } else {
        if (this.currentLocale === "cn") {
          return _.sortBy(
            this.contact_locale.countryList.filter(
              (item) => item.continent === this.getInTouch.continent.value
            ),
            ["valueEN"]
          );
        } else {
          return _.sortBy(
            this.contact_locale.countryList.filter(
              (item) => item.continent === this.getInTouch.continent.value
            ),
            ["value"]
          );
        }

        //return this.contact_locale.countryList.filter((item) => item.continent === this.getInTouch.continent.value)
      }
    },
    officeBranchList: function () {
      const countryDE =
        this.currentLocale === "de"
          ? this.getInTouch.country.value
          : this.contact_locale.getCountryDeForEn(
              this.getInTouch.country.title
            );
      if (this.officeBranchItems.length === 0) return [];
      if (countryDE == null) return [];
      return this.officeBranchItems.filter((item) => {
        return item.country_de === countryDE;
      });
    },
    showBranchList() {
      if (
        this.getInTouch.product != "" &&
        this.getInTouch.country.value != "" &&
        this.getInTouch.country.value != undefined
      ) {
        if (this.officeBranchList.length > 1) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
      /*  if(this.getInTouch.product != '' && this.getInTouch.country.value != '' && this.officeBranchList.length > 1){
          return true;
        } else {
          return false
        }*/
    },
    // showBranchItem(){
    //   if(this.getInTouch.product != '' && this.getInTouch.country.value != ''
    //       && this.getInTouch.country.value != undefined && this.getInTouch.officeBranch.officeName != '' && this.getInTouch.officeBranch.contact_email != ''){
    //     if(this.officeBranchList.length > 1){
    //       return  false;
    //     } else {
    //       return true
    //     }
    //
    //   } else {
    //     return false
    //   }
    // }
  },
  methods: {
    ...mapActions([
      "getInTouch_clearForProductChanged",
      "getInTouch_clearForContinentChanged",
      "getInTouch_clearForCountryChanged",
      "setGetInTouch_officeName",
      "setGetInTouch_contact_email",
      "fetchOfficeBranches",
    ]),
    openOptionList(e) {
      if (e == null) {
        return;
      }
      if (e == "TECH" && this.showProductWarn) {
        this.showProductWarn = false;
      } else if (e == "CONTINENT" && this.showContinentWarn) {
        this.showContinentWarn = false;
      }
    },
    openAutoCompleteList(e) {
      if (e == null) {
        return;
      }
      if (e == "COUNTRY" && this.showCountryWarn) {
        this.showCountryWarn = false;
      }
    },
    productChanged(e) {
      // console.log(e)
      this.getInTouch_clearForProductChanged(e);
      this.showProductWarn = false;
      this.showContinentWarn = false;
      this.showCountryWarn = false;
      this.showBranchWarn = false;
      this.showBranchWarn = false;
      // this.officeBranchItems = []
      setTimeout(() => {
        this.fetchOfficeBranches();
      }, 60);
    },

    continentChanged(e) {
      this.showBranchWarn = false;
      this.showProductWarn = false;
      //      this.officeBranchItems = []
      this.showContinentWarn = false;
      this.showCountryWarn = false;
      this.showBranchWarn = false;
      this.getInTouch_clearForContinentChanged({
        value: e.value,
        title: e.title,
      });
      setTimeout(() => {
        this.fetchOfficeBranches();
      }, 60);
    },

    countryChanged(e) {
      this.showProductWarn = false;
      this.showBranchWarn = false;
      this.showContinentWarn = false;
      this.showCountryWarn = false;
      this.showBranchWarn = false;

      this.getInTouch_clearForCountryChanged(e);
      setTimeout(() => {
        // console.log(this.officeBranchList)
        if (this.officeBranchList.length == 1) {
          // console.log(this.officeBranchList[0])
          this.setGetInTouch_officeName(this.officeBranchList[0].officeName);
          this.setGetInTouch_contact_email(
            this.officeBranchList[0].contact_email
          );
          /*this.getInTouch.officeBranch.contact_email = this.officeBranchList[0].contact_email
          this.getInTouch.officeBranch.officeName = this.officeBranchList[0].value*/
          // console.log("selectedEmail ",  this.getInTouch.officeBranch)
        }
      }, 500);

      // else
      // if(this.getInTouch.product != '' && this.getInTouch.product != ''
      // && this.getInTouch.country != '' &&  this.officeBranchList.length > 1) {
      //   /*this.showBranchList =  true;*/
      // }
      // console.log(this.getInTouch)
    },

    officeBranchChanged(e) {
      this.showBranchWarn = false;
      this.setGetInTouch_officeName(e.officeName);
      this.setGetInTouch_contact_email(e.contact_email);
    },

    nextTapped() {
      let hasWarning = false;
      if (this.getInTouch.product == null || this.getInTouch.product == "") {
        this.showProductWarn = true;
        hasWarning = true;
      }
      if (
        this.getInTouch.continent == null ||
        this.getInTouch.continent == ""
      ) {
        this.showContinentWarn = true;
        hasWarning = true;
      }

      if (this.getInTouch.country == null || this.getInTouch.country == "") {
        this.showCountryWarn = true;
        hasWarning = true;
      }

      if (
        !(this.officeBranchItems == null || this.officeBranchItems.length <= 1)
      ) {
        if (
          this.getInTouch.officeBranch.officeName == null ||
          this.getInTouch.officeBranch.officeName == ""
        ) {
          this.showBranchWarn = true;
          hasWarning = true;
        }
      }

      if (this.nextDisabled && !hasWarning) {
        this.next(this.stepType, OverlaySteps.EMAILFORM);
      }
    },

    pseudoResize() {},
    getEnProductValue() {
      var outPut = "Knitting";
      const index = this.product_list.findIndex(
        (item) => item.value == this.getInTouch.product
      );
      if (index != -1) {
        outPut = this.product_list[index].title;
      }
      return outPut;
    },
  },

  mounted() {
    this.countriesFlat = [];
    setTimeout(() => {
      this.resize();
    }, 1000);
  },
};
</script>

<style scoped>
.smF {
  font-weight: 300 !important;
  color: #333 !important;
}

.productWarn {
  position: absolute;
  left: 15px;
  z-index: 999;
  font-size: 12px;
  color: #ff0000;
}

@media (orientation: landscape) {
  .productWarn {
    top: 28px;
  }
}
@media (orientation: portrait) {
  .productWarn {
    bottom: 0px;
  }
}
</style>
