<template>
  <svg viewBox="0 0 33 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M19.9773 19H29C29.5523 19 30 18.5523 30 18V4C30 3.44772 29.5523 3 29 3H4C3.44772 3 3 3.44772 3 4V18C3 18.5523 3.44771 19 4 19H13.0227"
      stroke="currentColor"
      stroke-width="1.3"
      stroke-linecap="round"
    />
    <path
      d="M16.4984 9C16.3018 9.00791 16.074 9.09378 15.9312 9.23502L11.2558 13.8631C10.9883 14.1772 10.8777 14.7783 11.1871 15.1286C11.4922 15.4743 12.0841 15.463 12.3903 15.1196L15.6735 11.8654L15.6735 22.1322C15.6735 22.6113 16.0431 23 16.4985 23C16.954 23 17.3236 22.6113 17.3236 22.1322L17.3236 11.8654L20.6068 15.1196C20.8893 15.4156 21.5017 15.4709 21.81 15.1286C22.1183 14.7874 22.0195 14.1637 21.7413 13.8631L17.0659 9.23502C16.9058 9.07683 16.7176 9 16.4984 9Z"
      fill="currentColor"
    />
  </svg>
</template>
