<template>
  <div id="fullscreenOverlay">
    <div id="fullscreenOverlayBackdrop"></div>
    <div id="innerFullscreen" :class="{ noBottomMargin: noBottomMargin }">
      <component
        v-bind:is="innerComponent"
        :title="innerOverlayComponent?.title"
        :description="innerOverlayComponent?.description"
        :confirmText="innerOverlayComponent?.confirmText"
        :cancelText="innerOverlayComponent?.cancelText"
        @onConfirm="innerOverlayComponent?.onConfirm"
        @onCancel="innerOverlayComponent?.onCancel"
        @close="closeOverlay"
      ></component>
    </div>
  </div>
</template>

<script>
import OverlayHeader from "@/components/klein/OverlayHeader";
import { EB, EB_ACTION } from "@/utils/EB";
import { mapActions, mapGetters } from "vuex";
import { gsap } from "gsap";
import { DataProtectionTween, TweenVars } from "@/utils/tweenTime";
import { defineAsyncComponent } from "vue";

export default {
  name: "FullscreenOverlay",
  components: {
    OverlayHeader,
  },
  data() {
    return {};
  },

  emits: ["closed"],

  unmounted() {
    EB.$off(EB_ACTION.WIN_RESIZE);
  },

  mounted() {
    EB.$on(EB_ACTION.WIN_RESIZE, () => {
      this.winResized();
    });
    setTimeout(() => {
      this.fadeIn();
    }, 100);
  },

  watch: {
    innerComponent(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.winResized();
      }
    },
  },

  computed: {
    ...mapGetters(["innerOverlayComponent", "isLandscape"]),
    innerComponent() {
      let componentObj = this.innerOverlayComponent;
      return defineAsyncComponent(() =>
        import(`${componentObj.path}/${componentObj.name}`)
      );
    },
    noBottomMargin() {
      let componentObj = this.innerOverlayComponent;
      return componentObj != null && componentObj.name !== "DeviceSettings";
    },
  },

  methods: {
    ...mapActions(["setInnerOverlayComponent", "exitMeeting", "closeSession"]),

    winResized() {
      const elemt = document.getElementById("innerFullscreen");
      if (!elemt) {
        return;
      }
      const height = elemt.offsetHeight;
      const _width = this.isLandscape
        ? 500
        : this.$store.state.constrains.screenWidth - 30;
      gsap.to("#innerFullscreen", {
        width: _width,
        left: this.isLandscape
          ? this.$store.state.constrains.screenWidth / 2 - _width / 2
          : 15,
        top: this.isLandscape
          ? this.$store.state.constrains.screenHeight / 2 - height / 2
          : 60,
        duration: TweenVars.duration,
        ease: TweenVars.ease,
      });
    },
    fadeIn() {
      gsap.to("#fullscreenOverlayBackdrop", {
        opacity: 1,
        duration: DataProtectionTween.duration,
        ease: TweenVars.ease,
      });
      const elemt = document.getElementById("innerFullscreen");
      if (!elemt) {
        return;
      }
      const height = elemt.offsetHeight;
      //const width = elemt.offsetWidth;
      const _width = this.isLandscape
        ? 500
        : this.$store.state.constrains.screenWidth - 30;
      gsap.to("#innerFullscreen", {
        width: _width,
        left: this.isLandscape
          ? this.$store.state.constrains.screenWidth / 2 - _width / 2
          : 15,
        top: this.isLandscape
          ? this.$store.state.constrains.screenHeight / 2 - height / 2
          : 60,
        delay: 0.15,
        opacity: 1,
        duration: TweenVars.duration,
        ease: TweenVars.ease,
      });
    },
    fadeOut() {
      gsap.to("#fullscreenOverlayBackdrop", {
        opacity: 0,
        duration: 1,
        ease: TweenVars.ease,
      });
      gsap.to("#innerFullscreen", {
        bottom: 0,
        opacity: 0,
        duration: TweenVars.duration,
        ease: TweenVars.ease,
      });
    },
    closeOverlay() {
      this.fadeOut();
      setTimeout(() => {
        if (this.innerOverlayComponent?.onClose) {
          this.innerOverlayComponent.onClose();
        }

        this.$emit("closed");
      }, 1200);
    },
  },
};
</script>

<style scoped>
#fullscreenOverlay {
  pointer-events: all;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 99999999;
}
#fullscreenOverlayBackdrop {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999999991 !important;

  opacity: 0;
  background-color: rgba(255, 255, 255, 0.35);
  backdrop-filter: blur(10px);
}
#innerFullscreen {
  pointer-events: all;
  position: fixed;
  height: auto !important;
  left: 0;
  top: 0;
  opacity: 0;
  z-index: 999999992;
  background-color: rgba(255, 255, 255, 0.65);
  /*padding-bottom: 25px!important;*/
  color: #636363;
  font-family: "Univers LT Std";
}
.noBottomMargin {
  padding-bottom: 25px !important;
}
</style>
