<template>
  <svg viewBox="0 0 33 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M23.9773 19H29C29.5523 19 30 18.5523 30 18V4C30 3.44772 29.5523 3 29 3H4C3.44772 3 3 3.44772 3 4V18C3 18.5523 3.44772 19 4 19H9.02273"
      stroke="#636363"
      stroke-width="1.3"
      stroke-linecap="round"
    />
    <path
      d="M16.5016 23C16.6982 22.9921 16.926 22.9062 17.0688 22.765L21.7442 18.1369C22.0117 17.8228 22.1223 17.2217 21.8129 16.8714C21.5078 16.5257 20.9159 16.537 20.6097 16.8804L17.3265 20.1346L17.3265 9.86776C17.3265 9.3887 16.9569 9 16.5015 9C16.046 9 15.6764 9.3887 15.6764 9.86776L15.6764 20.1346L12.3932 16.8804C12.1107 16.5844 11.4983 16.5291 11.19 16.8714C10.8817 17.2126 10.9805 17.8363 11.2587 18.1369L15.9341 22.765C16.0942 22.9232 16.2824 23 16.5016 23Z"
      fill="#636363"
    />
  </svg>
</template>
