<script>
import ParticipantLetter from "./ParticipantLetter.vue";
export default {
  name: "UserListRow",
  props: ["user", "onKickUser", "onToggleMic"],
  components: { ParticipantLetter },
  emits: ["kickUser", "toggleMic"],
  computed: {
    canKickUser() {
      return typeof this.$props?.onKickUser === "function";
    },

    canToggleMic() {
      return typeof this.$props?.onToggleMic === "function";
    },
  },
};
</script>

<template>
  <li class="UserListRow">
    <div>
      <ParticipantLetter :user="user" />
    </div>
    <div>
      <button
        class="toggleMicButton"
        aria-label="Toggle microphone"
        :disabled="!canToggleMic"
        @click="$emit('toggleMic', user)"
      >
        <img
          alt="Microphone"
          :src="
            user.audioTrack
              ? '/assets/ui/icons/meeting_mic_on.svg'
              : '/assets/ui/icons/meeting_mic_off.svg'
          "
        />
      </button>
    </div>
    <div>{{ user.attrs.username }}</div>
    <div>
      <button
        v-if="canKickUser"
        class="disconnectButton"
        @click="$emit('kickUser', user)"
        aria-label="Kick out the user"
      >
        <img src="/assets/ui/icons/disconnect.svg" />
      </button>
      <div v-else class="buttonPlaceholder"></div>
    </div>
  </li>
</template>

<style scoped lang="scss">
.UserListRow {
  list-style-image: none !important;
  background: rgba(99, 99, 99, 0.1);
  border-radius: 2px;
  padding: 0 8px !important;
  color: #636363;
  margin: 0;
  display: grid;
  grid-template-columns: 24px 24px 1fr 24px;
  grid-template-rows: 32px;
  gap: 8px;
  line-height: 2.2;
  font-size: 11px;

  & > * {
    display: flex;
    align-items: center;
    white-space: nowrap;
    overflow: hidden;
  }
}

.toggleMicButton,
.disconnectButton {
  margin: 0;
  padding: 5px;
  width: 100%;
  display: flex;
  border: none;
  background-color: rgba(255, 255, 255, 0.25);
  border-radius: 50%;

  &:not([disabled]) {
    cursor: pointer;
  }

  > img {
    width: 100%;
    height: 100%;
  }
}
</style>
