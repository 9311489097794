<template>
  <div class="autocomplete-time-picker tfbb" style="pointer-events: all">
    <div
      class="_dropDowBar mr15 ml15"
      :class="{ fontMobile: isMobile === true, fontDesk: isMobile === false }"
    >
      <div
        class="d-flex align-items-center justify-content-between p0"
        style="height: 100%"
      >
        <div
          class="p-0 flex-fill mt1"
          v-if="hourValue != '' || minutesValue != ''"
          @click="open"
        >
          {{ hourValue }} : {{ minutesValue }}
        </div>
        <div
          class="p-0 flex-fill placeHolderItem"
          v-if="hourValue == '' && minutesValue == ''"
          @click="open"
        >
          {{ placeHolder }}
        </div>

        <div class="p-0 mr5" v-if="showAmPm">
          <div class="amToggle">
            <div class="amToggleCopyHolder">
              <div class="d-flex">
                <div
                  class="p-0 amC"
                  @click="setAM"
                  :class="{ amFontBold: isAm }"
                >
                  {{ amCopy }}
                </div>
                <div
                  class="p-0 pmC"
                  @click="setPM"
                  :class="{ amFontBold: !isAm }"
                >
                  {{ pmCopy }}
                </div>
              </div>
            </div>
            <div class="amToggleThumb" :class="{ pmActive: !isAm }"></div>
          </div>
        </div>

        <div class="p-0">
          <div
            class="collapseIcon _desk"
            v-if="!isMobile"
            :class="{ collapseOpen: isOpen, collapseClosed: !isOpen }"
            @click="open"
          >
            <img src="/assets/ui/ui_select_toggle_desk.svg" alt="" />
          </div>
          <div
            class="collapseIcon _mobile"
            v-if="isMobile"
            :class="{ collapseOpen: isOpen, collapseClosed: !isOpen }"
            @click="open"
          >
            <img src="/assets/ui/ui_select_toggle_mobile.svg" alt="" />
          </div>
        </div>
      </div>
    </div>
    <div id="autocomplete-time-picker-results" v-show="isOpen">
      <div class="d-flex autocomplete-time-picker-results-holder">
        <div
          class="p-0"
          style="
            border-right: 1px solid rgba(91, 91, 91, 0.6);
            background-color: rgba(255, 255, 255, 0.85);
            border-top-left-radius: 2px;
            border-bottom-left-radius: 2px;
          "
        >
          <ul class="autocomplete-time-picker-results">
            <li
              v-for="(h, hIndex) in hourList"
              :key="hIndex"
              @click="setHourResult(h)"
              class="autocomplete-time-picker-result"
              :class="{ 'is-active': h == currentHourVal }"
            >
              {{ h }}
            </li>
          </ul>
        </div>
        <div
          class="p-0"
          style="
            border-right: 1px solid rgba(91, 91, 91, 0);
            background-color: rgba(255, 255, 255, 0.85);
            border-top-right-radius: 2px;
            border-bottom-right-radius: 2px;
          "
        >
          <ul class="autocomplete-time-picker-results">
            <li
              v-for="(min, minIndex) in minutesList"
              :key="minIndex"
              @click="setMinResult(min)"
              class="autocomplete-time-picker-result"
              :class="{ 'is-active': min == currentMinVal }"
            >
              {{ min }}
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "TimePicker",
  props: [
    "resize",
    "placeHolder",
    "hoursChanged",
    "minutesChanged",
    "isMobile",
    "hour",
    "min",
  ],

  data() {
    return {
      isOpen: false,
      results: [],
      isLoading: false,
      resizeTimeout: null,
      isAm: false,
      showAmPm: false,
      currentHourVal: "",
      currentMinVal: "",
      showToggleArrow: true,

      hoursList24: [
        "07",
        "08",
        "09",
        "10",
        "11",
        "12",
        "13",
        "14",
        "15",
        "16",
        "17",
        "18",
        "19",
        "20",
        "21",
        "22",
        "23",
      ],
      hoursList: [
        "01",
        "02",
        "03",
        "04",
        "05",
        "06",
        "07",
        "08",
        "09",
        "10",
        "11",
      ],
      minutesList: ["00", "15", "30", "45"],
    };
  },
  computed: {
    ...mapGetters(["currentLocale"]),
    is24Mode: function () {
      return this.currentLocale === "de";
    },
    hourValue: function () {
      return this.hour;
    },
    minutesValue: function () {
      return this.min;
    },
    hourList: function () {
      return this.currentLocale === "de" ? this.hoursList24 : this.hoursList;
    },
    amCopy() {
      return this.currentLocale === "cn" ? " 上午" : "AM";
    },
    pmCopy() {
      return this.currentLocale === "cn" ? " 下午" : "PM";
    },
  },
  mounted() {
    document.addEventListener("click", this.handleClickOutside);
  },
  destroyed() {
    document.removeEventListener("click", this.handleClickOutside);
  },
  created() {
    this.currentMinVal = this.hour;
    this.currentHourVal = this.min;
  },
  methods: {
    open() {
      console.log("open");
      this.isOpen = !this.isOpen;
      this.$emit("clearTimeWarn");
      setTimeout(() => {
        if (this.isOpen) {
          if (!this.is24Mode) {
            this.showToggleArrow = false;
            this.showAmPm = true;
          }
        } else if (!this.isOpen) {
          this.showToggleArrow = true;
        }
      }, 50);
      this.aspects();
    },
    aspects() {
      if (this.resizeTimeout) {
        clearTimeout(this.resizeTimeout);
      }
      this.resizeTimeout = setTimeout(() => {
        this.resize();
      }, 150);
    },
    setHourResult(result) {
      /* this.isOpen = false; */
      this.currentHourVal = result;

      this.hoursChanged(this.currentHourVal);
      /* this.aspects() */
    },
    setMinResult(result) {
      this.currentMinVal = result;
      /* this.isOpen = false; */
      this.minutesChanged(this.currentMinVal);
      /* this.aspects() */
    },

    handleClickOutside() {
      /* console.log(event) */
      /*  */
      /* 
        const e = this.$el
        console.log(e) */
      /*   if (!this.$el.contains(event.target) && event.target != 'autocomplete-collapseIcon') {
          this.isOpen = false;
       
          this.aspects()  
        } */
    },
    setAM() {
      this.$emit("toogleAMPM", { isPm: false });
      this.isAm = true;
    },
    setPM() {
      this.$emit("toogleAMPM", { isPm: true });
      this.isAm = false;
    },
  },
};
</script>

<style>
.autocomplete-time-picker {
  position: relative;
}
._dropDowBar {
  height: 40px;
  cursor: pointer;
  background-color: rgba(255, 255, 255, 0);
}
@media (orientation: landscape) {
  ._dropDowBar {
    height: 40px;
    cursor: pointer;
    background-color: rgba(255, 255, 255, 0);
  }
}

@media (orientation: portrait) {
  ._dropDowBar {
    height: 54px;
    cursor: pointer;
    background-color: rgba(255, 255, 255, 0);
  }
}
.autocomplete-time-picker-results-holder {
  margin-left: 10px;
  margin-right: 10px;
  border-radius: 2px;
  backdrop-filter: blur(2px);
  background-color: rgba(255, 255, 255, 0);
}
.autocomplete-time-picker-results {
  padding: 0;
  margin: 0;
  /* margin-left: 10px; */
  /* s */
  list-style: none !important;
  /* border: 1px solid #eeeeee; */
  height: 100%;
  min-height: 40px;
  max-height: 180px;

  overflow: auto;
  margin-right: 2px !important;
  -ms-overflow-style: none;
  /*scrollbar-width: none;  */
}
.autocomplete-time-picker-results::-webkit-scrollbar {
  /* padding-right: 2px!important; */
  margin-right: 2px !important;
  width: 4px;
}
.autocomplete-time-picker-results::-webkit-scrollbar-thumb {
  margin-right: 2px !important;
  border-radius: 4px;
  background-color: rgba(91, 91, 91, 0.45);
}

.autocomplete-time-picker-result {
  text-align: left;
  /* padding: 4px 8px; */
  margin-left: 10px;
  margin-right: 8px;
  /*list-style-type: none!important;*/
  list-style: none !important;
  cursor: pointer;
  background-color: rgba(91, 91, 91, 0);

  transition: background-color 0.45s ease-out;
}

.autocomplete-time-picker-result.is-active,
.autocomplete-time-picker-result:hover {
  border-radius: 2px;
  background-color: rgba(91, 91, 91, 0.15);
  transition: background-color 0.45s ease-out;
  /* background-color: #4AAE9B;
    color: white; */
}
.amToggle {
  width: 86px;

  position: relative;
  border-radius: 4px;
  background-color: rgba(91, 91, 91, 0.1);
}
.amToggleThumb {
  z-index: 0;
  width: 35px;

  position: absolute;
  border-radius: 3px;
  background-color: rgba(255, 255, 255, 1);
  -webkit-box-shadow: 0 1px 6px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0 1px 6px rgba(0, 0, 0, 0.15);
  box-shadow: 0 1px 6px rgba(0, 0, 0, 0.15);
}
.amC,
.pmC {
  width: 38px;
  padding-top: 7px !important;
  padding-left: 2px !important;
  cursor: pointer;
  text-align: center;
}
.amToggleCopyHolder {
  z-index: 1;
  position: absolute;
  width: 86px;
  /* pointer-events: none;
    user-select: none; */
}
.amFontBold {
  font-family: "Univers LT Bold", serif;
}
@media (orientation: landscape) {
  .amToggle {
    height: 30px;
  }
  .amC {
    font-size: 12px;
    /* height: 30px; */
  }
  .pmC {
    margin-left: 2px;
    font-size: 12px;
  }
  .amToggleCopyHolder {
    /* padding-top: 7px;
      padding-left: 15px; */

    height: 30px;
  }
  .amToggleThumb {
    top: 4px;
    left: 4px;
    height: 21px;
    transition: all 0.45s ease-out;
  }
  .pmActive {
    left: 46px;
    transition: all 0.45s ease-out;
  }
  .placeHolderItem {
    font-family: "Univers LT Std";
    color: rgba(99, 99, 99, 0.4);
    font-weight: 300;
    font-size: 13px;
    line-height: 18px;
  }
  .autocomplete-time-picker-result {
    /* padding: 4px 4px; */
    padding-top: 6px;
    padding-bottom: 2px;
  }
  li {
    /* border: 1px solid deeppink!important; */
    font-size: 13px;
    margin-top: 4px;
    margin-bottom: 1px !important;
  }
}

@media (orientation: portrait) {
  .amToggle {
    height: 34px;
  }
  .amPmC {
    font-size: 12px;
  }
  .amC {
    font-size: 12px;
    /* height: 30px; */
  }
  .pmC {
    margin-left: 4px;
    font-size: 12px;
  }
  .amToggleCopyHolder {
    height: 34px;
  }
  .amToggleThumb {
    top: 3px;
    height: 28px;
    left: 4px;
    transition: all 0.45s ease-out;
  }
  .pmActive {
    left: 47px;
    transition: all 0.45s ease-out;
  }
  .placeHolderItem {
    font-family: "Univers LT Std";
    color: rgba(99, 99, 99, 0.4);
    font-weight: 300;
    font-size: 16px;
    line-height: 22px;
  }
  .autocomplete-time-picker-result {
    padding: 4px 8px;
  }
  li {
    font-size: 16px;
    margin-top: 4px;
    margin-bottom: 1px !important;
  }
}

.collapseOpen {
  transform: rotate(180deg);
  transition: all 0.25s ease-in-out;
}
.collapseClosed {
  transform: rotate(0deg);
  transition: all 0.25s ease-in-out;
}
._desk {
  width: 12px;
  height: 12px;
}
._mobile {
  width: 18px;
  height: 18px;
}
</style>
