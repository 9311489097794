<template>
  <svg
    width="50"
    height="50"
    viewBox="0 0 50 50"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    name="PromiseIcon2"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M25 48C37.7025 48 48 37.7025 48 25C48 12.2975 37.7025 2 25 2C12.2975 2 2 12.2975 2 25C2 37.7025 12.2975 48 25 48ZM25 50C38.8071 50 50 38.8071 50 25C50 11.1929 38.8071 0 25 0C11.1929 0 0 11.1929 0 25C0 38.8071 11.1929 50 25 50Z"
      fill="url(#paint0_linear_3543_1328)"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12.7933 34.9084C15.6459 38.6256 20.0569 41 25 41C29.9431 41 34.3541 38.6256 37.2067 34.9084L38.7933 36.126C35.5863 40.3051 30.6033 43 25 43C19.3967 43 14.4137 40.3051 11.2067 36.126L12.7933 34.9084ZM25 9C20.0569 9 15.6459 11.3744 12.7933 15.0916L11.2067 13.874C14.4137 9.69489 19.3967 7 25 7C30.6033 7 35.5863 9.69489 38.7933 13.874L37.2067 15.0916C34.3541 11.3744 29.9431 9 25 9Z"
      fill="url(#paint1_linear_3543_1328)"
    />
    <path
      d="M19.6449 26.8307L18.6183 32.8001C18.5562 33.1786 18.7144 33.5619 19.0238 33.7869C19.3355 34.0154 19.7468 34.0447 20.089 33.8677L25.4527 31.0459L30.8164 33.8689C30.9629 33.9439 31.1281 33.9861 31.2875 33.9861C31.4949 33.9861 31.707 33.9228 31.8816 33.7881C32.1934 33.5619 32.3492 33.1787 32.2836 32.8037L31.257 26.8343L35.5953 22.598C35.8777 22.3308 35.9738 21.9289 35.8566 21.5621C35.7371 21.1929 35.4219 20.9234 35.0434 20.8718L29.0434 20L26.3621 14.5637C26.191 14.2192 25.8395 14 25.4528 14C25.0661 14 24.7145 14.2191 24.5434 14.5637L21.8645 20L15.8681 20.873C15.4872 20.9269 15.1685 21.1941 15.0489 21.5621C14.9318 21.9289 15.0278 22.3309 15.3068 22.5945L19.6449 26.8307ZM22.6812 21.9287C23.0105 21.8807 23.2952 21.6709 23.4429 21.3709L25.4526 17.2999L27.4624 21.3709C27.6089 21.6709 27.8972 21.8807 28.2241 21.9287L32.7205 22.5803L29.4661 25.748C29.2294 25.9812 29.1216 26.314 29.1755 26.6445L29.9431 31.1211L25.9237 29.0117C25.7737 28.9309 25.6178 28.8946 25.4526 28.8946C25.2909 28.8946 25.1315 28.9309 24.985 29.0117L20.962 31.1211L21.7331 26.6445C21.787 26.3176 21.6792 25.9812 21.439 25.748L18.187 22.577L22.6812 21.9287Z"
      fill="url(#paint2_linear_3543_1328)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_3543_1328"
        x1="38"
        y1="-1.53422e-06"
        x2="11.5"
        y2="50"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="var(--gradientFrom)" />
        <stop offset="1" stop-color="var(--gradientTo)" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_3543_1328"
        x1="38"
        y1="-1.53422e-06"
        x2="11.5"
        y2="50"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="var(--gradientFrom)" />
        <stop offset="1" stop-color="var(--gradientTo)" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_3543_1328"
        x1="38"
        y1="-1.53422e-06"
        x2="11.5"
        y2="50"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="var(--gradientFrom)" />
        <stop offset="1" stop-color="var(--gradientTo)" />
      </linearGradient>
    </defs>
  </svg>
</template>

<script>
export default {
  name: "PromiseIcon2",
};
</script>
