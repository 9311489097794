// TODO: migrate to pinia
import { DeviceOrientation } from "@/utils/enum";
import { breakpoints } from "@/utils/responsive.js";

const state = {
  screenWidth: 1,
  screenHeight: 1,
  orientation: DeviceOrientation.LANDSCAPE,
  forceMobileView: false,
  overlayAspects: {
    overlayWidth: 274,
  },
};

// =========================== GETTERS :::::::::::::::::::::::::
const getters = {
  DeviceOrientation: () => {
    return DeviceOrientation;
  },
  isLandscape: (state) => {
    return state.orientation === DeviceOrientation.LANDSCAPE;
  },
  isPortrait: (state) => {
    return state.orientation === DeviceOrientation.PORTRAIT;
  },
  isMobile: () => {
    return window.matchMedia("only screen and (max-width: 760px)").matches;
  },
  orientation: (state) => {
    return state.orientation;
  },
  overlayAspects: (state) => {
    return state.overlayAspects;
  },
  screenWidth: (state) => {
    return state.screenWidth;
  },
  screenHeight: (state) => {
    return state.screenHeight;
  },
  forceMobileView: (state) => {
    return state.forceMobileView;
  },
};

// ================== ACTIONS =========================
const actions = {
  updateWindowAspects({ commit, state, getters }) {
    // update height of body for webkit fix
    const doc = document.documentElement;
    if (doc) doc.style.setProperty("--app-height", `${window.innerHeight}px`);

    commit("setScreenWidth", window.innerWidth);
    commit("setScreenHeight", window.innerHeight);
    let orientation = DeviceOrientation.LANDSCAPE;
    if (window.innerHeight > window.innerWidth) {
      orientation = DeviceOrientation.PORTRAIT;
    }
    commit("setOrientation", orientation);
    if (orientation === DeviceOrientation.LANDSCAPE) {
      if (getters.screenWidth < breakpoints.tabletMinLandscapeWidth) {
        commit("setForceMobileView", true);
      }
    }

    setTimeout(() => {
      var _newRightPadding = 15;
      const screenScaleDiffToMaxWidth =
        state.screenWidth - breakpoints.maxRightPaddingDeskWidth;

      if (state.screenWidth >= breakpoints.maxRightPaddingDeskWidth) {
        _newRightPadding = breakpoints.maxDeskRightPadding;
      } else if (
        state.screenWidth < breakpoints.maxRightPaddingDeskWidth &&
        state.screenWidth > breakpoints.tabletMinLandscapeWidth
      ) {
        _newRightPadding =
          breakpoints.maxDeskRightPadding *
            (screenScaleDiffToMaxWidth / breakpoints.tabletMinLandscapeWidth) +
          breakpoints.minDeskRightPadding;
      } else {
        _newRightPadding = breakpoints.minMobileHorizontalPadding;
      }

      var _newWidth = 274;
      if (getters.isLandscape) {
        _newWidth = breakpoints.landscapePanelWidth;
      } else {
        if (state.screenWidth > breakpoints.maxPhoneWidth) {
          _newWidth = breakpoints.maxPortraitPanelWidth;
        } else {
          const tempWidth = state.screenWidth - _newRightPadding * 2;
          _newWidth =
            tempWidth > breakpoints.maxPortraitPanelWidth
              ? breakpoints.maxPortraitPanelWidth
              : tempWidth;
          _newWidth += 5;
        }
      }

      commit("SET_OVERLAY_ASPECTS", {
        overlayWidth: _newWidth,
      });
    }, 10);
  },
};

const mutations = {
  setScreenWidth(state, _data) {
    //console.log(' X -> setScreenWidth ', _data)
    state.screenWidth = _data;
  },
  setScreenHeight(state, _data) {
    //console.log(' X -> setScreenHeight ', _data)
    state.screenHeight = _data;
  },
  setOrientation(state, _data) {
    // console.log(' X -> setOrientation ', _data)
    state.orientation = _data;
  },
  setForceMobileView(state, _data) {
    //console.log(' X -> setForceMobileView ', _data)
    state.forceMobileView = _data;
  },
  SET_OVERLAY_ASPECTS(state, values) {
    state.overlayAspects = values;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
