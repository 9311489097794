<template>
  <div>
    <!-- <div class="pl15 pt15 pr15 pb10" :class="{fontMobile:isMobile === true, fontDesk:isMobile === false}">{{copy1}}</div> -->
    <div
      class="wrapper d-flex flex-column align-items-center"
      v-bind:style="{
        height: lottieContainerHeight + 'px',
        paddingTop: margin + '.px',
      }"
    >
      <div
        class="p-0"
        id="sendLottieHolder"
        ref="_lottieHolder"
        v-if="!showError"
      >
        <div class="lottieContainer _l1" :class="{ playerVisible: isSending }">
          <lottie-animation
            path="assets/ui/contact/lottie2/loading_loop.json"
            :loop="true"
            :autoPlay="false"
            :height="230"
            :width="230"
            @AnimControl="handleAnimation"
          />
        </div>
        <div
          class="lottieContainer _l2"
          :class="{ playerVisible: isSendingComplete }"
        >
          <lottie-animation
            path="assets/ui/contact/lottie2/check.json"
            :loop="false"
            :autoPlay="false"
            :height="230"
            :width="230"
            @AnimControl="handleCheckAnimation"
          />
        </div>
      </div>
    </div>

    <div
      class="d-flex flex-column justify-content-center align-items-center tfbt pt10"
      style="height: 60px"
      v-if="!showError"
    >
      <div
        class="p-0 ml15 mr15 pb10"
        :class="{ fontMobile: isMobile === true, fontDesk: isMobile === false }"
        v-if="showMessage"
      >
        {{ contact_locale.copy }}
      </div>
    </div>

    <div
      class="d-flex flex-column justify-content-center align-items-center"
      style="height: 60px"
      v-if="showError"
    >
      <div
        class="p-0 ml15 mr15"
        :class="{ fontMobile: isMobile === true, fontDesk: isMobile === false }"
      >
        {{ contact_locale.sendMailError }}
      </div>
    </div>

    <div
      class="p-0 d-flex flex-column ml15 mr15 mb20 justify-content-end"
      v-if="showError"
    >
      <div class="p-0 d-flex flex-fill justify-content-center">
        <div
          v-wave
          class="contact-next-button"
          :class="{ contextNextClickable: showError }"
          @click="onBackTapped"
        >
          <div class="contact-next-button-text">
            {{ contact_locale.buttonCopy }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { OverlaySteps } from "@/utils/enum.js";
import LottieAnimation from "lottie-vuejs/src/LottieAnimation.vue";
import { mailBody } from "@/utils/contactHelp";
import { EB } from "@/utils/EB";
import { mapGetters } from "vuex";

export default {
  name: "SendMail",
  props: ["resize", "backTapped"],
  components: {
    LottieAnimation,
  },
  data() {
    return {
      stepType: OverlaySteps.SEND_EMAIL,
      isSending: false,
      isSendingComplete: false,
      lottieContainerHeight: 200,
      margin: 30,
      showMessage: false,
      showError: false,
    };
  },

  created() {},

  mounted() {
    const _margin =
      this.isMobile === true
        ? (this.$store.state.constrains.screenWidth - 60) / 2 - 117
        : 20; // 500 panel breite / 2 = 250 - 117 halbe lottiebreite
    this.lottieContainerHeight = 230; // + _margin
    this.margin = _margin;
    this.$refs._lottieHolder.style.paddingLeft = _margin + ".px";
    setTimeout(() => {
      this.resize();
    }, 1100);
    setTimeout(() => {
      this.startLoadingLoop();
      /* this.resize()*/
      if (this.getInTouch.email == "jm@artificialrome.com") {
        /* this.showError = true;*/
      } else {
        this.sendMail();
      }
      //
    }, 800);
  },

  computed: {
    ...mapGetters([
      "isMobile",
      "getInTouch",
      "contact_locale",
      "currentLocale",
      "apiPath",
    ]),
    nextDisabled: function () {
      return true;
    },
  },
  methods: {
    async sendMail() {
      const mailObject = {
        mailBody: mailBody(this.currentLocale, this.getInTouch),
        mailSubject:
          this.currentLocale == "de"
            ? "Kontakt-Anfrage (Groz-Beckert Experience)"
            : "Contact request (Groz-Beckert Experience)",
        officeEmail: this.getInTouch.officeBranch.contact_email,
      };
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(mailObject),
      };
      fetch(`${this.apiPath}mailservice/form/`, requestOptions).then(
        (response) => response.json()
      );
    },
    onBackTapped() {
      this.backTapped();
    },
    loopComplete() {
      console.log("loopComplete");
    },
    handleAnimation: function (anim) {
      this.anim = anim;
      /* console.log(this.anim) */
      /* this.anim.play() */
    },
    handleCheckAnimation: function (anim) {
      this.anim2 = anim;
      this.anim2.goToAndStop(81);
      /* console.log(this.anim2.totalFrames) */
    },
    startLoadingLoop() {
      this.anim.playSegments([1, 81]);
      setTimeout(() => {
        this.isSending = false;
      }, 5550);
      setTimeout(() => {
        /* this.anim.pause(); */
        this.isSendingComplete = true;

        this.anim2.playSegments([81, 240], true);
        this.anim2.loop = false;
        this.anim2.addEventListener("complete", this.sendAniComplete);
        /* this.anim2.addEventListener('enterFrame', this.emterframe); */
        this.animation1Complete();
      }, 5400);
      this.isSending = true;
    },
    emterframe() {
      /* if(this.anim2.currentFrame > ) */
      /*  console.log(this.anim2.currentFrame) */
    },
    animation1Complete() {
      /* this.anim.pause(); */
    },
    sendAniComplete() {
      console.log("LOTTIE COMPLETE");
      this.anim2.removeEventListener("complete");
      /* this.anim2.pause(); */
      this.isSendingComplete = false;
      if (this.getInTouch.email == "jm@artificialrome.com") {
        this.lottieContainerHeight = 60;
        this.showError = true;
      } else {
        this.showMessage = true;
        setTimeout(() => {
          EB.$emit("closeProductContactOverlay", { yes: true });
        }, 3000);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
#sendLottieHolder {
  position: relative;

  width: 230px;
  height: 230px;
}
.lottieContainer {
  position: absolute;
  top: 0;
  left: 0;
  width: 230px;
  height: 230px;
  opacity: 0;
  transition: all 0.75s ease-out;
}
.playerVisible {
  opacity: 1;
  transition: all 0.75s ease-out;
}
._l1 {
  z-index: 0;
}
._l2 {
  z-index: 1;
}
</style>
