<template>
  <div id="fullscreenOverlay">
    <div id="fullscreenOverlayBackdrop"></div>
    <div id="innerFullscreen">
      <OverlayHeader
        v-if="activeAppointmentTitle"
        :title="activeAppointmentTitle"
        :closeIcon="true"
        @close="closeOverlay"
      />

      <div class="d-flex flex-column mt15">
        <div class="p-0 mt15 ml15 mr15 flex-fill pt16">
          <div style="color: #ff0000; text-align: center">
            {{ locale[message] }}
          </div>
        </div>

        <div
          class="p-0 flex-fill mt25 align-self-center"
          v-if="userData != null"
        >
          <div
            class="contact-next-button mt15 contextNextClickable"
            @click="gotoCreateTour"
          >
            <div class="contact-next-button-text">
              {{ locale.meeting_2_intro_button_2_presenter }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import OverlayHeader from "@/components/klein/OverlayHeader";
import { EB, EB_ACTION } from "@/utils/EB";
import { mapActions, mapGetters } from "vuex";
import { gsap } from "gsap";
import { DataProtectionTween, TweenVars } from "@/utils/tweenTime";

export default {
  name: "AppointmentErrorWin",
  components: {
    OverlayHeader,
  },
  data() {
    return {
      viewMode: null,
    };
  },

  props: ["message"],

  unmounted() {
    EB.$off(EB_ACTION.WIN_RESIZE);
  },

  mounted() {
    EB.$on(EB_ACTION.WIN_RESIZE, () => {
      this.winResized();
    });

    this.getAndSetLang(this.currentLocale);
    setTimeout(() => {
      this.fadeIn();
    }, 100);
  },

  computed: {
    ...mapGetters([
      "userData",
      "currentLocale",
      "isLandscape",
      "locale",
      "activeAppointmentTitle",
    ]),
  },

  methods: {
    ...mapActions(["exitMeeting", "closeSession", "getAndSetLang"]),

    winResized() {
      const elemt = document.getElementById("innerFullscreen");
      if (!elemt) {
        return;
      }
      const height = elemt.offsetHeight;
      const _width = this.isLandscape
        ? 500
        : this.$store.state.constrains.screenWidth - 30;
      gsap.to("#innerFullscreen", {
        width: _width,
        left: this.isLandscape
          ? this.$store.state.constrains.screenWidth / 2 - _width / 2
          : 15,
        top: this.isLandscape
          ? this.$store.state.constrains.screenHeight / 2 - height / 2
          : 60,
        duration: TweenVars.duration,
        ease: TweenVars.ease,
      });
    },
    fadeIn() {
      gsap.to("#fullscreenOverlayBackdrop", {
        opacity: 1,
        duration: DataProtectionTween.duration,
        ease: TweenVars.ease,
      });
      const elemt = document.getElementById("innerFullscreen");
      if (!elemt) {
        return;
      }
      const height = elemt.offsetHeight;
      //const width = elemt.offsetWidth;
      const _width = this.isLandscape
        ? 500
        : this.$store.state.constrains.screenWidth - 30;
      gsap.to("#innerFullscreen", {
        width: _width,
        left: this.isLandscape
          ? this.$store.state.constrains.screenWidth / 2 - _width / 2
          : 15,
        top: this.isLandscape
          ? this.$store.state.constrains.screenHeight / 2 - height / 2
          : 60,
        delay: 0.15,
        opacity: 1,
        duration: TweenVars.duration,
        ease: TweenVars.ease,
      });
    },
    fadeOut() {
      gsap.to("#fullscreenOverlayBackdrop", {
        opacity: 0,
        duration: 1,
        ease: TweenVars.ease,
      });
      gsap.to("#innerFullscreen", {
        bottom: 0,
        opacity: 0,
        duration: TweenVars.duration,
        ease: TweenVars.ease,
      });
    },
    closeOverlay() {
      this.fadeOut();
      setTimeout(() => {
        this.$emit("errorWinClosed");
      }, 1200);
    },
  },
};
</script>

<style scoped>
#fullscreenOverlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 99999999;
}
#fullscreenOverlayBackdrop {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999999991 !important;

  opacity: 0;
  background-color: rgba(255, 255, 255, 0.35);
  backdrop-filter: blur(10px);
}
#innerFullscreen {
  position: fixed;
  height: auto !important;
  left: 0;
  top: 0;
  opacity: 0;
  z-index: 999999992;
  background-color: rgba(255, 255, 255, 0.65);
  padding-bottom: 25px !important;
  color: #636363;
  font-family: "Univers LT Std";
}
</style>
