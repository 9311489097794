// TODO: migrate to pinia
import AgoraRTC from "agora-rtc-sdk-ng";
import AgoraRTM from "agora-rtm-sdk";
import { meeting_video_size } from "@/utils/responsive.js";
import { EB } from "@/utils/EB";

if (AgoraRTC) AgoraRTC.setLogLevel(3);

const ACTIVE_VOLUME_MIN_THRESHOLD = 10;

const USER_COLORS = [
  "#E15241",
  "#D63864",
  "#9031AA",
  "#613CB0",
  "#4350AF",
  "#4994EC",
  "#4BA6EE",
  "#54B9D1",
  "#429488",
  "#67AD5B",
  "#97C15C",
  "#D0DC59",
  "#FCEC60",
  "#F6C344",
  "#F19D38",
  "#EC6337",
  "#74574A",
  "#9E9E9E",
  "#667C89",
];

const initialOverlaysState = Object.freeze({
  deviceSettingsOverlay: false,
  resizeMeetingOverlay: false,
  userList: false,
});

const state = {
  currentCam: null,
  currentMic: null,
  micDeviceError: null,
  camDeviceError: null,
  devices: {},

  // A pair of 'uid' + 'order number' when the user has been connected.
  // This is needed to maintain a stable color for each user per client session.
  usersOrder: Object.create(null),

  // A pair of 'uid' + 'timestamp' when the user has spoken something.
  lastMicActivities: Object.create(null),

  params: {
    isVideoOn: true,
    isAudioOn: true,
    isClientReady: false,

    appId: "bce0a64b240849dba1bdde971d46603b",
    // Set the channel name.
    channel: "Nadelkopf",
    rtcTokenEndpoint: `${process.env.VUE_APP_API_URL}/api/gb-content/rtc/`,
    rtmTokenEndpoint: `${process.env.VUE_APP_API_URL}/api/gb-content/rtm/`,
    // TODO : et the user role in the channel.
    role: "host",
  },

  localVideoTrack: null,
  localAudioTrack: null,
  screenUser: null,

  rtmUsers: [],
  rtcUsers: [],
  rtcToken: null,
  client: null,
  rtmClient: null,
  rtmChannel: null,
  screenRtcClient: null,
  username: null,
  isSyncingWithRemote: false,
  showTooltip: false,
  tooltipText: "NO MESSAGE",

  clientHasMobileDevice: false,

  // this is a workaround for broken getters caching in vuex@3
  // @see: https://github.com/vuejs/vuex/issues/2102
  // ideally we don't need this state if `userId` getter were cached.
  // let's remove this after fully upgraded to pinia
  randomId: randomUserId(),

  meetingWinStyleNr: 0,
  clientScreenSize: {
    screenSize: {
      width: 0,
      height: 0,
    },
  },

  overlayViews: {
    ...initialOverlaysState,
  },
};

// =========================== GETTERS :::::::::::::::::::::::::
const getters = {
  usersOrder(state) {
    return state.usersOrder;
  },

  lastMicActivities(state) {
    return state.lastMicActivities;
  },

  client: (state) => {
    return state.client;
  },
  clientHasMobileDevice: (state) => {
    return state.clientHasMobileDevice;
  },
  clientScreenSize: (state) => {
    return state.clientScreenSize;
  },
  currentCam: (state) => {
    return state.currentCam;
  },
  currentMic: (state) => {
    return state.currentMic;
  },
  devices: (state) => {
    return state.devices;
  },
  canRunMeeting: (state, getters) => {
    // both `getters.otherUsers` and `getters.moderatorUser` are calculated based
    // on remote Agora users, which means the current client would never appear there.
    // So we use `isModerator` to encounter for the current client in our condition.
    const { isModerator } = getters;
    const hasParticipant = !isModerator || getters.otherUsers.length > 0;
    const hasModerator = isModerator || Boolean(getters.moderatorUser);

    return hasParticipant && hasModerator;
  },

  // FIXME: Tons of places in the code are looking at getters.isModerator, which doesn't seem to exist in here!

  meetingWinStyleNr: (state) => {
    return state.meetingWinStyleNr;
  },
  overlayViews: (state) => {
    return state.overlayViews;
  },
  tooltipText: (state) => {
    return state.tooltipText;
  },

  otherUsers: (state, getters) => {
    const appointmentData = getters.activeAppointmentData;
    return getters.users.filter(
      (u) =>
        String(u.uid) !== String(appointmentData.moderatorId) &&
        !u.attrs.screensharing
    );
  },

  remoteScreenUser: (state, getters) => {
    return getters.users.find((u) => u.attrs.screensharing);
  },

  moderatorUser: (state, getters) => {
    const appointmentData = getters.activeAppointmentData;
    return getters.users.find(
      (u) => String(u.uid) === String(appointmentData.moderatorId)
    );
  },

  // The rest of the users are remote and constructed against a mix of RTC and RTM users.
  // However, local user is not represented in the RTC users, and also we need to have user name
  // for a local user before the meeting starts.
  //
  // That's why we build the `myself` user based on a local state at the first place,
  myself: (state, getters) => {
    const rtmMember = state.rtmUsers.find((rtmUser) => {
      return String(rtmUser.uid) === String(getters.userId);
    });
    return {
      videoTrack: state.params.isVideoOn ? state.localVideoTrack : null,
      audioTrack: state.params.isAudioOn ? state.localAudioTrack : null,
      uid: rtmMember?.uid,
      attrs: {
        username: state.username,
        isLocal: true,
        ...rtmMember?.attrs,
      },
    };
  },

  screenUser(state) {
    return state.screenUser;
  },

  username(state) {
    return state.username;
  },

  userId(state, rootGetters) {
    if (rootGetters.meetingRole === "admin" && rootGetters.userData.uid) {
      return rootGetters.userData.uid;
    }

    return state.randomId;
  },

  users(state) {
    return state.rtcUsers
      .map((rtcUser) => {
        const rtmMember = state.rtmUsers
          .filter((rtmUser) => {
            // ignore users whose attributes hasn't been fully initialized yet
            return Boolean(rtmUser.attrs.username);
          })
          .find((rtmUser) => {
            return String(rtmUser.uid) === String(rtcUser.uid);
          });

        if (!rtmMember) {
          return null;
        }

        return {
          videoTrack: rtcUser.videoTrack,
          audioTrack: rtcUser.audioTrack,
          ...rtmMember,
        };
      })
      .filter(Boolean);
  },

  rtcParams: (state) => {
    return state.params;
  },
  showTooltip: (state) => {
    return state.showTooltip;
  },

  getCamWinSize: () => {
    return state.clientHasMobileDevice
      ? meeting_video_size.portrait.find(
          (item) => item.meetingWinStyleNr == state.meetingWinStyleNr
        )
      : meeting_video_size.landscape.find(
          (item) => item.meetingWinStyleNr == state.meetingWinStyleNr
        );
  },

  initialCamWinSizeMobile: () => {
    return meeting_video_size.portrait.find(
      (item) => item.meetingWinStyleNr == 2
    );
  },

  /** quickfix --.- fuer 27.7.2021
   * Breite auf 500
   *
   * TODO - remove
   */
  initialCamWinSizeDesk: () => {
    return {
      breakpoints: [
        {
          maxWidth: 5400,
          minWidth: 440,
          camWidth: 500,
          camHeight: 281,
          startTop: 45,
          startLeft: 70,
        },
      ],
    };
  },

  micDeviceError: (state) => {
    return state.micDeviceError;
  },
  camDeviceError: (state) => {
    return state.camDeviceError;
  },
};

// ================== ACTIONS =========================
const actions = {
  setUsername({ commit }, username) {
    commit("username", username);
  },

  remoteUserLeft({ getters, dispatch }) {
    if (!getters.canRunMeeting) {
      setTimeout(() => {
        const { locale, isModerator } = getters;
        return dispatch("confirm", {
          title: locale.meeting_6_recipient_hl_1,
          description: isModerator
            ? locale.meeting_6_presenter_copy_1
            : locale.meeting_6_recipient_copy_1,
          confirmText: locale.meeting_6_recipient_button_1,
          onConfirm: () => {
            return dispatch("exitMeeting").then(() => {
              return dispatch("closeSession");
            });
          },
        });
      }, 100);
    }
  },

  /**
   *  Device Check
   */

  videoCheck({ commit }) {
    return AgoraRTC.getCameras().catch((e) => {
      commit("setCamDeviceError", e.code);
      return e.code;
    });
  },

  audioCheck({ commit }) {
    return AgoraRTC.getMicrophones().catch((e) => {
      commit("setMicDeviceError", e.code);
      return e.code;
    });
  },

  /**
   *  Find all audio and video devices from system
   * - and set it to store
   */
  async getDevices({ commit }) {
    console.log("MeetingX -> getDevices ");

    /*      AgoraRTC.getDevices().then(
        payload =>{
          console.log(payload)
        }
      ).catch( e => {
        console.log('DEVICES ERROR ', e)
      }) */
    let devices = [];
    // get mics
    let mics = await AgoraRTC.getMicrophones();
    devices.mics = mics;

    // get cameras
    let vids = await AgoraRTC.getCameras();
    devices.vids = vids;

    commit("setDevices", devices);
    if (mics.length > 0) {
      commit("setCurrentMic", mics[0]);
    }
    if (vids.length > 0) {
      commit("setCurrentCam", vids[0]);
    }
    console.log("MeetingX  -> audioDevices ", devices.mics);
    console.log("MeetingX  -> videoDevices ", devices.vids);
  },

  showTooltipAndHideLater({ commit }, _text) {
    // this._vm.$matomo.trackEvent('Buttons', 'tooltip', _text, 11)
    commit("showTooltip", _text);
    setTimeout(
      function () {
        commit("hideTooltip"); //jetzt vue X : vorher local ->  this.showTooltip = true;
      }.bind(this),
      5000
    );
  },

  // debug
  toggleClientRatio({ commit }, val) {
    commit("toggleClientRatio", val);
  },

  tryModeratorLogin({ getters, dispatch }) {
    const rtmClient = AgoraRTM.createInstance(getters.rtcParams.appId, {
      enableLogUpload: false,
      logFilter: AgoraRTM.LOG_FILTER_ERROR,
    });

    return dispatch("joinRtm", {
      userId: randomUserId(),
      client: rtmClient,
      attributes: {
        isModerator: "yes",
      },
    }).finally(() => {
      return rtmClient.logout().catch(() => {
        // swallow possible errors, cause it's not really critical to the workflow,
        // it's just a resource cleanup
      });
    });
  },

  async join({ getters, commit, dispatch }) {
    const userRole = getters.isModerator ? 1 : 2;
    const userId = getters.userId;

    if (getters.isModerator) {
      commit("setMeetingWinStyleNr", 2);
    }

    const rtmClient = AgoraRTM.createInstance(getters.rtcParams.appId, {
      enableLogUpload: false,
      logFilter: AgoraRTM.LOG_FILTER_ERROR,
    });
    commit("rtmClient", rtmClient);

    await dispatch("joinRtm", {
      userId,
      client: rtmClient,
      attributes: {
        username: state.username,
        ...(getters.isModerator ? { isModerator: "yes" } : null),
      },
    });

    await dispatch("joinRtc", { userRole, userId });

    commit("setParam", { isClientReady: true });
    if (getters.canRunMeeting) {
      dispatch("startMeetingWithRemote");
    }

    window.addFakeUser = async (username) => {
      const userId = randomUserId();
      if (!username) {
        username = `fake user (${userId})`;
      }

      const rtmClient = AgoraRTM.createInstance(getters.rtcParams.appId, {
        enableLogUpload: false,
        logFilter: AgoraRTM.LOG_FILTER_ERROR,
      });
      await dispatch("joinRtm", {
        userId,
        attributes: { username },
        client: rtmClient,
      });

      const clientConfig = { mode: "rtc", codec: "vp8" };
      let rtcClient = AgoraRTC.createClient(clientConfig);
      await dispatch("joinRtc", { userRole: 2, userId, rtcClient });
    };
  },

  async exitMeeting({ commit, state, dispatch }) {
    await state.client.leave();
    await state.rtmClient.logout();
    await state.screenRtmClient?.logout();
    await state.screenRtcClient?.leave();

    commit("rtcUsers", []);
    commit("rtmUsers", []);
    commit("screenRtcClient", null);
    commit("screenRtmClient", null);

    commit("setParam", { isClientReady: false });
    commit("setMeetingWinStyleNr", 0);
    dispatch("stopLocal");
    dispatch("closeLocal");
  },

  async publishVideo({ commit, state }) {
    if (!state.localVideoTrack) {
      // no Video - no Publish
      console.log(" X -> publishVideo : FAILURE - NO localVideoTrack");
      return;
    }

    commit("setParam", { isVideoOn: true });

    if (state.params.isClientReady === true) {
      await state.client.publish(state.localVideoTrack);
      console.log(" X -> publishVideo - video published");
    } else {
      console.log(
        " X -> publishVideo - video NOT published - NO MEETING ACTIVCE"
      );
    }
  },
  /**  Unpublish the video, the audio is still being published
   *   and stop it local
   *     */
  async unpublishVideo({ commit, dispatch, state }) {
    if (!state.localVideoTrack) {
      console.log(" X -> unpublishVideo : FAILURE - NO localVideoTrack");
      return;
    }

    if (state.params.isClientReady === true) {
      await state.client.unpublish(state.localVideoTrack);
    }

    dispatch("stopLocal");
    commit("setParam", { isVideoOn: false });
    console.log(" X -> unpublishVideo end");
  },

  async publishAudio({ commit, state }) {
    if (!state.localAudioTrack) {
      console.log(" X -> publishVideo : FAILURE - NO localAudioTrack");
      return;
    }
    commit("setParam", { isAudioOn: true });

    if (state.params.isClientReady === true) {
      await state.client.publish(state.localAudioTrack);
      console.log(" X -> publishAudio - Audio published");
    } else {
      console.log(
        " X -> publishAudio - Audio NOT published - NO MEETING ACTIVE"
      );
    }
  },

  async muteAudio({ commit, state }) {
    if (!state.localAudioTrack) {
      console.log(" X -> publishVideo : FAILURE - NO localAudioTrack");
      return;
    }

    if (state.params.isClientReady === true) {
      await state.client.unpublish(state.localAudioTrack);
    }

    commit("setParam", { isAudioOn: false });
    console.log(" X -> muteAudio");
  },

  toggleCamState({ state, dispatch }) {
    if (state.params.isVideoOn === true) {
      dispatch("unpublishVideo");
    } else {
      dispatch("publishVideo");
    }
  },

  toggleMicState({ state, dispatch }) {
    if (state.params.isAudioOn === true) {
      dispatch("muteAudio");
    } else {
      dispatch("publishAudio");
    }
  },

  /** show the local video track on screen */
  stopLocal({ state }) {
    if (state.screenUser?.videoTrack) {
      state.screenUser?.videoTrack.stop();
    }

    if (state.localVideoTrack) {
      state.localVideoTrack.stop();
      console.log(" X -> stopLocal -> !! localVideoTrack STOPPED  !!");
    } else {
      console.log(" X -> stopLocal -> !! FAILURE - NO localVideoTrack !!");
    }
  },
  /** close the local video track */
  closeLocal({ state }) {
    if (state.screenUser?.videoTrack) {
      state.screenUser?.videoTrack.close();
    }

    if (state.localVideoTrack) {
      state.localVideoTrack.close();
      console.log(" X -> closeLocal -> !! localVideoTrack removed  !!");
    } else {
      console.log(" X -> closeLocal -> !! FAILURE - NO localVideoTrack !!");
    }
    if (state.localAudioTrack) {
      state.localAudioTrack.close();
      console.log(" X -> closeLocal -> !! localAudioTrack removed  !!");
    } else {
      console.log(" X -> closeLocal -> !! FAILURE - NO localAudioTrack !!");
    }
  },

  async startScreensharing({ commit, dispatch, getters, state }) {
    const { username } = state;
    const userId = randomUserId();

    const screenTrack = await AgoraRTC.createScreenVideoTrack();
    screenTrack.on("track-ended", () => {
      dispatch("stopScreensharing");
    });

    commit("screenUser", {
      videoTrack: screenTrack,
      uid: userId,
      attrs: {
        username,
      },
    });

    const clientConfig = { mode: "rtc", codec: "vp8" };
    const rtcClient = AgoraRTC.createClient(clientConfig);
    await dispatch("joinRtc", { userRole: 2, userId, rtcClient });

    const rtmClient = AgoraRTM.createInstance(getters.rtcParams.appId, {
      enableLogUpload: false,
      logFilter: AgoraRTM.LOG_FILTER_ERROR,
    });
    await dispatch("joinRtm", {
      userId,
      attributes: { username, screensharing: "screensharing" },
      client: rtmClient,
    });

    commit("screenRtcClient", rtcClient);
    commit("screenRtmClient", rtmClient);

    return rtcClient.publish(screenTrack);
  },

  async stopScreensharing({ state, commit }) {
    await state.screenRtmClient.logout();
    commit("screenRtmClient", null);

    state.screenRtcClient.unpublish(state.screenUser.videoTrack);
    await state.screenRtcClient.leave();
    commit("screenRtcClient", null);

    state.screenUser?.videoTrack.stop();
    state.screenUser?.videoTrack.close();
    commit("screenUser", null);
  },

  async createLocals({ commit }) {
    let taudio = await AgoraRTC.createMicrophoneAudioTrack();
    let tvideo = await AgoraRTC.createCameraVideoTrack();
    commit("setLocalVideoTrack", tvideo);
    commit("setLocalAudioTrack", taudio);
  },

  /**
   * Switch to selected camare Device
   *
   * stop the local track if exist
   * stop the remote track if exist
   * change device
   * start local track
   * start remote if streamed before
   *
   */
  async switchCamDevice({ commit, dispatch, state }, device) {
    console.log(" X -> switchCamDevice ", device);
    // stop the current video track if exist
    let videoOn = false;
    // stop remote
    if (state.params.isVideoOn === true) {
      dispatch("unpublishVideo");
      videoOn = true;
    }
    // stop local
    if (state.localVideoTrack) {
      state.localVideoTrack.stop();
    }
    // change
    var selectedCameraId = device.deviceId;
    var videoTrack = await AgoraRTC.createCameraVideoTrack({
      cameraId: selectedCameraId,
    });
    commit("setLocalVideoTrack", videoTrack);
    // start remote video
    if (videoOn === true) {
      dispatch("publishVideo");
    }
  },

  /**
   * Switch to selected Mic Device
   *
   *  TODO -- Publish unpublish ?
   */
  async switchMicDevice({ commit, state }, device) {
    // stop the current video track if exist
    if (state.localAudioTrack) {
      state.localAudioTrack.stop();
    }
    var selectedMicrophoneId = device.deviceId;
    var auidoTrack = await AgoraRTC.createMicrophoneAudioTrack({
      microphoneId: selectedMicrophoneId,
    });

    commit("setLocalAudioTrack", auidoTrack);
  },

  startMeetingWithRemote({ dispatch, getters }) {
    // publish is allowed
    if (getters.rtcParams.isVideoOn) {
      setTimeout(() => {
        dispatch("publishVideo");
      }, 1000);
    }
    if (getters.rtcParams.isAudioOn) {
      setTimeout(() => {
        dispatch("publishAudio");
      }, 1500);
    }
  },

  createRtcClient({ commit, dispatch, getters }) {
    let client = AgoraRTC.createClient({ mode: "rtc", codec: "vp8" });
    commit("setClient", client);
    console.log(" X -> createRtcClient : ", client);

    dispatch("createLocals");

    // renewToken
    client.on("token-privilege-will-expire", async () => {
      client.renewToken();
    });

    // show the remote user
    client.on("user-published", async (user, mediaType) => {
      if (String(user.uid) !== String(state.screenUser?.uid)) {
        await client.subscribe(user, mediaType);
        // If the subscribed track is an audio track

        // force remote users to re-render
        commit("rtcUsers", client.remoteUsers);
      }
    });

    // remote user joined channel
    client.on("user-joined", async (/*user*/) => {
      // force remote users to re-render
      commit("rtcUsers", client.remoteUsers);

      if (getters.canRunMeeting) {
        dispatch("startMeetingWithRemote");
      }

      if (getters.isModerator) {
        dispatch("notifyHostState");
      }
    });
    // remote user unpublish video or audio stream
    client.on("user-unpublished", async (/*user, mediaType*/) => {
      // force remote users to re-render
      commit("rtcUsers", client.remoteUsers);
      /*    console.log('AgoraClientOn -> USER-> -unpublished ', user) */
    });
    // remote user exit the channel
    client.on("user-left", async (/*user, reason*/) => {
      // force remote users to re-render
      commit("rtcUsers", client.remoteUsers);
      /*         console.log(this.$options.name + ' -> AgoraClientOn -> user-left : reason: ' + reason, user) */

      dispatch("remoteUserLeft");
    });

    client.on("exception", function (evt) {
      console.log("  AgoraClientOn ->  !!!!! ERROR !!!!! ", evt);
    });
  },

  /**
   *  Fetch a rtcToken from the Golang server.
   * METHOD GET
   *  /rtc/:channel/:role/:uid
   *  role 1 -> RtcRole.PUBLISHER;
   *  role 2 -> RtcRole.SUBSCRIBER;
   */
  fetchRtcToken: async function ({ getters }, { uid, role }) {
    let rtcTokenEndpoint = getters.rtcParams.rtcTokenEndpoint;

    return fetch(
      `${rtcTokenEndpoint}${getters.sessionChannelId}/${role}/${uid}`
    )
      .then((response) => response.json())
      .then(function (response) {
        return response.rtcToken;
      });
  },

  /**
   * Fetch RTM token
   * METHOD GET /rtm/:uid
   */
  fetchRtmToken({ getters }, uid) {
    let rtmTokenEndpoint = getters.rtcParams.rtmTokenEndpoint;

    return fetch(`${rtmTokenEndpoint}${uid}`)
      .then((response) => response.json())
      .then(function (response) {
        return response.rtmToken;
      });
  },

  joinRtc: async function (
    { getters, dispatch, state, commit },
    { userRole, userId, rtcClient }
  ) {
    const client = rtcClient ?? state.client;

    const rtcToken = await dispatch("fetchRtcToken", {
      uid: userId,
      role: userRole,
    });

    await client.join(
      getters.rtcParams.appId,
      "" + getters.sessionChannelId,
      rtcToken,
      userId
    );

    client.enableAudioVolumeIndicator();
    client.on("volume-indicator", (volumes) => {
      const lastMicActivities = volumes.filter((volume) => {
        return volume.level > ACTIVE_VOLUME_MIN_THRESHOLD;
      });

      commit("lastMicActivities", lastMicActivities);
    });
  },

  async syncUsersAttrs({ commit, dispatch, getters }, { client, channel }) {
    const memberNames = await channel.getMembers();

    return Promise.all(
      memberNames.map((uid) => {
        return dispatch("checkUserOrder", uid).then((order) => {
          const color = USER_COLORS[order % USER_COLORS.length];

          return client.getUserAttributes(uid).then((attrs) => {
            return {
              attrs,
              uid,
              color,
            };
          });
        });
      })
    ).then((members) => {
      if (getters.isModerator) {
        const existingModerators = members.filter((m) => m.attrs.isModerator);
        if (existingModerators.length > 1) {
          throw new Error(getters.locale.meeting_concurrent_organizers_error);
        }
      }
      commit("rtmUsers", members);
    });
  },

  async joinRtm({ getters, dispatch, commit }, { userId, attributes, client }) {
    const uid = `${userId}`;

    return dispatch("fetchRtmToken", uid)
      .then((token) => {
        return client.login({ uid, token });
      })
      .then(() => {
        if (attributes) {
          return client.setLocalUserAttributes(attributes);
        }
      })
      .then(() => {
        const channel = client.createChannel("" + getters.sessionChannelId);
        return channel.join().then(() => channel);
      })
      .then((channel) => {
        console.log("AgoraRTM client channel join success.");

        // Display channel member joined updated users
        channel.on("MemberJoined", () => {
          // use `setTimeout(` cause username attribute seems to be acceible
          // a bit late accidentally. If the attribute gets accessible later,
          // unfortunately does not seem possible to trigger recalculation.
          //
          // At the same time we need username to detect if the member is fully
          // initialized and can be shown to the others.
          setTimeout(() => {
            dispatch("syncUsersAttrs", { client, channel });
            commit("resetInnerOverlayComponent");
          }, 100);
        });

        channel.on("ChannelMessage", (message, originUid) => {
          if (`${originUid}` === uid) {
            return;
          }

          const command = JSON.parse(message.text);

          switch (command.name) {
            case "HIGHLIGHT_VIDEO_PLAY":
              EB.$emit("HIGHLIGHT_VIDEO_PLAY");
              break;
            case "HIGHLIGHT_VIDEO_PAUSE":
              EB.$emit("HIGHLIGHT_VIDEO_PAUSE");
              break;
            case "HIGHLIGHT_VIDEO_SEEKED":
              EB.$emit("HIGHLIGHT_VIDEO_SEEKED", command.time);
              break;
            case "OVERLAY_ACTION_HEADER_TAPPED":
              dispatch("baseOverlayHeaderTapped", null, { root: true });
              break;
            case "OVERLAY_ACTION_TOGGLE_TAPPED":
              dispatch("baseOverlayToggleButtonTapped", null, { root: true });
              break;
            case "PRODUCT_OVERLAY_ACTION":
              commit("productOverlayAction", command.action, {
                root: true,
              });
              break;
            case "hostState":
              EB.$emit("HOST_STATE_UPDATED", command.options);
              break;
            default:
              console.error("unknown ChannelMessage", command);
          }
        });

        // Display channel member left updated users
        channel.on("MemberLeft", () => {
          dispatch("syncUsersAttrs", { client, channel });
        });

        commit("rtmChannel", channel);

        client.on("MessageFromPeer", function (message, peerId) {
          if (`${getters.moderatorUser?.uid}` !== `${peerId}`) {
            throw new Error("message received from not a moderator");
          }

          if (message.text === "DISCONNECT") {
            dispatch("exitMeeting");
          }

          if (message.text === "STOP_SCREEN_SHARING") {
            dispatch("stopScreensharing");
          }

          if (message.text === "TOGGLE_MIC") {
            dispatch("toggleMicState");
          }
        });

        return dispatch("syncUsersAttrs", { client, channel });
      })
      .catch((err) => {
        console.error("AgoraRTM client login failure: ", err);
        throw err;
      });
  },

  stopRemoteScreen({ state }, user) {
    state.rtmClient.sendMessageToPeer(
      { text: "STOP_SCREEN_SHARING" },
      `${user.uid}`
    );
  },

  syncWithRemote({ commit }, cb) {
    const syncTimeCredit = 1000; // ms
    commit("isSyncingWithRemote", true);

    return Promise.resolve(cb()).finally(() => {
      setTimeout(() => commit("isSyncingWithRemote", false), syncTimeCredit);
    });
  },

  sendToRtmChannel({ state }, { name, data }) {
    if (state.isSyncingWithRemote) {
      return;
    }

    if (!state.rtmChannel) {
      return;
    }

    state.rtmChannel.sendMessage({
      text: JSON.stringify({ name, ...data }),
    });
  },

  notifyActionHeaderTapped({ state }, value) {
    state.rtmChannel.sendMessage({
      text: JSON.stringify({ name: "OVERLAY_ACTION_HEADER_TAPPED", value }),
    });
  },

  notifyActionToggleTapped({ state }, value) {
    state.rtmChannel.sendMessage({
      text: JSON.stringify({ name: "OVERLAY_ACTION_TOGGLE_TAPPED", value }),
    });
  },

  notifyProductOverlayAction({ state }, action) {
    state.rtmChannel.sendMessage({
      text: JSON.stringify({ name: "PRODUCT_OVERLAY_ACTION", action }),
    });
  },

  notifyHostState({ state, rootGetters, getters }) {
    state.rtmChannel.sendMessage({
      text: JSON.stringify({
        name: "hostState",
        options: {
          ref: location.hash,
          layoutNr: getters.meetingWinStyleNr,
          lang: rootGetters.lang,
        },
      }),
    });
  },

  kickUser({ state, dispatch, getters }, user) {
    const { locale } = getters;
    return dispatch("confirm", {
      title: locale.meeting_kick_user_confirmation_title,
      description: locale.meeting_kick_user_confirmation_description.replace(
        "[username]",
        user.attrs.username
      ),
      confirmText: locale.meeting_kick_user_confirmation_confirm_text,
      cancelText: locale.meeting_kick_user_confirmation_cancel_text,
      onConfirm() {
        state.rtmClient.sendMessageToPeer(
          { text: "DISCONNECT" },
          `${user.uid}`
        );
      },
    });
  },

  toggleRemoteMic({ state }, user) {
    state.rtmClient.sendMessageToPeer({ text: "TOGGLE_MIC" }, `${user.uid}`);
  },

  setClientScreenSize({ commit }, values) {
    commit("setClientScreen", values);
    console.log(values);
  },

  /**
   *  - hide start button if needed
   *  - change local view
   *  - change remote view via webSox
   */
  changeLayout({ commit, dispatch }, _nr) {
    commit("setMeetingWinStyleNr", _nr);

    // NOTE: In commit 119a3c0ddd06282a9410ce17f2051917b9bf8acf, the code here was changed to check for
    // getters.isModerator and only execute the dispatch if that is true. But getters.isModerator does not exist!
    return dispatch(
      "sendActionViaWebsox",
      { name: "setLayout", layoutNr: _nr },
      { root: true }
    );
  },

  /** hide all overlays and toggle the given one */
  toggleOverlay({ commit }, overlayName) {
    commit("overlayVisibility", {
      [overlayName]: !state.overlayViews[overlayName],
    });
  },

  resetOverlayVisibility({ commit }, overlayVisibility) {
    commit("overlayVisibility", overlayVisibility);
  },

  checkUserOrder({ state, commit }, uid) {
    const { usersOrder } = state;

    if (typeof usersOrder[uid] === "number") {
      return usersOrder[uid];
    }

    const order = Object.values(usersOrder).length;
    commit("userOrder", {
      [uid]: order,
    });

    return order;
  },
}; // ------- END ACTIONS ------

const mutations = {
  isSyncingWithRemote(state, value) {
    state.isSyncingWithRemote = value;
  },

  lastMicActivities(state, lastMicActivities) {
    state.lastMicActivities = lastMicActivities.reduce((all, volume) => {
      return {
        ...all,
        [volume.uid]: Date.now(),
      };
    }, state.lastMicActivities);
  },

  // debug client size
  toggleClientRatio(state, val) {
    state.clientHasMobileDevice = val;
  },

  username(state, value) {
    state.username = value;
  },

  userOrder(state, keyValueUserOrder) {
    state.usersOrder = {
      ...state.usersOrder,
      ...keyValueUserOrder,
    };
  },

  /** merge _data params to store
   * bsp:  data = {paramname : value} */
  setParam(state, _data) {
    console.log(" X -> setParam - (merge)", _data);
    for (var prop in _data) {
      // skip loop if the property is from prototype
      if (!Object.prototype.hasOwnProperty.call(_data, prop)) continue;
      // set properties to existing param obj
      state.params = {
        ...state.params,
        [prop]: _data[prop],
      };
    }
  },

  screenRtcClient(state, client) {
    state.screenRtcClient = client;
  },

  screenRtmClient(state, client) {
    state.screenRtmClient = client;
  },

  screenUser(state, screenUser) {
    state.screenUser = screenUser;
  },

  setLocalVideoTrack(state, _data) {
    state.localVideoTrack = _data;
    console.log(" X -> setLocalVideoTrack ");
  },
  setLocalAudioTrack(state, _data) {
    state.localAudioTrack = _data;
    console.log(" X -> setLocalAudioTrack ");
  },
  rtmClient(state, _data) {
    state.rtmClient = _data;
  },
  rtmChannel(state, _data) {
    state.rtmChannel = _data;
  },
  setClient(state, _data) {
    state.client = _data;
    console.log(" X -> setClient ");
  },
  setDevices(state, _data) {
    console.log(" X -> setCurrentCam ", _data);
    state.devices = _data;
  },

  // closeDevices(state) {
  //   console.log(' X -> closeDevices ', _data)
  //   state.currentCam.  = _data
  // },

  setCurrentCam(state, _data) {
    console.log(" X -> setCurrentCam ", _data);
    state.currentCam = _data;
  },
  setCurrentMic(state, _data) {
    console.log(" X -> setCurrentMic ", _data);
    state.currentMic = _data;
  },

  rtcUsers(state, users) {
    state.rtcUsers = users.filter((rtcUser) => {
      // since local screen user is handled by a dedicated RTC client,
      // we need to filter it out and treat in a special way
      // via a lock `screenUser` state
      return String(rtcUser.uid) !== String(state.screenUser?.uid);
    });
  },

  rtmUsers(state, rtmUsers) {
    state.rtmUsers = rtmUsers;
  },

  setClientScreen(state, values) {
    state.clientScreenSize = values;
  },

  /** set the layout nr for client view */
  setMeetingWinStyleNr(state, _nr) {
    state.meetingWinStyleNr = _nr;
  },

  showTooltip(state, _text) {
    state.tooltipText = _text;
    state.showTooltip = true;
  },
  hideTooltip(state) {
    state.showTooltip = false;
  },

  // ------ OVERLAY VIEWS --------
  overlayVisibility(state, overlayState) {
    state.overlayViews = {
      ...initialOverlaysState,
      ...overlayState,
    };
  },

  setCamDeviceError(state, value) {
    state.camDeviceError = value;
  },

  setMicDeviceError(state, value) {
    state.micDeviceError = value;
  },
}; // END MUTATIONS

function randomUserId() {
  return parseInt(Math.random() * 1000000, 10);
}

export default {
  state,
  getters,
  actions,
  mutations,
};
