// TODO: migrate to pinia
import locale from "./xmodules/locale.module.js";
import meeting from "./xmodules/meeting.module.js";
import websox from "./xmodules/websox.module.js";
import constrains from "./xmodules/constrains.module.js";
import getInTouch from "./xmodules/getInTouch.module.js";
import baseOverlayStateMod from "./xmodules/baseoverlay.moduleX";
import hashConnector from "./xmodules/hashState.moduleX";
import _ from "lodash";
import { differenceInHours } from "date-fns";
import { createStore } from "vuex";

const UserI = "authI";
const SESSION_CHANNEL = "SESSION_CHANNEL";

export const Store = {
  modules: {
    locale,
    meeting,
    websox,
    getInTouch,
    constrains,
    baseOverlayStateMod,
    hashConnector,
  },

  state: {
    countries: null,
    currentLocale: "de",
    showLoginOverlay: false,
    onLoginSuccess: null,
    user: null,
    manuelNewPasswordMode: false,
    appointments: [],
    sessionChannelId: null,
    sessionUid: null,
    isModerator: false,
    isSocketClusterActive: false,
    loginError: false,
    consentAccepted: false,
    meetingRole: "",
    userSession: {},
    userSessionCloseStore: {},
    signupEmail: "",
    debug: false,
    innerOverlayComponent: null,
    showFullscreenOverlay: false,
    activeAppointmentData: null,
    changePasswordResponse: null,
    MATOMO: null,
    matomoDev: false,
    lastMatomoEvent: null,
    matomoTimeout: null,
    awsPath: true,
    apiPath: `${process.env.VUE_APP_API_URL}/api/gb-content/`,
  },

  mutations: {
    countries(state, countries) {
      state.countries = countries;
    },

    openLoginOverlay(state, onSuccess) {
      state.showLoginOverlay = true;
      state.onLoginSuccess = onSuccess;
    },

    closeLoginOverlay(state) {
      state.showLoginOverlay = false;
      state.onLoginSuccess = null;
    },

    toggleNewPasswordMode(state, value) {
      state.manuelNewPasswordMode = value;
    },
    appointments(state, data) {
      state.appointments = data;
    },
    // SET_GLOBAL_PRESENCE_GROUP(state,index){
    //   state.global_presence_group = index;
    // },
    setUser(state, User) {
      state.user = User;
      if (state.sessionChannelId != null) {
        state.userSession.uId = state.user.uid;
        state.userSessionCloseStore.uId = state.user.uid;
        state.userSessionCloseStore.isModerator = true;
        state.isModerator = true;
        window.sessionStorage.removeItem(SESSION_CHANNEL);
        window.sessionStorage.setItem(
          SESSION_CHANNEL,
          JSON.stringify(state.userSession)
        );
      }
      window.sessionStorage.removeItem(UserI);
      window.sessionStorage.setItem(UserI, JSON.stringify(User));
    },
    removeUser(state) {
      state.user = null;
      window.sessionStorage.removeItem(UserI);
    },
    setLoginError(state, value) {
      state.loginError = value;
    },

    sessionChannel(state, channel) {
      state.userSession = {
        ...channel,
        uId: parseInt(channel.uId),
      };
      state.sessionChannelId = channel.channelId;
      state.userSessionCloseStore = {
        channelId: [channel.channelId, channel.uId].filter(Boolean).join("-"),
      };
      window.sessionStorage.removeItem(SESSION_CHANNEL);

      window.sessionStorage.setItem(SESSION_CHANNEL, JSON.stringify(channel));
    },
    closeSessionChannel(state) {
      state.sessionChannelId = null;
      window.sessionStorage.removeItem(SESSION_CHANNEL);
      state.userSession = null;
    },
    // websockets
    setWebsocketsStartet(state) {
      state.isSocketClusterActive = true;
    },
    setWebsocketsStopped(state) {
      state.isSocketClusterActive = false;
    },
    setSocketsModerator(state) {
      state.isModerator = true;
    },

    setConsentAccepted(state) {
      state.consentAccepted = true;
    },
    setMeetingRole(state, role) {
      state.meetingRole = role;
    },
    setSignupEmail(state, email) {
      state.signupEmail = email;
    },

    // MAIN LOCALE
    setMainLocale(state, _locale) {
      state.currentLocale = _locale;
    },
    /***************************************************/

    // inneroverlay comp
    setInnerOverlayComponent(state, comp) {
      state.innerOverlayComponent = comp;
      if (!state.showFullscreenOverlay) {
        state.showFullscreenOverlay = true;
      }
    },
    resetInnerOverlayComponent(state) {
      if (state.showFullscreenOverlay) {
        state.showFullscreenOverlay = false;
      }
      state.innerOverlayComponent = null;
    },

    /// meeting details

    noActiveAppointment(state) {
      state.activeAppointmentData = null;
    },

    activeAppointment(state, data) {
      state.activeAppointmentData = data;
    },

    /****************need if clicked footer login**********************/
    setChangePasswordResponse(state, value) {
      state.changePasswordResponse = value;
    },
    setMatomo(state, value) {
      state.MATOMO = value;
    },
    setLastMatomo(state, value) {
      state.lastMatomoEvent = value;
    },

    matomoTrackEvent(state, value) {
      if (!state.MATOMO || !value.length) return;

      const matomoData = value.length === 1 ? [...value, " "] : [...value];

      state.MATOMO.trackEvent(...matomoData);
    },
  },

  /***
   *
    ACTIONS
   *
  ***/
  actions: {
    confirm({ dispatch }, options) {
      return new Promise((resolve) => {
        const finish = (...args) => {
          return dispatch("resetInnerOverlayComponent").then(() => {
            return resolve(...args);
          });
        };

        const onConfirm = (...args) => {
          return Promise.resolve(options.onConfirm(...args)).then(finish);
        };

        const onCancel = (...args) => {
          return Promise.resolve(
            options.onCancel && options.onCancel(...args)
          ).then(finish);
        };

        return dispatch("setInnerOverlayComponent", {
          path: "./inner_overlay_components",
          name: "AppConfirmation",
          ...options,
          onConfirm,
          onCancel,
        });
      });
    },
    /***************************************************/
    // MAIN LOCALE
    changeMainLocale({ commit }, _locale) {
      commit("setMainLocale", _locale);
    },

    /***************************************************/
    // inneroverlay comp
    setInnerOverlayComponent({ commit }, component) {
      commit("setInnerOverlayComponent", component);
    },
    resetInnerOverlayComponent({ commit }) {
      commit("resetInnerOverlayComponent");
    },

    /***************************************************/
    /**
     * - accept Consent
     * - set Role
     * - check for moderator not Mobile
     */
    acceptConsentAndOpenAs({ commit, getters, dispatch }, role) {
      commit("setMeetingRole", role);

      if (getters.isModerator) {
        const { userData, activeAppointmentData } = getters;
        if (
          userData &&
          String(userData.uid) !== String(activeAppointmentData.moderatorId)
        ) {
          return Promise.reject(
            new Error(getters.locale.meeting_created_by_another_organizer_error)
          );
        }

        return dispatch("tryModeratorLogin").then(() => {
          commit("setConsentAccepted");

          const sessionChannel = JSON.parse(
            sessionStorage.getItem(SESSION_CHANNEL)
          );
          commit("sessionChannel", {
            ...sessionChannel,
            uId: userData.uid,
          });
        });
      }

      commit("setConsentAccepted");
    },

    /***************************************************/
    // websockets
    startWebsockets({ commit, getters }) {
      commit("setWebsocketsStartet");
      if (getters.isModerator) {
        commit("setSocketsModerator");
      }
    },

    /***************************************************/
    stopWebsockets({ commit }) {
      commit("setWebsocketsStopped");
    },

    // open/close loginoverlay
    /***************************************************/
    openLoginOverlay({ commit }, onSuccess) {
      commit("openLoginOverlay", onSuccess);
    },
    /***************************************************/
    closeLoginOverlay({ commit }) {
      commit("closeLoginOverlay");
    },

    /***************************************************/
    // guided tour channel
    initSessionChannel({ commit }, channelReference) {
      console.log(" ///// userSession ", channelReference);
      commit("sessionChannel", channelReference);
      /*dispatch('setActiveAppointment',userSession);*/
    },

    /***************************************************/
    closeSession({ commit }) {
      commit("closeSessionChannel");
      commit("setWebsocketsStopped");
    },

    /***************************************************/
    checkSessionChannel({ commit }) {
      const _sessionItem = JSON.parse(sessionStorage.getItem(SESSION_CHANNEL));
      if (_sessionItem) {
        commit("sessionChannel", _sessionItem);
      }

      return _sessionItem;
    },

    /***************************************************/
    signup({ commit }, signupEmail) {
      commit("setSignupEmail", signupEmail), commit("openLoginOverlay");
    },

    /***************************************************/
    // guided tour auth
    //weiss@tentable.de / tentable
    checkLogin({ commit }) {
      const _user = JSON.parse(sessionStorage.getItem(UserI));
      if (_user) {
        commit("setUser", {
          uid: _user.uid,
          accessToken: _user.accessToken,
          email: _user.email,
          firstName: _user.firstName,
          lastName: _user.lastName,
          verified: _user.verified,
        });
      } else {
        commit("openLoginOverlay");
      }
    },

    checkUserAuth({ commit }) {
      const _user = JSON.parse(sessionStorage.getItem(UserI));
      if (_user) {
        commit("setUser", {
          uid: _user.uid,
          accessToken: _user.accessToken,
          email: _user.email,
          firstName: _user.firstName,
          lastName: _user.lastName,
          verified: _user.verified,
        });
      }
    },
    /***************************************************/
    /// auth
    logout({ commit }) {
      commit("removeUser");
      commit("setMeetingRole", "");
    },

    /***************************************************/
    async logIn({ commit, getters, state }, User) {
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(User),
      };
      fetch(getters.apiPath + "tour/signin", requestOptions)
        .then((response) => response.json())
        .then((data) => {
          console.log(data);
          if (
            data.accessToken != null &&
            data.accessToken != "" &&
            data.email != "" &&
            data.uid != ""
          ) {
            commit("setUser", data);
            commit("setMeetingRole", "admin");

            if (data.verified === true) {
              if (typeof state.onLoginSuccess === "function") {
                state.onLoginSuccess();
              }

              commit("closeLoginOverlay", false);
            }

            commit("setLoginError", false);
          } else {
            commit("setLoginError", true);
          }
        });
    },
    clearChangePasswordResponse({ commit }) {
      commit("setChangePasswordResponse", null);
    },
    /***************************************************/
    async setNewPasswordAndLogin({ commit }, User) {
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(User),
      };
      const url = `${process.env.VUE_APP_API_URL}/api/gb-content/account/new-password`;
      fetch(url, requestOptions)
        .then((response) => response.json())
        .then((data) => {
          console.log(data);
          if (
            data.accessToken != null &&
            data.accessToken != "" &&
            data.email != "" &&
            data.uid != ""
          ) {
            commit("setUser", data);
            commit("setChangePasswordResponse", "success");
            if (data.verified === true) {
              /*commit('closeLoginOverlay')*/
            }

            commit("setLoginError", false);
          } else {
            commit("setLoginError", true);
            commit("setChangePasswordResponse", "fail");
          }
        });
    },

    /***************************************************/

    async loadActiveAppointment({ dispatch }, channelReference) {
      const appointmentId = [channelReference.channelId, channelReference.uId]
        .filter(Boolean)
        .join("-");

      const response = await fetch(
        `${process.env.VUE_APP_API_URL}/api/gb-content/v2/appointment-details/${appointmentId}`
      );

      const data = await response.json();

      await dispatch(
        "setActiveAppointment",
        data
          ? {
              ...data,
              title: data.appointmentTitle,
              state: data.appointmentState,
            }
          : {
              title: null,
              startDateTime: null,
              endDateTime: null,
              channelId: null,
              timezone: null,
            }
      );
    },

    async loadAppointments({ commit, getters, dispatch }) {
      const __path = getters.apiPath + "v2/appointments/";
      let myRequest = new Request(__path);
      let myHeaders = new Headers();
      myHeaders.append("x-access-token", this.state.user.accessToken);
      myHeaders.append("cache-control", "no-cache");
      myHeaders.append("pragma", "no-cache");
      myHeaders.append("cache", "no-store");
      let myInit = {
        method: "GET",
        headers: myHeaders,
      };
      fetch(myRequest, myInit)
        .then((resp) => {
          if (!resp.ok) {
            return Promise.reject(resp.status);
          }

          return resp.json();
        })
        .then((response) => {
          commit("appointments", response);
        })
        .catch((errorCode) => {
          if (errorCode === 401) {
            dispatch("openLoginOverlay");
          }
        });
    },

    deleteAppointment({ state, getters }, appointmentId) {
      const requestOptions = {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          "x-access-token": state.user.accessToken,
        },
      };
      return fetch(
        getters.apiPath + `v2/appointments/${appointmentId}`,
        requestOptions
      ).then((response) => {
        if (!response.ok) {
          Promise.reject(new Error(response.status));
        }
      });
    },

    exitAppointment({ commit }) {
      commit("noActiveAppointment");
    },

    /** load meeting infos */
    setActiveAppointment({ commit }, data) {
      commit("activeAppointment", data);
    },

    async fetchCountries({ getters, state, commit }) {
      if (!state.countries) {
        const loadedCountries = await fetch(
          `${getters.apiPath}contact-countries`
        ).then((resp) => resp.json());

        let _list =
          loadedCountries?.map((c) => {
            return {
              value: c["country_en"],
              continent: c["continent_en"],
            };
          }) ?? [];

        commit("countries", _list);
      }

      return state.countries;
    },
    /***************************************************/

    setMatomo({ commit }, value) {
      commit("setMatomo", value);
    },
    matomoTrackEvent({ commit }, _value) {
      commit("matomoTrackEvent", _value);
    },
    toggleNewPasswordMode({ commit }, value) {
      commit("toggleNewPasswordMode", value);
    },

    MATOMO_ROUTE_EVENT({ state, commit }, values) {
      let matomoData = []; // sparte / produkt / produkt id /
      const p = values;
      if (!state.MATOMO) {
        return;
      }
      if (p.length > 1) {
        if (p[0] === "prod") {
          matomoData.push(p[1]);
          if (p.length > 2) {
            matomoData.push("prod_" + (Number(p[2]) + 1));
          }
          if (p.length > 3) {
            matomoData.push(p[3]);
          }
          if (p.length > 4) {
            matomoData.push(p[4]);
          }
        }

        if (p[0] === "cat") {
          matomoData.push(p[1]);
          if (p.length > 2) {
            if (p[1] === "promise") {
              const promisePreviews = [
                "history",
                "globalpresence",
                "values",
                "topstories",
              ];
              matomoData.push("preview_" + promisePreviews[Number(p[2])]);
            } else if (p[1] === "knitting") {
              matomoData.push("preview_group_" + (Number(p[2]) + 1));
            } else {
              matomoData.push("preview_prod_" + (Number(p[2]) + 1));
            }
          }
          if (p.length > 3) {
            matomoData.push(p[3]);
          }
          if (p.length > 4) {
            matomoData.push(p[4]);
          }
        }
      }

      if (p[0] === "home") {
        matomoData[0] = p[0];
        if (p.length === 2) {
          matomoData[1] = "preview " + p[1];
        }
      }

      if (p[0] === "promise") {
        if (p.includes("globalpresence") || p.includes("values")) {
          matomoData[0] = p[0];
          if (p.length > 2) {
            if (p.length === 3) {
              matomoData.push(p[1]);
              matomoData.push(`preview_detail_${Number(p[2]) + 1}`);
            } else if (p.length === 4) {
              matomoData.push(p[1]);
              if (p[1] === "values") {
                matomoData.push(`detail_${Number(p[3]) + 1}`);
              } else {
                matomoData.push(`detail_${Number(p[2]) + 1}`);
              }
            }
          }
        } else {
          for (let i = 0; i < p.length; i++) {
            if (p[i] !== "") {
              matomoData[i] = p[i];
            }
          }
        }
      }

      if (p[0] === "career") {
        const [moduleName, sectionName, productName, mode] = p;
        let category, action;

        if (productName) {
          action = mode === "detail" ? productName : `preview_${productName}`;
          category = sectionName;
        } else {
          action = `preview_${sectionName}`;
          category = moduleName;
        }

        matomoData = [category, action];
      }

      commit(
        "matomoTrackEvent",
        matomoData.length === 1 ? [...matomoData, "-"] : [...matomoData]
      );
    },
  },

  getters: {
    countries(state) {
      return state.countries;
    },

    currentLocale: (state) => {
      return state.currentLocale;
    },
    debug: (state) => {
      return state.debug;
    },
    userIsVerified: (state) => {
      return state.user != null && state.user.verified === true;
    },
    consentAccepted: (state) => {
      return state.consentAccepted;
    },
    loginError: (state) => {
      return state.loginError;
    },

    activeAppointmentError(state) {
      const data = state.activeAppointmentData;
      if (!data) {
        return;
      }

      if (!data.title) {
        return state.user
          ? "meeting_2_presenter_error_3"
          : "meeting_2_recipient_error_3";
      }

      if (data.state === "deleted") {
        return state.user
          ? "meeting_2_presenter_error_3"
          : "meeting_2_recipient_error_3";
      }

      const today = new Date(Date.now());
      if (differenceInHours(today, new Date(data.endDateTime)) >= 24) {
        return state.user
          ? "meeting_2_presenter_error_1"
          : "meeting_2_recipient_error_1";
      }

      if (differenceInHours(new Date(data.startDateTime), today) > 24) {
        return state.user
          ? "meeting_2_presenter_error_2"
          : "meeting_2_recipient_error_2";
      }

      return null;
    },

    showLoginOverlay: (state) => {
      return state.showLoginOverlay;
    },
    isModerator: (state) => {
      return state.meetingRole === "admin";
    },
    meetingRole: (state) => {
      return state.meetingRole;
    },
    isSockeClusterActive: (state) => {
      return state.isSocketClusterActive;
    },
    sessionChannelId: (state) => {
      return state.sessionChannelId;
    },
    userSession: (state) => {
      return state.userSession;
    },
    userData: (state) => {
      return state.user;
    },
    signupEmail: (state) => {
      return state.signupEmail;
    },
    userSessionCloseStore: (state) => {
      return state.userSessionCloseStore;
    },
    appointments: (state) => {
      return _.orderBy(
        state.appointments,
        [(appointment) => appointment.startDateTime],
        ["desc"]
      );
    },
    innerOverlayComponent: (state) => {
      return state.innerOverlayComponent;
    },
    showFullscreenOverlay: (state) => {
      return state.showFullscreenOverlay;
    },
    activeAppointmentData: (state) => {
      return state.activeAppointmentData;
    },
    activeAppointmentTitle: (state) => {
      return state.activeAppointmentData?.title;
    },
    changePasswordResponse: (state) => {
      return state.changePasswordResponse;
    },
    MATOMO: (state) => {
      return state.MATOMO;
    },
    lastMatomoEvent: (state) => {
      return state.lastMatomoEvent;
    },
    manuelNewPasswordMode: (state) => {
      return state.manuelNewPasswordMode;
    },

    apiPath: (state) => {
      return state.apiPath;
    },

    // Using Regex (from detectmobilebrowsers.com):
    isPhoneDevice: () => {
      let check = false;
      (function (a) {
        if (
          /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
            a
          ) ||
          /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
            a.substr(0, 4)
          )
        )
          check = true;
      })(navigator.userAgent || navigator.vendor || window.opera);
      return check;
    },
    isPhoneOrTabletDevice: () => {
      let check = false;
      (function (a) {
        if (
          /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino|android|ipad|playbook|silk/i.test(
            a
          ) ||
          /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
            a.substr(0, 4)
          )
        )
          check = true;
      })(navigator.userAgent || navigator.vendor || window.opera);
      return check;
    },
    /* debugApiPath: state => { return state.debugApiPath },  */
  },
};

export default createStore(Store);
