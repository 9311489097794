<template>
  <div
    id="loginOverlay"
    class="overlayInnerCopy"
    v-bind:class="{ frostedbg: showFrost }"
  >
    <div id="meetingOverlayBackdrop"></div>

    <div id="innetMeetingOverlay" class="innerOverlay">
      <OverlayHeader
        :title="headerTitle"
        :closeIcon="true"
        @close="closeOverlay"
      />

      <div class="d-flex flex-column _formHolder" v-if="viewMode == 'LOGIN'">
        <div class="p-3 flex-fill pt16">{{ login_locale.login_copy_1 }}</div>
        <div class="input-holder tfbt">
          <input
            type="text"
            name="email"
            v-model="User.email"
            @focus="checkForInput"
            :placeholder="login_locale.login_label_1"
            @input="lowerCase($event)"
          />
        </div>
        <div class="input-holder tfbt">
          <input
            type="password"
            name="password"
            v-model="User.password"
            @keydown.enter="onKeyDownEnter"
            @focus="checkForInput"
            :placeholder="login_locale.login_label_2"
          />
        </div>

        <div class="p-0 flex-fill tfbt tfbb" @click="noPasswd">
          <div class="formFielLink">{{ login_locale.login_link_1 }}</div>
        </div>

        <div
          class="p-0 flex-fill align-self-center tfError mt5"
          v-if="loginError"
        >
          {{ login_locale.login_error_1 }}
        </div>

        <div class="p-0 flex-fill mt15 align-self-center">
          <div
            class="contact-next-button contextNextClickable"
            @click="processLogin"
          >
            <div class="contact-next-button-text">
              {{ login_locale.login_button_1 }}
            </div>
          </div>
        </div>
      </div>

      <div
        class="d-flex flex-column _formHolder"
        v-if="viewMode == 'FORGOT_PASSWORD'"
      >
        <div class="p-3 flex-fill pt16">{{ login_locale.login_copy_3 }}</div>
        <div class="input-holder tfbt tfbb">
          <input
            type="text"
            name="email"
            v-model="User.email"
            @focus="checkForInput"
            @input="lowerCase($event)"
            :placeholder="login_locale.login_label_1"
          />
        </div>

        <div
          class="p-0 flex-fill align-self-center tfError mt5"
          v-if="loginError"
        >
          {{ login_locale.login_error_3_1 }}
        </div>

        <div class="p-0 flex-fill mt15 align-self-center">
          <div
            class="contact-next-button contextNextClickable"
            @click="processPasswordReset"
          >
            <div class="contact-next-button-text">
              {{ login_locale.login_button_3 }}
            </div>
          </div>
          <div
            class="contact-next-button mt15 contextNextClickable"
            @click="setLoginMode"
          >
            <div class="contact-next-button-text">
              {{ login_locale.login_button_4 }}
            </div>
          </div>
        </div>
      </div>

      <div
        class="d-flex flex-column _formHolder"
        v-if="viewMode == 'NEW_PASSWORD_CONFIRMATION'"
      >
        <div class="p-3 flex-fill pt16">{{ login_locale.login_copy_4 }}</div>

        <div class="p-0 flex-fill mt15 align-self-center">
          <div
            class="contact-next-button contextNextClickable"
            @click="setLoginMode"
          >
            <div class="contact-next-button-text">
              {{ login_locale.login_button_4 }}
            </div>
          </div>
        </div>
      </div>

      <div
        class="d-flex flex-column _formHolder"
        v-if="viewMode == 'NEW_PASSWORD_FINALIZED'"
      >
        <div class="p-3 flex-fill pt16">
          You can use your new password from now on to login to the backstage of
          the Groz-Beckert Experience.
        </div>

        <div class="p-0 flex-fill mt15 align-self-center">
          <div
            class="contact-next-button contextNextClickable"
            @click="closeOverlay"
          >
            <div class="contact-next-button-text">OK</div>
          </div>
        </div>
      </div>

      <div
        class="d-flex flex-column _formHolder"
        v-if="viewMode == 'SET_NEW_PASSWORD'"
      >
        <div class="p-3 flex-fill pt16">{{ login_locale.login_copy_2 }}</div>
        <div class="input-holder tfbt">
          <div class="d-flex align-items-center" style="height: 100%">
            <div class="p-0 flex-fill">
              <input
                :type="pwType1"
                name="password"
                v-model="newPassword"
                @input="newPassInput"
                :placeholder="login_locale.login_label_3"
              />
            </div>
            <div class="p-0 mr10">
              <div class="visibilityButtonIcon" @click="togglePasswordVis(1)">
                <svg
                  v-if="pwType1 === 'text'"
                  xmlns="http://www.w3.org/2000/svg"
                  height="18px"
                  viewBox="0 0 24 24"
                  width="18px"
                  fill="#949494"
                >
                  <path d="M0 0h24v24H0z" fill="none" />
                  <path
                    d="M12 4.5C7 4.5 2.73 7.61 1 12c1.73 4.39 6 7.5 11 7.5s9.27-3.11 11-7.5c-1.73-4.39-6-7.5-11-7.5zM12 17c-2.76 0-5-2.24-5-5s2.24-5 5-5 5 2.24 5 5-2.24 5-5 5zm0-8c-1.66 0-3 1.34-3 3s1.34 3 3 3 3-1.34 3-3-1.34-3-3-3z"
                  />
                </svg>
                <svg
                  v-if="pwType1 === 'password'"
                  xmlns="http://www.w3.org/2000/svg"
                  height="18px"
                  viewBox="0 0 24 24"
                  width="18px"
                  fill="#949494"
                >
                  <path
                    d="M0 0h24v24H0zm0 0h24v24H0zm0 0h24v24H0zm0 0h24v24H0z"
                    fill="none"
                  />
                  <path
                    d="M12 7c2.76 0 5 2.24 5 5 0 .65-.13 1.26-.36 1.83l2.92 2.92c1.51-1.26 2.7-2.89 3.43-4.75-1.73-4.39-6-7.5-11-7.5-1.4 0-2.74.25-3.98.7l2.16 2.16C10.74 7.13 11.35 7 12 7zM2 4.27l2.28 2.28.46.46C3.08 8.3 1.78 10.02 1 12c1.73 4.39 6 7.5 11 7.5 1.55 0 3.03-.3 4.38-.84l.42.42L19.73 22 21 20.73 3.27 3 2 4.27zM7.53 9.8l1.55 1.55c-.05.21-.08.43-.08.65 0 1.66 1.34 3 3 3 .22 0 .44-.03.65-.08l1.55 1.55c-.67.33-1.41.53-2.2.53-2.76 0-5-2.24-5-5 0-.79.2-1.53.53-2.2zm4.31-.78l3.15 3.15.02-.16c0-1.66-1.34-3-3-3l-.17.01z"
                  />
                </svg>
              </div>
            </div>
          </div>
        </div>

        <div class="input-holder tfbt tfbb">
          <div class="d-flex align-items-center" style="height: 100%">
            <div class="p-0 flex-fill">
              <input
                :type="pwType2"
                name="password"
                v-model="newPassword2"
                @input="newPassInput"
                :placeholder="login_locale.login_label_4"
              />
            </div>
            <div class="p-0 mr10">
              <div class="visibilityButtonIcon" @click="togglePasswordVis(2)">
                <svg
                  v-if="pwType2 === 'text'"
                  xmlns="http://www.w3.org/2000/svg"
                  height="18px"
                  viewBox="0 0 24 24"
                  width="18px"
                  fill="#949494"
                >
                  <path d="M0 0h24v24H0z" fill="none" />
                  <path
                    d="M12 4.5C7 4.5 2.73 7.61 1 12c1.73 4.39 6 7.5 11 7.5s9.27-3.11 11-7.5c-1.73-4.39-6-7.5-11-7.5zM12 17c-2.76 0-5-2.24-5-5s2.24-5 5-5 5 2.24 5 5-2.24 5-5 5zm0-8c-1.66 0-3 1.34-3 3s1.34 3 3 3 3-1.34 3-3-1.34-3-3-3z"
                  />
                </svg>
                <svg
                  v-if="pwType2 === 'password'"
                  xmlns="http://www.w3.org/2000/svg"
                  height="18px"
                  viewBox="0 0 24 24"
                  width="18px"
                  fill="#949494"
                >
                  <path
                    d="M0 0h24v24H0zm0 0h24v24H0zm0 0h24v24H0zm0 0h24v24H0z"
                    fill="none"
                  />
                  <path
                    d="M12 7c2.76 0 5 2.24 5 5 0 .65-.13 1.26-.36 1.83l2.92 2.92c1.51-1.26 2.7-2.89 3.43-4.75-1.73-4.39-6-7.5-11-7.5-1.4 0-2.74.25-3.98.7l2.16 2.16C10.74 7.13 11.35 7 12 7zM2 4.27l2.28 2.28.46.46C3.08 8.3 1.78 10.02 1 12c1.73 4.39 6 7.5 11 7.5 1.55 0 3.03-.3 4.38-.84l.42.42L19.73 22 21 20.73 3.27 3 2 4.27zM7.53 9.8l1.55 1.55c-.05.21-.08.43-.08.65 0 1.66 1.34 3 3 3 .22 0 .44-.03.65-.08l1.55 1.55c-.67.33-1.41.53-2.2.53-2.76 0-5-2.24-5-5 0-.79.2-1.53.53-2.2zm4.31-.78l3.15 3.15.02-.16c0-1.66-1.34-3-3-3l-.17.01z"
                  />
                </svg>
              </div>
            </div>
          </div>
        </div>
        <div
          class="p-0 flex-fill align-self-center tfError mt5"
          v-if="pw2isEmty && resetButtonTapped"
        >
          {{ login_locale.login_error_2_1 }}
        </div>
        <div
          class="p-0 flex-fill align-self-center tfError mt5"
          v-if="newPWDoNotMatch"
        >
          {{ login_locale.login_error_2_2 }}
        </div>
        <div class="p-0 flex-fill ml15 mt5">
          <div class="d-flex">
            <div
              class="p-0 passwordCheck mt2"
              :class="{ passwortCheckError: !pwLengthOk }"
            >
              {{ login_locale.login_error_1_1 }}
            </div>
            <div class="p-0 ml4" v-if="pwLengthOk">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="18px"
                viewBox="0 0 24 24"
                width="18px"
                fill="#636363"
              >
                <path d="M0 0h24v24H0z" fill="none" />
                <path d="M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41z" />
              </svg>
            </div>
          </div>
        </div>
        <div class="p-0 flex-fill ml15">
          <div class="d-flex">
            <div
              class="p-0 passwordCheck mt2"
              :class="{ passwortCheckError: !hasUpperCase }"
            >
              {{ login_locale.login_error_1_2 }}
            </div>
            <div class="p-0 ml4" v-if="hasUpperCase">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="18px"
                viewBox="0 0 24 24"
                width="18px"
                fill="#636363"
              >
                <path d="M0 0h24v24H0z" fill="none" />
                <path d="M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41z" />
              </svg>
            </div>
          </div>
        </div>
        <div class="p-0 flex-fill ml15">
          <div class="d-flex">
            <div
              class="p-0 passwordCheck mt2"
              :class="{ passwortCheckError: !hasNumber }"
            >
              {{ login_locale.login_error_1_3 }}
            </div>
            <div class="p-0 ml4" v-if="hasNumber">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="18px"
                viewBox="0 0 24 24"
                width="18px"
                fill="#636363"
              >
                <path d="M0 0h24v24H0z" fill="none" />
                <path d="M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41z" />
              </svg>
            </div>
          </div>
        </div>

        <div class="p-0 flex-fill mt15 align-self-center">
          <div
            class="contact-next-button contextNextClickable"
            @click="saveNewPasswordTapped"
          >
            <div class="contact-next-button-text">
              {{ login_locale.login_button_2 }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { mapGetters } from "vuex";
import { gsap } from "gsap";
import { EB, EB_ACTION } from "@/utils/EB";
import { TweenVars } from "@/utils/tweenTime.js";
import OverlayHeader from "@/components/klein/OverlayHeader";
import { LOGIN_MODES } from "@/utils/enum.js";
export default {
  name: "OverlayLogin",
  components: {
    OverlayHeader,
  },
  data() {
    return {
      viewMode: LOGIN_MODES.LOGIN,
      showContent: true,
      showFrost: false,
      newPassword: "",
      newPassword2: "",
      pwType1: "password",
      pwType2: "password",
      uppercase: RegExp("^(?=.*[A-Z])"),
      numbers: RegExp("^(?=.*[0-9])"),
      resetButtonTapped: false,
      User: {
        email: "",
        password: "",
      },
      /* User: {
          email: 'dude@criseg.de',
          password: 'TotalKrassesPassw0rt#',
        },   */
    };
  },
  watch: {
    userData(newValue, oldValue) {
      if (newValue != oldValue) {
        if (newValue.verified == false) {
          this.viewMode = LOGIN_MODES.SET_NEW_PASSWORD;
        }
        /*else if(newValue.verified == true && this.viewMode == LOGIN_MODES.SET_NEW_PASSWORD){

          }*/
      }
    },
    manuelNewPasswordMode(newValue, oldValue) {
      if (oldValue == false && newValue == true) {
        this.viewMode = LOGIN_MODES.SET_NEW_PASSWORD;
        this.User.email = this.userData.email;
        this.User.password = null;
        setTimeout(() => {
          this.toggleNewPasswordMode(false);
        }, 500);
      }
    },
    changePasswordResponse(newValue) {
      if (newValue == "success") {
        this.viewMode = LOGIN_MODES.NEW_PASSWORD_FINALIZED;
        this.clearChangePasswordResponse();
      } else if (newValue == "fail") {
        this.viewMode = LOGIN_MODES.NEW_PASSWORD_FINALIZED;
        this.clearChangePasswordResponse();
      }
    },
  },
  computed: {
    ...mapGetters([
      "isLandscape",
      "apiPath",
      "currentLocale",
      "login_locale",
      "loginError",
      "userData",
      "userIsVerified",
      "signupEmail",
      "changePasswordResponse",
      "manuelNewPasswordMode",
    ]),

    headerTitle() {
      switch (this.viewMode) {
        case LOGIN_MODES.LOGIN:
          return this.login_locale.login_hl_1;
        case LOGIN_MODES.SET_NEW_PASSWORD:
          return this.login_locale.login_hl_2;
        case LOGIN_MODES.FORGOT_PASSWORD:
          return this.login_locale.login_hl_3;
        case LOGIN_MODES.NEW_PASSWORD_CONFIRMATION:
          return this.login_locale.login_hl_4;
        case LOGIN_MODES.NEW_PASSWORD_FINALIZED:
          return "Your new password has been saved!";
      }

      return "";
    },
    hasNumber() {
      return this.newPassword.match(this.numbers);
    },
    hasUpperCase() {
      return this.newPassword.match(this.uppercase);
    },
    pwLengthOk() {
      return this.newPassword.length >= 12;
    },
    newPWDoNotMatch() {
      return (
        this.newPassword !== this.newPassword2 && this.newPassword2.length > 1
      );
    },
    pw2isEmty() {
      return (
        this.newPassword !== this.newPassword2 && this.newPassword2.length == 0
      );
    },
  },
  created() {
    this.getAndSetLoginLang(this.currentLocale);
  },
  mounted() {
    this.fadeIn();
    if (this.signupEmail != "") {
      this.User.email = this.signupEmail;
    }
    EB.$on(EB_ACTION.WIN_RESIZE, () => {
      this.centerWin();
    });
  },
  unmounted() {
    EB.$off(EB_ACTION.WIN_RESIZE);
  },

  methods: {
    ...mapActions([
      "toggleNewPasswordMode",
      "logIn",
      "getAndSetLoginLang",
      "closeLoginOverlay",
      "setNewPasswordAndLogin",
      "clearChangePasswordResponse",
    ]),
    checkForInput() {
      this.$store.commit("setLoginError", false);
    },
    lowerCase(e) {
      // e.target.value = e.target.value.toLowerCase()
      // console.log( e.target.value)
      this.User.email = e.target.value.toLowerCase();
    },
    noPasswd: function () {
      this.viewMode = LOGIN_MODES.FORGOT_PASSWORD;
    },
    setLoginMode() {
      this.viewMode = LOGIN_MODES.LOGIN;
    },
    setConfirmationMode() {
      this.viewMode = LOGIN_MODES.NEW_PASSWORD_CONFIRMATION;
    },
    newPassInput() {
      /* console.log(this.newPassword) */
    },
    async processPasswordReset() {
      const that = this;
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ email: this.User.email }),
      };
      const _response = await fetch(
        `${this.apiPath}account/forgot-password`,
        requestOptions
      );
      if (_response) {
        //.status == 200){
        that.setConfirmationMode();
      }
    },
    async onKeyDownEnter() {
      await this.logIn(this.User);
    },
    async processLogin() {
      await this.logIn(this.User);
    },
    async saveNewPasswordTapped() {
      this.resetButtonTapped = true;
      if (
        this.hasNumber &&
        this.hasUpperCase &&
        this.pwLengthOk &&
        this.newPassword === this.newPassword2
      ) {
        await this.setNewPasswordAndLogin({
          email: this.User.email,
          password: this.newPassword,
          tmpPassword: this.User.password,
        });
      }
    },
    togglePasswordVis(inputIndex) {
      if (inputIndex == 1) {
        this.pwType1 = this.pwType1 == "password" ? "text" : "password";
      } else {
        this.pwType2 = this.pwType2 == "password" ? "text" : "password";
      }
    },

    closeOverlay() {
      this.showFrost = false;
      gsap.to("#meetingOverlayBackdrop", {
        opacity: 0,
        duration: 1,
        ease: TweenVars.ease,
      });
      gsap.to("#innetMeetingOverlay", {
        top: this.$store.state.constrains.screenHeight / 3,
        opacity: 0,
        duration: TweenVars.duration,
        ease: TweenVars.ease,
      });
      setTimeout(() => {
        this.closeLoginOverlay();
      }, 1100);
    },

    fadeIn: function () {
      gsap.to("#meetingOverlayBackdrop", {
        opacity: 1,
        duration: 1,
        ease: TweenVars.ease,
      });

      const _w = this.isLandscape
        ? 500
        : this.$store.state.constrains.screenWidth - 30;
      /* const _h = this.isLandscape ? 310 : (this.$store.state.constrains.screenHeight - 80); */
      const _l = this.isLandscape
        ? this.$store.state.constrains.screenWidth / 2 - 250
        : 15;
      const _t = this.isLandscape
        ? this.$store.state.constrains.screenHeight / 2 - 155
        : 60;
      gsap.to("#innetMeetingOverlay", {
        width: _w,
        left: _l,
        top: _t,
        duration: 1,
        ease: TweenVars.ease,
        delay: 0.5,
        opacity: 1,
      });
      if (!this.showFrost) {
        setTimeout(() => {
          this.showFrost = true;
        }, 800);
      }
    },

    /***************************************************/
    centerWin() {
      console.log("centerWin");
      const win = document.getElementById("innetMeetingOverlay");
      let _h = win != null ? win.clientHeight : 300;
      const _l = this.isLandscape
        ? this.$store.state.constrains.screenWidth / 2 - 250
        : 15;
      const _t = this.isLandscape
        ? this.$store.state.constrains.screenHeight / 2 - _h / 2
        : 60;
      gsap.to("#innetMeetingOverlay", {
        left: _l,
        top: _t,
        duration: TweenVars.duration,
        ease: TweenVars.ease,
        delay: 0.1,
        opacity: 1,
      });
    },
  },
};
</script>

<style scoped lang="scss">
#innetMeetingOverlay {
  height: auto;
}

.tfError {
  font-size: 12px;
  color: #ff0000;
}

.visibilityButtonIcon {
  cursor: pointer;
  margin-top: 4px;
  width: 24px;
  height: 24px;
}

.input-holder * {
  -webkit-user-select: auto !important;
  user-select: auto !important;
  pointer-events: all !important;
}
.passwortCheckError {
  color: #ff0000;
}
</style>
