<template>
  <div
    class="globalPresenceCompMod"
    ref="modRef"
    :class="{ mobilePadding: !isLandscape }"
  >
    <div class="d-flex flex-column" style="position: relative">
      <div class="p-0" style="width: 30px; height: 30px; position: absolute">
        <div class="topLine" ref="_toptLine"></div>
        <div class="leftLine" ref="_leftLine"></div>
      </div>
      <div
        class="p-0 globContent"
        ref="_globContent"
        :class="{ overlayHolderScroll: canScroll }"
      >
        <div class="d-flex flex-column">
          <div class="p-0 globPrHeader">
            {{ headline }}
          </div>
          <div class="p-0 globCopy">
            {{ copy }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { gsap } from "gsap";
import { TweenVars, ScrollTween } from "@/utils/tweenTime.js";
import { mapGetters } from "vuex";
export default {
  name: "GlobalPresenceCompMod",
  props: ["headline", "copy", "index", "panelHeightCallback"],
  data() {
    return {};
  },

  computed: {
    ...mapGetters(["isLandscape", "isMobile"]),
    canScroll: function () {
      return this.scrollEnabled;
    },
  },
  mounted() {
    this.initView();
    // setTimeout(() => {
    // },280)
  },

  methods: {
    initView() {
      setTimeout(() => {
        var panelHeight = this.$refs.modRef.clientHeight;
        this.$emit("panelHeightCallback", panelHeight);
      }, 650);

      gsap.to(this.$refs._globContent, {
        opacity: 1,
        duration: TweenVars.duration,
        ease: TweenVars.ease,
      });
      gsap.to(this.$refs._toptLine, {
        width: 30,
        delay: 0.35,
        duration: TweenVars.durationSlow,
        ease: ScrollTween.ease,
      });
      gsap.to(this.$refs._leftLine, {
        height: 29,
        delay: 0.35,
        duration: TweenVars.durationSlow,
        ease: ScrollTween.ease,
      });
    },
    beforeEnter22: function () {},
    enter22: function (el, done) {
      gsap.to(el, {
        opacity: 1,
        duration: ScrollTween.duration,
        ease: TweenVars.ease,
        onComplete: () => {
          done();
        },
      });
    },
    leave22: function () {},
  },
};
</script>

<style lang="scss">
.globalPresenceCompMod {
  position: relative;
}
.globContent {
  opacity: 0;
}
.leftLine {
  position: absolute;
  width: 3px;
  height: 0px;
  bottom: 0px;
  background-color: #ffffff;
}
.topLine {
  float: right;
  width: 0px;
  height: 3px;
  background-color: #ffffff;
}
.mobilePadding {
  padding-left: 15px;
  padding-right: 15px !important;
}
</style>
