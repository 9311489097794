<template>
  <div class="autocomplete tfbb" style="pointer-events: all">
    <div
      class="_dropDowBar"
      :class="{
        fontMobile: isMobile === true,
        fontDesk: isMobile === false,
        appointmentmargin: appoointment === false,
        appointmentpadding: appoointment === true,
      }"
      @click="open"
    >
      <div
        class="d-flex align-items-center justify-content-between p0"
        style="height: 100%; padding-top: 3px !important"
      >
        <div class="p-0" v-if="selectedItem !== ''">
          {{ selectedItem }}
        </div>
        <div class="p-0 placeHolderItem" v-if="selectedItem === ''">
          {{ placeHolder }}
        </div>
        <div
          class="p-0"
          :class="{ collapseOpen: isOpen, collapseClosed: !isOpen }"
        >
          <div class="collapseIcon _desk" v-if="!isMobile">
            <img src="/assets/ui/ui_select_toggle_desk.svg" alt="" />
          </div>
          <div class="collapseIcon _mobile" v-if="isMobile">
            <img src="/assets/ui/ui_select_toggle_mobile.svg" alt="" />
          </div>
        </div>
      </div>
    </div>
    <ul
      id="autocomplete-results"
      v-show="isOpen"
      class="autocomplete-results"
      :class="{ appointmentClass: appoointment === true }"
    >
      <li
        v-for="(result, i) in items"
        :key="i"
        @click="setResult(result)"
        class="autocomplete-result"
        :class="{ 'is-active': i === arrowCounter }"
      >
        <span v-if="type === 'OFFICE_BRANCH'">{{ result.officeName }}</span>
        <span v-if="type !== 'OFFICE_BRANCH'">{{ result.value }}</span>
      </li>
    </ul>
  </div>
</template>

<script>
import { AutoCompleteType } from "@/utils/enum.js";
import { mapGetters } from "vuex";

export default {
  name: "OptionList",
  props: [
    "placeHolder",
    "items",
    "resize",
    "change",
    "disabled",
    "type",
    "selectedValue",
    "appoointment",
  ],

  data() {
    return {
      isOpen: false,
      results: [],
      isLoading: false,
      arrowCounter: -1,
      resizeTimeout: null,
    };
  },

  computed: {
    ...mapGetters(["isMobile"]),
    selectedItem: function () {
      if (
        this.type === AutoCompleteType.TIMEZONE ||
        this.type === AutoCompleteType.FILTER ||
        this.type === AutoCompleteType.CONTINENT
      ) {
        return this.selectedValue.value;
      } else if (this.type === AutoCompleteType.OFFICE_BRANCH) {
        return this.selectedValue.officeName;
      } else {
        return this.selectedValue;
      }
    },
  },

  mounted() {
    document.addEventListener("click", this.handleClickOutside);
  },
  destroyed() {
    document.removeEventListener("click", this.handleClickOutside);
  },
  methods: {
    open() {
      this.$emit("open", this.type);
      this.isOpen = !this.isOpen;
    },
    aspects() {
      if (this.resizeTimeout) {
        clearTimeout(this.resizeTimeout);
      }
      this.resizeTimeout = setTimeout(() => {
        this.resize();
      }, 150);
    },
    setResult(result) {
      this.isOpen = false;
      if (
        this.type == AutoCompleteType.TIMEZONE ||
        this.type == AutoCompleteType.FILTER ||
        this.type == AutoCompleteType.OFFICE_BRANCH ||
        this.type == AutoCompleteType.CONTINENT
      ) {
        this.change(result);
      } else {
        this.change(result.value);
      }
      this.aspects();
    },

    handleClickOutside(event) {
      if (!this.$el.contains(event.target)) {
        this.isOpen = false;
        this.arrowCounter = -1;
      }
      this.aspects();
    },
  },
};
</script>

<style>
.autocomplete {
  position: relative;
}
._dropDowBar {
  height: 40px;
  cursor: pointer;
  background-color: rgba(255, 255, 255, 0);
}
@media (orientation: landscape) {
  ._dropDowBar {
    height: 40px;
    cursor: pointer;
    background-color: rgba(255, 255, 255, 0);
  }
}

@media (orientation: portrait) {
  ._dropDowBar {
    height: 54px;
    cursor: pointer;
    background-color: rgba(255, 255, 255, 0);
  }
}
.autocomplete-results {
  padding: 0;
  margin: 0;
  margin-left: 10px;
  margin-right: 10px;
  list-style-type: none !important;
  list-style: none !important;
  /* border: 1px solid #eeeeee; */
  height: 100%;
  min-height: 40px;
  max-height: 148px;

  overflow: auto;
  border-radius: 2px;
  backdrop-filter: blur(2px);
  background-color: rgba(255, 255, 255, 0.85);
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.autocomplete-results::-webkit-scrollbar {
  width: 4px;
}
.autocomplete-results::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(91, 91, 91, 0.45);
}

.autocomplete-result {
  text-align: left;
  /* padding: 4px 8px; */
  margin-left: 8px;
  margin-right: 6px;
  list-style-type: none !important;
  list-style: none !important;
  cursor: pointer;
  background-color: rgba(91, 91, 91, 0);
  transition: background-color 0.45s ease-out;
}

.autocomplete-result.is-active,
.autocomplete-result:hover {
  border-radius: 2px;
  background-color: rgba(91, 91, 91, 0.15);
  transition: background-color 0.45s ease-out;
  /* background-color: #4AAE9B;
    color: white; */
}
.appointmentClass {
  position: fixed !important;
  border-radius: 2px;
  -webkit-box-shadow: 0 1px 8px rgba(91, 91, 91, 0.1);
  -moz-box-shadow: 0 1px 8px rgba(91, 91, 91, 0.1);
  box-shadow: 0 1px 8px rgba(91, 91, 91, 0.1);
}
@media (orientation: landscape) {
  .placeHolderItem {
    font-family: "Univers LT Std";
    color: rgba(99, 99, 99, 0.4);
    font-weight: 300;
    font-size: 13px;
    line-height: 18px;
  }
  .autocomplete-result {
    /* padding: 4px 4px; */
    padding-top: 6px;
    padding-bottom: 2px;
  }
  li {
    /* border: 1px solid deeppink!important; */
    font-size: 13px;
    margin-top: 4px;
    margin-bottom: 1px !important;
  }
}

@media (orientation: portrait) {
  .placeHolderItem {
    font-family: "Univers LT Std";
    color: rgba(99, 99, 99, 0.4);
    font-weight: 300;
    font-size: 16px;
    line-height: 22px;
  }
  .autocomplete-result {
    padding: 4px 8px;
  }
  li {
    font-size: 16px;
    margin-top: 4px;
    margin-bottom: 1px !important;
  }
}

.collapseOpen {
  transform: rotate(180deg);
  transition: all 0.25s ease-in-out;
}
.collapseClosed {
  transform: rotate(0deg);
  transition: all 0.25s ease-in-out;
}
._desk {
  width: 12px;
  height: 12px;
}
._mobile {
  width: 18px;
  height: 18px;
}
.appointmentmargin {
  margin-left: 15px;
  margin-right: 15px;
}
.appointmentpadding {
  padding-left: 15px;
  padding-right: 15px;
}
</style>
