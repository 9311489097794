<template>
  <div class="slideToggle" @click="toggleActive">
    <div class="slideToggleThumb" :class="{ thumbActive: isActive }"></div>
  </div>
</template>

<script>
export default {
  name: "SlideToggle",
  props: ["active"],
  /*   data(){
      return{
        isActive:false,
      }
    }, */
  computed: {
    isActive() {
      return this.active;
    },
  },
  mounted() {
    /*  this.isActive = this.active */
  },
  methods: {
    toggleActive() {
      /*   this.isActive = this.isActive === true ? false : true; */
      this.$emit("toggled");
    },
  },
};
</script>

<style scoped>
.slideToggle {
  cursor: pointer;
  background-color: rgba(91, 91, 91, 0.2);
  border-radius: 4px;
  position: relative;
}
.slideToggleThumb {
  position: absolute;
  border-radius: 3px;
  background-color: rgba(255, 255, 255, 1);
  border-radius: 4px;
  position: relative;
  transition: all 0.25s ease-out;
  -webkit-box-shadow: 0 1px 6px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0 1px 6px rgba(0, 0, 0, 0.15);
  box-shadow: 0 1px 6px rgba(0, 0, 0, 0.15);
}
.thumbActive {
  background-color: rgba(91, 91, 91, 1);
  transition: all 0.25s ease-out;
  -webkit-box-shadow: 0 0px 0px rgba(0, 0, 0, 0);
  -moz-box-shadow: 0 0px 0px rgba(0, 0, 0, 0);
  box-shadow: 0 0px 0px rgba(0, 0, 0, 0);
}
@media (orientation: landscape) {
  .slideToggle {
    width: 65px;
    height: 28px;
  }
  .slideToggleThumb {
    width: 30px;
    height: 22px;
    top: 3px;
    left: 3px;
  }
  .thumbActive {
    margin-left: 30px;
  }
}

@media (orientation: portrait) {
  .slideToggle {
    width: 86px;
    height: 37px;
  }
  .slideToggleThumb {
    width: 39px;
    height: 29px;
    top: 4px;
    left: 4px;
  }
  .thumbActive {
    margin-left: 39px;
  }
}
</style>
