<template>
  <div style="height: 100%">
    <div class="d-flex flex-column pl0 pr0">
      <div
        class="p-0 d-flex flex-column flex-fill"
        :class="{ fontMobile: isMobile === true, fontDesk: isMobile === false }"
      >
        <div class="p-0">
          <div class="d-flex flex-column">
            <div class="p-0 tfbb mt15">
              <div class="pb10 mr15 ml15">
                {{ contact_locale.copy }}
              </div>
            </div>
            <div class="p-0 tfbb pRelative">
              <div
                class="d-flex datePickerbar"
                :class="{
                  fontMobile: isMobile === true,
                  fontDesk: isMobile === false,
                }"
              >
                <div
                  class="p-0 flex-fill ml15 mr15 mt1"
                  style="opacity: 0.5"
                  v-if="showPlaceholder"
                  @click="toggleCalMode"
                >
                  {{ contact_locale.label1 }}
                </div>
                <div
                  class="p-0 flex-fill ml15 mr15"
                  v-if="!showPlaceholder"
                  @click="toggleCalMode"
                >
                  {{ meetingDate }}
                </div>
                <div
                  class="p-0 mr10 calIcon mt1"
                  @click="toggleCalMode"
                  v-if="showCal"
                >
                  <img src="/assets/ui/calIconClose.svg" alt="" />
                </div>
                <div
                  class="p-0 mr10 calIcon mt1"
                  @click="toggleCalMode"
                  v-if="!showCal"
                >
                  <img src="/assets/ui/calIcon.svg" alt="" />
                </div>
              </div>
              <div class="dateWarn" v-if="showDateWarn">
                {{ contact_locale.label1Error }}
              </div>
            </div>

            <div class="p-2" v-if="showCal">
              <div class="d-flex justify-content-between">
                <div class="p-2 switchMonthBtn" @click="preMonth">
                  <img
                    src="/assets/ui/cal_monthBack.svg"
                    alt=""
                    v-if="!preMonthBtnDisabled"
                  />
                </div>
                <div class="p-2" v-if="currentLocale == 'cn'">
                  {{ monthsLocalized[selectedMonth] }} {{ selectedYear }} 年
                </div>
                <div class="p-2" v-if="currentLocale != 'cn'">
                  {{ monthsLocalized[selectedMonth] }} {{ selectedYear }}
                </div>
                <div class="p-2 switchMonthBtn" @click="nextMonth">
                  <img
                    src="/assets/ui/cal_monthNext.svg"
                    alt=""
                    v-if="!nextMonthBtnDisabled"
                  />
                </div>
              </div>
              <div class="d-flex justify-content-center">
                <div class="p-0 weekDay">{{ weekDaysLocalized[0] }}</div>
                <div class="p-0 weekDay">{{ weekDaysLocalized[1] }}</div>
                <div class="p-0 weekDay">{{ weekDaysLocalized[2] }}</div>
                <div class="p-0 weekDay">{{ weekDaysLocalized[3] }}</div>
                <div class="p-0 weekDay">{{ weekDaysLocalized[4] }}</div>
                <div class="p-0 weekDay">{{ weekDaysLocalized[5] }}</div>
                <div class="p-0 weekDay">{{ weekDaysLocalized[6] }}</div>
              </div>
              <div
                class="d-flex justify-content-center"
                v-for="(week, index) in weekAndDays"
                :key="week + index"
                :data-index="index"
              >
                <div
                  class="p-0 day"
                  v-for="(item2, index2) in daysForWeek(index)"
                  :key="item2.date"
                  :class="{
                    daySelected:
                      selectedDay == item2.day && selectedMonth == item2.month,
                    past: isInPast(item2),
                  }"
                  :data-index="index2"
                  @click="setDay(item2)"
                >
                  <div class="dayText">{{ item2.day }}</div>
                </div>
              </div>
            </div>

            <div class="p-0 flex-fill pRelative" v-if="!showCal">
              <TimePicker
                :resize="resize"
                :placeHolder="contact_locale.label2"
                @clearTimeWarn="clearTimeWarn"
                :isMobile="isMobile"
                :minutesChanged="minChanged"
                :hoursChanged="hoursChanged"
                :hour="hoursVal"
                :min="minVal"
                @toogleAMPM="toogleAMPM"
              />
              <div class="dateWarn" v-if="showTimeWarn">
                {{ contact_locale.label2Error }}
              </div>
            </div>

            <div class="p-0 flex-fill pRelative" v-if="!showCal">
              <OptionList
                :placeHolder="contact_locale.label3"
                :appoointment="false"
                :resize="resize"
                :type="timeZoneType"
                :change="timezoneChanged"
                :disabled="false"
                :selectedValue="selectedTZ"
                :items="getZones"
                :isMobile="isMobile"
              />
            </div>
          </div>
        </div>
      </div>
      <!-- </div> -->
      <div
        class="p-0 d-flex flex-column ml15 mr15 mt20 mb20 justify-content-end"
        v-if="!showCal"
      >
        <div class="p-0 d-flex flex-fill justify-content-center">
          <div
            v-wave
            class="contact-next-button"
            :class="{ contextNextClickable: nextDisabled }"
            @click="nextTapped"
          >
            <div class="contact-next-button-text">
              {{ contact_locale.buttonCopy }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { mapActions } from "vuex";
import { timezones_fin } from "@/utils/timezones_fin.js";
import OptionList from "@/components/OptionList.vue";
import {
  addMonths,
  subDays,
  addDays,
  getWeeksInMonth,
  startOfMonth,
  getDay,
  differenceInMonths,
  isBefore,
  isSaturday,
  getMonth,
  isSunday,
} from "date-fns";
import TimePicker from "@/components/TimePicker.vue";

import { AutoCompleteType, OverlaySteps } from "@/utils/enum.js";

import moment from "moment-timezone";
export default {
  name: "ContactMeetDateTime",
  components: {
    TimePicker,
    OptionList,
  },
  props: ["next", "resize"],
  data: () => ({
    twenty4Mode: true,
    time: "",
    selectedDate: new Date(Date.now()),

    monthsDE: [
      "Jan",
      "Feb",
      "Mär",
      "Apr",
      "Mai",
      "Juni",
      "Juli",
      "August",
      "Sep",
      "Okt",
      "Nov",
      "Dez",
    ],
    months: [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "June",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ],
    monthsCN: [
      "1月",
      "2月",
      "3月",
      "4月",
      "5月",
      "6月",
      "7月",
      "8月",
      "9月",
      "10月",
      "11月",
      "12月",
    ],
    weekDaysDe: ["Mo", "Di", "Mi", "Do", "Fr", "Sa", "So"],
    weekDays: ["Mon", "Tue", "Wed", " Thr", "Fri", "Sat", "Sun"],
    weekDaysCN: [
      "星期一",
      "星期二",
      "星期三",
      " 星期四",
      "星期五",
      "星期六",
      "星期日",
    ],
    dateTimeKeys: [
      "0",
      "1",
      "2",
      "3",
      "4",
      "5",
      "6",
      "7",
      "8",
      "9",
      "10",
      "11",
      "12",
    ],
    timezones: [],
    stepType: OverlaySteps.DATESELECTION,
    timeZoneType: AutoCompleteType.TIMEZONE,
    selectedTZ: null,
    showDateWarn: false,
    showTimeWarn: false,
    startDayOffset: 0,
    selectedMonth: 0,
    selectedYear: 2021,
    startDayOfMonth: 0,
    minVal: "",
    hoursVal: "",
    timezonePlaceHolder: "ZONES",
    weeks: 0,
    days: [],
    weekAndDays: [],
    currentMonthIndex: 0,
    selectedDay: 0,
    showCal: false,
    today: Date,
    _selectedHours: "",
    _selecteMinutes: "",
    preMonthBtnDisabled: true,
    nextMonthBtnDisabled: false,

    h1: "",
    h2: "",
    m1: "",
    m2: "",
    dateTime: "",

    isPM: true,
  }),
  created() {
    this.today = new Date(Date.now());
    this.selectedYear =
      this.getInTouch.meetingYear != null && this.getInTouch.meetingYear != ""
        ? this.getInTouch.meetingYear
        : this.today.getFullYear();
    this.selectedMonth =
      this.getInTouch.meetingMonth != null && this.getInTouch.meetingMonth != ""
        ? this.getInTouch.meetingMonth
        : getMonth(this.today);
    this.selectedDay =
      this.getInTouch.meetingDay != null && this.getInTouch.meetingDay != ""
        ? this.getInTouch.meetingDay
        : this.today.getDate();

    this.selectedDate = new Date();
    this.selectedDate.setFullYear(this.selectedYear);
    this.selectedDate.setMonth(this.selectedMonth);
    this.selectedDate.setDate(this.selectedDay);

    this.twenty4Mode = this.currentLocale == "de" ? true : false;

    this.hoursVal = this.getInTouch.meetHour;
    this.minVal = this.getInTouch.meetMinutes;

    const tz = moment.tz.guess();
    const currentTzKey = moment.tz
      .zone(tz)
      .abbr(new Date(Date.now()).getTime());
    const item = timezones_fin.find((item) => item.key == currentTzKey);
    /* console.log(moment.tz.zone(tz).abbr(1623527971000)) */
    if (item != null) {
      this.selectedTZ = {
        text: item.title,
        offset: item.offset,
        value: item.display_title,
      };
    }
    this.setGetInTouch_timezone(this.selectedTZ);
  },
  mounted() {
    setTimeout(() => {
      this.resize();
    }, 1000);
    this.renderCalender(this.selectedDate);
  },

  computed: {
    ...mapGetters([
      "isMobile",
      "contact_locale",
      "currentLocale",
      "getInTouch",
    ]),
    weekDaysLocalized: function () {
      if (this.currentLocale === "de") {
        return this.weekDaysDe;
      } else if (this.currentLocale === "cn") {
        return this.weekDaysCN;
      } else {
        return this.weekDays;
      }
    },
    monthsLocalized: function () {
      switch (this.currentLocale) {
        case "de":
          return this.monthsDE;
        case "cn":
          return this.monthsCN;
        default:
          return this.months;
      }
      // return this.currentLocale == "en" ? this.months : this.monthsDE
    },

    timezoneDisabled: function () {
      return false;
    },

    nextDisabled: function () {
      return false;
    },
    meetingDate: function () {
      if (
        this.getInTouch.meetingDay == "" ||
        this.getInTouch.meetingMonth == "" ||
        this.getInTouch.meetingYear == ""
      ) {
        return "";
      }
      const _tmpMonth = this.getInTouch.meetingMonth + 1;
      const date =
        this.getInTouch.meetingDay < 10
          ? "0" + this.getInTouch.meetingDay
          : this.getInTouch.meetingDay;
      const month = _tmpMonth < 10 ? "0" + _tmpMonth : _tmpMonth;
      const year = this.getInTouch.meetingYear;
      if (this.currentLocale == "de") {
        return date + "." + month + "." + year;
      } else if (this.currentLocale == "cn") {
        return year + "年/" + month + "月/" + date + "日";
      } else {
        return date + "/" + month + "/" + year;
      }
    },
    showPlaceholder() {
      return (
        this.getInTouch.meetingYear == null ||
        this.getInTouch.meetingMonth == null ||
        this.getInTouch.meetingDay == null ||
        this.getInTouch.meetingYear == "" ||
        this.getInTouch.meetingMonth == "" ||
        this.getInTouch.meetingDay == ""
      );
    },
    meetingTime: function () {
      return "";
    },
    /* meetHours:function(){

      },
      meetHours:function(){

      }, */

    getZones() {
      let z = timezones_fin.map((item) => ({
        text: item.title,
        offset: item.offset,
        value:
          this.currentLocale === "cn" && item.display_title_cn != null
            ? item.display_title_cn
            : item.display_title,
      }));
      return z.sort((a, b) => {
        return b.offset - a.offset;
      });
    },

    /* selectedTZ:function(){
        return selectedTZ
      } */
  },

  methods: {
    ...mapActions([
      "setGetInTouch_isPM",
      "setGetInTouch_timezone",
      "setGetInTouch_meetHour",
      "setGetInTouch_meetMinutes",
      "setGetInTouch_timezone",
      "setGetInTouch_meetingDay",
      "setGetInTouch_meetingMonth",
      "setGetInTouch_meetingYear",
    ]),
    clearTimeWarn() {
      this.showTimeWarn = false;
    },

    hoursChanged(e) {
      this.hoursVal = e;
      this.showTimeWarn = false;
      setTimeout(() => {
        this.setGetInTouch_meetHour(this.hoursVal);
      }, 50);
    },
    minChanged(e) {
      this.minVal = e;
      this.showTimeWarn = false;
      setTimeout(() => {
        this.setGetInTouch_meetMinutes(this.minVal);
      }, 50);
    },
    timezoneChanged(e) {
      this.selectedTZ = e;
      this.setGetInTouch_timezone(e);
    },
    toggleCalMode() {
      this.showCal = !this.showCal;
      if (this.showCal) {
        // TODO CHRIS && store.state.getInTouch.meetDateTime == null ??????
        this.selectedDay = new Date(Date.now()).getDate();
      }
      this.showDateWarn = false;
      setTimeout(() => {
        this.resize();
      }, 50);
    },
    preMonth() {
      if (this.preMonthBtnDisabled) {
        return;
      }
      const n = new Date(Date.now());
      this.currentMonthIndex--;
      const date = addMonths(n, this.currentMonthIndex);
      this.selectedYear = date.getFullYear();
      this.renderCalender(date);
    },
    nextMonth() {
      if (this.nextMonthBtnDisabled) {
        return;
      }
      const n = new Date(Date.now());
      this.currentMonthIndex++;
      this.preMonthBtnDisabled = false;
      const date = addMonths(n, this.currentMonthIndex);
      const year = date.getFullYear();
      if (this.selectedYear < year) {
        this.selectedYear = year;
      }
      setTimeout(() => {
        this.renderCalender(date);
      }, 25);
    },

    isInPast(day) {
      const _month = day.month;
      const _day = day.day;
      let _date = new Date();
      _date.setFullYear(this.selectedYear);
      _date.setMonth(_month);
      _date.setDate(_day);
      return (
        isBefore(_date, this.today) || isSaturday(_date) || isSunday(_date)
      );
    },
    isDateInPast(date) {
      return isBefore(date, this.today) || isSaturday(date) || isSunday(date);
    },
    daysForWeek(index) {
      return this.weekAndDays[index];
    },
    toogleAMPM(mode) {
      this.isPM = mode.isPm === true;
      this.setGetInTouch_isPM(this.isPM);
    },

    setDay(day) {
      this.showDateWarn = false;
      let _date = new Date();
      _date.setFullYear(this.selectedYear);
      _date.setMonth(day.month);
      _date.setDate(day.day);
      console.log(_date);
      if (this.isDateInPast(_date)) {
        return;
      } else {
        this.selectedDay = day.day;
        this.selectedMonth = day.month;
        setTimeout(() => {
          this.setGetInTouch_meetingYear(this.selectedYear);
          this.setGetInTouch_meetingMonth(this.selectedMonth);
          this.setGetInTouch_meetingDay(this.selectedDay);
          this.showCal = false;
        }, 100);
      }
    },

    renderCalender(date) {
      const now = new Date(date);

      this.weekAndDays = [];

      this.selectedMonth = getMonth(now);

      this.weeks = getWeeksInMonth(this.selectedMonth);

      const startOfM = startOfMonth(now);

      this.startDayOfMonth = getDay(startOfM) - 1;

      const lastDaysOfLastMonth = subDays(startOfM, this.startDayOfMonth);

      if (this.today.getMonth() == this.selectedMonth) {
        this.preMonthBtnDisabled = true;
      }
      if (differenceInMonths(now, this.today) > 4) {
        this.nextMonthBtnDisabled = true;
      } else {
        this.nextMonthBtnDisabled = false;
      }
      let dayIndex = 0;
      for (var i = 0; i < this.weeks; i++) {
        let _week = [];
        for (var j = 1; j < 8; j++) {
          let _date = addDays(lastDaysOfLastMonth, dayIndex);
          _week.push({ day: _date.getDate(), month: _date.getMonth() });
          dayIndex++;
        }
        this.weekAndDays.push(_week);
      }
    },
    nextTapped() {
      if (this.hoursVal == null || this.hoursVal == "") {
        this.showTimeWarn = true;
      }
      if (this.minVal == null || this.minVal == "") {
        this.showTimeWarn = true;
      }

      if (this.meetingDate == "") {
        this.showDateWarn = true;
      }

      if (this.showDateWarn == false && this.showTimeWarn == false) {
        this.next(this.stepType, OverlaySteps.ADD_FORMDATA);
      }

      // if(this.hoursVal != '' && this.minVal != ''){
      //   const hours = this.hoursVal.startsWith("0") ? parseInt(this.hoursVal.substr(0,1)) : parseInt(this.hoursVal)
      //   const mins = parseInt(this.minVal);
      //   let newDate = moment(this.selectedDate);
      //   newDate.set({month: this.selectedMonth, date: this.selectedDay,hour: hours, minutes: mins});
      //   // TODO CHRIS //store.state.getInTouch.meetDateTime = new Date(newDate)
      // //  this.next(this.stepType, OverlaySteps.ADD_FORMDATA)
      // } else {
      //   this.showTimeWarn = true;
      // }
    },
  },
};
</script>

<style scoped>
.controlC {
  /* height: calc(100% - 60px); */
  position: relative;
  height: 100%;
  /* overflow: hidden; */
  padding-bottom: 4px;
}
.buttonToggle {
  margin-top: 3px;
  width: 124px;
  height: 40px;
  border-radius: 4px;
  background-color: #c4c4c4;
}
.toggleButtonItem {
  cursor: pointer;
  width: 55px;
  height: 32px;
  text-align: center;
  padding-top: 8px !important;
  background-color: rgba(255, 255, 255, 0);
  transition: background-color 0.25s ease-in-out;
}
.toggleButtonItemActive {
  border-radius: 4px;
  background-color: rgba(255, 255, 255, 1);
  transition: background-color 0.25s ease-in-out;
}
.weekDay {
  width: 39px;
  font-size: 13px;
  height: 18px;

  margin-bottom: 8px;
  font-size: 13px;
  font-weight: bold;
  text-align: center;
}
.day {
  cursor: pointer;
  width: 39px;
  margin-top: 4px;
  height: 30px;
  background-color: rgba(99, 99, 99, 0);
  /*  border: 1px solid rgba(255,255,255,0);*/
  color: #636363;
  border-radius: 2px;
  transition: all 0.25s ease-in-out;
}
@media (orientation: landscape) {
  .day,
  .weekDay {
    text-align: center;
    margin-right: 8px;
    margin-left: 8px;
    /* border: 1px solid rgba(255,255,255,0);*/
  }
  .day:hover {
    background-color: rgba(255, 255, 255, 0.25);
    color: #636363;
    transition: all 0.25s ease-in-out;
  }
  .datePickerbar {
    align-items: center;
    height: 40px;
  }
  .dayText {
    margin-top: 10px;
    font-size: 13px;
    text-align: center;
    /* height: 16px; */
  }
}
@media (orientation: portrait) {
  .day,
  .weekDay {
    text-align: center;
    margin-right: 8px;
    margin-left: 8px;
    /*border: 1px solid rgba(255,255,255,0);*/
  }
  .dayText {
    margin-top: 7px;
    font-size: 13px;
    text-align: center;
    /* height: 16px; */
  }
  .datePickerbar {
    align-items: center;
    height: 52px;
    margin-right: 2px;
  }
}

.past {
  cursor: not-allowed;
  pointer-events: none !important;
  background-color: rgba(99, 99, 99, 0);
  color: rgba(99, 99, 99, 0.25);
  border: 1px solid rgba(255, 255, 255, 0);

  /* top: -20px; */
}
.pastLine {
  width: 33px;
  height: 33px;
  border-bottom: 1px solid rgba(99, 99, 99, 0.7);
  -webkit-transform: translateY(-15px) translateX(10px) rotate(45deg);
  position: absolute;
}
.daySelected {
  background-color: rgba(99, 99, 99, 0.6) !important;
  color: #fff;
  transition: all 0.25s ease-in-out;
  font-family: "Univers LT Bold";
}

.calIcon {
  cursor: pointer;
}

input[type="time"]::-webkit-calendar-picker-indicator {
  background: none;
}
input {
  border: 1px solid transparent;
  background-color: rgba(255, 255, 255, 0);
  padding: 10px;
  padding-left: 0px !important;
  font-size: 16px;
  font-family: "Univers LT Std";
  color: #636363;
}
.switchMonthBtn {
  cursor: pointer;
}
.smTF {
  font-size: 16px;
  text-align: center !important;
  padding-left: 0px;
  padding-right: 0px;
  width: 8px;
  border: 1px solid rgba(99, 99, 99, 0);
  color: #636363;
  font-family: "Univers LT Std";
}
.dateWarn {
  position: absolute;
  bottom: -3px;
  left: 15px;
  font-size: 12px;
  color: #ff0000;
}
</style>
