<template>
  <div
    class="d-flex align-items-center"
    style="background-color: rgba(255, 255, 255, 0); height: 60px"
  >
    <div
      class="p-0 ml15 mt5"
      v-if="hastBackButtonIcon === true"
      @click="backButtonTapped"
    >
      <img
        src="../../assets/ui/accordionIcon2.svg"
        class="close-btn-icon"
        alt=""
      />
    </div>
    <div class="p-0 ml15 overlayHeader flex-fill mt5">{{ title }}</div>
    <div
      class="p-0 mr15 overlayHeaderCloseBtn"
      style="width: 16px; height: 16px"
      @click="closeButtonTapped"
      v-if="closeIcon === true"
    >
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clip-path="url(#clip0)">
          <rect
            x="14.5361"
            y="3.2224"
            width="16"
            height="1.6"
            rx="0.8"
            transform="rotate(135 14.5361 3.2224)"
            fill="#636363"
          />
          <rect
            x="3.2224"
            y="2.09131"
            width="16"
            height="1.6"
            rx="0.8"
            transform="rotate(45 3.2224 2.09131)"
            fill="#636363"
          />
        </g>
        <defs>
          <clipPath id="clip0">
            <rect width="16" height="16" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "MobileCloseHeader",
  props: ["title", "closeIcon", "backBtnIcon", "closeCallback"],
  // components:{
  // },
  /* data(){
     return{

     }
   },*/
  computed: {
    hastBackButtonIcon() {
      return this.backBtnIcon != null && this.backBtnIcon === true;
    },
  },
  methods: {
    ...mapActions(["setInnerOverlayComponent"]),
    closeButtonTapped() {
      this.closeCallback();
    },
    backButtonTapped() {
      this.setInnerOverlayComponent({
        path: "./inner_overlay_components",
        name: "DeviceSettings",
      });
    },
  },
};
</script>

<style scoped>
.close-btn-icon {
  cursor: pointer;
}
</style>
