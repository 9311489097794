<template>
  <svg
    width="50"
    height="50"
    viewBox="0 0 50 50"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    name="PromiseIcon1"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M25 48C37.7025 48 48 37.7025 48 25C48 12.2975 37.7025 2 25 2C12.2975 2 2 12.2975 2 25C2 37.7025 12.2975 48 25 48ZM25 50C38.8071 50 50 38.8071 50 25C50 11.1929 38.8071 0 25 0C11.1929 0 0 11.1929 0 25C0 38.8071 11.1929 50 25 50Z"
      fill="url(#paint0_linear_3543_70)"
    />
    <path
      d="M22.3215 23.3099C21.8492 22.8375 21.0988 22.8375 20.6253 23.3099C20.1529 23.7823 20.1529 24.5327 20.6253 25.0062L23.0991 27.48C23.3217 27.7026 23.6268 27.8416 23.9331 27.8416C24.2393 27.8416 24.5445 27.7308 24.7671 27.48L29.4931 22.7539C29.9655 22.2815 29.9655 21.5312 29.4931 21.0577C29.0208 20.5853 28.2704 20.5853 27.7969 21.0577L23.9048 24.9497L22.3215 23.3099Z"
      fill="url(#paint1_linear_3543_70)"
    />
    <path
      d="M35.2203 17.0548C28.4376 14.3312 25.9072 12.1344 25.8789 12.1072C25.4336 11.6902 24.7386 11.7184 24.2945 12.1072C24.2663 12.1354 21.7642 14.303 14.953 17.0559C14.5078 17.2503 14.2026 17.6673 14.2026 18.1679C14.2026 32.1787 24.0717 37.8508 24.5171 38.0735C24.6843 38.1843 24.9059 38.2125 25.1014 38.2125C25.2957 38.2125 25.4901 38.1571 25.6856 38.0735C26.1026 37.8509 36.0001 32.1801 36.0001 18.1679C35.9436 17.6673 35.6656 17.2221 35.2203 17.0548ZM25.0732 35.5985C23.099 34.2638 16.9005 29.2327 16.5941 18.9466C21.2649 17.0006 23.8775 15.3609 25.0732 14.4986C26.2689 15.3608 28.8816 17.0006 33.5523 18.9466C33.2461 29.26 27.0476 34.264 25.0732 35.5985Z"
      fill="url(#paint2_linear_3543_70)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_3543_70"
        x1="38"
        y1="-1.53422e-06"
        x2="11.5"
        y2="50"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="var(--gradientFrom)" />
        <stop offset="1" stop-color="var(--gradientTo)" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_3543_70"
        x1="38"
        y1="-1.53422e-06"
        x2="11.5"
        y2="50"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="var(--gradientFrom)" />
        <stop offset="1" stop-color="var(--gradientTo)" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_3543_70"
        x1="38"
        y1="-1.53422e-06"
        x2="11.5"
        y2="50"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="var(--gradientFrom)" />
        <stop offset="1" stop-color="var(--gradientTo)" />
      </linearGradient>
    </defs>
  </svg>
</template>

<script>
export default {
  name: "PromiseIcon1",
};
</script>
