<template>
  <svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0)" style="transform: translate(2px, 2px)">
      <rect
        x="14.5361"
        y="3.2224"
        width="16"
        height="1.6"
        rx="0.8"
        transform="rotate(135 14.5361 3.2224)"
        fill="currentColor"
      />
      <rect
        x="3.2224"
        y="2.09131"
        width="16"
        height="1.6"
        rx="0.8"
        transform="rotate(45 3.2224 2.09131)"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>
