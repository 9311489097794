<template>
  <div
    class="_mainPanel2"
    ref="_gpPanel"
    :class="{ _mainPanel2Mob: !isLandscape }"
    v-if="apiDataLoaded"
  >
    <div
      v-for="(item, index) in computedComponents"
      :key="item.index"
      :data-index="index"
    >
      <transition
        mode="out-in"
        v-on:before-enter="beforePanelEnter"
        v-on:enter="onPanelEnter"
        v-on:leave="onPanelLeave"
        tag="div"
      >
        <div v-if="item.index == gbIndex" style="position: absolute">
          <component
            @panelHeightCallback="panelHeightCallback"
            :headline="item.content._hl_1"
            :index="item.index"
            :copy="item.content._copy_1"
            v-bind:is="item.component"
          ></component>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import { gsap } from "gsap";
import { TweenVars } from "@/utils/tweenTime.js";
import { EB, EB_ACTION } from "@/utils/EB";
import { breakpoints } from "@/utils/responsive.js";
import { mapGetters } from "vuex";
import { mapActions } from "vuex";
import GlobalPresenceCompMod from "@/overlays/GlobalPresenceCompMod";
export default {
  name: "GlobalPresenceOverlay",
  components: {
    GlobalPresenceCompMod: GlobalPresenceCompMod,
  },
  data() {
    return {
      viewComponents: [],
      url: "",
      apiDataLoaded: false,
      currentIndex: 0,
      startMobile: false,
      scrollEnabled: false,
      matomoTimeout: null,
      toUp: false,
    };
  },

  computed: {
    ...mapGetters([
      "currentLocale",
      "apiPath",
      "isLandscape",
      "isMobile",
      "global_presence",
      // "global_presence_group",
      "global_presense_loaded",
      "CURRENT_PATH_ITEMS",
    ]),
    canScroll: function () {
      return this.scrollEnabled;
    },
    gbIndex() {
      return this.CURRENT_PATH_ITEMS[2] != null
        ? parseInt(this.CURRENT_PATH_ITEMS[2])
        : 0;
    },
    computedComponents: function () {
      var vm = this;
      const l = vm.viewComponents;
      return l;
    },
    currentLocaleKey() {
      return this.currentLocale;
    },
  },
  watch: {
    gbIndex(newValue, oldValue) {
      if (newValue != oldValue) {
        this.toUp = newValue < oldValue;
        this.currentIndex = newValue;
      }
    },
    global_presense_loaded(newValue, oldValue) {
      if (this.apiDataLoaded == false && newValue == true) {
        this.initComponents();
        console.log(
          "global_presense_loaded CHANGED -> old -> " +
            oldValue +
            " new -> " +
            newValue
        );
      }
    },
    currentLocaleKey(newValue, oldValue) {
      if (newValue != oldValue) {
        this.fetchData();
      }
    },
  },
  unmounted() {
    this.$store.commit("CLEAR_GLOBAL_PRESENSE");
  },
  destroyed() {
    EB.$off(EB_ACTION.WIN_RESIZE);
    this.apiDataLoaded = false;
    this.$store.commit("CLEAR_GLOBAL_PRESENSE");
  },

  mounted() {
    this.url = this.apiPath + "promise/global-presence-modules/";
    this.fetchData();
  },

  created() {
    this.startMobile = this.isMobile;
    EB.$on(EB_ACTION.WIN_RESIZE, () => {
      this.updateAspects();
    });
    // this.listeners();
  },

  methods: {
    ...mapActions(["matomoTrackEvent", "fetchGloabelPresence"]),

    async fetchData() {
      await this.fetchGloabelPresence();
    },

    initComponents() {
      for (var i = 0; i < this.global_presence.length; i++) {
        this.viewComponents.push({
          component: "GlobalPresenceCompMod",
          index: i,
          content: this.global_presence[i].content,
        });
      }
      this.apiDataLoaded = true;
      setTimeout(() => {
        this.updateAspects();
      }, 10);
    },
    reloadOverlayContent() {},

    beforePanelEnter: function (el) {
      el.style.opacity = "0";
      el.style.marginTop = this.toUp ? "0px" : "80px";
    },
    onPanelEnter: function (el, done) {
      gsap.to(el, {
        marginTop: 0,
        opacity: 1,
        delay: 0.35,
        duration: TweenVars.durationSlow,
        ease: TweenVars.ease,
        onComplete: () => {
          done();
        },
      });
    },
    onPanelLeave: function (el, done) {
      const __p = this.toUp ? 40 : -40;
      gsap.to(el, {
        opacity: 0,
        marginTop: __p,
        duration: TweenVars.duration,
        ease: TweenVars.ease,
        onComplete: () => {
          done();
        },
      });
    },

    matomoTrack(event) {
      if (this.matomoTimeout) {
        clearTimeout(this.matomoTimeout);
      }
      this.matomoTimeout = setTimeout(() => {
        this.matomoTrackEvent([
          this.productOverlayParams.sectionName +
            " - prod_" +
            this.productOverlayParams.productId,
          "- details_" + +event.selectedSubItem,
        ]);
      }, 200);
    },

    _scrollTo(pos, delay) {
      setTimeout(() => {
        gsap.to(this.$refs.overlayHolderRef, {
          scrollTop: pos,
          duration: TweenVars.duration,
          ease: TweenVars.ease,
        });
      }, delay);
    },

    updateAspects() {
      var rightPadding = 0;
      const screenScaleDiffToMaxWidth =
        this.$store.state.constrains.screenWidth -
        breakpoints.maxRightPaddingDeskWidth;

      if (
        this.$store.state.constrains.screenWidth >=
        breakpoints.maxRightPaddingDeskWidth
      ) {
        rightPadding = breakpoints.maxDeskRightPadding;
      } else if (
        this.$store.state.constrains.screenWidth <
          breakpoints.maxRightPaddingDeskWidth &&
        this.$store.state.constrains.screenWidth >
          breakpoints.tabletMinLandscapeWidth
      ) {
        rightPadding =
          breakpoints.maxDeskRightPadding *
            (screenScaleDiffToMaxWidth / breakpoints.tabletMinLandscapeWidth) +
          breakpoints.minDeskRightPadding;
      } else {
        rightPadding = breakpoints.minMobileHorizontalPadding;
      }

      var newWidth = 334; //274 ;
      if (this.isLandscape) {
        newWidth = breakpoints.landscapePanelWidth + 60;
      } else {
        newWidth = this.$store.state.constrains.screenWidth;
        // if(this.$store.state.constrains.screenWidth > breakpoints.maxPhoneWidth){
        //   newWidth = breakpoints.maxPortraitPanelWidth ;
        // } else {
        //   const tempWidth = this.$store.state.constrains.screenWidth - (rightPadding *2);
        //      newWidth = tempWidth > breakpoints.maxPortraitPanelWidth ?
        //      breakpoints.maxPortraitPanelWidth : tempWidth; //store.screenWidth - 30;
        //      newWidth +=5
        // }
      }
      // this.addProductOverlayPanelWidth(newWidth);
      gsap.to(this.$refs._gpPanel, {
        width: newWidth,
        duration: TweenVars.duration,
        ease: TweenVars.ease,
      });

      var newX = 1920;
      if (this.isLandscape) {
        newX =
          this.$store.state.constrains.screenWidth - (newWidth + rightPadding);
      } else {
        newX = this.$store.state.constrains.screenWidth / 2 - newWidth / 2;
      }

      gsap.to(this.$refs._gpPanel, {
        x: newX,
        duration: 0.4,
        ease: TweenVars.ease,
      });
      this.setPanelYPos();
    },

    setPanelYPos() {
      gsap.to(this.$refs._gpPanel, {
        opacity: 1,
        duration: TweenVars.duration,
        ease: TweenVars.ease,
      });
    },
    panelHeightCallback(height) {
      var fact = this.$store.state.constrains.screenHeight * 0.05 * 2 + 40;
      var newHeight = this.isLandscape ? height : height + fact;
      var newY = this.isLandscape
        ? this.$store.state.constrains.screenHeight - height - 130
        : this.$store.state.constrains.screenHeight - height - fact;
      // ((this.$store.state.screenHeight -100) - panelHeight);
      gsap.to(this.$refs._gpPanel, {
        y: newY,
        height: newHeight,
        opacity: 1,
        duration: TweenVars.duration,
        ease: TweenVars.ease,
      });
    },
  },
};
</script>

<style lang="scss">
._mainPanel2 {
  opacity: 0;
  position: absolute;
  width: 330px;
  height: auto;
  transform: translateY(110vh);
}

._mainPanel2Mob {
  height: auto;
  background: url(../assets/ui/darken_layer.png);

  /** allow arrows to be clickable on mobile */
  pointer-events: none !important;
}

.globPrHeader {
  font-size: 70px;
  font-weight: 400;
  margin-top: 25px;
  margin-left: 25px;
  color: #fff;
}
.globCopy {
  font-family: "Univers LT Std";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 128%;
  color: #ffffff;
}
</style>
