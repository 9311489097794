<template>
  <div
    class="meetingTools"
    :class="{
      isMobile,
      popupOpened,
    }"
  >
    <div class="toolbarItems">
      <button
        v-if="!isMobile"
        class="iconButton"
        @click="$emit('openSettings')"
      >
        <SettingsIcon />
      </button>

      <div class="toolbarItem">
        <button
          class="iconButton"
          @click="
            rtcParams.isVideoOn === true ? unpublishVideo() : publishVideo()
          "
        >
          <template v-if="rtcParams.isVideoOn === true">
            <CamOnIcon />
            <span v-if="isMobile">{{ locale.meeting_2_intro_button_1_1 }}</span>
          </template>
          <template v-else>
            <CamOffIcon />
            <span v-if="isMobile">{{ locale.meeting_2_intro_button_1_2 }}</span>
          </template>
        </button>

        <SlideToggle
          v-if="isMobile"
          :active="rtcParams.isVideoOn"
          @toggled="
            rtcParams.isVideoOn === true ? unpublishVideo() : publishVideo()
          "
          class="videoToggle"
        />
      </div>

      <div class="toolbarItem">
        <button
          class="iconButton"
          @click="rtcParams.isAudioOn ? muteAudio() : publishAudio()"
        >
          <template v-if="rtcParams.isAudioOn === true">
            <MicOnIcon />
            <span v-if="isMobile">{{ locale.meeting_2_intro_button_2_1 }}</span>
          </template>
          <template v-else>
            <MicOffIcon />
            <span v-if="isMobile">{{ locale.meeting_2_intro_button_2_2 }}</span>
          </template>
        </button>

        <SlideToggle
          v-if="isMobile"
          :active="rtcParams.isAudioOn"
          @toggled="rtcParams.isAudioOn === true ? muteAudio() : publishAudio()"
          class="audioToggle"
        />
      </div>

      <button
        v-if="!isMobile"
        class="screenSharingButton"
        @click="$emit('startScreensharing')"
        :disabled="screenUser || remoteScreenUser || !canRunMeeting"
      >
        <ShareIcon />
      </button>

      <div class="toolbarItem">
        <button class="membersButton toolbarItem" @click="openUserList">
          <div class="membersIconWrapper">
            <UsersIcon />

            <div class="usersCount">{{ usersCount }}</div>
          </div>

          <span v-if="isMobile">
            {{ locale.meeting_participants }} ({{ usersCount }})
          </span>
        </button>
      </div>

      <button
        class="iconButton"
        v-if="isModerator"
        @click="toggleOverlay('resizeMeetingOverlay')"
      >
        <HideVideoIcon v-if="meetingWinStyleNr === 0" />
        <SmallVideoIcon v-else-if="meetingWinStyleNr === 1" />
        <BigVideoIcon v-else />
      </button>

      <div class="toolbarItem" v-if="isMobile">
        <button class="iconButton" @click="openSettings">
          <SettingsIcon />

          <span v-if="isMobile">{{ locale.meeting_settings_hl_1 }}</span>
        </button>
      </div>

      <div class="toolbarItem">
        <button class="stopCallButton" @click="showCloseMeeting">
          <img src="/assets/ui/tour/call_end.svg" />

          <span v-if="isMobile">{{ locale.meeting_stop_call }}</span>
        </button>
      </div>
    </div>

    <!-- ======================= END Screensize Selection ======================== -->

    <OverlayResizeMeeting
      v-if="overlayViews['resizeMeetingOverlay'] === true"
      @change="changeSizeMode"
      @close="onClosePopup"
    />
    <UserList
      v-if="overlayViews['userList'] === true"
      @kickUser="kickUser"
      @toggleRemoteMic="toggleRemoteMic"
      @close="onClosePopup"
    />
  </div>
</template>

<script>
import OverlayResizeMeeting from "@/components/meeting/OverlayResizeMeeting.vue";
import UserList from "@/components/meeting/UserList.vue";
import { EB, EB_ACTION } from "@/utils/EB";
import { mapActions, mapGetters } from "vuex";
import MicOnIcon from "@/icons/MicOnIcon.vue";
import MicOffIcon from "@/icons/MicOffIcon.vue";
import SettingsIcon from "@/icons/SettingsIcon.vue";
import CamOnIcon from "@/icons/CamOnIcon.vue";
import CamOffIcon from "@/icons/CamOffIcon.vue";
import ShareIcon from "@/icons/ShareIcon.vue";
import UsersIcon from "@/icons/UsersIcon.vue";
import BigVideoIcon from "@/icons/BigVideoIcon.vue";
import HideVideoIcon from "@/icons/HideVideoIcon.vue";
import SmallVideoIcon from "@/icons/SmallVideoIcon.vue";
import SlideToggle from "@/components/SlideToggle";

export default {
  name: "MeetingTools",
  components: {
    OverlayResizeMeeting,
    UserList,
    MicOffIcon,
    MicOnIcon,
    SettingsIcon,
    SlideToggle,
    CamOnIcon,
    CamOffIcon,
    ShareIcon,
    UsersIcon,
    BigVideoIcon,
    HideVideoIcon,
    SmallVideoIcon,
  },
  emits: [
    "changeSizeMode",
    "kickUser",
    "toggleRemoteMic",
    "startScreensharing",
    "openSettings",
    "close",
  ],
  data() {
    return {
      popupOpened: false,
    };
  },

  unmounted() {
    EB.$off(EB_ACTION.WIN_RESIZE);
  },

  mounted() {
    //  EB.$on('closeTextmessageOverlay', e => {
    //     this.closeTextmessageOverlay();
    // });
    EB.$on(EB_ACTION.WIN_RESIZE, () => {
      this.winResized();
    });
    setTimeout(() => {
      this.winResized();
    }, 1000);
  },

  beforeDestroy() {
    if (this.overlayViews["userList"]) {
      this.toggleOverlay("userList");
    }
  },

  computed: {
    ...mapGetters([
      "locale",
      "rtcParams",
      "overlayViews",
      "isModerator",
      "isMobile",
      "screenUser",
      "remoteScreenUser",
      "myself",
      "moderatorUser",
      "otherUsers",
      "canRunMeeting",
      "meetingWinStyleNr",
    ]),

    usersCount() {
      return [this.moderatorUser, this.myself, ...this.otherUsers].filter(
        Boolean
      ).length;
    },
  },

  methods: {
    ...mapActions([
      "exitMeeting",
      "closeSession",
      "setInnerOverlayComponent",
      "publishVideo",
      "unpublishVideo",
      "publishAudio",
      "muteAudio",
      "toggleOverlay",
      "confirm",
    ]),

    openUserList() {
      this.popupOpened = true;

      this.toggleOverlay("userList");
    },

    onClosePopup() {
      if (this.isMobile) {
        this.$emit("close");
      }
    },

    changeSizeMode(sizeNr) {
      this.$emit("changeSizeMode", sizeNr);
    },

    kickUser(user) {
      this.$emit("kickUser", user);
    },

    toggleRemoteMic(user) {
      this.$emit("toggleRemoteMic", user);
    },

    winResized() {},
    openSettings() {
      this.popupOpened = true;
      this.$emit("openSettings");
    },

    showCloseMeeting() {
      const { locale } = this;
      this.popupOpened = true;

      return this.confirm({
        title: locale.meeting_5_recipient_hl_1,
        description: locale.meeting_5_recipient_copy_1,
        confirmText: locale.meeting_5_recipient_button_2,
        cancelText: locale.meeting_5_recipient_button_1,
        onConfirm: () => {
          this.exitMeeting();
          this.closeSession();
          setTimeout(() => {
            this.setInnerOverlayComponent({
              path: "./inner_overlay_components",
              name: "SessionClosedFinish",
            });
          }, 500);
        },
        onCancel: () => {
          this.onClosePopup();
        },
      });
    },
  },
};
</script>

<style lang="scss">
@import "../../styles/IconButton.scss";

/* meeting tools icon */
.mti {
  width: 28px;
  height: 28px;
}

.iconUndText {
  cursor: pointer;
  color: #636363;
  display: flex;
  background-color: rgba(91, 91, 91, 0.15);
  border-radius: 2px;
  transition: all 0.25s ease-out;
}
.iconUndText:hover {
  background-color: rgba(255, 255, 255, 0.6);
  transition: all 0.25s ease-out;
}
.toolBarPopupBtn {
  padding: 6px 2px;
  border-radius: 3px;
  cursor: pointer;
  color: #636363;
  display: flex;
  background-color: rgba(91, 91, 91, 0);
  border-radius: 2px;
  transition: all 0.45s ease-in;
}
.toolBarPopupBtn:hover {
  background-color: rgba(91, 91, 91, 0.1);
  transition: all 0.35s ease-out;
}
.iconButton,
.screenSharingButton,
.membersButton {
  @extend %IconButton;

  height: 32px;
  width: 46px;
  border-radius: 2px;
  padding: 4px;
  color: rgb(96, 96, 96);
}

button {
  margin: 0;
  padding: 0;
}

.membersIconWrapper {
  position: relative;
  width: min-content;
  height: 100%;

  .usersCount {
    position: absolute;
    right: -6px;
    top: -2px;
    background: #636363;
    opacity: 0.5;
    border-radius: 20px;
    font-weight: 700;
    font-size: 8px;
    line-height: 1.5;
    display: flex;
    align-items: center;
    justify-content: center;
    letter-spacing: 0.06em;
    color: #ffffff;
    min-width: 17px;
  }
}

.stopCallButton {
  @extend %IconButton;

  line-height: 2em;
  color: #636363;
  border-radius: 40px;
  overflow: hidden;

  img {
    box-sizing: border-box;
    padding: 2px;
    background-color: #ff0000;
    width: 70px;
    height: 32px;
    border-radius: 40px;
  }

  &:hover img {
    background-color: rgba(255, 0, 0, 0.75);
  }
}

#meetingClose {
  white-space: nowrap;
}

.toolbarItems {
  display: flex;
  gap: 16px;
  padding: 4px 5px 4px 16px;
}

.meetingTools.isMobile {
  box-sizing: border-box;
  bottom: unset !important;
  right: 0 !important;
  left: 0 !important;

  button {
    font-size: 16px;
    width: 100%;
  }

  .toolbarItems {
    flex-direction: column;
    width: 100%;
    padding: 8px 16px;
  }

  .toolbarItem {
    display: flex;
    flex: 1;
    gap: 16px;
    justify-content: flex-start;

    button {
      padding: 0;
      gap: 16px;
      flex: 1;
      justify-content: flex-start;
    }

    svg,
    img {
      box-sizing: border-box;
      padding: 2px;
      width: 32px;
      height: 32px;
    }
  }

  .videoToggle,
  .audioToggle {
    margin-left: auto;
    flex-shrink: 0;
  }

  &.popupOpened {
    background: none !important;
    border: none !important;
    inset: 0 !important;

    .toolbarItems {
      display: none;
    }
  }
}
</style>
