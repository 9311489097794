<template>
  <div
    class="MeetingUsername overlayInnerCopy"
    v-bind:class="{ frostedbg: showFrost }"
  >
    <div id="meetingOverlayBackdrop"></div>

    <div id="innetMeetingOverlay" class="innerOverlay">
      <OverlayHeader :title="activeAppointmentTitle" />

      <form @submit="save($event)" name="username-form">
        <div class="d-flex flex-column pt6">
          <label class="username" :class="{ hasError: Boolean(errorMessage) }">
            <span class="label">{{ locale.meeting_username_label }}:</span>

            <input
              autocomplete="off"
              type="text"
              @input="onInput"
              :placeholder="locale.meeting_username_placeholder"
              :value="value"
              maxlength="40"
            />

            <span class="errorMessage">
              <template v-if="nextTapped && errorMessage">
                {{ errorMessage }}
              </template>
              &nbsp;
            </span>
          </label>
        </div>

        <div class="d-flex flex-column justify-content-xl-center">
          <div class="p-0 align-self-lg-center mt15 flex-fill ml15 mr15">
            <button class="contact-next-button contextNextClickable">
              {{ locale.meeting_username_save }}
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { mapGetters } from "vuex";
import { gsap } from "gsap";
import { TweenVars } from "@/utils/tweenTime.js";
import OverlayHeader from "@/components/klein/OverlayHeader";
export default {
  name: "MeetingUsername",
  components: {
    OverlayHeader,
  },
  data() {
    return {
      showFrost: false,
      value: "",
      nextTapped: false,
    };
  },

  computed: {
    ...mapGetters(["isLandscape", "locale", "activeAppointmentTitle"]),

    errorMessage() {
      if (!this.value) {
        return this.locale.meeting_username_error_required;
      }

      if (this.value.length < 3) {
        return this.locale.meeting_username_error_too_short;
      }

      return "";
    },
  },

  mounted() {
    this.fadeIn();
  },

  methods: {
    ...mapActions(["closeLoginOverlay", "setUsername"]),

    onInput(e) {
      this.value = (e.target.value ?? "").trim();
    },

    save(event) {
      event.preventDefault();

      this.nextTapped = true;

      if (!this.errorMessage) {
        this.setUsername(this.value);
      }
    },

    fadeIn: function () {
      gsap.to("#meetingOverlayBackdrop", {
        opacity: 1,
        duration: 1,
        ease: TweenVars.ease,
      });

      const _w = this.isLandscape
        ? 500
        : this.$store.state.constrains.screenWidth - 30;
      /* const _h = this.isLandscape ? 310 : (this.$store.state.constrains.screenHeight - 80);*/
      const _l = this.isLandscape
        ? this.$store.state.constrains.screenWidth / 2 - 250
        : 15;
      const _t = this.isLandscape
        ? this.$store.state.constrains.screenHeight / 2 - 155
        : 60;
      gsap.to("#innetMeetingOverlay", {
        width: _w,
        left: _l,
        top: _t,
        duration: 1,
        ease: TweenVars.ease,
        delay: 0.5,
        opacity: 1,
      });
      if (!this.showFrost) {
        setTimeout(() => {
          this.showFrost = true;
        }, 800);
      }
    },
  },
};
</script>

<style scoped lang="scss">
.tfError {
  font-size: 12px;
  line-height: 16px;
  color: #ff0000;
}

.innerOverlay {
  height: auto !important;
  padding-bottom: 15px !important;
}

form {
  font-size: 16px;
  line-height: 1.2;
}

.username {
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid rgba(99, 99, 99, 0.2);

  input,
  .label,
  .errorMessage {
    padding-left: 16px;
    margin: 0;
  }

  .errorMessage {
    color: red;
    font-size: 12px;
    line-height: 1.1;
  }

  .label {
    color: #636363;
    padding-top: 20px;
    padding-bottom: 2px;
    border-bottom: 1px solid rgba(99, 99, 99, 0.2);
  }

  input {
    padding-top: 16px;
    padding-bottom: 0;
    user-select: all;
    font-size: inherit;
  }
}

.contextNextClickable {
  outline: none;
  font-family: "Univers LT Std";
  height: auto;
  line-height: 28px;

  &:hover,
  &:active,
  &:focus {
    border-color: rgba(99, 99, 99, 0.6);
    background-color: rgba(0, 0, 0, 0.05);
  }
}
</style>
