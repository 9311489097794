<template>
  <div
    class="MediaFeed"
    :class="{
      isVideoOff: !videoTrack,
      extraSmall,
    }"
    :is-speaking="isSpeaking || null"
  >
    <div ref="videoContainer" class="cameraOn"></div>

    <div class="cameraOff">
      <div
        class="d-flex justify-content-center align-items-center"
        style="height: 100%"
      >
        <div class="p-0">
          <img src="/assets/ui/icons/meeting_cam_off.svg" />
        </div>
      </div>
    </div>

    <div class="micIndicator" v-if="showMicIndicator !== false">
      <MicOffIcon v-if="isMuted" />
      <VoiceIcon
        v-else-if="isSpeaking || hasSpokenRecently"
        :isSpeaking="isSpeaking"
      />
      <MicOnIcon v-else />
    </div>

    <div class="userName" v-if="username">
      <span>{{ username }}</span>
    </div>
  </div>
</template>

<script>
import { ResizeObserver } from "@juggle/resize-observer";
import MicOffIcon from "../../icons/MicOffIcon.vue";
import MicOnIcon from "../../icons/MicOnIcon.vue";
import VoiceIcon from "../../icons/VoiceIcon.vue";
import { toRaw } from "vue";

const IS_SPEAKING_INTERVAL = 1500;
const HAS_SPOKEN_RECENTLY_INTERVAL = 10000;

export default {
  name: "MediaFeed",

  props: [
    "videoTrack",
    "audioTrack",
    "username",
    "lastActivity",
    "showMicIndicator",
    "allowAudio",
  ],

  computed: {
    isMuted() {
      return !this.audioTrack;
    },
  },

  data() {
    return {
      isSpeaking: false,
      hasSpokenRecently: false,
      timerDescriptor: null,
      extraSmall: false,
      resizeObserver: new ResizeObserver(this.handleResize),
    };
  },

  methods: {
    micActivityTimer() {
      const msAgo = Date.now() - this.lastActivity;
      this.isSpeaking = msAgo < IS_SPEAKING_INTERVAL;
      this.hasSpokenRecently =
        !this.isSpeaking && msAgo < HAS_SPOKEN_RECENTLY_INTERVAL;

      this.timerDescriptor = setTimeout(this.micActivityTimer.bind(this), 100);
    },

    handleResize(entries) {
      for (const entry of entries) {
        if (entry.contentBoxSize) {
          // Firefox implements `contentBoxSize` as a single content rect, rather than an array
          const contentBoxSize = Array.isArray(entry.contentBoxSize)
            ? entry.contentBoxSize[0]
            : entry.contentBoxSize;

          this.extraSmall = contentBoxSize.inlineSize < 101;
        }
      }
    },
  },

  mounted() {
    this.$emit("mounted", this.$el);
    this.micActivityTimer();

    if (typeof this.videoTrack?.play === "function") {
      this.videoTrack.play(this.$refs.videoContainer);
    }

    if (
      this.allowAudio !== false &&
      typeof this.audioTrack?.play === "function"
    ) {
      this.audioTrack.play();
    }

    toRaw(this.resizeObserver).observe(this.$el);
  },

  updated() {
    if (
      this.videoTrack?.isPlaying === false &&
      typeof this.videoTrack.play === "function"
    ) {
      this.videoTrack.play(this.$refs.videoContainer);
    }

    if (
      this.allowAudio !== false &&
      typeof this.audioTrack?.play === "function"
    ) {
      this.audioTrack.play();
    }
  },

  beforeUnmount() {
    clearTimeout(this.timerDescriptor);
    toRaw(this.resizeObserver).disconnect();
  },
  components: { MicOffIcon, MicOnIcon, VoiceIcon },
};
</script>

<style scoped lang="scss">
.cameraOn,
.cameraOff {
  position: absolute;
  inset: 0;
}

.cameraOff {
  background-color: #cccccc;
  display: none;
}

.MediaFeed {
  overflow: hidden;
  position: relative;

  &:after {
    content: "";
    position: absolute;
    inset: 0;
    pointer-events: none;
    border: 4px solid rgba(255, 255, 255, 0.5);
    opacity: 0;
    transition: opacity 2s;
  }

  &[is-speaking]:after {
    opacity: 1;
  }

  &.isVideoOff .cameraOn {
    display: none;
  }
  &.isVideoOff .cameraOff {
    display: block;
  }

  &.extraSmall .userName {
    opacity: 0;
  }
}

.userName {
  position: absolute;
  height: 30px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.25) 100%
  );
  color: white;
  display: flex;
  align-items: center;
  padding-left: 15px;
  padding-right: 5px;
  font-weight: 700;

  > * {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.micIndicator {
  box-sizing: border-box;
  position: absolute;
  width: 24px;
  height: 24px;
  top: 8px;
  right: 8px;
  background: rgba(99, 99, 99, 0.25);
  color: #fff;
  padding: 4px;
  border-radius: 50%;

  > * {
    width: 100%;
    height: 100%;
  }
}
</style>
