// TODO: migrate to pinia
import _ from "lodash";

const state = {
  CURRENT_PATH_ITEMS: [
    // {1:''},
    // {2:''},
    // {3:''},
    // {4:''},
  ],
  CURENT_PATH: [],
  CURREN_HASH: "",
};

// ================== getters ========================
const getters = {
  CURENT_PATH: (state) => {
    return state.CURENT_PATH;
  },
  CURRENT_PATH_ITEMS: (state) => {
    return state.CURRENT_PATH_ITEMS;
  },
};

// ================== actions ========================
const actions = {
  async updateHash({ dispatch, commit, getters }, path) {
    const _fixed = path.slice(path.indexOf("#/") + 2);
    const _newPathItems = _fixed.split("/");

    dispatch("MATOMO_ROUTE_EVENT", _newPathItems);

    const diffNew = _.difference(_newPathItems, getters.CURRENT_PATH_ITEMS);
    const isInitialPageOpen = getters.CURRENT_PATH_ITEMS.length === 0;

    if (_newPathItems.includes("home") && _newPathItems.length === 1) {
      dispatch("hideAllOverlays");
    }

    if (_newPathItems.includes("career")) {
      dispatch("hideAllOverlays");
      dispatch("loadCareerContact");

      if (
        _newPathItems.includes("detail") ||
        _newPathItems.includes("openings")
      ) {
        commit("SET_PATH_ITEMS", _newPathItems);
        dispatch("openProductOverlayFromStateParams");
      }
    }

    if (_newPathItems.includes("promise") && _newPathItems.includes("values")) {
      dispatch("hideAllOverlays");

      if (!isInitialPageOpen && _newPathItems.includes("detail")) {
        commit("SET_PATH_ITEMS", _newPathItems);
        dispatch("openProductOverlayFromStateParams");
      }
    }

    if (diffNew.length == 0) {
      if (
        getters.CURRENT_PATH_ITEMS.includes("prod") &&
        getters.CURRENT_PATH_ITEMS.includes("poi") &&
        !_newPathItems.includes("poi")
      ) {
        dispatch("openProductOverlayFromStateParams");
        commit("SET_PATH_ITEMS", _newPathItems);
        return;
      }
    }

    const isChangingGlobalPrecencePOI =
      diffNew.length < 2 &&
      getters.CURRENT_PATH_ITEMS.includes("globe") &&
      !_newPathItems.includes("globe");

    if (isChangingGlobalPrecencePOI) {
      commit("HIDE_GLOBAL_PRESENCE_OVERLAY");
      commit("SET_PATH_ITEMS", _newPathItems);
      return;
    }

    switch (diffNew[0]) {
      case "home":
        // hide overlays if visible
        dispatch("hideAllOverlays");
        break;
      case "cat":
        // hide overlays if visible
        dispatch("hideAllOverlays");
        break;
      case "globe":
        // show global presence if not visible?
        commit("SHOW_GLOBAL_PRESENCE_OVERLAY", null, { root: true });
        break;
      case "prod":
        commit("SET_PATH_ITEMS", _newPathItems);
        // show product overlay ...
        dispatch("openProductOverlayFromStateParams");
        break;
      case "poi":
        commit("HIDE_PRODUCT_OVERLAY");
        break;
    }

    commit("SET_PATH_ITEMS", _newPathItems);
  },
};

// ================== mutations ========================
const mutations = {
  SET_PATH_ITEMS(state, values) {
    state.CURRENT_PATH_ITEMS = values;
  },

  SET_PATH(state, path) {
    // state.CURREN_HASH = path;
    // state.CURENT_PATH = path.split('#')
    const _fixed = path.slice(path.indexOf("#/") + 2);
    state.CURREN_HASH = _fixed;
    state.CURENT_PATH = _fixed.split("/");
    console.log(state.CURENT_PATH);

    if (state.CURENT_PATH[0] === "prod") {
      const _sectionName = state.CURENT_PATH[1];
      const _productIndex = state.CURENT_PATH[2];
      const _groupIndex = state.CURENT_PATH[3];
      state.productOverlayParams = {
        sectionName: _sectionName,
        productIndex: _productIndex,
        groupIndex: _groupIndex,
      };
      console.log(">>>>> PROD");
    }
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
