export const EB_ACTION = {
  WIN_RESIZE: "WIN_RESIZE",
};

class EventBus {
  constructor() {
    this.subscriptions = {};
    this.lastId = 0;
  }

  $on(eventType, callback) {
    const { subscriptions } = this;

    if (!subscriptions[eventType]) {
      subscriptions[eventType] = {};
    }

    const id = this.__getNextUniqueId();
    subscriptions[eventType][id] = callback;
  }

  $off(eventType, cb) {
    const { subscriptions } = this;

    const byEvent = subscriptions[eventType] ?? {};
    Object.keys(byEvent).forEach((id) => {
      if (byEvent[id] === cb) {
        delete byEvent[id];
      }
    });
  }

  $emit(eventType, arg) {
    const { subscriptions } = this;
    if (!subscriptions[eventType]) {
      return;
    }

    const byEvent = subscriptions[eventType] ?? {};
    Object.keys(byEvent).forEach((key) => {
      byEvent[key](arg);
    });
  }

  __getNextUniqueId() {
    return ++this.lastId;
  }
}

export const EB = new EventBus();
