export class ContactI {
  constructor(locale) {
    this.fetchCountries();
    this.currentLocale = locale;
    this._currentStep = "1";
    return (async () => {
      this.data = await fetch(
        `${process.env.VUE_APP_API_URL}/api/gb-content/contact`
      )
        .then((resp) => resp.json())
        .then((data) => {
          return data;
        });
      return this;
    })();
  }

  // @todo: reuse `fetchCountries` from the store
  async fetchCountries() {
    this._countries = await fetch(
      `${process.env.VUE_APP_API_URL}/api/gb-content/contact-countries`
    )
      .then((resp) => resp.json())
      .then((data) => {
        return data;
      });
  }

  // CRIS TODO - Remove
  /* get currentLocale(){
    this._currentLocale = store.state.currentLocale == null ? 'de' : store.state.currentLocale
    return this._currentLocale;
  } */

  setCurrentLocale(val) {
    // console.log('CONTECXT I localeChanged')
    this.currentLocale = val;
  }

  setStep(value) {
    this._currentStep = value;
  }

  get allData() {
    return this.data;
  }

  get headline() {
    const hl = this.data["_" + this._currentStep]._hl_1;
    if (hl) {
      const localeI = hl.findIndex((item) => item.locale == this.currentLocale);
      return localeI != -1 ? hl[localeI].value : "";
    }
    return "";
  }
  get copy() {
    const cp = this.data["_" + this._currentStep]._copy_1;
    if (cp) {
      const localeI = cp.findIndex((item) => item.locale == this.currentLocale);
      return localeI != -1 ? cp[localeI].value : "";
    }
    return "";
  }
  get copy2() {
    const cp = this.data["_" + this._currentStep]._copy_2;
    if (cp) {
      const localeI = cp.findIndex((item) => item.locale == this.currentLocale);
      return localeI != -1 ? cp[localeI].value : "";
    }
    return "";
  }

  get buttonCopy() {
    const button = this.data["_" + this._currentStep]._button_1;
    if (button) {
      const localeI = button.findIndex(
        (item) => item.locale == this.currentLocale
      );
      return localeI != -1 ? button[localeI].value : "";
    }
    return "";
  }
  get sendMailError() {
    const errorItem = this.data["_" + this._currentStep]._error;
    if (errorItem) {
      const localeI = errorItem.findIndex(
        (item) => item.locale == this.currentLocale
      );
      return localeI != -1 ? errorItem[localeI].value : "";
    }
    return "";
  }

  get productList() {
    let options = this.data["_1"]._options_1._options;

    let optionsObj = [];
    if (options) {
      options.sort((a, b) => {
        return a.order - b.order;
      });
      options.forEach((_option) => {
        const optionLocaleIndex = _option.value.findIndex(
          (item) => item.locale == this.currentLocale
        );

        if (this.currentLocale === "cn") {
          const optionENLocaleIndex = _option.value.findIndex(
            (item) => item.locale == "en"
          );
          optionsObj.push({
            value:
              optionLocaleIndex != -1
                ? _option.value[optionLocaleIndex].value
                : _option.value[0].value,
            title:
              optionENLocaleIndex != -1
                ? _option.value[optionENLocaleIndex].value
                : _option.value[0].value,
          });
        } else {
          optionsObj.push({
            value:
              optionLocaleIndex != -1
                ? _option.value[optionLocaleIndex].value
                : _option.value[0].value,
            title:
              optionLocaleIndex != -1
                ? _option.value[optionLocaleIndex].value
                : _option.value[0].value,
          });
        }
      });
    }
    return optionsObj;
  }

  getEnProductFromCN() {
    let options = this.data["_1"]._options_1._options;

    let optionsObj = [];
    if (options) {
      options.forEach((_option) => {
        optionsObj.push(_option);
        /*const optionLocaleIndex = _option.value.findIndex(item => item.locale == this.currentLocale);
        optionsObj.push(
            {
              value: optionLocaleIndex != -1 ? _option.value[optionLocaleIndex].value : _option.value[0].value,
              title: optionLocaleIndex != -1 ? _option.value[optionLocaleIndex].value : _option.value[0].value
            }
        )*/
      });
    }
    return optionsObj;
  }

  get options1() {
    let options = this.data["_" + this._currentStep]._options_1._options;
    let label = this.data["_" + this._currentStep]._options_1._label;
    let optionsObj = [];
    if (options && label) {
      options.sort((a, b) => {
        return a.order - b.order;
      });
      const labelIndex = label.findIndex(
        (item) => item.locale == this.currentLocale
      );
      optionsObj.push({
        disabled: true,
        value: "",
        title: labelIndex != -1 ? label[labelIndex].value : label[0].value,
      });
      options.forEach((_option) => {
        const optionLocaleIndex = _option.value.findIndex(
          (item) => item.locale == this.currentLocale
        );
        optionsObj.push({
          disabled: false,
          value:
            optionLocaleIndex != -1
              ? _option.value[optionLocaleIndex].value
              : _option.value[0].value,
          title:
            optionLocaleIndex != -1
              ? _option.value[optionLocaleIndex].value
              : _option.value[0].value,
        });
      });
    }
    return optionsObj;
  }

  get continents() {
    let options = this.data["_1"]._options_2._options;
    let label = this.data["_1"]._options_2._label;
    let optionsObj = [];
    if (options && label) {
      options.sort((a, b) => {
        return a.order - b.order;
      });
      const labelIndex = label.findIndex(
        (item) => item.locale == this.currentLocale
      );
      optionsObj.push({
        disabled: true,
        value: "",
        title: labelIndex != -1 ? label[labelIndex].value : label[0].value,
      });
      options.forEach((_option) => {
        const optionLocaleIndex = _option.value.findIndex(
          (item) => item.locale == this.currentLocale
        );
        if (this.currentLocale === "cn") {
          const optionENLocaleIndex = _option.value.findIndex(
            (item) => item.locale == "en"
          );
          optionsObj.push({
            disabled: false,
            table: _option.fetch != null ? _option.fetch : null,
            value:
              optionLocaleIndex != -1
                ? _option.value[optionLocaleIndex].value
                : _option.value[0].value,
            title:
              optionENLocaleIndex != -1
                ? _option.value[optionENLocaleIndex].value
                : _option.value[0].value,
          });
        } else {
          optionsObj.push({
            disabled: false,
            table: _option.fetch != null ? _option.fetch : null,
            value:
              optionLocaleIndex != -1
                ? _option.value[optionLocaleIndex].value
                : _option.value[0].value,
            title:
              optionLocaleIndex != -1
                ? _option.value[optionLocaleIndex].value
                : _option.value[0].value,
          });
        }
        /*optionsObj.push(
          {
            disabled:false, 
            table: _option.fetch != null ? _option.fetch : null,
            value: optionLocaleIndex != -1 ? _option.value[optionLocaleIndex].value : _option.value[0].value,
            title: optionLocaleIndex != -1 ? _option.value[optionLocaleIndex].value : _option.value[0].value
          }
        )*/
      });
    }
    return optionsObj;
  }

  continentEnFromCn(pCn) {
    let options = this.data["_1"]._options_2._options;

    if (options) {
      for (const contOpt of options) {
        for (const countryFetch of contOpt.value) {
          if (countryFetch.value == pCn) {
            // console.log(contOpt.value)
            const __continent = contOpt.value.find(
              (item) => item.locale == "en"
            );
            if (__continent) {
              return __continent;
            }
          }
        }
      }
    }
    return null;
  }

  getCountryDataTable(continent) {
    if (continent == null || continent == "") {
      return null;
    }
    let __continents = this.data["_1"]._options_2._options;
    /* if(store.state.getInTouch.product == null || store.state.getInTouch.product == ''){
      return null;
    } */

    const _fetch = __continents.find(
      (item) =>
        item.value[0].value == continent || item.value[1].value == continent
    );

    return _fetch != null && _fetch.fetch != null ? _fetch.fetch : null;
  }

  get countryList() {
    let _list = [];
    if (this._countries != null) {
      this._countries.forEach((_country) => {
        if (this.currentLocale == "de") {
          _list.push({
            valueEN: _country["country_en"],
            continentEN: _country["continent_en"],
            value: _country["country_de"],
            continent: _country["continent_de"],
          });
        } else if (this.currentLocale == "cn") {
          _list.push({
            valueEN: _country["country_en"],
            continentEN: _country["continent_en"],
            value: _country["country_cn"],
            continent: _country["continent_cn"],
          });
        } else {
          _list.push({
            valueEN: _country["country_en"],
            continentEN: _country["continent_en"],
            value: _country["country_en"],
            continent: _country["continent_en"],
          });
        }
      });
    }

    return _list;
  }

  getCountryDeForEn(enCountry) {
    if (this._countries != null) {
      for (const country of this._countries) {
        if (country["country_en"] === enCountry) {
          return country["country_de"];
        }
      }
    }
    return null;
  }

  get continentLabel() {
    let label = this.data["_1"]._options_2._label;
    const labelIndex = label.findIndex(
      (item) => item.locale == this.currentLocale
    );
    return labelIndex != -1 ? label[labelIndex].value : label[0].value;
  }
  get countryLabel() {
    let label = this.data["_1"]._options_3._label;
    const labelIndex = label.findIndex(
      (item) => item.locale == this.currentLocale
    );
    return labelIndex != -1 ? label[labelIndex].value : label[0].value;
  }
  get branchLabel() {
    let label = this.data["_1"]._options_4._label;
    const labelIndex = label.findIndex(
      (item) => item.locale == this.currentLocale
    );
    return labelIndex != -1 ? label[labelIndex].value : label[0].value;
  }

  get productsLabel() {
    let label = this.data["_" + this._currentStep]._options_1._label;
    const labelIndex = label.findIndex(
      (item) => item.locale == this.currentLocale
    );
    return labelIndex != -1 ? label[labelIndex].value : label[0].value;
  }
  get label1() {
    let label = this.data["_" + this._currentStep]._label_1;
    if (label == null) {
      label = this.data["_" + this._currentStep]._label;
    }
    const labelIndex = label.findIndex(
      (item) => item.locale == this.currentLocale
    );
    return labelIndex != -1 ? label[labelIndex].value : label[0].value;
  }
  get label2() {
    let label = this.data["_" + this._currentStep]._label_2;
    const labelIndex = label.findIndex(
      (item) => item.locale == this.currentLocale
    );
    return labelIndex != -1 ? label[labelIndex].value : label[0].value;
  }
  get label3() {
    let label = this.data["_" + this._currentStep]._label_3;
    const labelIndex = label.findIndex(
      (item) => item.locale == this.currentLocale
    );
    return labelIndex != -1 ? label[labelIndex].value : label[0].value;
  }
  get label4() {
    let label = this.data["_" + this._currentStep]._label_4;
    const labelIndex = label.findIndex(
      (item) => item.locale == this.currentLocale
    );
    return labelIndex != -1 ? label[labelIndex].value : label[0].value;
  }
  get label5() {
    let label = this.data["_" + this._currentStep]._label_5;
    const labelIndex = label.findIndex(
      (item) => item.locale == this.currentLocale
    );
    return labelIndex != -1 ? label[labelIndex].value : label[0].value;
  }

  get options1Error1() {
    let label = this.data["_1"]._options_1_error;
    const labelIndex = label.findIndex(
      (item) => item.locale == this.currentLocale
    );
    return labelIndex != -1 ? label[labelIndex].value : label[0].value;
  }
  get options2Error1() {
    let label = this.data["_1"]._options_2_error;
    const labelIndex = label.findIndex(
      (item) => item.locale == this.currentLocale
    );
    return labelIndex != -1 ? label[labelIndex].value : label[0].value;
  }
  get options3Error1() {
    let label = this.data["_1"]._options_3_error;
    const labelIndex = label.findIndex(
      (item) => item.locale == this.currentLocale
    );
    return labelIndex != -1 ? label[labelIndex].value : label[0].value;
  }
  get options4Error1() {
    let label = this.data["_1"]._options_4_error;
    const labelIndex = label.findIndex(
      (item) => item.locale == this.currentLocale
    );
    return labelIndex != -1 ? label[labelIndex].value : label[0].value;
  }

  get label1Error() {
    let label = this.data["_" + this._currentStep]._label_1_error_1;
    if (label == null) {
      label = this.data["_" + this._currentStep]._label_1_error;
    }
    const labelIndex = label.findIndex(
      (item) => item.locale == this.currentLocale
    );
    return labelIndex != -1 ? label[labelIndex].value : label[0].value;
  }
  get label1Error2() {
    let label = this.data["_" + this._currentStep]._label_1_error_2;
    const labelIndex = label.findIndex(
      (item) => item.locale == this.currentLocale
    );
    return labelIndex != -1 ? label[labelIndex].value : label[0].value;
  }
  get label2Error() {
    let label = this.data["_" + this._currentStep]._label_2_error_1;
    if (label == null) {
      label = this.data["_" + this._currentStep]._label_2_error;
    }

    const labelIndex = label.findIndex(
      (item) => item.locale == this.currentLocale
    );
    return labelIndex != -1 ? label[labelIndex].value : label[0].value;
  }
  get label3Error() {
    let label = this.data["_" + this._currentStep]._label_3_error_1;
    const labelIndex = label.findIndex(
      (item) => item.locale == this.currentLocale
    );
    return labelIndex != -1 ? label[labelIndex].value : label[0].value;
  }

  get label5_1Error() {
    let label = this.data["_" + this._currentStep]._label_5_error_1;
    const labelIndex = label.findIndex(
      (item) => item.locale == this.currentLocale
    );
    return labelIndex != -1 ? label[labelIndex].value : label[0].value;
  }
  get label5_2Error() {
    let label = this.data["_" + this._currentStep]._label_5_error_2;
    const labelIndex = label.findIndex(
      (item) => item.locale == this.currentLocale
    );
    return labelIndex != -1 ? label[labelIndex].value : label[0].value;
  }
}
