<template>
  <div id="_overlay" ref="pgroup">
    <div
      class="panel"
      :class="{
        fontMobile: isMobile === true,
        fontDesk: isMobile === false,
        useHighlightNavigation,
      }"
      v-bind:style="{ width: overlayWidth }"
    >
      <transition-group
        :css="false"
        appear
        v-on:enter="enter"
        v-on:leave="leave"
      >
        <component
          v-for="(item, index) in computedComponents"
          :ref="item.name"
          :key="item.id"
          :data-index="index"
          @heightCallback="heightCallback"
          :subMenuListOpen="subMenuListOpen"
          :item_id="item.id"
          :isFirstItem="item.isFirstSub"
          :isLastItem="index == computedComponents.length - 1"
          v-bind:is="item.component"
          :data="item.data"
          @componentCall="onComponentCall"
          role="listitem"
          v-on="item.eventHandlers ? item.eventHandlers : {}"
          v-bind="item.extraProps"
          :data-type="item.type"
          :data-testid="item.type"
        />
      </transition-group>
    </div>
  </div>
</template>

<script>
import { gsap, Expo } from "gsap";
import { EB } from "@/utils/EB.js";

import MainOverlayCopy from "@/modules/MainOverlayCopy";
import HighlightButton from "@/modules/HighlightButton";
import ProductDetails from "@/modules/ProductDetails";
import ProductListItem from "@/modules/ProductListItem";
import OverlayButton from "./elements/Button";
import OverlayVideo from "./elements/Video";
import { PanelOpenState, PANELEVENT } from "../utils/enum.js";
import { TweenVars } from "../utils/tweenTime";
import { mapActions, mapGetters } from "vuex";

import BenefitsIcon from "@/components/icons/BenefitsIcon";
import PromiseIcon1 from "@/components/icons/PromiseIcon1.vue";
import PromiseIcon2 from "@/components/icons/PromiseIcon2.vue";
import PromiseIcon3 from "@/components/icons/PromiseIcon3.vue";
import PromiseIcon4 from "@/components/icons/PromiseIcon4.vue";
import PlayIconMobile from "@/components/icons/PlayIconMobile.vue";
import PlayIcon from "@/components/icons/PlayIcon.vue";
import { shallowRef } from "vue";

export default {
  name: "BaseProductOverlay",
  components: {
    BenefitsIcon,
    MainOverlayCopy,
    HighlightButton,
    ProductDetails,
    ProductListItem,
    OverlayButton,
    OverlayVideo,
  },
  props: ["rootPanelState"],
  data() {
    return {
      viewComponents: [],
      currentSubNavigationState: null,
      selectedSubItem: null,
      compCount: 0,
    };
  },

  created() {
    this.currentSubNavigationState = this.productOverlayFirstTimeOpen
      ? "open"
      : "closed";

    this.initComponents();
  },

  unmounted() {
    EB.$off(PANELEVENT.CLOSE_TAPPED, this.componentsOut);
    EB.$off("HIGHLIGHT_VIDEO_PLAY", this.onVideoPlay);
    EB.$off("HIGHLIGHT_VIDEO_PAUSE", this.onVideoPause);
    EB.$off("HIGHLIGHT_VIDEO_SEEKED", this.onVideoSeeked);
  },
  mounted() {
    EB.$on(PANELEVENT.CLOSE_TAPPED, this.componentsOut);
    EB.$on("HIGHLIGHT_VIDEO_PLAY", this.onVideoPlay);
    EB.$on("HIGHLIGHT_VIDEO_PAUSE", this.onVideoPause);
    EB.$on("HIGHLIGHT_VIDEO_SEEKED", this.onVideoSeeked);
  },

  computed: {
    ...mapGetters([
      "productOverlayParams",
      "baseOverlayParams",
      "currentProduct",
      "isMobile",
      "rtcParams",
      "productOverlayFirstTimeOpen",
      "overlayAspects",
      "isModerator",
      "productOverlayAction",
    ]),

    useHighlightNavigation() {
      return !this.currentProduct.productDetailsButton;
    },

    computedComponents() {
      return [...this.viewComponents]
        .sort((a, b) => {
          return a.order - b.order;
        })
        .filter((item) => item.visible === true);
    },
    subMenuListOpen: function () {
      return this.currentSubNavigationState == "open" ? true : false; //subMenuListOpen
    },
    appPanelState() {
      return this.baseOverlayParams.appPanelState;
    },
    currentOverlayIndex() {
      return this.baseOverlayParams.activeOverlayIndex;
    },
    overlayWidth() {
      return this.overlayAspects.overlayWidth.toString() + "px";
    },
  },

  watch: {
    currentProduct(newValue, oldValue) {
      if (newValue && newValue !== oldValue) {
        this.initComponents();
      }
    },
    productOverlayAction(newValue) {
      if (newValue.name == "componentCall") {
        const e = JSON.parse(newValue.value);
        this.componentCall(e);
      }
    },
    appPanelState(newValue, oldValue) {
      if (
        (newValue != oldValue && newValue == PanelOpenState.CLOSED) ||
        newValue == PanelOpenState.NAV_BACK
      ) {
        this.componentsOut();
        setTimeout(() => {
          EB.$emit("panelEvent", { type: "CLOSE_PANEL" });
        }, 250);
      }
    },

    currentOverlayIndex(newValue, oldValue) {
      if (newValue != oldValue) {
        this.componentsOut();
      }
    },
  },
  methods: {
    ...mapActions([
      "notifyProductOverlayAction",
      "changeProductOverlayFirstTimeOpen",
      "product_sub_menu_selected",
      "notifyProductOverlayAction",
      "sendToRtmChannel",
      "syncWithRemote",
    ]),

    onVideoSeeked(value) {
      this.syncWithRemote(() => {
        this.$refs.video[0].seek(value);
      });
    },

    onVideoPause() {
      this.syncWithRemote(() => {
        this.$refs.video[0].pause();
      });
    },

    onVideoPlay() {
      this.syncWithRemote(() => {
        this.$refs.video[0].play();
      });
    },

    heightCallback(vel) {
      const _itemIndex = this.viewComponents.findIndex(
        (item) => item.id == vel.item_id
      );
      if (_itemIndex != -1) {
        this.viewComponents[_itemIndex].height = vel.height;
      }
    },
    componentsOut() {
      for (const comp of this.viewComponents) {
        comp.visible = false;
      }
    },

    onComponentCall(e) {
      if (this.rtcParams.isClientReady) {
        this.notifyProductOverlayAction({
          name: "componentCall",
          value: JSON.stringify(e),
        });
      }

      this.componentCall(e);
    },

    componentCall(e) {
      switch (e.event) {
        case "toggle_groups_overlay":
          if (this.currentSubNavigationState != null) {
            this.currentSubNavigationState =
              this.currentSubNavigationState === "open" ? "closed" : "open";

            for (const comp of this.viewComponents) {
              comp.visible = this.isVisibleItem(comp.name);
            }

            if (this.currentSubNavigationState === "open") {
              this.panelScrollTo(220, 0.3);
              this.changeProductOverlayFirstTimeOpen(true);
            }
          }

          break;

        case "group_product_overlay_selected":
          this.selectedSubItem = e.value;
          for (const comp of this.viewComponents) {
            comp.visible = false;
          }
          setTimeout(() => {
            EB.$emit("panelEvent", {
              type: PANELEVENT.PRODUCT_SELECTED,
              selectedSubItem: this.selectedSubItem,
            });
          }, 250);

          break;
      }
    },
    enter(el, done) {
      el.style.opacity = 0;
      el.style.transform = "translateY(-80px)";
      gsap.to(el, {
        opacity: 1,
        translateY: 0,
        duration: TweenVars.duration,
        onComplete: done,
        ease: Expo.easeInOut,
        delay: el.dataset.index * 0.015,
      });
    },
    leave(el, done) {
      gsap.to(el, {
        translateY:
          this.baseOverlayParams.appPanelState === PanelOpenState.CLOSED ||
          this.selectedSubItem != null
            ? -el.offsetHeight
            : -80,
        opacity: 0,
        duration: TweenVars.duration,
        ease: TweenVars.ease,
        onComplete: done,
        delay: el.dataset.index * 0.015,
      });
    },
    panelScrollTo(scrolTp) {
      const topP = scrolTp;
      EB.$emit("panelEvent", {
        type: "PANEL_SCROLL_TOP",
        delay: 1500,
        scrollTop: topP,
      });
    },

    initComponents() {
      this.viewComponents = [];

      const video = this.currentProduct.baseOverlay.find(
        (item) => item.type == "VIDEO"
      );
      if (video != null) {
        this.viewComponents.push({
          extraProps: {
            src: `${video.value}.mp4`,
          },
          component: OverlayVideo,
          visible: false,
          name: "video",
          id: "video-001",
          order: 0,
          isFirstSub: false,
          type: video.type,
          eventHandlers: {
            play: () => {
              this.sendToRtmChannel({ name: "HIGHLIGHT_VIDEO_PLAY" });
            },
            pause: () => {
              this.sendToRtmChannel({ name: "HIGHLIGHT_VIDEO_PAUSE" });
            },
            seeked: () => {
              this.sendToRtmChannel({
                name: "HIGHLIGHT_VIDEO_SEEKED",
                time: this.$refs.video[0].currentTime,
              });
            },
          },
        });
      }

      const copy = this.currentProduct.baseOverlay.find(
        (item) => item.type == "COPY"
      );
      if (copy != null) {
        this.viewComponents.push({
          data: copy.value,
          component: shallowRef(MainOverlayCopy),
          visible: false,
          name: "_mainOverlayCopyRef",
          id: "001",
          order: 0,
          isFirstSub: false,
          type: copy.type,
        });
      }

      const buttons = this.currentProduct.baseOverlay.filter(
        (item) => item.type == "BUTTON" && Boolean(item.url)
      );
      buttons.forEach((button, i) => {
        this.viewComponents.push({
          data: copy.value,
          component: shallowRef(OverlayButton),
          visible: false,
          name: `_groupBtn${i + 1}`,
          id: `button-00${i}`,
          order: 20 + (i + 1),
          isFirstSub: false,
          extraProps: button,
          type: button.type,
        });
      });

      const highlight = this.currentProduct.highlight;
      if (highlight != null) {
        this.viewComponents.push({
          data: highlight,
          component: shallowRef(HighlightButton),
          isFirstSub: false,
          name: "_mainOverlayLabelRef",
          visible: false,
          id: "002",
          order: 1,
          extraProps: {
            label: this.currentProduct.highlightLabel,
            icon: this.isMobile
              ? shallowRef(PlayIconMobile)
              : shallowRef(PlayIcon),
          },
          eventHandlers: {
            click: () => {
              const label =
                  highlight.find((item) => item.type == "LABEL")?.value ?? "",
                headline =
                  highlight.find((item) => item.type == "HL")?.value ?? "";

              EB.$emit("SHOW_HIGHLIGHT", { event: label, value: headline });
            },
          },
          type: "HIGHLIGHT",
        });
      }

      const detailsButton = this.currentProduct.productDetailsButton;
      if (detailsButton) {
        this.viewComponents.push({
          data: detailsButton,
          component: shallowRef(ProductDetails),
          isFirstSub: false,
          name: "_mainOverlayButtonRef",
          visible: false,
          id: "003",
          order: 2,
        });
      }

      const headlines = this.currentProduct.productDetails;
      for (let i = 0; i < headlines.length; i++) {
        const _itemKey = headlines[i];
        const _buttonName = "_groupBtn" + (i + 1).toString();
        const _buttonId = "00" + (i + 4).toString();
        const headline = this.currentProduct.getHeadlineByKey(_itemKey);
        const { sectionName, groupId, productId } = this.productOverlayParams;

        let button = {};
        if (detailsButton) {
          button.component = shallowRef(ProductListItem);
          button.extraProps = {
            headline,
          };
        } else {
          button.component = shallowRef(HighlightButton);
          button.extraProps = {
            label: headline,
          };
          let icon = this.isMobile ? PlayIconMobile : PlayIcon;
          if (sectionName === "career") {
            icon = BenefitsIcon;
          } else if (
            sectionName === "promise" &&
            groupId === "values" &&
            productId
          ) {
            const promiseIcons = [
              null,
              PromiseIcon1,
              PromiseIcon2,
              PromiseIcon3,
              PromiseIcon4,
            ];
            const promiseIcon = promiseIcons[Number(productId)];
            if (promiseIcon) icon = promiseIcon;
          }
          button.extraProps.icon = shallowRef(icon);
        }
        this.viewComponents.push({
          ...button,
          data: _itemKey,
          name: _buttonName,
          order: 2 + (i + 1),
          isFirstSub: i == 0,
          visible: false,
          id: _buttonId,
          type: "nav-button",
          eventHandlers: {
            click: () => {
              this.product_sub_menu_selected(_itemKey);

              if (this.rtcParams.isClientReady) {
                this.notifyProductOverlayAction({
                  name: "childOverlaySelected",
                  value: _itemKey,
                });
              }
            },
          },
        });
      }

      for (const comp of this.viewComponents) {
        comp.visible = this.isVisibleItem(comp.name);
      }
    },

    isVisibleItem(itemName) {
      if (!this.currentProduct.productDetailsButton) {
        return true;
      }

      var _rfs = [
        "_mainOverlayCopyRef",
        "_mainOverlayLabelRef",
        "_mainOverlayButtonRef",
      ];

      if (this.subMenuListOpen) {
        _rfs = _rfs.concat([
          "_groupBtn1",
          "_groupBtn2",
          "_groupBtn3",
          "_groupBtn4",
        ]);
      }

      return _rfs.includes(itemName);
    },
  },
};
</script>
<style scoped lang="scss">
.panel {
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding-top: 15px;
  padding-bottom: 15px;
}

[data-type="VIDEO"]:first-child {
  margin-top: -15px;
}

#_overlay {
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.copy {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  padding-left: 15px !important;
  padding-right: 15px !important;
  font-family: Univers LT Std;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
}

[role="listitem"][type="BUTTON"],
.panel:not(.useHighlightNavigation) [role="listitem"][data-type="nav-button"] {
  margin-left: 15px;
  margin-right: 15px;
}

// @todo: wrap navigation items into a container and have their own gap eq to 2px
.useHighlightNavigation [data-type="nav-button"] {
  & + [data-type="nav-button"] {
    // for multiple highlight button we don't need default 15px gap. We just need a small spacing.
    margin-top: -13px;
  }

  &:last-child {
    // mitigate parent constainer bottom padding
    margin-bottom: -13px;
  }
}
</style>
