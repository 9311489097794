export const PanelOpenState = {
  OPEN: "OPEN",
  CLOSED: "CLOSED",
  NAV_BACK: "NAV_BACK",
};
export const BasePanelState = {
  OPEN: "OPEN",
  CLOSED: "CLOSED",
};
export const DeviceOrientation = {
  LANDSCAPE: "LANDSCAPE",
  PORTRAIT: "PORTRAIT",
};

export const PANELEVENT = {
  OPEN: "OPEN",
  CLOSE: "CLOSE",
  RESIZE: "RESIZE",
  SHOW_BASE: "SHOW_BASE",
  CLOSE_TAPPED: "CLOSE_TAPPED",
  PRODUCT_SELECTED: "PRODUCT_SELECTED",
};
export const AutoCompleteType = {
  TECH: "TECH",
  CONTINENT: "CONTINENT",
  COUNTRY: "COUNTRY",
  OFFICE_BRANCH: "OFFICE_BRANCH",
  TIMEZONE: "TIMEZONE",
  FILTER: "FILTER",

  /* TECH: "TECH",
  TECH: "TECH", */
};

export const MEETING_STATES = {
  STATE_MODERATOR_NOT_JOINED: "STATE_MODERATOR_NOT_JOINED",
  STATE_CLIENT_NOT_JOINED: "STATE_CLIENT_NOT_JOINED",
  STATE_CLIENT_JOINED_ALONE: "STATE_CLIENT_JOINED_ALONE",
  STATE_MODERATOR_JOINED_ALONE: "STATE_MODERATOR_JOINED_ALONE",
  STATE_MODERATOR_CLIENT_PRESENT: "STATE_MODERATOR_CLIENT_PRESENT",
  STATE_CLIENT_MODERATOR_PRESENT: "STATE_CLIENT_MODERATOR_PRESENT",
  STATE_MODERATOR_CLIENT_LEFT: "STATE_MODERATOR_CLIENT_LEFT",
  STATE_CLIENT_MODERATOR_LEFT: "STATE_CLIENT_MODERATOR_LEFT",
};

export const OverlaySteps = {
  LOCATION: "LOCATION",
  EMAILFORM: "EMAILFORM",
  RESPONSETYPE: "RESPONSETYPE",
  DATESELECTION: "DATESELECTION",
  ADD_FORMDATA: "ADD_FORMDATA",
  SEND_EMAIL: "SEND_EMAIL",
};

export const LOGIN_MODES = {
  LOADING: "LOADING",
  LOGIN: "LOGIN",
  FORGOT_PASSWORD: "FORGOT_PASSWORD",
  SET_NEW_PASSWORD: "SET_NEW_PASSWORD",
  NEW_PASSWORD_CONFIRMATION: "NEW_PASSWORD_CONFIRMATION",
  NEW_PASSWORD_FINALIZED: "NEW_PASSWORD_FINALIZED",
};

export const APPOINTMENT_VIEW = {
  OVERVIEW: "OVERVIEW",
  EDIT_VIEW: "EDIT_VIEW",
  CREATE_VIEW: "CREATE_VIEW",
  PREVIEW: "PREVIEW",
  DETAIL_VIEW: "DETAIL_VIEW",
  SEND_VIEW: "SEND_VIEW",
  ADD_CALENDER_VIEW: "ADD_CALENDER_VIEW",
};
export const APPOINTMENT_MODE = {
  CREATE: "CREATE",
  EDIT: "EDIT",
};
export const APPOINTMENT_ERROR_VIEW_MODE = {
  EXPIRED_NOT_AVAILABLE: "EXPIRED_NOT_AVAILABLE",
  CURRENTLY_NOT_AVAILABLE: "CURRENTLY_NOT_AVAILABLE",
  DELETED: "DELETED",
  NOT_AVAILABLE_TF_HOURS_AGO: "NOT_AVAILABLE_TF_HOURS_AGO",
  AVAILABLE_IN_FUTURE: "AVAILABLE_IN_FUTURE",
  NO_DATA: "NO_DATA",
};
export const BASE_OVERLAY_TYPE = {
  PRODUCT: "PRODUCT",
  PROMISE_VALUES: "PROMISE_VALUES",
};
