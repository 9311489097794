import { createApp } from "vue";
import App from "./App.vue";
import { router } from "./routes";
import VWave from "v-wave";
import { device } from "./utils/device";
import LottieAnimation from "lottie-web-vue";
import store from "./store";

import VueMatomo from "vue-matomo";
import VueCryptojs from "vue-cryptojs";
import { createPinia } from "pinia";
import { VueClipboard } from "@soerenmartius/vue3-clipboard";

const app = createApp(App);

app.config.productionTip = false;

app.use(router);

app.use(createPinia());

app.use(store);

app.use(VueClipboard);

app.use(VueCryptojs);

app.use(LottieAnimation);

app.use(device);

app.use(VWave, {
  color: "white",
  startingOpacity: 0.95,
  easing: "ease-in",
});

app.use(VueMatomo, {
  host: "https://weba.groz-beckert.com/",
  siteId: 19,
  trackerFileName: "matomo",
  router: router,
  enableLinkTracking: true,
  requireConsent: false,
  trackInitialView: true,
  disableCookies: false,
  requireCookieConsent: false,
  enableHeartBeatTimer: true,
  heartBeatTimerInterval: 15,
  debug: false,
  userId: undefined,
  cookieDomain: undefined,
  domains: "experience.groz-beckert.com/",
  preInitActions: [],
  trackSiteSearch: true,
});

app.mount("#app");
