<template>
  <div
    class="HomePage"
    :data-url-path="urlPath"
    :class="{ isMobile, canvasDegraded, isLandscape }"
    :style="{
      '--meetingRoomWidth':
        !this.isMobile && this.meetingRoomWidth
          ? `${this.meetingRoomWidth}px`
          : `unset`,
    }"
  >
    <div class="loadingLogo" id="loadingAnimLogo"></div>
    <ContactOverlay
      :initFromProduct="contactInitFromProduct"
      v-bind="contactOverlay"
      v-if="showContactOverlay"
    />

    <div
      class="glOverlay"
      :class="{
        glOverlayOff: !showContactOverlay,
        blurryBG: showContactOverlay,
        blurryBGOut: !showContactOverlay,
        scaledGL: showContactOverlay,
        scaledGLOut: !showContactOverlay,
      }"
    ></div>

    <div
      id="glContainer"
      v-if="showCanvas"
      :class="{
        loading: !patchLoaded,
        disabledPointerEvents: hasDisabledPointerEvents,
      }"
    >
      <canvas id="glcanvas" width="100vw" height="100vh"></canvas>
    </div>

    <transition-group
      appear
      v-on:before-enter="baseContactBeforeEnter"
      v-on:enter="baseContactEnter"
      v-on:leave="baseContactLeave"
    >
      <div
        id="baseContactButtonHit"
        key="bch"
        data-index="1"
        v-if="baseContactToolTipVisible"
        @mouseover="contactOver"
        @mouseleave="contactOut"
        @click="openContactOverlay"
      ></div>
      <!--  && isMainScreen -->
      <div
        id="baseContactButton"
        key="bc"
        data-index="2"
        v-if="baseContactToolTipVisible"
      >
        <!-- && isMainScreen -->
        <div class="lottieMobile" v-if="isPhoneDevice && !device.ipad"></div>
        <template v-else>
          <div id="getInTouch" style="text-align: right">
            {{ contactTooltipText }}
          </div>
          <div class="contactOfferIcon">
            <lottie-animation
              id="lottieIcon"
              :autoPlay="lootieAutoPlay"
              :speed="speed"
              ref="lotte"
              :loop="false"
              path="assets/ui/contactLottie.json"
            />
          </div>
        </template>
      </div>
    </transition-group>

    <transition
      appear
      v-on:before-enter="productContactBeforeEnter"
      v-on:enter="productContactEnter"
      v-on:leave="productContactLeave"
    >
      <div id="product-contact-button" v-if="showProductContactTooltip">
        <div class="d-flex p0">
          <div
            class="p-0 p0 pr10 mr15"
            @click="productContactOverlayCloseTapped"
          >
            <div id="product-contact-button-close"></div>
          </div>
          <div
            class="p-0 p0"
            id="product-contact-button-inner"
            @click="openProductContactOverlay"
          >
            <div class="d-flex">
              <div class="p-2 pt3 product-contact-copy">
                <span v-if="productContactTooltipText">{{
                  productContactTooltipText
                }}</span>
              </div>
              <div class="p-2 ml-auto">
                <img
                  src="assets/ui/contact_icon.svg"
                  width="18px"
                  class="mr15 mt3"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>

    <div class="meetingClientAbdecker" v-if="showMeetingClientAbdecker"></div>
    <div class="productBackButtonAbdecker" v-if="showBackbuttonAbdecker"></div>
    <div
      class="languageButtonsbdecker"
      v-if="showBackbuttonAbdecker && canRunMeeting"
    ></div>

    <div class="detailsOverlay">
      <BaseOverlay
        v-if="isProductOverlayVisible && isCareerDetail"
        :canClose="false"
      />
      <BaseOverlay
        v-else-if="isProductOverlayVisible"
        @close="onCloseProductOverlay"
      />
      <GlobalPresenceOverlay v-if="isPromiseGlobalPresenceOverlayVisible" />
    </div>

    <AppointmentErrorWin
      v-if="activeAppointmentError"
      @gotoCreateTour="errorWinGotoCreateTour"
      @errorWinClosed="errorWinClosed"
      :message="activeAppointmentError"
    />

    <FullscreenOverlay @closed="fsOclosed" v-if="showFullscreenOverlay" />
  </div>
</template>

<script>
import BaseOverlay from "@/overlays/BaseOverlay.vue";
import GlobalPresenceOverlay from "@/overlays/GlobalPresenceOverlay.vue";
import ContactOverlay from "@/overlays/ContactOverlay.vue";
import MeetingConsent from "@/overlays/MeetingConsent.vue";
import MeetingUsername from "@/overlays/MeetingUsername.vue";
import FullscreenOverlay from "@/overlays/FullscreenOverlay";
import MeetingInit from "@/components/meeting/MeetingInit";
import { store } from "@/utils/Store";
import { EB, EB_ACTION } from "@/utils/EB";
import LottieAnimation from "lottie-vuejs/src/LottieAnimation.vue";
import { TweenVars } from "@/utils/tweenTime.js";
import { gsap } from "gsap";
import AppointmentErrorWin from "@/modules/AppointmentErrorWin";
import { mapActions, mapGetters } from "vuex";
import { device } from "../utils/device";

import {
  withPatch,
  shouldEnableCables,
  shouldDegradeCanvas,
  setCablesVar,
  getCablesVar,
} from "@/utils/cables.js";

export default {
  name: "HomePage",
  components: {
    GlobalPresenceOverlay,
    BaseOverlay,
    AppointmentErrorWin,
    LottieAnimation,
    ContactOverlay,
    MeetingInit,
    FullscreenOverlay,
    MeetingConsent,
    MeetingUsername,
  },

  data() {
    return {
      isInitial: true,
      resizeTimeout: null,
      isProductView: false,
      mobileNavTimeOut: null,
      contactInitFromProduct: false,
      isInit: false,

      gitCopy: true,
      lootieAutoPlay: false,
      speed: 2,
      showContactOverlay: false,
      showLoginOverlay: false,
      isProductContactTooltipActive: false,
      showBaseContactTooltip: false,
      showTourSection: false,
      dev: false,
      matomoDev: false,
      wbsocketDebug: false,
      isMainScreen: true,
      patchLoaded: false,
      /*isTouchDevice: false,*/
      showCanvas: true,
      /* isLandscape:true, */
      isProductBtnClicked: false,
      // contactTooltipText: 'Jetzt Kontakt aufnehmen',
      currentHash: "",
      urlPath: "",
      ebEventsInitialized: false,
      today: null,
      footerLinkAdded: false,
      allowedLocales: ["de", "en", "cn"],
      currentOverlayType: "PRODUCT",
      device,
      canvasDegraded: shouldDegradeCanvas(),
      meetingRoomWidth: null,
    };
  },
  props: ["q"],

  watch: {
    isSockeClusterActive(newVal) {
      if (newVal === true) {
        document.body.classList.add("inGuidedTour");
        setCablesVar("socketcluster_active", 1);
      }
    },
    isModerator(newValue) {
      if (newValue === true) {
        document.body.classList.add("isPresenter");
        setCablesVar("socketcluster_master", 1);
      }
    },
  },

  computed: {
    ...mapGetters([
      "activeAppointmentError",
      "isProductOverlayVisible",
      "isPromiseGlobalPresenceOverlayVisible",
      "productOverlayParams",
      "userSessionCloseStore",
      "showFullscreenOverlay",
      "currentLocale",
      "consentAccepted",
      "username",
      "isLandscape",
      "isMobile",
      "isModerator",
      "canRunMeeting",
      "isSockeClusterActive",
      "sessionChannelId",
      "userData",
      "currentProduct",
      "userSession",
      "isPhoneDevice",
      "rtcParams",
      "careerContactOverlay",
    ]),

    contactOverlay() {
      if (this.isCareer && this.careerContactOverlay) {
        return {
          headline: this.careerContactOverlay?.headline,
          copy: this.careerContactOverlay?.copy,
          buttonCopy: this.careerContactOverlay?.button,
          link: this.careerContactOverlay?.link,
        };
      } else if (this.currentProduct) {
        return {
          headline: this.currentProduct.contactHeadline,
          copy: this.currentProduct.contactCopy,
          buttonCopy: this.currentProduct.contactButton,
          link: this.currentProduct.contactLink,
        };
      }

      return {};
    },

    isPromiseValues() {
      const path = this.urlPath.split("/");

      return path.includes("promise") && path.includes("values");
    },

    isCareer() {
      const path = this.urlPath.split("/");

      return path.includes("career");
    },

    isCareerDetail() {
      const path = this.urlPath.split("/");
      return (
        this.isCareer && (path.includes("detail") || path.includes("openings"))
      );
    },

    showProductContactTooltip() {
      return (
        this.isProductContactTooltipActive &&
        !(this.showContactOverlay || this.showBaseContactTooltip)
      );
    },

    productContactTooltipText: function () {
      if (
        this.currentProduct == null ||
        this.currentProduct.contact == null ||
        this.currentProduct.contactLabel == null
      ) {
        return "";
      }
      return this.currentProduct.contactLabel;
    },
    currentWinLoc: function () {
      return this.currentHash;
    },
    baseContactToolTipVisible: function () {
      if (this.isProductBtnClicked == false) {
        return false;
      }
      if (this.showContactOverlay == true) {
        return false;
      }
      if (this.showProductContactTooltip == true) {
        return false;
      }
      if (this.userSession != null && this.userSession.channelId != null) {
        return false;
      }

      if (
        this.currentWinLoc.endsWith("home/") ||
        this.currentWinLoc.endsWith("home") ||
        this.currentWinLoc == ""
      ) {
        return true;
      } else {
        return this.isLandscape;
      }
    },
    contactTooltipText() {
      /*return  this.currentLocale === 'en' ? "Get in touch" : "Jetzt Kontakt aufnehmen";*/
      if (this.currentLocale === "de") {
        return "Jetzt Kontakt aufnehmen";
      } else if (this.currentLocale === "cn") {
        return "联系我们";
      } else {
        return "Get in touch";
      }
    },
    showMeetingClientAbdecker() {
      if (
        this.sessionChannelId &&
        this.isProductBtnClicked &&
        !this.isProductView &&
        this.patchLoaded &&
        !this.isModerator
      ) {
        return true;
      } else {
        return false;
      }
    },
    showBackbuttonAbdecker() {
      if (
        this.sessionChannelId &&
        this.isProductBtnClicked &&
        this.patchLoaded &&
        !this.isModerator
      ) {
        return true;
      } else {
        return false;
      }
    },
    hasDisabledPointerEvents() {
      return (
        this.sessionChannelId &&
        !this.isModerator &&
        this.canRunMeeting &&
        !this.isProductView
      );
    },
  },

  methods: {
    ...mapActions([
      "openProductOverlayFromStateParams",
      "activeAppointmentData",
      "updateHash",
      "matomoTrackEvent",
      "setMatomo",
      "exitAppointment",
      "setGetInTouch_product",
      "loadActiveAppointment",
      "changeMainLocale",
      "checkSessionChannel",
      "resetInnerOverlayComponent",
      "getAndSetLang",
      "getAndSetLoginLang",
      "initSessionChannel",
      "initSockets",
      "openLoginOverlay",
      "recieveWebsoxMessageForUI",
      "setClientScreenSize",
      "getInTouch_clear",
      "exitMeeting",
      "closeSession",
      "updateWindowAspects",
      "sendActionViaWebsox",
    ]),

    openContactOverlay() {
      if (this.isCareer) {
        this.openProductContactOverlay();
      } else {
        this.openBaseContactOverlay();
      }
    },

    onCloseProductOverlay() {
      // show contact tooltip
      if (
        !this.isPromiseValues &&
        !this.showContactOverlay &&
        this.userSession.channelId == null
      ) {
        this.isProductContactTooltipActive = true;
        this.showBaseContactTooltip = false;
      }
    },

    onMeetingRoomWidthChanged({ width, isMinimized }) {
      if (isMinimized || !width) {
        this.meetingRoomWidth = width;
      }
    },

    fsOclosed() {
      this.resetInnerOverlayComponent();
    },
    contactOver() {
      if (this.isLandscape) {
        gsap.killTweensOf("#getInTouch");
        gsap.killTweensOf("#baseContactButtonHit");
        gsap.killTweensOf("#baseContactButton");
        gsap.killTweensOf("#lottieIcon");
        this.$refs.lottie.anim.playSegments([1, 30]);

        let w =
          document.getElementById("getInTouch").getBoundingClientRect().width +
          60;
        gsap.to("#baseContactButtonHit", {
          width: w,
          duration: 0.65,
          ease: TweenVars.ease,
        });
        gsap.to("#baseContactButton", {
          width: w,
          duration: 0.65,
          ease: TweenVars.ease,
        });
        gsap.to("#getInTouch", {
          opacity: 1,
          duration: 0.65,
          delay: 0.15,
          ease: TweenVars.ease,
        });
      }
    },

    contactOut() {
      if (this.isLandscape) {
        if (this.$refs.lottie) this.$refs.lotte.anim.playSegments([30, 1]);
        gsap.killTweensOf("#getInTouch");
        gsap.killTweensOf("#baseContactButtonHit");
        gsap.killTweensOf("#baseContactButton");
        gsap.killTweensOf("#lottieIcon");
        gsap.to("#getInTouch", {
          opacity: 0,
          duration: 0.45,
          ease: TweenVars.ease,
        });
        gsap.to("#baseContactButtonHit", {
          width: 40,
          duration: 0.65,
          ease: TweenVars.ease,
        });
        gsap.to("#baseContactButton", {
          width: 40,
          duration: 0.65,
          ease: TweenVars.ease,
        });
      }
    },

    showError(errId, errMsg) {
      alert("An error occured: " + errId + ", " + errMsg);
    },

    patchInitialized(patch) {
      patch.getVar("loadingStatusProgress").addListener(function (value) {
        if (value) {
          const percentageFloat = parseFloat(value) * 100;
          const percent = Math.ceil(percentageFloat);
          EB.$emit("cables_loading_percent", percent);
        }
      });
    },

    openBaseContactOverlay() {
      if (this.showContactOverlay) {
        return;
      }
      this.contactInitFromProduct = false;
      this.getInTouch_clear();
      this.showContactOverlay = true;
      setTimeout(() => {
        this.showBaseContactTooltip = false;
      }, 250);
      this.$store.commit("HIDE_PRODUCT_OVERLAY");
    },

    openProductContactOverlay() {
      if (this.showContactOverlay) {
        return;
      }
      this.contactInitFromProduct = true;
      this.showContactOverlay = true;
      setTimeout(() => {
        this.showBaseContactTooltip = false;
      }, 250);
      this.$store.commit("HIDE_PRODUCT_OVERLAY");

      if (
        this.productOverlayParams.sectionName != null &&
        this.productOverlayParams.sectionName != "" &&
        this.productOverlayParams.productId != null &&
        this.productOverlayParams.productId != -1
      ) {
        const __matomoArr = [
          this.productOverlayParams.sectionName +
            " - prod_" +
            this.productOverlayParams.productId,
          " - contact_offer",
        ];
        this.matomoTrackEvent(__matomoArr);
      }
    },

    productContactOverlayCloseTapped() {
      this.isProductContactTooltipActive = false;
      if (this.isLandscape) {
        if (!this.showContactOverlay) {
          this.showBaseContactTooltip = true;
        }
      } else if (this.isMobile) {
        const _path = window.location.hash.split("#");
        if (_path[1] != "/home" && _path[1] != "/home/") {
          this.isMainScreen = false;
          this.showBaseContactTooltip = false;
        }
      }
    },

    onScreenChanged(e) {
      this.isMainScreen = e.currentscreen === "main";
      store.state.isMainScreen = this.isMainScreen;
    },

    initBaseOverlay() {
      this.openProductOverlayFromStateParams();
    },

    playOverlaySound() {
      withPatch((patch) => {
        patch.config.triggerAudioEvent("open_overlay");
      });
    },

    openDataProtection() {
      let routeData = this.$router.resolve({ name: "data-protection" });
      window.open(routeData.href, "_blank");
    },
    hideiFrame() {
      const frameDesk = document.getElementById("iframeDesk");
      if (frameDesk) {
        frameDesk.style.display = "none";
      }
    },

    hideLoadingBar() {
      const element = document.getElementById("loadingAnimLogo");
      if (element) {
        element.style.display = "none";
        document.body.classList.remove("waitingForPlayButton");
      }
      setTimeout(() => {
        EB.$emit("initial_desk_contact_button");
      }, 2000);
    },

    patchFinishedLoading() {

      if (this.patchLoaded) {
        return;
      }

      this.hideiFrame();

      withPatch(
        (patch) => {
          const shouldDisableAnimation =
            null !== localStorage.getItem("cables.animation.disabled");
          if (shouldDisableAnimation) {
            document.querySelector("#glContainer > canvas").style.display =
              "none";
          }

          patch.getVar("playbuttonclicked").addListener((value) => {
            if (value) {
              this.hideLoadingBar();
            }
          });

          patch.getVar("playSound").addListener(
            function (value) {
              if (this.rtcParams?.isClientReady && this.isModerator) {
                this.sendActionViaWebsox({
                  name: "wxSettings",
                  action: { name: "playSound", value: value },
                });
              }
            }.bind(this)
          );

          patch.getVar("lang").addListener(function () {
            const value = patch.getVarValue("currentLang") || "en";
            EB.$emit("LOCALE_CHANGED", { locale: value });
          });

          patch.getVar("isMobileMenuOpen").addListener(function (value) {
            if (value) {
              if (this.mobileNavTimeOut) {
                clearTimeout(this.mobileNavTimeOut);
              }
              this.mobileNavTimeOut = setTimeout(() => {
                EB.$emit("MOBILE_NAV_OPEN", { locale: value });
              }, 500);
            }
          });

          patch.getVar("bgGradientFromHex").addListener(function () {
            const value = getCablesVar("bgGradientFromHex", "#f3f0adff");
            const root = document.documentElement;
            if (root) root.style.setProperty("--gradientFrom", value);
          });

          patch.getVar("bgGradientToHex").addListener(function () {
            const value = getCablesVar("bgGradientToHex", "#6bdddaff");
            const root = document.documentElement;
            if (root) root.style.setProperty("--gradientTo", value);
          });
        },
        () => {
          document.getElementById("loadingAnimLogo").style.display = "none";
          setTimeout(() => {
            EB.$emit("initial_desk_contact_button");
          }, 2000);
        }
      );

      if (!this.ebEventsInitialized) {
        this.initListeners();
        this.checkCNFooter(this.currentLocale);
        this.ebEventsInitialized = true;
      }

      withPatch((patch) => {
        patch.config.eventForUI = function (params) {
          const name = params[0];
          switch (name) {
            case "openContactForm":
              EB.$emit("cables_event", { params: params });
              break;
            case "productInfoClick":
              break;
            case "productInfoClose":
              this.hideProductOverlay();
              break;
            case "dataProtectionClick":
              EB.$emit("cables_event", { params: params });
              break;
            case "cookieClick":
              EB.$emit("cables_event", { params: params });
              break;
            case "socketClusterMessage":
              EB.$emit("cables_event", { params: params });
              this.recieveWebsoxMessageForUI(params[2]);
              break;
            case "socketClusterBroadcastMessage":
              EB.$emit("cables_event", { params: params });
              this.recieveWebsoxMessageForUI(params[2]);
              break;
            default:
              break;
          }
        }.bind(this);
      });

      if (!this.patchLoaded) {
        this.patchLoaded = true;
      }
    },

    initListeners() {
      EB.$on("screen_changed", (e) => {
        this.onScreenChanged(e);
      });

      EB.$on("closeLoginOverlay", () => {
        this.$store.commit("closeLoginOverlay");
      });

      EB.$on("loginSuxxess", () => {
        this.showLoginOverlay = false;
        this.showTourSection = true;
      });

      EB.$on("closeMeetingOverlay", () => {
        this.showTourSection = false;
      });

      EB.$on("playOverlaySound", () => {
        this.playOverlaySound();
      });

      EB.$on("cables_event", (e) => {
        switch (e.params[0]) {
          case "dataProtectionClick":
            this.openDataProtection();
            break;
          case "openContactForm":
            this.openContactOverlay();
            break;
          case "socketClusterMessage":
            break;
          case "socketClusterBroadcastMessage":
            this.setClientScreenSize(e.params[2]);
            break;
          case "cookieClick":
            this.onCookieClick();
            break;
        }
      });
      EB.$on("LOCALE_CHANGED", (e) => {
        this.localeChanged(e.locale);
      });

      EB.$on("MOBILE_NAV_OPEN", () => {});
    },
    localeChanged(locale) {
      if (this.currentLocale !== locale) {
        this.changeMainLocale(locale);
        this.getAndSetLang(locale);
        if (UC_UI && UC_UI.getActiveLanguage() !== locale) {
          let ucLocale = locale;
          if (ucLocale === "cn") ucLocale = "zh";
          UC_UI.updateLanguage(ucLocale);
        }
        this.checkCNFooter(locale);
      }
      if (this.rtcParams?.isClientReady && this.isModerator) {
        this.sendActionViaWebsox({
          name: "wxSettings",
          action: { name: "localeChanged", value: locale },
        });
      }
    },
    checkCNFooter(pLocale) {
      const cnLink1 = document.getElementById("cnFooterLink1");
      const cnLink2 = document.getElementById("cnFooterLink2");
      const cnImg = document.getElementById("cnFooterImg1");

      if (pLocale === "cn") {
        if (cnLink1 != null) {
          cnLink1.remove();
        }
        if (cnLink2 != null) {
          cnLink2.remove();
        }
        if (cnImg != null) {
          cnImg.remove();
        }

        let footer = document.getElementsByClassName("footer");
        for (var itemIndex = 0; itemIndex < footer.length; itemIndex++) {
          let link1 = document.createElement("a");
          link1.innerText = "京ICP备20031608号";
          link1.setAttribute("href", "https://www.beian.miit.gov.cn/");
          link1.setAttribute("id", "cnFooterLink1");
          link1.setAttribute("target", "_blank");

          let cnFooterImg = document.createElement("img");
          cnFooterImg.setAttribute(
            "src",
            "https://groz-beckert.cn/mm/media/web/0_corporate_1/1_gbkg_1/bilder_10/logos_1/gbkg_1/Image_logo_file_of_PSB.png"
          );
          cnFooterImg.setAttribute("id", "cnFooterImg1");

          let link2 = document.createElement("a");
          link2.innerText = "京公网安备 11010502042749号";
          link2.setAttribute("href", "https://www.beian.miit.gov.cn/");
          link2.setAttribute("id", "cnFooterLink2");
          link2.setAttribute("target", "_blank");

          footer[itemIndex].appendChild(link1);
          footer[itemIndex].appendChild(cnFooterImg);
          footer[itemIndex].appendChild(link2);

          let brs = footer[itemIndex].getElementsByTagName("br");
          for (const elm of brs) {
            elm.remove();
          }

          footer[itemIndex].classList.add("footerCN");
        }

        return;
      } else {
        if (cnLink1 != null) {
          cnLink1.remove();
        }
        if (cnLink2 != null) {
          cnLink2.remove();
        }
        if (cnImg != null) {
          cnImg.remove();
        }

        setTimeout(() => {
          var footer = document.getElementsByClassName("footer");
          for (var i = 0; i < footer.length; i++) {
            footer[i].classList.remove("footerCN");
          }
        }, 500);
      }
    },
    removeListeners() {},
    onCookieClick() {
      UC_UI.showSecondLayer();
    },

    baseContactBeforeEnter: function (el) {
      el.style.opacity = 0;
      el.style.transform =
        this.isInitial === true ? "translateX(0px)" : "translateX(40px)";
    },

    baseContactEnter: function (el, done) {
      gsap.to(el, {
        opacity: 1,
        x: 0,
        duration: TweenVars.duration,
        onComplete: done,
        ease: TweenVars.ease,
      });
      if (this.isInitial) {
        this.isInitial = false;
      }
    },
    baseContactLeave: function (el, done) {
      gsap.to(el, {
        translateX: 40,
        opacity: 0,
        delay: 0.7,
        duration: TweenVars.duration,
        ease: TweenVars.ease,
        onComplete: () => {
          done();
        },
      });
    },
    productContactBeforeEnter: function (el) {
      if (this.isLandscape === false) {
        el.style.top = "60px";
      }
      el.style.opacity = 0;
      el.style.transform = "translateX(40px)";
    },
    productContactEnter: function (el, done) {
      gsap.to(el, {
        opacity: 1,
        x: 0,
        duration: TweenVars.duration,
        onComplete: done,
        ease: TweenVars.ease,
        delay: 1,
      });
    },
    productContactLeave: function (el, done) {
      gsap.to(el, {
        translateX: 40,
        opacity: 0,
        duration: TweenVars.duration,
        ease: TweenVars.ease,
        onComplete: () => {
          done();
        },
      });
    },

    hideProductOverlay() {
      setTimeout(() => {
        this.$store.commit("HIDE_PRODUCT_OVERLAY");
        this.isProductContactTooltipActive = false;
      }, 500);
      setTimeout(() => {
        if (this.showContactOverlay) {
          this.showContactOverlay = false;
        }
      }, 1000);
    },

    cablesLoaded() {
      this.showCanvas = true;
      this.run();
    },

    hashChanged(e) {
      this.currentHash = e.newURL;
      this.urlPath = location.hash;
      const url = new URL(e.newURL);

      this.isProductView = url.hash.startsWith("#/prod/");
      return this.updateHash(e.newURL);
    },

    matomoLoaded() {
      this.setMatomo(this.$matomo);
    },

    run() {
      this.matomoLoaded();

      this.urlPath = location.hash;
      this.updateHash(window.location.hash);
      this.updateWindowAspects();
      var userLang = navigator.language || navigator.userLanguage;
      userLang = userLang.split("-")[0];
      let _lang = userLang == "zh" ? "cn" : userLang;

      if (!this.allowedLocales.includes(_lang)) {
        _lang = "en";
      }
      this.localeChanged(_lang);
      if (shouldEnableCables()) {
        const optimizations = this.canvasDegraded
          ? {
              preserveDrawingBuffer: true,
              powerPreference: "low-power",
              desynchronized: true,
            }
          : null;

        const cablesVariables = {
          currentLang: _lang,
          socketcluster_authServerUrl: `${process.env.VUE_APP_API_URL}/api/gb-content/v2/auth/token`,
          languageApiUrl: `${process.env.VUE_APP_API_URL}/api/gb-content/complete/`,
        };
        if (this.$store.state.constrains.forceMobileView) {
          cablesVariables.isMobile = true;
          cablesVariables.isPortrait = true;
          cablesVariables.isLandscape = false;
          cablesVariables.isDesktop = false;
        }
        CABLES.patch = new CABLES.Patch({
          patch: CABLES.exportedPatch,
          variables: cablesVariables,
          prefixAssetPath: "",
          prefixJsPath: "",
          glCanvasId: "glcanvas",
          glCanvasResizeToWindow: true,
          onError: this.showError,
          onPatchLoaded: this.patchInitialized,
          onFinishedLoading: this.patchFinishedLoading,
          silent: true,
          canvas: {
            antialias: false,
            preserveDrawingBuffer: false,
            alpha: false,
            powerPreference: "high-performance",
            ...optimizations,
          },
        });
      } else {
        this.patchFinishedLoading();
        this.hideLoadingBar();
      }

      EB.$on("initial_desk_contact_button", () => {
        if (this.isProductBtnClicked === false) {
          this.isProductBtnClicked = true;
        }
        if (!this.showBaseContactTooltip) {
          this.showBaseContactTooltip = true;
        }
      });
      EB.$on("contact_call", (e) => {
        this.openContactOverlay();

        setTimeout(() => {
          this.setGetInTouch_product(e.product);
        }, 50);
      });
      EB.$on("closeProductContactOverlay", () => {
        this.getInTouch_clear();
        this.showContactOverlay = false;
        if (!this.isPhoneDevice) {
          this.showBaseContactTooltip = true;
        } else {
          if (
            window.location.hash.endsWith("home") ||
            window.location.hash.endsWith("home/")
          ) {
            this.showBaseContactTooltip = true;
          }
        }

        if (window.location.hash.startsWith("#/prod") || this.isCareerDetail) {
          this.initBaseOverlay();
        }
      });
    },

    errorWinClosed() {
      this.exitAppointment();
      this.run();
    },

    async initAppointment(channelReference) {
      await this.loadActiveAppointment(channelReference);

      if (this.activeAppointmentError) {
        this.hideiFrame();
        return;
      }

      return this.initSessionChannel(channelReference);
    },
  },
  /****END METHODS*****/
  unmounted() {
    //window.removeEventListener("resize", this.onResize);
    EB.$off(EB_ACTION.WIN_RESIZE);
  },

  async mounted() {
    window.addEventListener("hashchange", this.hashChanged, false);

    window.addEventListener("orientationchange", () => {
      this.updateWindowAspects();
    });

    if (shouldEnableCables()) {
      let _cables = document.createElement("script");
      _cables.setAttribute("src", "/js/patch.js");
      this.$el.appendChild(_cables);
      document.addEventListener("CABLES.jsLoaded", this.cablesLoaded);
    } else {
      console.info("LOADING CABLES IS DISABLED VIA localStorage 'cables.mode'");
      this.run();
    }

    window.addEventListener("ucEvent", (e) => {
      if (e.detail && e.detail.event == "consent_status") {
        if (e.detail["Matomo (self hosted)"] === true) {
          this.setMatomo(this.$matomo);
        } else {
          this.setMatomo(null);
        }
      }
    });
  },
};
</script>

<style scoped lang="scss">
.HomePage {
  --zIndex--underMobileMenu: 550;
  --zIndex--overMobileMenu: 1000;
}

.canvasDegraded:not(.isMobile) #glcanvas {
  width: 300px !important;
  height: 280px !important;
}

.blurryBG {
  backdrop-filter: blur(10px);
  background-color: rgba(255, 255, 255, 0.2);
  transition: all 0.7s ease-out;
}

.blurryBGOut {
  backdrop-filter: blur(0px);
  background-color: rgba(255, 255, 255, 0);
  transition: all 0.7s ease-out;
}

.loadingLogo {
  z-index: 1000;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -60px;
  background-image: url(../assets/60113ffada682e1e1a3ae557_Logo.svg);
  width: 200px;
  height: 40px;
  transform: translate(-50%, -50%);
  background-position: center center;
  background-repeat: no-repeat;
}

.meetingConsent,
.meetingUsername,
.meetingRoom {
  position: absolute;
  z-index: var(--zIndex--overMobileMenu);
}

.meetingRoom {
  inset: 0;
}

#glContainer {
  width: 100%;
  height: 100%;
}

.glOverlayOff {
  z-index: 0 !important;
}

.glOverlay {
  position: absolute;
  z-index: 550;
  width: 100%;
  height: 100%;
}

canvas {
  display: block;
  position: absolute;
  box-sizing: border-box;
  width: 100%;
}

.HomePage:not(.isLandscape) {
  #baseContactButtonHit,
  #baseContactButton {
    min-width: 50px;
    height: 50px;
  }

  #product-contact-button,
  #baseContactButtonHit,
  #baseContactButton {
    bottom: 20px;
  }
}

.HomePage.isLandscape {
  #product-contact-button,
  #baseContactButtonHit,
  #baseContactButton {
    min-width: 40px;
    height: 40px;
    bottom: 45px;
  }
}

#product-contact-button {
  cursor: pointer;
  position: absolute;
  z-index: 888 !important;
  height: 40px;
  right: 15px;
}

#product-contact-button-inner {
  height: 40px;
  border-radius: 25px;
  background-color: rgba($color: #ffffff, $alpha: 0.6);
}

.product-contact-copy {
  pointer-events: none !important;
  color: #636363;
  font-size: 16px;
  margin-left: 10px;
  margin-top: 8px;
}

#product-contact-button-close {
  height: 40px;
  width: 40px;
  background-color: rgba(255, 255, 255, 0.6);
  border-radius: 100%;
  background-image: url("../assets/ui/minus_mobile.svg");
  background-repeat: no-repeat;
  background-position: center;
}

#baseContactButton {
  display: flex;
  align-items: center;
  position: absolute;
  z-index: 888 !important;
  background-color: rgba($color: #ffffff, $alpha: 0.6);
  border-radius: 25px;
  right: 15px;
}

#baseContactButtonHit {
  cursor: pointer;
  position: absolute;
  z-index: 999 !important;
  background-color: rgba(255, 0, 0, 0);
  border-radius: 25px;
  right: 15px;
}

.pt3 {
  padding-top: 3px !important;
}

#lottieIcon {
  pointer-events: none;
  width: 35px !important;
  height: 35px !important;
}

.lottieMobile {
  pointer-events: none;
  width: 23px !important;
  height: 19px !important;
  background-image: url("../assets/ui/contact_icon_mobile.svg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  margin: auto;
}

#getInTouch {
  position: absolute;
  width: max-content;
  left: 15px;
  line-height: 1em;
  pointer-events: none !important;
  opacity: 0;
  color: #636363;
  font-size: 16px;
}

.meetingClientAbdecker,
.productBackButtonAbdecker,
.languageButtonsbdecker {
  position: absolute;
  z-index: var(--zIndex--overMobileMenu);
  background-color: rgba(255, 0, 255, 0);
}

.meetingClientAbdecker {
  top: 30px;
  width: 100%;
  height: 100%;
}

.productBackButtonAbdecker {
  top: 0px;
  left: 0px;
  width: 150px;
  height: 150px;
}

.languageButtonsbdecker {
  top: 0px;
  left: 0px;
  width: 100%;
  height: 40px;
}

.detailsOverlay {
  position: absolute;
  left: 0;
  top: 0;
  right: var(--meetingRoomWidth, 0);
  bottom: 0;
  pointer-events: none;
  z-index: var(--zIndex--underMobileMenu);
  transition: right 0.5s;

  & > * {
    pointer-events: all;
  }
}

.contactOfferIcon {
  height: 100%;
  aspect-ratio: 1;
  margin-left: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>

<style>
/*
We don't need any Cables initialization artifacts
like load percentage or sporadic text blocks appearing.

Note: we use non-scoped `style` declaration here since
the Cables DOM elements are not own by us.
*/
#glContainer.loading > div {
  display: none !important;
}
</style>
