<template>
  <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="5" y="10" width="3" height="4" rx="1" fill="currentColor">
      <animate
        attributeName="height"
        dur="1.5s"
        :values="heights[0].join(';')"
        repeatCount="indefinite"
      />
      <animate
        attributeName="y"
        dur="1.5s"
        :values="y[0].join(';')"
        repeatCount="indefinite"
      />
    </rect>
    <rect x="11" y="10" width="3" height="4" rx="1" fill="currentColor">
      <animate
        attributeName="height"
        dur="1.5s"
        :values="heights[1].join(';')"
        repeatCount="indefinite"
      />
      <animate
        attributeName="y"
        dur="1.5s"
        :values="y[1].join(';')"
        repeatCount="indefinite"
      />
    </rect>
    <rect x="17" y="10" width="3" height="4" rx="1" fill="currentColor">
      <animate
        attributeName="height"
        dur="1.5s"
        :values="heights[2].join(';')"
        repeatCount="indefinite"
      />
      <animate
        attributeName="y"
        dur="1.5s"
        :values="y[2].join(';')"
        repeatCount="indefinite"
      />
    </rect>
  </svg>
</template>

<script>
const MAX_HEIGHT = 24;

export default {
  name: "VoiceIcon",
  props: ["isSpeaking"],

  computed: {
    heights() {
      return this.isSpeaking
        ? [
            ["5", "8", "6", "5"],
            ["4", "12", "16", "11", "4"],
            ["4", "6", "5", "4"],
          ]
        : [["4"], ["4"], ["4"]];
    },

    y() {
      return this.heights.map((row) => {
        return row.map((height) => {
          return (MAX_HEIGHT - height) / 2;
        });
      });
    },
  },
};
</script>
