import { createRouter, createWebHistory } from "vue-router";

import HomePage from "./routes/HomePage.vue";
import Fallback from "./routes/Fallback.vue";

const routes = [
  {
    path: "/",
    name: "main",
    meta: {
      reload: true,
    },
    component: HomePage,
    props: (route) => ({ param: route.query.u }),
  },
  /* { path: '/', redirect: '/experience' }, */
  /* { path: '/experience', component: Main }, */
  /* { path: '/experience/:channel/', component: Main}, */
  { path: "/fallback", component: Fallback },
  /* { path: ':categorie', component:null }, */
  /* { path: ':categorie', component: Tour, props: route => ({ query: route.query.q, param:route.query.t }) },  */
  {
    path: "/tour/:channel",
    name: "Tour",
    component: () => import("./routes/Tour.vue"),
    props: true,
  },
  {
    path: "/data-protection",
    name: "data-protection",
    component: () => import("./routes/DataProtectionRoute"),
  },
];

export const router = createRouter({
  history: createWebHistory(),
  routes,
});
