<template>
  <svg viewBox="0 0 37 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <mask id="path-1-inside-1_554_15348" fill="white">
      <rect x="2" y="2" width="33" height="20" rx="1" />
    </mask>
    <rect
      x="2"
      y="2"
      width="33"
      height="20"
      rx="1"
      stroke="#636363"
      stroke-width="2.6"
      mask="url(#path-1-inside-1_554_15348)"
    />
  </svg>
</template>
