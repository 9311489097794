<template>
  <div class="overlay">
    <div
      class="panel d-flex flex-column pb15"
      id="panelGroup"
      :class="{ fontMobile: isMobile === true, fontDesk: isMobile === false }"
    >
      <div v-if="overlayItems">
        <transition-group
          appear
          v-on:before-enter="beforeEnter"
          v-on:enter="enter"
          v-on:leave="leave"
          tag="div"
        >
          <div
            v-for="(item, index) in overlayItems"
            :key="item.fullPath"
            :data-index="index"
            :data-testid="item.type"
            :data-type="item.type"
            role="listitem"
          >
            <OverlayButton
              v-if="item.type == 'BUTTON'"
              class="navItem"
              :copy="item.copy"
              :url="item.url"
              @click="buttonClick(item)"
            />

            <OverlayVideo
              v-if="item.type == 'VIDEO'"
              class="mt15"
              shallowRef="video"
              @play="onPlay"
              @pause="onPause"
              @seeked="onSeeked"
              :src="`${item.value}` + '.mp4'"
              :data-testid="item.type"
            />

            <div
              v-if="item.type == 'IMAGE' && item.value"
              class="p-0 mt15"
              style="min-height: 149px"
            >
              <img :src="item.value" style="width: 100%" alt="" />
            </div>

            <div v-if="item.type == 'COPY' && !isHighlight" class="p-0 copy">
              <div v-if="item.key == '1'" v-html="getCopy1"></div>
              <div v-if="item.key == '2'" v-html="getCopy2"></div>
            </div>
            <div v-if="item.type == 'COPY' && isHighlight" class="p-0 copy">
              <div v-html="getCopy1"></div>
            </div>
          </div>
        </transition-group>
      </div>
    </div>
  </div>
</template>

<script>
import { gsap } from "gsap";
import { TweenVars } from "../utils/tweenTime";
import { EB } from "@/utils/EB";
import { PANELEVENT } from "../utils/enum.js";
import _ from "lodash";
import { mapActions, mapGetters } from "vuex";
import OverlayButton from "./elements/Button.vue";
import OverlayVideo from "./elements/Video.vue";

export default {
  components: {
    OverlayButton,
    OverlayVideo,
  },
  name: "ChildProductOverlay",
  props: ["overlayData", "isHighlight"],
  data() {
    return {
      overlayKey: null,
      backDetected: false,
    };
  },
  created() {
    this.overlayKey = parseInt(this.baseOverlayParams.activeOverlayIndex);
  },

  unmounted() {
    EB.$off("HIGHLIGHT_VIDEO_PLAY", this.onVideoPlayMessage);
    EB.$off("HIGHLIGHT_VIDEO_PAUSE", this.onVideoPauseMessage);
    EB.$off("HIGHLIGHT_VIDEO_SEEKED", this.onVideoSeekedMessage);
  },

  mounted() {
    this.visibleItems = [];

    EB.$on("HIGHLIGHT_VIDEO_PLAY", this.onVideoPlayMessage);
    EB.$on("HIGHLIGHT_VIDEO_PAUSE", this.onVideoPauseMessage);
    EB.$on("HIGHLIGHT_VIDEO_SEEKED", this.onVideoSeekedMessage);

    EB.$on("NAVIGATE_BACK", () => {
      setTimeout(() => {
        EB.$emit("panelEvent", { type: PANELEVENT.SHOW_BASE });
      }, 550);
    });
  },
  watch: {
    currentPanelState(newValue, oldValue) {
      if (newValue != oldValue) {
        if (this.backDetected == false && newValue == "NAV_BACK") {
          this.backDetected = true;
          console.log("NAVIGATE_BACK DETECTED");
          setTimeout(() => {
            EB.$emit("panelEvent", { type: PANELEVENT.SHOW_BASE });
          }, 550);
        }
        if (newValue == "CLOSED") {
          this.visibleItems = [];
          setTimeout(() => {
            EB.$emit("panelEvent", { type: PANELEVENT.CLOSE_PANEL });
          }, 150);
        }
      }
    },
  },
  computed: {
    ...mapGetters([
      "isMobile",
      "currentProduct",
      "productOverlayPanelWidth",
      "baseOverlayParams",
    ]),

    video() {
      return this.$refs.video?.[0];
    },

    currentPanelState() {
      return this.baseOverlayParams.appPanelState;
    },

    getCopy1: function () {
      if (this.isHighlight) {
        return this.currentProduct.highlightCopy;
      } else {
        return this.currentProduct.getCopyForKeyAndOverlay(
          this.overlayKey,
          "1"
        );
      }
    },
    getCopy2: function () {
      if (this.isHighlight) {
        return this.currentProduct.highlightCopy;
      } else {
        return this.currentProduct.getCopyForKeyAndOverlay(
          this.overlayKey,
          "2"
        );
      }
    },
    overlayItems() {
      if (this.isHighlight) {
        return this.currentProduct.highlight;
      }

      return (
        this.currentProduct.getOverlayItemsByOverlayId(this.overlayKey) ?? []
      );
    },
  },

  methods: {
    ...mapActions([
      "setGetInTouch_product",
      "sendToRtmChannel",
      "syncWithRemote",
    ]),
    onVideoSeekedMessage(value) {
      this.syncWithRemote(() => {
        this.video.seek(value);
      });
    },

    onVideoPauseMessage() {
      this.syncWithRemote(() => {
        this.video.pause();
      });
    },

    onVideoPlayMessage() {
      this.syncWithRemote(() => {
        if (this.video) this.video.play();
      });
    },

    fadeOut() {
      this.visibleItems = [];
      this.overlay_data = [];
    },
    buttonClick(item) {
      const { url } = item;

      if (!url.startsWith("contactCall")) {
        throw new Error("no cantactCall call url defined");
      }

      const _product = _.capitalize(url.replace("contactCall_", ""));
      this.setGetInTouch_product(_product);
      setTimeout(() => {
        EB.$emit("contact_call", { product: _product });
      }, 50);
    },

    beforeEnter: function (el) {
      el.style.opacity = 0;
      el.style.transform = "translateY(-20px)";
    },
    enter(el, done) {
      gsap.to(el, {
        opacity: 1,
        translateY: 0,
        // height: targetHeight,
        duration: TweenVars.duration,
        onComplete: done,
        ease: TweenVars.ease,
        delay: 0.2 + el.dataset.index * 0.075,
      });
    },
    leave(el, done) {
      gsap.to(el, {
        translateY: 20,
        opacity: 0,
        height: 0,
        duration: TweenVars.duration,
        ease: TweenVars.ease,
        onComplete: done,
        delay: el.dataset.index * 0.025,
      });
    },
    onPlay() {
      this.sendToRtmChannel({ name: "HIGHLIGHT_VIDEO_PLAY" });
    },
    onPause() {
      this.sendToRtmChannel({ name: "HIGHLIGHT_VIDEO_PAUSE" });
    },
    onSeeked() {
      this.sendToRtmChannel({
        name: "HIGHLIGHT_VIDEO_SEEKED",
        time: this.$refs.video[0].currentTime,
      });
    },
  },
};
</script>

<style scoped lang="scss">
.panel {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  opacity: 1;
}
.copy {
  padding-top: 15px !important;
  /* padding-bottom:15px!important; */
  padding-left: 15px !important;
  padding-right: 15px !important;
  font-family: "Univers LT Std";
  font-style: normal;
  font-weight: 400;
}

.navItem {
  margin-top: 15px;
  padding-left: 15px !important;
  padding-right: 15px !important;
}
</style>
