<template>
  <div style="pointer-events: all; overflow: hidden">
    <div
      class="pl15 pt15 pr15 pb10"
      :class="{ fontMobile: isMobile === true, fontDesk: isMobile === false }"
    >
      {{ contact_locale.copy }}
    </div>

    <div class="input-holder tfbt tfbb">
      <input
        type="text"
        name="email"
        v-model="email"
        :placeholder="contact_locale.label1"
        @keypress="clearErrors"
      />
      <div class="emailWarn" v-if="emailEmpty">
        {{ contact_locale.label1Error }}
      </div>
      <div class="emailWarn" v-if="emailWrong">
        {{ contact_locale.label1Error2 }}
      </div>
    </div>

    <div class="input-holder tfbb" style="width: 100%">
      <textarea
        type="text"
        name="message"
        v-model="textMessage"
        rows="3"
        :placeholder="contact_locale.label2"
        @keypress="clearErrors"
      ></textarea>
      <div class="messageWarn" v-if="textEmpty">
        {{ contact_locale.label2Error }}
      </div>
    </div>

    <div class="input-holder d-flex tfbb">
      <div class="p-0">
        <div class="d-flex">
          <div class="p-0 ml15">
            <label
              class="cb_container"
              style="margin-top: 19px !important; margin-right: 4px"
            >
              <input
                type="checkbox"
                :checked="checked"
                @click="checkBoxClick"
              />
              <span
                class="checkmark"
                :class="{ checkmarkWarn: acceptWarn }"
              ></span>
            </label>
          </div>
          <div
            class="p-0"
            :class="{
              fontMobile: isMobile === true,
              fontDesk: isMobile === false,
            }"
          >
            <div class="pt17 pb15 pr15">{{ contact_locale.label3 }}</div>
          </div>
        </div>
        <div class="checkboxWarn" v-if="acceptWarn">
          {{ contact_locale.label3Error }}
        </div>
      </div>
    </div>

    <div class="input-holder d-flex tfbb">
      <div
        class="p-3"
        :class="{ fontMobile: isMobile === true, fontDesk: isMobile === false }"
        v-html="contact_locale.copy2"
      ></div>
    </div>

    <div class="p-0 d-flex flex-column ml15 mr15 mt20 mb20 justify-content-end">
      <div class="p-0 d-flex flex-fill justify-content-center">
        <div
          v-wave
          class="contact-next-button"
          :class="{ contextNextClickable: nextDisabled }"
          @click="nextTapped"
        >
          <div class="contact-next-button-text">
            {{ contact_locale.buttonCopy }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { OverlaySteps } from "@/utils/enum.js";
import { mapGetters } from "vuex";
import { mapActions } from "vuex";

export default {
  name: "EmailMessage",
  props: ["next", "resize"],
  data() {
    return {
      email: "",
      textMessage: "",
      reg: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/,
      userSaved: false,
      sending: false,
      lastUser: null,
      checked: false,
      count: 0,
      acceptWarn: false,
      textEmpty: false,
      emailEmpty: false,
      emailWrong: false,
      stepType: OverlaySteps.EMAILFORM,
    };
  },

  created() {
    this.email = this.getInTouch.email;
    this.textMessage = this.getInTouch.message;
    console.log(this.getInTouch);
  },
  mounted() {
    setTimeout(() => {
      this.resize();
    }, 1000);
  },
  computed: {
    ...mapGetters(["isMobile", "contact_locale", "getInTouch"]),
    nextDisabled: function () {
      return true;
    },
  },
  methods: {
    ...mapActions(["setGetInTouch_message", "setGetInTouch_email"]),
    dataProtectionClick() {
      console.log("dataProtectionClick");
    },
    checkBoxClick() {
      this.checked = !this.checked;
      this.acceptWarn = !this.checked;
    },
    clearErrors() {
      this.emailWrong = false;
      this.emailEmpty = false;
      this.textEmpty = false;
    },
    isEmailValid() {
      return this.email === ""
        ? false
        : this.reg.test(this.email)
        ? true
        : false;
    },
    nextTapped() {
      if (this.email === "") {
        this.emailEmpty = true;
        return;
      }
      if (!this.isEmailValid()) {
        this.emailWrong = true;
        return;
      }
      if (this.textMessage === "") {
        this.textEmpty = true;
        return;
      }
      if (this.checked === false) {
        this.acceptWarn = true;
        return;
      }
      this.setGetInTouch_email(this.email);
      this.setGetInTouch_message(this.textMessage);
      if (this.isEmailValid()) {
        this.next(this.stepType, OverlaySteps.RESPONSETYPE);
      }
    },
  },
};
</script>

<style scoped>
* {
  -webkit-user-select: auto;
  pointer-events: all;
}

textarea {
  width: calc(100% - 30px);
  height: auto;
}
.rc {
  width: 20px !important;
  -webkit-user-select: none; /* Chrome all / Safari all */
  -moz-user-select: none; /* Firefox all */
  -ms-user-select: none; /* IE 10+ */
  user-select: none;
  pointer-events: none;
  border: none;

  text-align: center;
  color: rgba(99, 99, 99, 1);
  padding-left: 0px !important;
  padding-right: 0px !important;
}
.captchaRepeatBtn {
  width: 26px;
  height: 26px;
  margin-top: 6px !important;
  cursor: pointer;
}

.cb_container {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.cb_container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 22px;
  width: 22px;
  background-color: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(99, 99, 99, 0.35);
  border-radius: 2px;
}
.checkmarkWarn {
  border: 1px solid #ff0000;
}

/* On mouse-over, add a grey background color */
.cb_container:hover input ~ .checkmark {
  background-color: rgba(255, 255, 255, 0.1);
}

/* When the checkbox is checked, add a blue background */
.cb_container input:checked ~ .checkmark {
  background-color: rgba(255, 255, 255, 0.1);
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.cb_container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.cb_container .checkmark:after {
  left: 7px;
  top: 3px;
  width: 5px;
  height: 10px;
  border: solid rgba(99, 99, 99, 1);
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.input-holder {
  position: relative;
}
.emailWarn {
  position: absolute;
  /* top: 30px; */
  bottom: -2px;
  left: 15px;
  font-size: 12px;
  color: #ff0000;
}
.messageWarn {
  position: absolute;
  /* top: 70px; */
  bottom: -2px;
  left: 15px;
  font-size: 12px;
  color: #ff0000;
}
.checkboxWarn {
  position: absolute;
  /* top: 43px; */
  bottom: -2px;
  /* left: 15px;*/
  left: 55px;
  font-size: 12px;
  color: #ff0000;
}
/* @media (orientation: landscape) {
  .emailWarn{
    top: 28px;
  }
  
}
@media (orientation: portrait) {
  .emailWarn{
    bottom: 0px;
  }
} */
::placeholder {
  color: rgba(0, 0, 0, 0.1) !important;
}
</style>
