<template>
  <svg viewBox="0 0 28 27" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M15.8528 7H4.81405C3.81054 7 3 7.81051 3 8.81405V18.3089C3 19.3124 3.81051 20.1229 4.81405 20.1229H15.8528C16.8563 20.1229 17.6668 19.3124 17.6668 18.3089V8.81405C17.6668 7.81054 16.8563 7 15.8528 7V7Z"
      fill="#636363"
    />
    <path
      d="M24.6908 9.04518C24.4206 8.85219 24.1119 8.8136 23.8031 8.89079L18.8242 10.6276V17.1118L23.8416 18.8101C23.9574 18.8487 24.0732 18.8487 24.189 18.8487C24.382 18.8487 24.4978 18.7715 24.6907 18.6557C24.9609 18.4627 24.9995 18.1539 24.9995 17.8066V9.89435C24.9995 9.54699 24.9223 9.23822 24.6907 9.04523L24.6908 9.04518Z"
      fill="#636363"
    />
  </svg>
</template>
