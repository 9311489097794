<template>
  <svg
    width="26"
    height="22"
    viewBox="0 0 26 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M13.8661 0.500001C13.4812 -0.166666 12.5189 -0.166667 12.134 0.5L0.875701 20C0.490801 20.6667 0.971925 21.5 1.74173 21.5H24.2584C25.0282 21.5 25.5093 20.6667 25.1244 20L13.8661 0.500001ZM12.0001 7H14.0001L13.6858 15.0596H12.3143L12.0001 7ZM12.1429 18V16.1717H13.8572V18H12.1429Z"
      fill="#FF0000"
    />
  </svg>
</template>
