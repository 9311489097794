<template>
  <div class="app">
    <router-view></router-view>
    <OverlayLogin v-if="showLoginOverlay" />
    <AppLoader
      class="iFrameLoader"
      id="iframeDesk"
      :copy-text="loadingCopyText"
    />
  </div>
</template>

<script>
import OverlayLogin from "@/overlays/OverlayLogin";
import { EB, EB_ACTION } from "@/utils/EB";
import { mapGetters } from "vuex";
import { mapActions } from "vuex";
import AppLoader from "./components/AppLoader.vue";

export default {
  name: "App",
  components: {
    OverlayLogin,
    AppLoader,
  },
  data() {
    return {
      device: null,
      resizeTimeout: null,
      loadingPercent: 0,
      loadingCopyText: "",
    };
  },

  computed: {
    ...mapGetters([
      "isSockeClusterActive",
      "showLoginOverlay",
      "userData",
      "currentLocale",
    ]),
  },

  methods: {
    ...mapActions([
      "checkUserAuth",
      "getAndSetLang",
      "getAndSetLoginLang",
      "initSockets",
      "sendParamsViaWebsox",
      "updateWindowAspects",
      "changeMainLocale",
    ]),

    updateLoadingPercent(value) {
      this.loadingPercent = value;
    },
    onResize() {
      if (this.resizeTimeout) {
        clearTimeout(this.resizeTimeout);
      }
      this.resizeTimeout = setTimeout(() => {
        this.updateWindowAspects_root();
      }, 500);
    },

    updateWindowAspects_root() {
      this.updateWindowAspects();
      EB.$emit(EB_ACTION.WIN_RESIZE);
      if (this.isSockeClusterActive == true && this.isModerator == false) {
        this.sendParamsViaWebsox({
          screenSize: { width: window.innerWidth, height: window.innerHeight },
        });
      }
    },
  },

  mounted() {
    var userLang = navigator.language || navigator.userLanguage;
    userLang = userLang.split("-")[0];
    if (userLang === "de") {
      this.changeMainLocale("de");
    } else if (userLang === "en") {
      this.changeMainLocale("en");
    } else if (userLang === "zh" || userLang === "cn") {
      this.changeMainLocale("cn");
    } else {
      this.changeMainLocale("en");
    }

    switch (this.currentLocale) {
      case "de":
        this.loadingCopyText =
          "Sie betreten eine 3D-Experience.<br/>Das Laden der Welt kann einen Moment dauern.";
        break;

      case "cn":
        this.loadingCopyText = "您正在进入3D世界体验，加载可能需要一些时间。";
        break;

      case "en":
      default:
        this.loadingCopyText =
          "You're entering a 3D experience.<br/>Loading the world may take a moment.";
        break;
    }

    this.checkUserAuth();
    this.updateWindowAspects();

    window.addEventListener("resize", this.onResize);

    EB.$on("cables_loading_percent", this.updateLoadingPercent);
  },

  unmounted() {
    window.removeEventListener("resize", this.onResize);
    EB.$off("cables_loading_percent", this.updateLoadingPercent);
  },
  watch: {
    showLoginOverlay(showLogin) {
      if (showLogin === false) {
        this.$store.commit("changeLoginRoute", false);
      }
    },
  },
};
</script>

<style lang="scss">
@import url("./styles/styles.scss");
@import url("./styles/grid.css");

body {
  margin: 0;
  overflow: hidden;
  line-height: 1.3;
}

.app {
  font-family: Univers LT Std;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #636363;
  width: 100%;
  height: 100%;
  position: fixed;
  color: #636363;
  margin: 0px;
  padding: 0px;
  min-width: 100vw;
  overflow: hidden;
  min-height: 100%;
}

* {
  user-select: none;
}
</style>
