export const mailBody = (locale, _data) => {
  const _reTypeDE =
    _data.responseType === "requestMeeting"
      ? "Ich möchte einen Termin für ein Online-Meeting vereinbaren."
      : "Bitte beantworten Sie meine Anfrage per E-Mail.";
  const _reTypeEN =
    _data.responseType === "requestMeeting"
      ? "I would like to schedule an online meeting."
      : "Please answer my request by e-mail.";
  const _enAmPm = _data.isPm ? "pm" : "am";
  const _dateCopy =
    _data.meetingDay != null && _data.meetingDay != ""
      ? _data.meetingDay +
        "/" +
        (_data.meetingMonth + 1) +
        "/" +
        _data.meetingYear
      : "";
  const _dateCopyDE =
    _data.meetingDay != null && _data.meetingDay != ""
      ? _data.meetingDay +
        "." +
        (_data.meetingMonth + 1) +
        "." +
        _data.meetingYear
      : "";
  const _timeCopy =
    _data.meetHour != null && _data.meetHour != ""
      ? _data.meetHour + ":" + _data.meetMinutes + " " + _enAmPm
      : "";
  const _timeCopyDE =
    _data.meetHour != null && _data.meetHour != ""
      ? _data.meetHour + ":" + _data.meetMinutes
      : "";
  return locale == "de"
    ? `Technologie / Produktbereich: ${_data.product}
Kontinent: ${_data.continent.title}
Land: ${_data.country.title}
Groz-Beckert Niederlassung: ${_data.officeBranch.officeName}
E-Mail: ${_data.email}
Nachricht: ${_data.message}
Art der Anfrage: ${_reTypeDE}
      
Meeting Datum: ${_dateCopyDE}
Meeting Startzeit: ${_timeCopyDE}
Zeitzone: ${_data.timezone.value}
      
Telefonnummer: ${_data.phone}
Name:  ${_data.firstName} ${_data.lastName}
Unternehmen:  ${_data.companyName}`
    : `Technology/product area: ${_data.product}
Continent: ${_data.continent.title}
Country: ${_data.country.title}
Groz-Beckert branch: ${_data.officeBranch.officeName}
E-mail: ${_data.email}
Message: ${_data.message}
Request type: ${_reTypeEN}
      
Meeting date: ${_dateCopy}
Meeting time: ${_timeCopy}
Time zone: ${_data.timezone.value}
      
Phone number: ${_data.phone}
Name:  ${_data.firstName} ${_data.lastName}
Company:  ${_data.companyName}`;
};
