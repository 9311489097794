<template>
  <svg
    width="50"
    height="50"
    viewBox="0 0 50 50"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    name="ContactIconMobile"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M13.075 15.075H33.325V23.3875H37.925V35.925H27.225V34.075H36.075V25.2375H33.325V31.175H25.7571L17.675 36.0433V31.175H13.075V15.075ZM14.925 16.925V29.325H19.525V32.7692L25.2429 29.325H31.475V16.925H14.925Z"
      fill="url(#paint0_linear)"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M25 48C37.7025 48 48 37.7025 48 25C48 12.2975 37.7025 2 25 2C12.2975 2 2 12.2975 2 25C2 37.7025 12.2975 48 25 48ZM25 50C38.8071 50 50 38.8071 50 25C50 11.1929 38.8071 0 25 0C11.1929 0 0 11.1929 0 25C0 38.8071 11.1929 50 25 50Z"
      fill="url(#paint1_linear)"
    />
    <defs>
      <linearGradient
        id="paint0_linear"
        x1="41.5"
        y1="3"
        x2="8"
        y2="46.5"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="var(--gradientFrom)" />
        <stop offset="1" stop-color="var(--gradientTo)" />
      </linearGradient>
      <linearGradient
        id="paint1_linear"
        x1="41.5"
        y1="3"
        x2="8"
        y2="46.5"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="var(--gradientFrom)" />
        <stop offset="1" stop-color="var(--gradientTo)" />
      </linearGradient>
    </defs>
  </svg>
</template>

<script>
export default {
  name: "ContactIconMobile",
};
</script>
