<template>
  <div
    id="MeetingConsent"
    class="overlayInnerCopy"
    v-bind:class="{ frostedbg: showFrost }"
  >
    <div id="meetingOverlayBackdrop"></div>

    <div class="innerOverlay consentInner">
      <OverlayHeader
        :title="activeAppointmentTitle"
        @close="closeOverlay"
        v-if="!presenterMobileError"
      />
      <MobileCloseHeader
        :closeIcon="true"
        :closeCallback="presenterErrorClose"
        v-if="presenterMobileError"
      />

      <div
        class="d-flex flex-column justify-content-center align-items-center"
        v-if="presenterMobileError"
      >
        <div class="p-2">
          <img src="/assets/ui/tour/mobile_off_warn.svg" />
        </div>
        <div
          class="p-3 flex-fill pt16"
          v-html="locale.meeting_presenter_mobile_message"
        ></div>
      </div>

      <div class="d-flex flex-column pt6" v-if="!presenterMobileError">
        <div class="p-0 flex-fill ml15 mr15">
          <div class="d-flex justify-content-left align-items-center">
            <template v-if="joinError">
              <ErrorIcon id="checkboxContainer" />
              <div class="p-2 fontDesk errorMessage">
                {{ joinError }}
              </div>
            </template>
            <template v-else>
              <div id="checkboxContainer" class="">
                <label class="cb_container2">
                  <input
                    type="checkbox"
                    :checked="consentChecked"
                    @click="checkBoxClick"
                  />
                  <span class="checkmark"></span>
                </label>
              </div>
              <div class="p-2 fontDesk">
                {{ locale.meeting_1_consent_copy_1 }}
                <div class="tfError" v-if="!consentChecked && nextTapped">
                  {{ locale.meeting_1_consent_error }}
                </div>
              </div>
            </template>
          </div>
        </div>
        <!--         <div class='p-0 flex-fill  tfError ml15 mr15' >
           {{locale.meeting_1_consent_error}}
         </div>-->

        <!-- ---- Datenschutzerklärung ----  -->
        <div class="p-0 flex-fill tfbt" v-if="!joinError">
          <div
            class="ml15 mr15 pt16 pb10"
            v-html="locale.meeting_1_consent_copy_2"
          ></div>
        </div>
      </div>

      <div
        class="d-flex flex-column justify-content-xl-center tfbt"
        v-if="!presenterMobileError"
      >
        <div class="p-0 align-self-lg-center mt15 flex-fill ml15 mr15">
          <div
            class="contact-next-button contextNextClickable"
            @click="startAs('client')"
          >
            <div class="contact-next-button-text">
              <template v-if="!joinError">
                {{ locale.meeting_1_consent_button_1 }}
              </template>
              <template v-else>
                {{ locale.meeting_enter_as_participant }}
              </template>
            </div>
          </div>
        </div>
        <div class="p-0 align-self-center">
          <div
            v-if="!joinError"
            class="secondayButton mt10"
            @click="startAs('admin')"
            style="margin-left: 7px !important"
          >
            <div class="d-flex align-items-center h100 secondaryHolder">
              <div class="p-0 mt2">{{ locale.meeting_1_consent_button_2 }}</div>
              <div class="p-0 mt5 secondaryIcon" v-if="isLandscape">
                <img src="/assets/ui/icons/arrow_right_desk.svg" />
              </div>
              <div class="p-0 mt5 secondaryIcon" v-if="!isLandscape">
                <img
                  src="/assets/ui/icons/arrow_right_mobile2.svg"
                  class="ml10"
                />
              </div>
            </div>
          </div>
          <div
            v-else
            class="secondayButton mt10"
            @click="$router.push({ path: `/create-tour` })"
            style="margin-left: 7px !important"
          >
            <div class="d-flex align-items-center h100 secondaryHolder">
              <div class="p-0">
                {{ locale.meeting_2_intro_button_2_presenter }}
              </div>
              <div class="p-0 mt2 secondaryIcon">
                <img src="/assets/ui/icons/arrow_right_desk.svg" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { mapGetters } from "vuex";
import { gsap } from "gsap";
import { TweenVars } from "@/utils/tweenTime.js";
import OverlayHeader from "@/components/klein/OverlayHeader";
import MobileCloseHeader from "@/components/klein/MobileCloseHeader";
import ErrorIcon from "@/icons/ErrorIcon";

export default {
  name: "MeetingConsent",
  components: {
    OverlayHeader,
    MobileCloseHeader,
    ErrorIcon,
  },
  data() {
    return {
      showContent: true,
      showFrost: false,
      consentChecked: false,
      nextTapped: false,
      presenterMobileError: false,
      joinError: null,
    };
  },

  computed: {
    ...mapGetters([
      "isLandscape",
      "isMobile",
      "currentLocale",
      "locale",
      "consentAccepted",
      "activeAppointmentTitle",
      "userData",
    ]),
  },
  created() {
    this.getAndSetLoginLang(this.currentLocale);
    this.getAndSetLang(this.currentLocale);
  },
  mounted() {
    this.fadeIn();
  },

  methods: {
    ...mapActions([
      "acceptConsentAndOpenAs",
      "getAndSetLang",
      "checkLogin",
      "getAndSetLoginLang",
      "closeLoginOverlay",
      "setNewPasswordAndLogin",
      "openLoginOverlay",
    ]),
    presenterErrorClose() {
      this.presenterMobileError = false;
    },
    startAs(role) {
      this.nextTapped = true;
      if (this.consentChecked === true) {
        if (this.isMobile && role === "admin") {
          this.presenterMobileError = true;
        } else if (role === "admin" && !this.userData) {
          return this.openLoginOverlay(() => {
            this.startAs(role);
          });
        } else {
          return Promise.resolve(this.acceptConsentAndOpenAs(role)).catch(
            (e) => {
              this.joinError = e.message;
            }
          );
        }
      }
    },
    checkBoxClick() {
      this.consentChecked = !this.consentChecked;
    },
    closeOverlay() {
      this.showFrost = false;
      gsap.to("#meetingOverlayBackdrop", {
        opacity: 0,
        duration: 1,
        ease: TweenVars.ease,
      });
      gsap.to(".consentInner", {
        top: this.$store.state.constrains.screenHeight / 3,
        opacity: 0,
        duration: TweenVars.duration,
        ease: TweenVars.ease,
      });
      setTimeout(() => {
        this.closeLoginOverlay();
      }, 1100);
    },

    fadeIn: function () {
      gsap.to("#meetingOverlayBackdrop", {
        opacity: 1,
        duration: 1,
        ease: TweenVars.ease,
      });

      const _w = this.isLandscape
        ? 500
        : this.$store.state.constrains.screenWidth - 30;
      /* const _h = this.isLandscape ? 310 : (this.$store.state.constrains.screenHeight - 80);*/
      const _l = this.isLandscape
        ? this.$store.state.constrains.screenWidth / 2 - 250
        : 15;
      const _t = this.isLandscape
        ? this.$store.state.constrains.screenHeight / 2 - 155
        : 60;
      gsap.to(".consentInner", {
        width: _w,
        left: _l,
        top: _t,
        duration: 1,
        ease: TweenVars.ease,
        delay: 0.5,
        opacity: 1,
      });
      if (!this.showFrost) {
        setTimeout(() => {
          this.showFrost = true;
        }, 800);
      }
    },
  },
};
</script>

<style scoped lang="scss">
.errorMessage {
  color: #ff0000;
}

.tfError {
  font-size: 12px;
  line-height: 16px;
  color: #ff0000;
}

.cb_container2 {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  height: 1rem;
}

/* Hide the browser's default checkbox */
.cb_container2 input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 22px;
  width: 22px;
  background-color: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(99, 99, 99, 0.35);
  border-radius: 2px;
}
.checkmarkWarn {
  border: 1px solid #ff0000;
}

/* On mouse-over, add a grey background color */
.cb_container2:hover input ~ .checkmark {
  background-color: rgba(255, 255, 255, 0.1);
}

/* When the checkbox is checked, add a blue background */
.cb_container2 input:checked ~ .checkmark {
  background-color: rgba(255, 255, 255, 0.1);
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.cb_container2 input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.cb_container2 .checkmark:after {
  left: 7px;
  top: 3px;
  width: 5px;
  height: 10px;
  border: solid rgba(99, 99, 99, 1);
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
.consentInner {
  height: auto !important;
  padding-bottom: 15px !important;
}

#checkboxContainer {
  padding: 0.5rem 0 0.5rem 0 !important;
}

.consentInner {
  position: absolute;
  opacity: 0;
  top: calc(50% - 100px);
  left: calc(50% - 100px);
  width: 200px;
  height: 200px;
}

#meetingOverlayBackdrop {
  z-index: auto !important;
}
</style>
