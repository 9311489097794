<template>
  <svg viewBox="0 0 28 27" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M6.68555 7.6748H5.52498C4.52148 7.6748 3.71094 8.48532 3.71094 9.48885V18.9837C3.71094 19.9872 4.52145 20.7978 5.52498 20.7978H16.5637C17.4475 20.7978 18.1817 20.169 18.3442 19.3335L6.68555 7.6748ZM18.3777 17.3871V9.48885C18.3777 8.48534 17.5672 7.6748 16.5637 7.6748H8.66545L18.3777 17.3871ZM25.4018 9.71998C25.1316 9.527 24.8228 9.4884 24.514 9.56559L19.5352 11.3024V17.7866L24.5526 19.4849C24.6684 19.5235 24.7842 19.5235 24.8999 19.5235C25.0813 19.5235 25.1945 19.4553 25.3677 19.3509L25.4017 19.3305C25.6719 19.1375 25.7104 18.8287 25.7104 18.4814V10.5692C25.7104 10.2218 25.6333 9.91302 25.4017 9.72003L25.4018 9.71998Z"
      fill="#636363"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M2.20502 2.20503C1.93166 2.47839 1.93166 2.92161 2.20503 3.19498L6.68368 7.67363H8.66358L3.19497 2.20503C2.92161 1.93166 2.47839 1.93166 2.20502 2.20503ZM18.3769 17.3869V18.9825C18.3769 19.1025 18.3653 19.2197 18.3432 19.3331L23.3439 24.3339C23.6173 24.6072 24.0605 24.6072 24.3339 24.3339C24.6072 24.0605 24.6072 23.6173 24.3339 23.3439L18.3769 17.3869Z"
      fill="#636363"
    />
  </svg>
</template>
