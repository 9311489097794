<template>
  <svg
    width="46"
    height="46"
    viewBox="0 0 46 46"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    name="ContactIcon"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12.506 14.266H30.326V21.581H34.374V32.614H24.958V30.986H32.746V23.209H30.326V28.434H23.6662L16.554 32.7181V28.434H12.506V14.266ZM14.134 15.894V26.806H18.182V29.8369L23.2138 26.806H28.698V15.894H14.134Z"
      fill="url(#paint0_linear)"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M23 43.24C34.1782 43.24 43.24 34.1782 43.24 23C43.24 11.8218 34.1782 2.76 23 2.76C11.8218 2.76 2.76 11.8218 2.76 23C2.76 34.1782 11.8218 43.24 23 43.24ZM23 45C35.1503 45 45 35.1503 45 23C45 10.8497 35.1503 1 23 1C10.8497 1 1 10.8497 1 23C1 35.1503 10.8497 45 23 45Z"
      fill="url(#paint1_linear)"
    />
    <defs>
      <linearGradient
        id="paint0_linear"
        x1="37.52"
        y1="3.64"
        x2="8.04"
        y2="41.92"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="var(--gradientFrom)" />
        <stop offset="1" stop-color="var(--gradientTo)" />
      </linearGradient>
      <linearGradient
        id="paint1_linear"
        x1="37.52"
        y1="3.64"
        x2="8.04"
        y2="41.92"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="var(--gradientFrom)" />
        <stop offset="1" stop-color="var(--gradientTo)" />
      </linearGradient>
    </defs>
  </svg>
</template>

<script>
export default {
  name: "ContactIcon",
};
</script>
