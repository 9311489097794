<script>
export default {
  name: "UserListParticipantLetter",
  props: ["user"],
  computed: {
    letter() {
      return this.user.attrs.username.substr(0, 1).toLocaleUpperCase();
    },
    styles() {
      return {
        backgroundColor: this.user.color,
      };
    },
  },
};
</script>

<template>
  <div class="UserListParticipantLetter" :style="styles">
    {{ letter }}
  </div>
</template>

<style scoped lang="scss">
.UserListParticipantLetter {
  border-radius: 50%;
  background-color: green;
  color: white;
  font-size: 12px;
  width: 2em;
  height: 2em;
  text-align: center;
  line-height: 2.2;
  text-transform: uppercase;
  font-weight: 700;
}
</style>
