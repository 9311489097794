export function shouldDegradeCanvas() {
  const value = localStorage.getItem("cables.mode");

  // enable if the flag is unset
  return "degraded" === value;
}

export function shouldEnableCables() {
  const value = localStorage.getItem("cables.mode");
  // enable if the flag is unset
  return "disabled" !== value;
}

export function withPatch(callback, fallback) {
  if (typeof CABLES !== "undefined" && CABLES.patch) {
    return callback(CABLES.patch);
  } else if (typeof fallback === "function") {
    return fallback();
  }
}

export function getCablesVar(name, defaultValue = null) {
  if (!name) return defaultValue;
  if (typeof CABLES !== "undefined" && CABLES.patch) {
    return CABLES.patch.getVarValue(name) || defaultValue;
  } else {
    return defaultValue;
  }
}

export function setCablesVar(name, value) {
  if (!name) return;
  if (typeof CABLES !== "undefined" && CABLES.patch) {
    CABLES.patch.setVarValue(name, value);
  }
}
