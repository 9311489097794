import { Quad, Expo, Power4 } from "gsap";
export const TweenVars = {
  duration: 0.8,
  durationSlow: 1.1,
  ease: Expo.easeInOut,
  /* ease: Back.easeInOut.config(0.6), */
};
export const ContactResizeTweenVars = {
  duration: 0.85,
  ease: Expo.easeOut,
};
export const DataProtectionTween = {
  duration: 0.75,
  ease: Expo.easeIn,
};
export const ScrollTween = {
  duration: 1.0,
  ease: Quad.easeInOut,
};
export const MouseMoveTween = {
  duration: 0.34,
  ease: Power4.easeOut,
};
export const easeMethods = {
  /* all: [
    "Back","Bounce", "Circ", "Cubic", "Elastic", "Expo", "Linear", "Power0"
    , "Power1", "Power2", "Power3", "Power4", "Quad", "Quart", "Quint", "Sine",
    "Strong"
  ] */
  all: [
    "back",
    "bounce",
    "circ",
    "cubic",
    "elastic",
    "expo",
    "linear",
    "power0",
    "power1",
    "power2",
    "power3",
    "power4",
    "quad",
    "quart",
    "quint",
    "sine",
    "strong",
  ],
};

/* export const easeMethods = {
  all: [
    easeEnum.Back, easeEnum.Bounce, easeEnum.Circ, easeEnum.Cubic, 
    easeEnum.Elastic, easeEnum.Expo, easeEnum.Linear, easeEnum.Power0
    , easeEnum.Power1, easeEnum.Power2, easeEnum.Power3, easeEnum.Power4, easeEnum.Quad, easeEnum.Quart, easeEnum.Quint, easeEnum.Sine,
    easeEnum.Strong
  ]
} */
export const easeEnum = {
  Back: "Back",
  Bounce: "Bounce",
  Circ: "Circ",
  Cubic: "Cubic",
  Elastic: "Elastic",
  Expo: "Expo",
  Linear: "Linear",
  Power0: "Power0",
  Power1: "Power1",
  Power2: "Power2",
  Power3: "Power3",
  Power4: "Power4",
  Quad: "Quad",
  Quart: "Quart",
  Quint: "Quint",
  Sine: "Sine",
  Strong: "Strong",
};
