// TODO: migrate to pinia
import { setCablesVar, withPatch } from "@/utils/cables";
import { EB } from "@/utils/EB.js";

const state = {
  debug: true,
};

// =========================== GETTERS :::::::::::::::::::::::::
const getters = {
  // login_locale: state => { return state.login_locale },
};

// ================== ACTIONS =========================
const actions = {
  /**  send from client  */
  sendParamsViaWebsox(options, _value) {
    withPatch((patch) => {
      patch.setVariable("socketCluster_broadcastData", _value);
      patch.config.sendSocketClusterBroadcastMessage();
    });
  },

  /** only moderator can send actions  */
  sendActionViaWebsox(options, _value) {
    // console.log(' X -> Websox -> sendActionViaWebsox ', _value)
    const actionName = _value.name;
    if (!actionName) {
      console.error(
        " X -> Websox -> sendActionViaWebsox -> FAILURE - NO NAME GIVEN"
      );
      return;
    }

    withPatch((patch) => {
      patch.setVariable("socketCluster_uiData", _value);
      patch.config.sendSocketClusterMessage();
    });
  },

  recieveWebsoxMessageForUI({ commit, dispatch }, _params) {
    const name = _params.name;

    switch (name) {
      case "showTooltip":
        dispatch("showTooltipAndHideLater", _params.text, { root: true });
        break;
      case "setLayout":
        EB.$emit("resizeMeetingWin", { styleNr: _params.layoutNr });
        break;
      case "wxSettings":
        // console.log('wxSettingsActions ', _params.action)
        switch (_params.action.name) {
          case "playSound":
            commit("setBgSound", _params.action.value);
            break;
          case "localeChanged":
            setCablesVar("currentLang", _params.action.value);
            break;
        }
        break;
      case "log":
        console.log(" X - websox : log recieved: ", _params.value);
        break;
      default:
        break;
    }
  },
};

const mutations = {
  setBgSound(state, _value) {
    setCablesVar("playSound", _value);
  },

  setLang(state, _lang) {
    state.lang = _lang;
  },

  setLangData(state, _data) {
    state.locale = _data;
  },

  setLoginLangData(state, _data) {
    state.login_locale = _data;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
