<template>
  <svg
    width="51"
    height="50"
    viewBox="0 0 51 50"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    name="PromiseIcon4"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M25.998 48C38.7006 48 48.998 37.7025 48.998 25C48.998 12.2975 38.7006 2 25.998 2C13.2955 2 2.99805 12.2975 2.99805 25C2.99805 37.7025 13.2955 48 25.998 48ZM25.998 50C39.8052 50 50.998 38.8071 50.998 25C50.998 11.1929 39.8052 0 25.998 0C12.1909 0 0.998047 11.1929 0.998047 25C0.998047 38.8071 12.1909 50 25.998 50Z"
      fill="url(#paint0_linear_3543_1001)"
    />
    <path
      d="M31.0203 18.7459C29.0627 17.1991 26.5364 16.6612 24.073 17.2299C21.1989 17.8936 18.8948 20.167 18.1988 23.0397C17.4093 26.2298 18.5146 29.4504 21.1038 31.4402C21.2937 31.5981 21.4195 31.8818 21.4195 32.1976V36.5235C21.4195 37.4079 22.1461 38.1345 23.0305 38.1345H24.0408C24.3245 38.9869 25.0831 39.5876 26.0305 39.5876C26.9778 39.5876 27.7352 38.956 28.0202 38.1345H29.0304C29.9149 38.1345 30.6414 37.4079 30.6414 36.5235V32.1666C30.6414 31.8829 30.7364 31.63 30.9252 31.4722C32.9457 29.9253 34.1152 27.5877 34.1152 25.0936C34.0844 22.5982 32.9471 20.2929 31.0203 18.7459ZM23.3477 36.1769V34.3451H28.6531L28.6519 36.1769H23.3477ZM29.7262 29.924C29.0625 30.4298 28.653 31.2501 28.653 32.1666V32.4195H23.3475V32.1987C23.3475 31.2834 22.9368 30.4298 22.2744 29.8932C20.3168 28.3772 19.4644 25.9459 20.0639 23.4825C20.5992 21.3349 22.336 19.6302 24.5158 19.1243C26.4105 18.6828 28.3374 19.0923 29.8213 20.2617C31.3052 21.4298 32.1576 23.1666 32.1576 25.0613C32.1255 26.9868 31.2422 28.756 29.7262 29.924Z"
      fill="url(#paint1_linear_3543_1001)"
    />
    <path
      d="M25.9995 15.0518C26.536 15.0518 26.9789 14.6102 26.9789 14.0723V11.0095C26.9789 10.4729 26.5373 10.03 25.9995 10.03C25.4629 10.03 25.02 10.4716 25.02 11.0095V14.0723C25.02 14.6089 25.4628 15.0518 25.9995 15.0518Z"
      fill="url(#paint2_linear_3543_1001)"
    />
    <path
      d="M18.9573 17.9566C19.336 17.5779 19.336 16.9463 18.9573 16.5676L16.7789 14.3892C16.4002 14.0106 15.7687 14.0106 15.39 14.3892C15.0113 14.7679 15.0113 15.3995 15.39 15.7782L17.5684 17.9566C17.7583 18.1466 18.01 18.2403 18.2629 18.2403C18.5157 18.2403 18.7686 18.1453 18.9573 17.9566Z"
      fill="url(#paint3_linear_3543_1001)"
    />
    <path
      d="M15.0423 24.0515H11.9794C11.4429 24.0515 11 24.4931 11 25.031C11 25.5675 11.4416 26.0104 11.9794 26.0104H15.0423C15.5789 26.0104 16.0217 25.5688 16.0217 25.031C16.0205 24.4931 15.5789 24.0515 15.0423 24.0515Z"
      fill="url(#paint4_linear_3543_1001)"
    />
    <path
      d="M40.0203 24.0515H36.9575C36.4209 24.0515 35.978 24.4931 35.978 25.031C35.978 25.5675 36.4196 26.0104 36.9575 26.0104H40.0203C40.5569 26.0104 40.9998 25.5688 40.9998 25.031C40.9998 24.4931 40.5582 24.0515 40.0203 24.0515Z"
      fill="url(#paint5_linear_3543_1001)"
    />
    <path
      d="M36.6109 14.4202C36.2322 14.0416 35.6006 14.0416 35.222 14.4202L33.0423 16.5987C32.6636 16.9773 32.6636 17.6089 33.0423 17.9876C33.2323 18.1776 33.4839 18.2713 33.7368 18.2713C33.9897 18.2713 34.2425 18.1763 34.4313 17.9876L36.6097 15.8092C36.9896 15.3984 36.9896 14.7989 36.6109 14.4202Z"
      fill="url(#paint6_linear_3543_1001)"
    />
    <path
      d="M29.2835 23.6086L27.5788 23.4508C27.3888 23.4187 27.2001 23.3249 27.1372 23.135L26.4735 21.556C26.2836 21.1145 25.6841 21.1145 25.4941 21.556L24.8305 23.135C24.7675 23.3249 24.5776 23.4508 24.3889 23.4508L22.6841 23.5766C22.2105 23.6086 22.0205 24.2081 22.4004 24.4918L23.6956 25.5971C23.8535 25.7229 23.9164 25.9129 23.8535 26.1028L23.4736 27.8088C23.3786 28.2504 23.8523 28.6304 24.2631 28.3775L25.7162 27.493C25.8741 27.398 26.0949 27.398 26.2528 27.493L27.7059 28.3775C28.1166 28.6303 28.5903 28.2516 28.4953 27.8088L28.0846 26.1349C28.0525 25.9449 28.1166 25.7562 28.2425 25.6291L29.5377 24.5239C29.9472 24.2093 29.7572 23.6407 29.2835 23.6086Z"
      fill="url(#paint7_linear_3543_1001)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_3543_1001"
        x1="38.998"
        y1="-1.53422e-06"
        x2="12.498"
        y2="50"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="var(--gradientFrom)" />
        <stop offset="1" stop-color="var(--gradientTo)" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_3543_1001"
        x1="38.998"
        y1="-1.53422e-06"
        x2="12.498"
        y2="50"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="var(--gradientFrom)" />
        <stop offset="1" stop-color="var(--gradientTo)" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_3543_1001"
        x1="38.998"
        y1="-1.53422e-06"
        x2="12.498"
        y2="50"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="var(--gradientFrom)" />
        <stop offset="1" stop-color="var(--gradientTo)" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_3543_1001"
        x1="38.998"
        y1="-1.53422e-06"
        x2="12.498"
        y2="50"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="var(--gradientFrom)" />
        <stop offset="1" stop-color="var(--gradientTo)" />
      </linearGradient>
      <linearGradient
        id="paint4_linear_3543_1001"
        x1="38.998"
        y1="-1.53422e-06"
        x2="12.498"
        y2="50"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="var(--gradientFrom)" />
        <stop offset="1" stop-color="var(--gradientTo)" />
      </linearGradient>
      <linearGradient
        id="paint5_linear_3543_1001"
        x1="38.998"
        y1="-1.53422e-06"
        x2="12.498"
        y2="50"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="var(--gradientFrom)" />
        <stop offset="1" stop-color="var(--gradientTo)" />
      </linearGradient>
      <linearGradient
        id="paint6_linear_3543_1001"
        x1="38.998"
        y1="-1.53422e-06"
        x2="12.498"
        y2="50"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="var(--gradientFrom)" />
        <stop offset="1" stop-color="var(--gradientTo)" />
      </linearGradient>
      <linearGradient
        id="paint7_linear_3543_1001"
        x1="38.998"
        y1="-1.53422e-06"
        x2="12.498"
        y2="50"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="var(--gradientFrom)" />
        <stop offset="1" stop-color="var(--gradientTo)" />
      </linearGradient>
    </defs>
  </svg>
</template>

<script>
export default {
  name: "PromiseIcon4",
};
</script>
