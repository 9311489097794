<template>
  <svg viewBox="0 0 31 23" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M15.0191 3.98713C12.6229 3.98713 10.6701 5.98683 10.6701 8.43901C10.6701 10.8926 12.6228 12.8947 15.0191 12.8947C17.4153 12.8947 19.368 10.8925 19.368 8.43901C19.368 5.98668 17.4153 3.98713 15.0191 3.98713ZM5.38696 2.60156C4.05778 2.60156 2.96787 3.71333 2.96787 5.07368C2.96787 6.43403 4.05778 7.54456 5.38696 7.54456C6.71613 7.54456 7.80242 6.43403 7.80242 5.07368C7.80242 3.71333 6.71613 2.60156 5.38696 2.60156ZM25.6178 7.75465C24.2887 7.75465 23.2036 8.86641 23.2036 10.2268C23.2036 11.5871 24.2887 12.6976 25.6178 12.6976C26.947 12.6976 28.0381 11.5871 28.0381 10.2268C28.0381 8.86641 26.947 7.75465 25.6178 7.75465ZM11.1512 13.8793C9.82077 13.8793 8.7369 14.9947 8.7369 16.3563V19.3244C8.7369 20.1381 9.38941 20.8071 10.1845 20.8071H19.8549C20.6487 20.8071 21.3025 20.1381 21.3025 19.3244V16.3563C21.3025 14.9947 20.2174 13.8793 18.887 13.8793H11.1512ZM4.42029 8.53504C3.08991 8.53504 2 9.64928 2 11.0109V12.0002C2 12.8139 2.65854 13.483 3.45362 13.483H7.32153C8.1154 13.483 8.76912 12.8139 8.76912 12.0002V11.0109C8.76912 9.64928 7.68524 8.53504 6.35486 8.53504H4.42029ZM24.6512 13.6881C23.3208 13.6881 22.2357 14.8024 22.2357 16.164V17.1533C22.2357 17.967 22.8894 18.6361 23.6845 18.6361H27.5524C28.3475 18.6361 29 17.967 29 17.1533V16.164C29 14.8024 27.9149 13.6881 26.5845 13.6881H24.6512Z"
      fill="#636363"
    />
  </svg>
</template>
