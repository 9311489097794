<template>
  <div class="p-0">
    <video
      alt=""
      class="video-fluid z-depth-1"
      controls="false"
      ref="videoEl"
      @play="$emit('play')"
      @pause="$emit('pause')"
      @seeked="$emit('seeked')"
    >
      <source :src="src" type="video/mp4" />
    </video>
  </div>
</template>

<script>
export default {
  name: "OverlayVideo",
  emits: ["play", "pause", "seek"],
  props: ["src"],

  methods: {
    play() {
      this.$refs.videoEl.play();
    },

    pause() {
      this.$refs.videoEl.pause();
    },

    seek(time) {
      this.$refs.videoEl.currentTime = time;
    },
  },
};
</script>

<style scoped lang="scss">
.video-fluid {
  width: 100%;
  height: auto;
}
</style>
