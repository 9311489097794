<template>
  <div class="autocomplete tfbb" style="pointer-events: all">
    <div
      class="d-flex align-items-center justify-content-between p0 mr15"
      style="height: 100%"
    >
      <div class="p-0 flex-fill">
        <input
          class="searchTextField"
          style="-webkit-user-select: auto; pointer-events: all"
          :disabled="disabled"
          type="text"
          :placeholder="placeHolder"
          @input="onChange"
          v-model="search"
          @click="firstTap"
          @keydown.down="onArrowDown"
          @keydown.up="onArrowUp"
          @keydown.enter="onEnter"
        />
      </div>

      <div
        class="p-0"
        :class="{ collapseOpen: isOpen, collapseClosed: !isOpen }"
        @click="firstTap"
      >
        <div class="collapseIcon _desk" v-if="!isMobile">
          <img
            src="/assets/ui/ui_select_toggle_desk.svg"
            :class="{
              toogleActiveOpacity: !disabled,
              tooglePassiveOpacity: disabled,
            }"
          />
        </div>
        <div class="collapseIcon _mobile" v-if="isMobile">
          <img
            src="/assets/ui/ui_select_toggle_mobile.svg"
            :class="{
              toogleActiveOpacity: !disabled,
              tooglePassiveOpacity: disabled,
            }"
          />
        </div>
      </div>
    </div>

    <ul id="autocomplete-results" v-show="isOpen" class="autocomplete-results">
      <li class="loading" v-if="isLoading">Loading results...</li>
      <li
        v-else
        v-for="(result, i) in results"
        :key="i"
        @click="setResult(result.value, result)"
        class="autocomplete-result"
        :class="{ 'is-active': i === arrowCounter }"
      >
        {{ result.value }}
      </li>
    </ul>
  </div>
</template>

<script>
import { AutoCompleteType } from "@/utils/enum.js";
import { mapGetters } from "vuex";

export default {
  name: "SearchAutocomplete",
  props: [
    "placeHolder",
    "items",
    "resize",
    "isAsync",
    "change",
    "disabled",
    "type",
    "selectedValue",
  ],

  data() {
    return {
      isOpen: false,
      results: [],
      search: "",
      isLoading: false,
      arrowCounter: -1,
      resizeTimeout: null,
    };
  },

  computed: {
    ...mapGetters(["isMobile"]),
  },

  watch: {
    selectedValue: function (value, oldValue) {
      if (value !== oldValue) {
        this.search = value;
      }
    },
    items: function (value, oldValue) {
      if (value.length !== oldValue.length) {
        this.results = value;
        this.isLoading = false;
      }
    },
  },
  mounted() {
    if (this.type == AutoCompleteType.COUNTRY) {
      this.search = this.selectedValue;
    }
    document.addEventListener("click", this.handleClickOutside);
  },
  destroyed() {
    document.removeEventListener("click", this.handleClickOutside);
  },
  methods: {
    firstTap() {
      console.log(this.search);
      if (this.disabled) {
        return;
      }
      this.isOpen = true;

      if (this.isOpen) {
        this.search = "";
        this.change(this.search);
        this.$emit("open", this.type);
        this.filterResults();
      }
      console.log(this.search);
    },
    aspects() {
      if (this.resizeTimeout) {
        clearTimeout(this.resizeTimeout);
      }
      this.resizeTimeout = setTimeout(() => {
        this.resize();
      }, 500);
    },
    setResult(result, results) {
      if (this.type == AutoCompleteType.COUNTRY) {
        this.search = results.value;
      } else {
        this.search = result;
      }
      this.isOpen = false;
      console.log(result);
      console.log(results);
      if (this.type == AutoCompleteType.COUNTRY) {
        this.change(results);
      } else {
        this.change(this.search);
      }

      this.aspects();
    },
    filterResults() {
      if (this.search == "" || this.search == null) {
        this.results = this.items;
      } else {
        this.results = this.items.filter((item) => {
          return (
            item.value.toLowerCase().indexOf(this.search.toLowerCase()) > -1
          );
        });
      }
    },
    onChange() {
      this.$emit("input", this.search);

      if (this.isAsync) {
        this.isLoading = true;
      } else {
        this.filterResults();
        this.isOpen = true;
        /* this.resize() */
      }
      this.aspects();
    },
    handleClickOutside(event) {
      if (!this.$el.contains(event.target)) {
        this.isOpen = false;
        this.arrowCounter = -1;
      }
      this.aspects();
    },
    onArrowDown() {
      if (this.arrowCounter < this.results.length) {
        this.arrowCounter = this.arrowCounter + 1;
      }
    },
    onArrowUp() {
      if (this.arrowCounter > 0) {
        this.arrowCounter = this.arrowCounter - 1;
      }
    },
    onEnter() {
      this.search = this.results[this.arrowCounter]["value"];
      this.change(this.search);
      this.aspects();
      this.isOpen = false;
      this.arrowCounter = -1;
      /* setTimeout(()=>{
        },100) */
    },
  },
};
</script>

<style>
/*    *,
*::before,
*::after {
  -webkit-user-select: auto;
  pointer-events: all;
}  */

.autocomplete {
  position: relative;

  /* height: 40px!important; */
  /* border: 1px solid deeppink!important; */
}

.autocomplete-results {
  padding: 0;
  margin: 0;
  /* margin-left: 10px; */
  /*  margin-right: 10px; */
  list-style-type: none !important;
  list-style: none !important;
  /* border: 1px solid #eeeeee; */
  height: 100%;
  min-height: 80px;
  max-height: 180px;

  overflow: auto;
  border-radius: 2px;
  backdrop-filter: blur(2px);
  background-color: rgba(255, 255, 255, 0.85);
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.autocomplete-results::-webkit-scrollbar {
  width: 4px;
}
.autocomplete-results::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(91, 91, 91, 0.45);
}

.autocomplete-result {
  text-align: left;
  /* padding: 4px 8px; */
  margin-left: 8px;
  margin-right: 6px;
  list-style-type: none !important;
  list-style: none !important;
  cursor: pointer;
  background-color: rgba(91, 91, 91, 0);
  transition: background-color 0.45s ease-out;
}

.autocomplete-result.is-active,
.autocomplete-result:hover {
  border-radius: 2px;
  background-color: rgba(91, 91, 91, 0.15);
  transition: background-color 0.45s ease-out;
  /* background-color: #4AAE9B;
    color: white; */
}
@media (orientation: landscape) {
  .autocomplete-result {
    /* padding: 4px 4px; */
    padding-top: 6px;
    padding-bottom: 2px;
  }
  li {
    /* border: 1px solid deeppink!important; */
    font-size: 13px;
    margin-top: 4px;
    margin-bottom: 1px !important;
  }
}

@media (orientation: portrait) {
  .autocomplete-result {
    padding-top: 6px;
    padding-bottom: 2px;
  }
  li {
    font-size: 16px;
    margin-top: 4px;
    margin-bottom: 1px !important;
  }
}
.collapseOpen {
  cursor: pointer;
  transform: rotate(180deg);
  transition: all 0.25s ease-in-out;
}
.searchTextField {
  width: calc(100% - 50px) !important;
}
.collapseClosed {
  cursor: pointer;
  transform: rotate(0);
  transition: all 0.25s ease-in-out;
}
._desk {
  width: 12px;
  height: 12px;
}
._mobile {
  width: 18px;
  height: 18px;
}
.toogleActiveOpacity {
  opacity: 1;
  transition: all 0.25s ease-in-out;
}
.tooglePassiveOpacity {
  opacity: 0.4;
  transition: all 0.25s ease-in-out;
}
input::placeholder {
  color: rgba(0, 0, 0, 0.2) !important;
}
input:disabled::placeholder {
  color: rgba(0, 0, 0, 0.1) !important;
}
</style>
