<template>
  <div id="_navItem" class="navItem" @click="$emit('click')">
    <div
      v-wave
      class="text-center _productDetailItem"
      :class="{
        mobileHeight: isMobile === true,
        deskHeight: isMobile === false,
      }"
    >
      <div class="pCenter">
        <div class="pt2">{{ headline }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "ProductListItem",
  emits: ["click"],
  props: ["headline"],

  computed: {
    ...mapGetters(["isMobile"]),
  },
};
</script>

<style scoped>
._productDetailItem {
  cursor: pointer;
  font-family: "Univers LT Std";

  border: 1px solid rgba(99, 99, 99, 0.45);
  border-radius: 3px;
  text-align: center;
  width: 100%;

  color: #636363;
  font-weight: 400;
  display: table;
  background-color: rgba(0, 0, 0, 0);
  transition: background-color 0.45s ease-in-out;
}
._productDetailItem:hover {
  background-color: rgba(0, 0, 0, 0.05);
  transition: background-color 0.25s ease-in-out;
}
.lastItem {
  margin-bottom: 15px;
}
.pCenter {
  height: 100%;
  display: table-cell;
  vertical-align: middle;
}
.mobileHeight {
  height: 40px !important;
  font-size: 16px !important;
}
.deskHeight {
  height: 30px !important;
  font-size: 13px !important;
}
</style>
