<template>
  <svg
    width="50"
    height="50"
    viewBox="0 0 50 50"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    role="img"
    name="BenefitsIcon"
  >
    <circle
      cx="25"
      cy="25"
      r="24"
      stroke="url(#paint0_linear_4405_546)"
      stroke-width="2"
    />
    <path
      d="M13.9251 25.2346L12.9285 18.0555C12.9285 18.0555 12.8432 17.0699 11.9245 16.9678C11.1714 16.8841 10.6694 17.1351 10.4184 17.8882C10.4184 17.8045 10 28.3472 10 28.3472L13.4306 36.8818L13.3896 40.0806"
      stroke="url(#paint1_linear_4405_546)"
      stroke-width="2"
      stroke-miterlimit="10"
      stroke-linejoin="round"
    />
    <path
      d="M18.9414 40.0723V37.6759C18.9414 37.6759 21.5052 33.9107 19.497 30.8985L16.3175 26.4638C16.3175 26.4638 14.8114 24.4557 12.8032 25.5434C12.8032 25.4598 15.146 32.9066 15.146 32.9066"
      stroke="url(#paint2_linear_4405_546)"
      stroke-width="2"
      stroke-miterlimit="10"
      stroke-linejoin="round"
    />
    <path
      d="M36.3373 25.2788L37.3334 18.1027C37.3334 18.1027 37.4187 17.1174 38.3371 17.0154C39.0898 16.9317 39.5916 17.1826 39.8426 17.9354C39.8426 17.8517 40.2607 28.3901 40.2607 28.3901L36.8316 36.9211L36.8726 40.1186"
      stroke="url(#paint3_linear_4405_546)"
      stroke-width="2"
      stroke-miterlimit="10"
      stroke-linejoin="round"
    />
    <path
      d="M31.3233 40.1102V37.7148C31.3233 37.7148 28.7607 33.9511 30.768 30.9402L33.9462 26.5074C33.9462 26.5074 35.4517 24.5001 37.459 25.5873C37.459 25.5037 35.1171 32.9475 35.1171 32.9475"
      stroke="url(#paint4_linear_4405_546)"
      stroke-width="2"
      stroke-miterlimit="10"
      stroke-linejoin="round"
    />
    <path
      d="M25 10L26.7634 13.5729L30.7063 14.1459L27.8532 16.9271L28.5267 20.8541L25 19L21.4733 20.8541L22.1468 16.9271L19.2937 14.1459L23.2366 13.5729L25 10Z"
      stroke="url(#paint5_linear_4405_546)"
      stroke-width="2"
      stroke-miterlimit="10"
    />
    <defs>
      <linearGradient
        id="paint0_linear_4405_546"
        x1="-2.70516e-06"
        y1="50"
        x2="49.471"
        y2="-0.518004"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="var(--gradientFrom)" />
        <stop offset="1" stop-color="var(--gradientTo)" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_4405_546"
        x1="10"
        y1="40.0806"
        x2="17.7019"
        y2="38.7459"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="var(--gradientFrom)" />
        <stop offset="1" stop-color="var(--gradientTo)" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_4405_546"
        x1="12.8032"
        y1="40.0723"
        x2="24.7424"
        y2="33.9468"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="var(--gradientFrom)" />
        <stop offset="1" stop-color="var(--gradientTo)" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_4405_546"
        x1="40.2607"
        y1="40.1186"
        x2="32.562"
        y2="38.7844"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="var(--gradientFrom)" />
        <stop offset="1" stop-color="var(--gradientTo)" />
      </linearGradient>
      <linearGradient
        id="paint4_linear_4405_546"
        x1="37.459"
        y1="40.1102"
        x2="25.5248"
        y2="33.9872"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="var(--gradientFrom)" />
        <stop offset="1" stop-color="var(--gradientTo)" />
      </linearGradient>
      <linearGradient
        id="paint5_linear_4405_546"
        x1="19"
        y1="22"
        x2="30.873"
        y2="9.87568"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="var(--gradientFrom)" />
        <stop offset="1" stop-color="var(--gradientTo)" />
      </linearGradient>
    </defs>
  </svg>
</template>

<script>
export default {
  name: "BenefitsIcon",
};
</script>
