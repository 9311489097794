<script>
import { gsap } from "gsap";
import { TweenVars } from "@/utils/tweenTime.js";
import OverlayHeader from "@/components/klein/OverlayHeader";
import UserListRow from "./UserList/UserListRow";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "OverlayUserList",
  components: {
    OverlayHeader,
    UserListRow,
  },
  emits: ["kickUser", "toggleRemoteMic", "close"],
  data() {
    return {};
  },

  mounted() {
    this.fadeIn();
  },

  computed: {
    ...mapGetters([
      "locale",
      "myself",
      "moderatorUser",
      "users",
      "otherUsers",
      "isModerator",
      "isMobile",
    ]),
    title(getters) {
      return `${getters.locale.meeting_participants} (${
        getters.users.length + 1
      })`;
    },
    usersSorted(getters) {
      return (
        getters.otherUsers?.sort((a, b) =>
          a.attrs.username.localeCompare(b.attrs.username)
        ) ?? []
      );
    },
    maybeToggleMic(getters) {
      return getters.isModerator ? "toggleMic" : null;
    },
    maybeKickUser(getters) {
      return getters.isModerator ? "kickUser" : null;
    },
  },

  methods: {
    ...mapActions(["resetOverlayVisibility"]),

    kickUser(user) {
      this.$emit("kickUser", user);
    },

    toggleRemoteMic(user) {
      this.$emit("toggleRemoteMic", user);
    },

    fadeIn() {
      const position = this.isMobile
        ? {
            top: 0,
          }
        : {
            bottom: 56,
          };

      gsap.to("#innerTextmessageOverlay", {
        ...position,
        opacity: 1,
        duration: TweenVars.duration,
        ease: TweenVars.ease,
      });
    },
    closeOverlay: function () {
      gsap.to("#innerTextmessageOverlay", {
        opacity: 0,
        duration: TweenVars.duration,
        ease: TweenVars.ease,
        ...(this.isMobile
          ? { top: -this.$store.state.constrains.screenHeight / 3 }
          : { top: this.$store.state.constrains.screenHeight / 3 }),
      });
      setTimeout(() => {
        this.resetOverlayVisibility({ userList: false });
        this.$emit("close");
      }, 500);
    },
  },
};
</script>

<template>
  <div class="overlay" :class="{ isMobile }">
    <div id="innerTextmessageOverlay" class="innerOverlay">
      <OverlayHeader :title="title" :closeIcon="true" @close="closeOverlay" />

      <section class="body">
        <ul class="list">
          <UserListRow v-if="moderatorUser" :user="moderatorUser" />

          <UserListRow v-if="myself" :user="myself" />

          <UserListRow
            v-for="user in usersSorted"
            :key="user.uid"
            :user="user"
            @[maybeKickUser]="kickUser"
            @[maybeToggleMic]="toggleRemoteMic"
          />
        </ul>
      </section>
    </div>
  </div>
</template>

<style scoped lang="scss">
.list {
  padding-inline-start: 0;
  display: flex;
  flex-direction: column;
  gap: 4px;
  max-height: 260px;
  overflow: auto;
}

#innerTextmessageOverlay {
  position: absolute;
  height: auto !important;
  bottom: 0;
  opacity: 0;
  backdrop-filter: blur(5px) !important;
  background-color: rgba(255, 255, 255, 0.65);
  width: 300px;
  right: 5%;
}

.isMobile #innerTextmessageOverlay {
  width: auto;
  top: -100vh;
  left: 16px;
  right: 16px;
  bottom: auto;
}
</style>
