import { BasePanelState } from "@/utils/enum.js";

export const store = {
  state: {
    subMenuListOpen: false,
    isMainScreen: true,
    currentProductOverlayParams: null,
    basePanelState: BasePanelState.CLOSED,
  },
};
