<template>
  <div style="pointer-events: all; overflow: hidden">
    <div
      class="p-0 ml15 mr15 mt15 mb10"
      :class="{ fontMobile: isMobile === true, fontDesk: isMobile === false }"
    >
      {{ contact_locale.copy }}
    </div>
    <div class="p-0 tfbb tfbt pRelative">
      <div class="d-flex align-items-center ml15">
        <div class="p-0 mr15 mr15 mb15 mt15">
          <div class="cgRadio" @click="setRequestEmail">
            <div
              class="cgRadioInner"
              :class="{
                cgRadioPassive: requestEmail === false,
                cgRadioActive: requestEmail === true,
              }"
            ></div>
          </div>
        </div>
        <div
          class="p-0 mt1"
          :class="{
            fontMobile: isMobile === true,
            fontDesk: isMobile === false,
          }"
        >
          {{ contact_locale.label1 }}
        </div>
      </div>
      <div class="selectionWarn" v-if="showError">
        {{ contact_locale.label1Error }}
      </div>
    </div>
    <div class="p-0 tfbb pRelative">
      <div class="d-flex align-items-center">
        <div class="p-0 ml15 mr15 mb15 mt15">
          <div class="cgRadio" @click="setRequestMeeting">
            <div
              class="cgRadioInner"
              :class="{
                cgRadioPassive: requestMeeting === false,
                cgRadioActive: requestMeeting === true,
              }"
            ></div>
          </div>
        </div>
        <div
          class="p-0 mt1"
          :class="{
            fontMobile: isMobile === true,
            fontDesk: isMobile === false,
          }"
        >
          {{ contact_locale.label2 }}
        </div>
      </div>
      <div class="selectionWarn" v-if="showError">
        {{ contact_locale.label1Error }}
      </div>
    </div>
    <div class="p-0 d-flex ml15 mr15 mt20 mb20 justify-content-center">
      <div
        v-wave
        class="contact-next-button"
        :class="{ contextNextClickable: !nextDisabled }"
        @click="nextTapped"
      >
        <div class="contact-next-button-text">
          {{ contact_locale.buttonCopy }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { OverlaySteps } from "@/utils/enum.js";
import { mapGetters } from "vuex";
import { mapActions } from "vuex";
export default {
  name: "ContactRequestType",
  props: ["next", "requestChanged", "resize"],
  data() {
    return {
      requestEmail: false,
      requestMeeting: false,
      showError: false,
      stepType: OverlaySteps.RESPONSETYPE,
    };
  },

  computed: {
    ...mapGetters(["isMobile", "getInTouch", "contact_locale"]),
    nextDisabled: function () {
      if (this.requestEmail === true || this.requestMeeting === true) {
        return false;
      } else {
        return true;
      }
    },
  },
  methods: {
    ...mapActions([]),
    setRequestMeeting() {
      this.showError = false;
      this.requestEmail = false;
      this.requestMeeting = true;
      this.requestChanged("requestMeeting");
    },
    setRequestEmail() {
      this.showError = false;
      this.requestEmail = true;
      this.requestMeeting = false;
      this.requestChanged("requestEmail");
    },
    nextTapped() {
      if (this.nextDisabled) {
        this.showError = true;
      }
      if (!this.nextDisabled) {
        this.next(
          this.stepType,
          this.requestEmail === true
            ? OverlaySteps.ADD_FORMDATA
            : OverlaySteps.DATESELECTION
        );
      }
    },
  },
  created() {
    if (
      this.getInTouch.responseType !== null &&
      this.getInTouch.responseType !== ""
    ) {
      if (this.getInTouch.responseType === "requestEmail") {
        this.requestEmail = true;
        this.requestMeeting = false;
      } else if (this.getInTouch.responseType === "requestMeeting") {
        this.requestEmail = false;
        this.requestMeeting = true;
      }
    }
  },
  mounted() {
    setTimeout(() => {
      this.resize();
    }, 1000);
  },
};
</script>

<style scoped>
@media (orientation: landscape) {
  .radioTopSpace {
    margin-top: 15px;
  }
  .cgRadio {
    cursor: pointer;
    position: relative;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 1px solid rgba(99, 99, 99, 0.25);
  }
  .cgRadioInner {
    position: absolute;
    width: 14px;
    height: 14px;
    border-radius: 50%;
    left: 3px;
    top: 3px;
  }
}
@media (orientation: portrait) {
  .radioTopSpace {
    margin-top: 17px;
    padding-bottom: 17px !important;
  }
  .cgRadio {
    cursor: pointer;
    position: relative;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    border: 1px solid rgba(99, 99, 99, 0.25);
  }
  .cgRadioInner {
    position: absolute;
    width: 22px;
    height: 22px;
    border-radius: 50%;
    left: 4px;
    top: 4px;
  }
}
.cgRadioActive {
  background-color: rgba(99, 99, 99, 1);

  transition: all 0.25s ease-out;
}
.cgRadioPassive {
  background-color: rgba(99, 99, 99, 0);

  transition: all 0.25s ease-out;
}
.fontMobile {
  line-height: 20px;
}
.selectionWarn {
  position: absolute;
  bottom: -3px;
  left: 15px;
  font-size: 12px;
  color: #ff0000;
}
</style>
