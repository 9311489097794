export const breakpoints = {
  landscapePanelWidth: 275,
  maxPortraitPanelWidth: 450,
  tabletMinLandscapeWidth: 1024,
  maxPhoneWidth: 440, //414,
  minDeskRightPadding: 25,
  maxDeskRightPadding: 55,
  maxRightPaddingDeskWidth: 1440,
  minMobileHorizontalPadding: 15,
  minPhoneTopPadding: 220,
  minDeskTopPadding: 50,
  //minDeskBottomPadding: 50,
  minDeskBottomPadding: 150,
  headerHeight: 60,
};

export const meeting_video_size = {
  landscape: [
    {
      meetingWinStyleNr: 0,
      width: 112,
      height: 150,
      startTop: 45,
      startLeft: 20,
    },
    {
      meetingWinStyleNr: 1,
      breakpoints: [
        /*{maxWidth: 1440, minWidth: 1024, camWidth: 112, camHeight:150, startTop: 45,  startLeft: 70},
        {maxWidth: 2400, minWidth: 1440, camWidth: 150, camHeight:213, startTop: 45,  startLeft: 70},
        {maxWidth: 3840, minWidth: 2400, camWidth: 150, camHeight:213, startTop: 45,  startLeft: 70},
        {maxWidth: 8000, minWidth: 3840, camWidth: 150, camHeight:213, startTop: 45,  startLeft: 70},*/
        {
          maxWidth: 1440,
          minWidth: 1024,
          camWidth: 112,
          camHeight: 150,
          startTop: 45,
          startLeft: 75,
        },
        {
          maxWidth: 2400,
          minWidth: 1440,
          camWidth: 112,
          camHeight: 150,
          startTop: 45,
          startLeft: 75,
        },
        {
          maxWidth: 3840,
          minWidth: 2400,
          camWidth: 112,
          camHeight: 150,
          startTop: 45,
          startLeft: 75,
        },
        {
          maxWidth: 8000,
          minWidth: 3840,
          camWidth: 112,
          camHeight: 150,
          startTop: 45,
          startLeft: 75,
        },
      ],
    },
    {
      meetingWinStyleNr: 2,
      breakpoints: [
        {
          maxWidth: 1440,
          minWidth: 1024,
          camWidth: 347,
          camHeight: 195,
          startTop: 45,
          startLeft: 75,
        },
        {
          maxWidth: 2400,
          minWidth: 1440,
          camWidth: 500,
          camHeight: 281,
          startTop: 45,
          startLeft: 75,
        },
        {
          maxWidth: 3840,
          minWidth: 2400,
          camWidth: 720,
          camHeight: 405,
          startTop: 45,
          startLeft: 75,
        },
        {
          maxWidth: 8000,
          minWidth: 3840,
          camWidth: 949,
          camHeight: 534,
          startTop: 45,
          startLeft: 75,
        },
      ],
    },
    {
      meetingWinStyleNr: 3,
      breakpoints: [
        {
          maxWidth: 1440,
          minWidth: 1024,
          camWidth: 482,
          camHeight: 292,
          startTop: 45,
          startLeft: 75,
        },
        {
          maxWidth: 2400,
          minWidth: 1440,
          camWidth: 660,
          camHeight: 400,
          startTop: 45,
          startLeft: 75,
        },
        {
          maxWidth: 3840,
          minWidth: 2400,
          camWidth: 1030,
          camHeight: 624,
          startTop: 45,
          startLeft: 75,
        },
        {
          maxWidth: 8000,
          minWidth: 3840,
          camWidth: 1437,
          camHeight: 870,
          startTop: 45,
          startLeft: 75,
        },
      ],
    },
    /*width: 112, height: 150, startTop: 45,  startLeft: 70 },*/

    /*{meetingWinStyleNr: 2, width: 500, height: 375, startTop: 150, startLeft: 20 },
    {meetingWinStyleNr: 3, width: 679, height: 380, startTop: 150, startLeft: 20 }, */
  ],

  portrait: [
    {
      meetingWinStyleNr: 0,
      breakpoints: [
        {
          maxWidth: 1440,
          minWidth: 300,
          camWidth: 112,
          camHeight: 150,
          startTop: 60,
          startLeft: 20,
        },
        {
          maxWidth: 2400,
          minWidth: 1440,
          camWidth: 112,
          camHeight: 150,
          startTop: 60,
          startLeft: 70,
        },
        {
          maxWidth: 3840,
          minWidth: 2400,
          camWidth: 150,
          camHeight: 213,
          startTop: 60,
          startLeft: 20,
        },
        {
          maxWidth: 8000,
          minWidth: 3840,
          camWidth: 269,
          camHeight: 382,
          startTop: 60,
          startLeft: 20,
        },
      ],
    },
    {
      meetingWinStyleNr: 1,
      breakpoints: [
        {
          maxWidth: 1440,
          minWidth: 300,
          camWidth: 68,
          camHeight: 121,
          startTop: 60,
          startLeft: 20,
        },
        {
          maxWidth: 2400,
          minWidth: 1440,
          camWidth: 68,
          camHeight: 121,
          startTop: 60,
          startLeft: 70,
        },
        {
          maxWidth: 3840,
          minWidth: 2400,
          camWidth: 68,
          camHeight: 121,
          startTop: 60,
          startLeft: 20,
        },
        {
          maxWidth: 8000,
          minWidth: 3840,
          camWidth: 68,
          camHeight: 121,
          startTop: 60,
          startLeft: 20,
        },
      ],
    },
    {
      meetingWinStyleNr: 2,
      breakpoints: [
        {
          maxWidth: 1440,
          minWidth: 300,
          camWidth: 140,
          camHeight: 248,
          startTop: 45,
          startLeft: 20,
        },
        {
          maxWidth: 2400,
          minWidth: 1440,
          camWidth: 140,
          camHeight: 248,
          startTop: 45,
          startLeft: 70,
        },
        {
          maxWidth: 3840,
          minWidth: 2400,
          camWidth: 140,
          camHeight: 248,
          startTop: 45,
          startLeft: 20,
        },
        {
          maxWidth: 8000,
          minWidth: 3840,
          camWidth: 140,
          camHeight: 248,
          startTop: 45,
          startLeft: 20,
        },
      ],
    },
    {
      meetingWinStyleNr: 3,
      breakpoints: [
        {
          maxWidth: 1440,
          minWidth: 300,
          camWidth: 140,
          camHeight: 248,
          startTop: 45,
          startLeft: 20,
        },
        {
          maxWidth: 2400,
          minWidth: 1440,
          camWidth: 140,
          camHeight: 248,
          startTop: 45,
          startLeft: 70,
        },
        {
          maxWidth: 3840,
          minWidth: 2400,
          camWidth: 140,
          camHeight: 248,
          startTop: 45,
          startLeft: 20,
        },
        {
          maxWidth: 8000,
          minWidth: 3840,
          camWidth: 140,
          camHeight: 248,
          startTop: 45,
          startLeft: 20,
        },
      ],
    },
    /* {meetingWinStyleNr: 0, width: 112, height: 150, startTop: 60,  startLeft: 20 },
    {meetingWinStyleNr: 1, width: 112, height: 150, startTop: 60,  startLeft: 70 },
    {meetingWinStyleNr: 2, width: 150, height: 213, startTop: 60,  startLeft: 20 },
    {meetingWinStyleNr: 3, width: 269, height: 382, startTop: 60,  startLeft: 20 },*/
  ],
};
