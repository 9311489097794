<template>
  <svg viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12.4871 2H12.488C14.6767 2 16.4514 4.18877 16.4514 5.96343V13.4965C16.4514 14.0869 16.3223 14.6472 16.0907 15.1506L8.52367 7.58359V5.96343C8.52367 3.77465 10.2982 2 12.4871 2ZM8.52367 9.47737V13.4965C8.52367 15.2711 10.2983 17.4599 12.4871 17.4599H12.488C13.5963 17.4599 14.5985 17.0049 15.3178 16.2715L8.52367 9.47737ZM16.4175 17.3712C15.4039 18.3658 14.0162 18.9797 12.4879 18.9797C9.39082 18.9797 6.87122 16.4575 6.87122 13.3578V9.8636C6.87122 9.49836 6.57473 9.20275 6.21038 9.20275C5.84514 9.20275 5.54953 9.49838 5.54953 9.8636V13.3578C5.54953 16.9637 8.30981 19.9352 11.8271 20.2696V22.6792H9.18451C8.81927 22.6792 8.52367 22.9748 8.52367 23.34C8.52367 23.7044 8.81929 24 9.18451 24H15.7912C16.1555 24 16.4512 23.7044 16.4512 23.34C16.4512 22.9748 16.1555 22.6792 15.7912 22.6792H13.1486L13.1477 20.2687C14.7774 20.1142 16.2449 19.3928 17.3505 18.3042L16.4175 17.3712ZM18.2134 17.2734C18.9777 16.1578 19.4253 14.8087 19.4253 13.3569L19.4261 9.8636C19.4261 9.68829 19.3565 9.51986 19.2328 9.39611C19.1082 9.27236 18.9406 9.20275 18.7653 9.20275C18.59 9.20279 18.4217 9.27239 18.2979 9.39611C18.1742 9.51986 18.1046 9.68828 18.1046 9.8636V13.3578C18.1046 14.4442 17.7953 15.4595 17.2602 16.3201L18.2134 17.2734Z"
      fill="currentColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M8.52282 7.58325L3.143 2.20343C2.88152 1.94195 2.45759 1.94196 2.19611 2.20343C1.93463 2.46491 1.93463 2.88885 2.19611 3.15032L8.52282 9.47704V7.58325ZM15.3169 16.2711C15.6349 15.9468 15.8977 15.5681 16.0899 15.1503L17.2593 16.3197C17.0208 16.7033 16.7375 17.0562 16.4167 17.3709L15.3169 16.2711ZM17.3497 18.3039L22.4156 23.3698C22.6771 23.6313 23.101 23.6313 23.3625 23.3698C23.624 23.1083 23.624 22.6844 23.3625 22.4229L18.2126 17.273C17.9584 17.6441 17.6691 17.9893 17.3497 18.3039Z"
      fill="currentColor"
    />
  </svg>
</template>
