<template>
  <div
    class="MeetingInit overlayInnerCopy"
    id="meetingInit"
    :class="{ isMinimized, isMobile }"
    :data-slots-count="Math.min(maxVisibleSlotsCount, mediaUsers.length)"
    :data-hide-feeds="meetingWinStyleNr === 0 || null"
  >
    <div id="meetingBackDrop" v-if="showMeetingBackdrop"></div>

    <div id="meetingOverlay" v-if="!canRunMeeting">
      <div id="innerContact">
        <!-- Vorschau video -->
        <div class="agora-view">
          <MediaFeed
            class="videoWin"
            :videoTrack="myself.videoTrack"
            :showMicIndicator="false"
            :username="myself.attrs.username"
          />
        </div>

        <!-- Meeting Title :  -->
        <h4 class="meetingTitle">
          {{ activeAppointmentData.title }}
        </h4>

        <!-- wait for remote  -->
        <div
          v-if="rtcParams.isClientReady === true"
          class="p-3 flex-fill pt16 tfbb"
        >
          <div v-if="isModerator">{{ locale.meeting_3_presenter_label_1 }}</div>
          <div v-else>{{ locale.meeting_3_recipient_label_1 }}</div>
        </div>

        <!-- ********************************* -->
        <!-- Device Control :  -->
        <!-- DESKTOP -->
        <div
          id="meetingMore"
          class="flex flex-column _formHolder"
          v-if="rtcParams.isClientReady === false && !isMobile"
        >
          <div class="d-flex flex-column tfbb">
            <div class="p-0 flex-fill ml15 mr15">
              <div class="d-flex align-items-center justify-content-between">
                <div class="p-0" style="width: 50%">
                  <div class="d-flex align-items-center mt5 mb5">
                    <div class="p-0 mr10 mt2">
                      <img
                        :src="
                          rtcParams.isVideoOn
                            ? '/assets/ui/icons/meeting_cam_on.svg'
                            : '/assets/ui/icons/meeting_cam_off.svg'
                        "
                      />
                    </div>
                    <div class="p-0 ml5">
                      {{
                        rtcParams.isVideoOn
                          ? locale.meeting_2_intro_button_1_1
                          : locale.meeting_2_intro_button_1_2
                      }}
                    </div>
                    <!-- cam : an / aus  -->
                    <div class="p-0 slideContainer">
                      <SlideToggle
                        :active="rtcParams.isVideoOn"
                        @toggled="camToggled"
                      />
                    </div>
                  </div>
                </div>

                <div class="p-0" style="width: 50%">
                  <div class="d-flex align-items-center mt5 mb5">
                    <div class="p-0 mt2 ml20">
                      <img
                        :src="
                          rtcParams.isAudioOn
                            ? '/assets/ui/icons/meeting_mic_on.svg'
                            : '/assets/ui/icons/meeting_mic_off.svg'
                        "
                      />
                    </div>
                    <div class="p-0 ml5">
                      {{
                        rtcParams.isAudioOn
                          ? locale.meeting_2_intro_button_2_1
                          : locale.meeting_2_intro_button_2_2
                      }}
                    </div>
                    <!-- mic : an / aus  -->
                    <div class="p-0 slideContainer">
                      <SlideToggle
                        :active="rtcParams.isAudioOn"
                        @toggled="micToggled"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- ============= CAM / MIC DEVICES ====================== -->
          <div class="_meetingSettingsRow p-0 flex-fill tfbb">
            <div
              class="d-flex align-items-center"
              style="padding-top: 3px; padding-bottom: 3px"
            >
              <div v-if="currentCam" class="p-0 ml5 mr5">
                <DeviceDropDown
                  :items="devices.vids"
                  :selected="currentCam"
                  @changed="camChanged"
                />
              </div>
              <div v-if="currentMic" class="p-0 mr5">
                <DeviceDropDown
                  :items="devices.mics"
                  :selected="currentMic"
                  @changed="micChanged"
                />
              </div>
            </div>
          </div>

          <!-- ================ Buttons ============================ -->
          <div class="_meetingSettingsRow p-3 flex-fill pt16">
            <div
              id="moderatorCreateTourButton"
              class="flex-fill"
              v-if="isModerator"
            >
              <div
                class="secondayButton"
                @click="$router.push({ path: `/create-tour` })"
              >
                <div class="d-flex align-items-center h100 secondaryHolder">
                  <div class="p-0">
                    {{ locale.meeting_2_intro_button_2_presenter }}
                  </div>
                  <div class="p-0 mt2 secondaryIcon">
                    <img src="/assets/ui/icons/arrow_right_desk.svg" />
                  </div>
                </div>
              </div>
            </div>

            <div
              class="contact-next-button contextNextClickable startButtonMobile"
              @click="initMeetingClient_joinChannel()"
              :disabled="rtcParams.isClientReady"
            >
              <div id="startMeetingButtonText" class="contact-next-button-text">
                {{ meetingStartButtonText }}
              </div>
            </div>
          </div>
        </div>

        <!-- MOBILE -->
        <div
          class="d-flex flex-column _formHolder"
          v-if="rtcParams.isClientReady === false && isMobile"
        >
          <div class="p-0 flex-fill tfbb">
            <div class="d-flex align-items-center mr10 ml10 mt5 mb5">
              <div class="p-0 mt2 device-setting-icons">
                <img
                  src="/assets/ui/icons/meeting_cam_on.svg"
                  v-if="rtcParams.isVideoOn"
                />
                <img
                  src="/assets/ui/icons/meeting_cam_off.svg"
                  v-if="!rtcParams.isVideoOn"
                />
              </div>
              <div class="p-0 ml10">
                {{
                  rtcParams.isVideoOn
                    ? locale.meeting_2_intro_button_1_1
                    : locale.meeting_2_intro_button_1_2
                }}
              </div>
              <!-- cam : an / aus  -->
              <div class="p-0 ms-auto">
                <SlideToggle
                  :active="rtcParams.isVideoOn"
                  @toggled="camToggled"
                />
              </div>
            </div>
          </div>

          <div v-if="currentCam" class="p-0 ml10 mr10 mt10 mb10">
            <DeviceDropDown
              :items="devices.vids"
              :selected="currentCam"
              @changed="camChanged"
            />
          </div>

          <div class="p-0 flex-fill tfbb tfbt">
            <div class="d-flex align-items-center mr10 ml10 mt5 mb5">
              <div class="p-0 mt2 device-setting-icons">
                <img
                  :src="
                    rtcParams.isAudioOn
                      ? '/assets/ui/icons/meeting_mic_on.svg'
                      : '/assets/ui/icons/meeting_mic_off.svg'
                  "
                />
              </div>
              <div class="p-0 ml10">
                {{
                  rtcParams.isAudioOn
                    ? locale.meeting_2_intro_button_2_1
                    : locale.meeting_2_intro_button_2_2
                }}
              </div>
              <!-- mic : an / aus  -->
              <div class="p-0 ms-auto">
                <SlideToggle
                  :active="rtcParams.isAudioOn"
                  @toggled="micToggled"
                />
              </div>
            </div>
          </div>
          <div v-if="currentMic" class="p-0 ml10 mr10 mt10 mb10">
            <DeviceDropDown
              :items="devices.mics"
              :selected="currentMic"
              @changed="micChanged"
            />
          </div>

          <div class="p-0 ml10 mr10 mb15">
            <div
              class="contact-next-button contextNextClickable startButtonMobile"
              @click="initMeetingClient_joinChannel()"
              :disabled="rtcParams.isClientReady"
            >
              <div id="startMeetingButtonText" class="contact-next-button-text">
                {{ meetingStartButtonText }}
              </div>
            </div>
          </div>
        </div>
        <!-- ENDE SETTINGS MOBILE-->
      </div>

      <div id="agoraDebug" v-if="debug === true">
        <div class="contact-next-button-text" @click="createRtcClient()">
          createRtcClient
        </div>
        <div class="contact-next-button-text" @click="stopLocal()">
          stop local
        </div>

        <div class="contact-next-button-text" @click="publish()">publish</div>
        <div class="contact-next-button-text" @click="unpublish()">
          unpublish
        </div>

        <div class="contact-next-button-text" @click="getDevices()">
          get Devices
        </div>
      </div>

      <DebugOverlay v-if="debug === true" id="ddebug" ref="ddebug" />
    </div>

    <div id="videoHolder">
      <div v-if="rtcParams.isClientReady && isMobile" class="mobileHeader">
        <div class="mobileHeaderTitle">
          <img src="/assets/Logo.svg" />
        </div>

        <div class="mobileSettingsButton" @click="toggleHud()">
          <SettingsIcon class="mobileSettingsIcon" v-if="hideHud" />
          <CloseIcon class="mobileSettingsIcon" v-else />
        </div>
      </div>

      <div class="videoHolderBody">
        <div v-if="canRunMeeting" class="workspace">
          <MediaFeed
            class="participantSlot"
            v-for="(user, i) in mediaUsers"
            :class="{
              'participantSlot--invisible': i >= visibleUsers.length,
            }"
            data-testid="participantSlot"
            :key="user.uid"
            :videoTrack="user.videoTrack"
            :audioTrack="user.audioTrack"
            :username="user.attrs.username"
            :lastActivity="lastMicActivities[user.uid]"
            :showMicIndicator="!user.attrs.screensharing"
            :allowAudio="!user.attrs.isLocal"
            @mounted="animateSlotInsertion"
          />

          <OtherUsersCell
            class="participantSlot"
            :class="{
              'participantSlot--invisible': !invisibleUsers.length,
            }"
            data-testid="participantSlot"
            :users="invisibleUsers"
            :hideTitle="isMobile && isMinimized"
          />
        </div>

        <div v-if="anyScreenUser" class="screensharingPanel">
          <div class="presenterLabel" :class="{ isRemote: remoteScreenUser }">
            <template v-if="remoteScreenUser">
              <ParticipantLetter :user="remoteScreenUser" />
              {{
                locale.meeting_username_is_presenting.replace(
                  "[username]",
                  remoteScreenUser.attrs.username
                )
              }}
            </template>
            <template v-else>
              <ShareIcon class="presenterLabelIcon" />
              {{ locale.meeting_you_are_presenting }}
            </template>
          </div>

          <MediaFeed
            class="screensharingSlot"
            data-testid="participantSlot"
            username=""
            :videoTrack="anyScreenUser.videoTrack"
            :showMicIndicator="false"
            @mounted="animateSlotInsertion"
          />
        </div>

        <button
          class="startExperienceButton"
          @click="changeSizeMode(1)"
          v-if="isModerator && canRunMeeting && !layoutHasChanged"
        >
          {{ locale.meeting_hud_presenter_start }}
        </button>

        <button
          v-if="isModerator && remoteScreenUser"
          class="stopScreenSharingButton"
          @click="stopRemoteScreen(remoteScreenUser)"
        >
          <span>{{ locale.meeting_stop_screen_sharing }}</span>

          <ExitScreenSharingIcon class="stopScreenSharingIcon" />
        </button>

        <button
          v-else-if="screenUser"
          class="stopScreenSharingButton"
          @click="stopScreensharing()"
        >
          <span>{{ locale.meeting_stop_presenting }}</span>

          <ExitScreenSharingIcon class="stopScreenSharingIcon" />
        </button>

        <MeetingTools
          class="meetingTools"
          v-if="rtcParams.isClientReady && !hideHud"
          @openSettings="toggleClientSettings"
          @changeSizeMode="changeSizeMode"
          @kickUser="kickUser"
          @toggleRemoteMic="toggleRemoteMic"
          @startScreensharing="startScreensharing"
          @close="onCloseMobileHudPopup"
        />
      </div>
    </div>
  </div>
</template>

<script>
import DebugOverlay from "./DebugOverlay.vue";
import { EB, EB_ACTION } from "@/utils/EB";
import { gsap } from "gsap";
// @see: https://greensock.com/forums/topic/29801-getting-error-cannot-use-import-statement-outside-a-module-when-importing-flip/#comment-148771
import Flip from "gsap/dist/Flip.js";
import { TweenVars } from "@/utils/tweenTime.js";
import MeetingTools from "@/components/meeting/MeetingTools.vue";
import SlideToggle from "@/components/SlideToggle";
import DeviceDropDown from "@/components/DeviceDropDown";
import { mapGetters } from "vuex";
import { mapActions } from "vuex";
import MediaFeed from "./MediaFeed.vue";
import SettingsIcon from "@/icons/SettingsIcon.vue";
import CloseIcon from "@/icons/CloseIcon.vue";
import ExitScreenSharingIcon from "@/icons/ExitScreenSharing.vue";
import ShareIcon from "@/icons/ShareIcon.vue";
import OtherUsersCell from "./OtherUsersCell.vue";
import { setCablesVar } from "@/utils/cables";
import ParticipantLetter from "./UserList/ParticipantLetter.vue";

gsap.registerPlugin(Flip);

export default {
  name: "MeetingInit",
  components: {
    ShareIcon,
    MeetingTools,
    DebugOverlay,
    SlideToggle,
    DeviceDropDown,
    MediaFeed,
    SettingsIcon,
    CloseIcon,
    OtherUsersCell,
    ExitScreenSharingIcon,
    ParticipantLetter,
  },

  emits: ["changeSize"],

  data() {
    return {
      layoutHasChanged: false,
      showFrost: false,
      showAllCamDevices: false,
      showAllMicDevices: false,

      meetingWinStyles: [
        "agora-video_aus",
        "agora-video_klein",
        "agora-video_mittel",
        "agora-video_gross",
      ],
      mobileContainerStyles: [
        " ",
        "mobile-klein",
        "mobile-gross",
        "mobile-gross",
      ],
      ContainerStyles: [
        "agora-video_aus",
        "container-klein",
        "container-mittel",
        "container-gross",
      ],

      checkUserCountInterval: null,
      hideHud: true,
    };
  },
  watch: {
    isSockeClusterActive(newValue) {
      if (newValue) {
        this.emitScreenSize();
      }
    },
    meetingWinStyleNr(newValue) {
      if (newValue !== 2) {
        this.layoutHasChanged = true;
      }
    },

    anyScreenUser(screenUser) {
      if (screenUser) {
        this.layoutHasChanged = true;
      }
    },
  },
  created() {
    this.hideHud = this.isMobile;

    this.getAndSetLang(this.currentLocale);
    return this.getDevices();
  },

  destroyed() {
    document.removeEventListener("click", this.handleClick);
  },
  mounted() {
    document.addEventListener("click", this.handleClick);

    this.initListeners();

    this.showFrost = true;

    this.createRtcClient();
  },

  computed: {
    anyScreenUser() {
      return this.remoteScreenUser ?? this.screenUser;
    },

    isMinimized() {
      return (
        this.mediaUsers.length > 1 &&
        (this.meetingWinStyleNr === 1 || this.anyScreenUser)
      );
    },

    sortedRemoteUsers() {
      return this.otherUsers.slice().sort((a, b) => {
        const lastActivity1 = this.lastMicActivities[a.uid];
        const lastActivity2 = this.lastMicActivities[b.uid];
        if (lastActivity1 && !lastActivity2) {
          return -1;
        } else if (lastActivity2 && !lastActivity1) {
          return 1;
        } else if (lastActivity1 && lastActivity2) {
          return lastActivity2 - lastActivity1;
        }

        const order1 = this.usersOrder[a.uid] ?? 999;
        const order2 = this.usersOrder[b.uid] ?? 999;
        return order1 - order2;
      });
    },

    mediaUsers() {
      return [
        this.moderatorUser,
        this.myself,
        ...this.sortedRemoteUsers,
      ].filter(Boolean);
    },

    maxVisibleSlotsCount() {
      return this.isMobile ? (this.isMinimized ? 4 : 6) : 10;
    },

    visibleUsers() {
      const shouldGroupTheLastSlot =
        this.mediaUsers.length > this.maxVisibleSlotsCount;

      return this.mediaUsers.slice(
        0,
        this.maxVisibleSlotsCount + (shouldGroupTheLastSlot ? -1 : 0)
      );
    },

    invisibleUsers() {
      return this.mediaUsers.slice(this.visibleUsers.length);
    },

    meetingStartButtonText: function () {
      if (this.isModerator === true) {
        return this.locale.meeting_2_intro_button_1_presenter;
      } else {
        return this.locale.meeting_2_intro_button_1_recipient;
      }
    },

    showMeetingBackdrop() {
      return (
        (this.meetingWinStyleNr !== 0 && !this.isMinimized) ||
        !this.canRunMeeting
      );
    },
    ...mapGetters([
      "activeAppointmentData",
      "currentCam",
      "currentMic",
      "debug",
      "devices",
      "locale",
      "currentLocale",
      "canRunMeeting",
      "isMobile",
      "isModerator",
      "isSockeClusterActive",
      "meetingRole",
      "meetingWinStyleNr",
      "myself",
      "otherUsers",
      "moderatorUser",
      "rtcParams",
      "lastMicActivities",
      "screenUser",
      "remoteScreenUser",
      "usersOrder",
    ]),
    /*showFullscreenOverlay(){
    }*/
  },

  methods: {
    ...mapActions([
      "setInnerOverlayComponent",
      "resetOverlayVisibility",
      "changeLayout",
      "createRtcClient",
      "getAndSetLang",
      "getDevices",
      "publishAudio",
      "publishVideo",
      "sendParamsViaWebsox",
      "stopLocal",
      "switchCamDevice",
      "switchMicDevice",
      "startWebsockets",
      "toggleCamState",
      "toggleMicState",
      "toggleClientRatio",
      "join",
      "exitMeeting",
      "kickUser",
      "toggleRemoteMic",
      "startScreensharing",
      "stopRemoteScreen",
      "stopScreensharing",
      "updateWindowAspects",
    ]),

    async changeSizeMode(num) {
      const slots = Array.from(this.$el.querySelectorAll(".participantSlot"));

      const workspaceState = Flip.getState(
        [
          this.$el.querySelector(".workspace"),
          this.$el.querySelector(".meetingTools"),
          ...slots,
        ].filter(Boolean)
      );

      await this.changeLayout(num);

      Flip.from(workspaceState, {
        duration: TweenVars.duration,
        ease: TweenVars.ease,
        absolute: true,
      }).then(this.sizeChanged);
    },

    sizeChanged() {
      const width =
        this.meetingWinStyleNr === 0
          ? 0
          : document.querySelector(".workspace")?.getBoundingClientRect().width;

      const { isMinimized } = this;

      this.$emit("changeSize", {
        width,
        isMinimized,
      });

      // this only needed for promise values overlay
      // ideally we should get rid of this once unify overlays
      // @see https://app.asana.com/0/1201458076524615/1204410833609067/f
      this.updateWindowAspects();
    },

    initListeners: function () {
      if (this.isMobile && !this.isModerator) {
        this.toggleClientRatio(true);
      }

      // ----- Meeting Window views ------
      EB.$on("resizeMeetingWin", (e) => {
        this.changeSizeMode(e.styleNr);
      });

      EB.$on("HOST_STATE_UPDATED", (state) => {
        this.changeSizeMode(state.layoutNr);
        setCablesVar("currentLang", state.lang);
        location.href = `/${state.ref}`;
      });
    },

    animateSlotInsertion(el) {
      el.classList.add("beforeAnimation");
      const slots = Array.from(this.$el.querySelectorAll(".participantSlot"));

      const workspaceState = Flip.getState(
        [
          this.$el.querySelector(".workspace"),
          this.$el.querySelector(".meetingTools"),
          ...slots,
        ].filter(Boolean)
      );

      const state = Flip.getState(workspaceState);
      el.classList.remove("beforeAnimation");

      Flip.from(state, {
        duration: TweenVars.duration,
        ease: TweenVars.ease,
        absolute: true,
      }).then(this.sizeChanged);
    },

    camToggled() {
      this.toggleCamState();
    },

    micToggled() {
      this.toggleMicState();
    },

    toggleHud() {
      if (this.hideHud) {
        this.hideHud = false;

        setTimeout(() => {
          const hud = this.$el.querySelector(".meetingTools");
          hud.style.top = `-${hud.offsetHeight}px`;
          const animSettings = { opacity: 1, top: 0 };

          return gsap.to(hud, {
            duration: TweenVars.duration,
            ease: TweenVars.ease,
            ...animSettings,
          });
        }, 50);
      } else {
        const hud = this.$el.querySelector(".meetingTools");
        const animSettings = { opacity: 0, top: -hud.offsetHeight };

        return gsap
          .to(hud, {
            duration: TweenVars.duration,
            ease: TweenVars.ease,
            ...animSettings,
          })
          .then(() => {
            this.hideHud = true;
            this.resetOverlayVisibility();
          });
      }
    },

    onCloseMobileHudPopup() {
      if (this.isMobile) {
        this.hideHud = true;
      }
    },

    toggleClientSettings() {
      this.setInnerOverlayComponent({
        path: "./inner_overlay_components",
        name: "DeviceSettings",
        onClose: this.onCloseMobileHudPopup.bind(this),
      });
    },

    camChanged(e) {
      this.switchCamDevice(e);
    },
    micChanged(e) {
      this.switchMicDevice(e);
    },

    initMeetingClient_joinChannel: function () {
      console.log(this.$options.name + " -> initMeetingClient_joinChannel");
      this.$store.commit("setBgSound", false); // no Background-Sound when meeting starts

      if (this.isSockeClusterActive == false) {
        this.startWebsockets();
      }

      return this.join();
    },

    // =================== MAIN AGORA FUNCTIONS =================================

    /***************************************************/
    /** publish audio and video to meeting channel */
    publish: async function () {
      await this.publishVideo();
      await this.publishAudio();
    },

    emitScreenSize() {
      setTimeout(() => {
        if (this.isModerator == false) {
          EB.$emit("SOCKET_BROADCAST_MESSAGE", {
            screenSize: {
              width: window.innerWidth,
              height: window.innerHeight,
            },
          });

          this.sendParamsViaWebsox({
            screenSize: {
              width: window.innerWidth,
              height: window.innerHeight,
            },
          });
        }
      }, 3000);
    },

    /***************************************************/
    handleClick() {
      // console.log(e.target)
    },
    /***************************************************/
  }, // end Methods

  /***************************************************/
  /** exit from agora on exit :) */
  beforeDestroy() {
    this.exitMeeting();
    EB.$off(EB_ACTION.WIN_RESIZE);
  },
};
</script>

<style scoped lang="scss">
@import "../../styles/IconButton.scss";

#agoraDebug {
  position: absolute;
  right: 0;
  top: 10vh;
  z-index: 1100;
  opacity: 1;
  margin: 1vw;
}

.devicesContainer {
  width: 90%;
  padding: 0.2rem;
}

#meetingOverlay {
  position: absolute;
  inset: 0;
  display: flex;
  // align-items: center;
  justify-content: center;
}

#innerContact {
  background-color: rgba(255, 255, 255, 0.25);
  color: #636363;
  font-family: "Univers LT Std";
  padding: 0;
  pointer-events: all;
}

#meetingSettingsRow {
  height: 3vh;
  display: flex;
  justify-content: space-around;
}

._meetingSettingsRow {
  display: flex;
  justify-content: space-around;
}
.container-klein {
  justify-content: flex-start;
  top: 3vh !important;
  left: 3vh !important;
}
.container-mittel {
  height: 100% !important;
  justify-content: center;
  align-items: center;
  top: 0vh !important;
  left: 0;
  background: rgba(255, 255, 255, 0.3);
}

.container-gross {
  height: 100% !important;
  justify-content: center;
  align-items: center;
  top: 0vh !important;
  left: 0;
  background: rgba(255, 255, 255, 0.3);
}

.mti {
  display: flex;
  align-items: center;
}

.flex {
  display: flex;
}

.centered {
  justify-content: space-around;
  align-items: center;
  text-align: center;
}

.agora-box {
  position: absolute;
  background-color: rgba(255, 255, 255, 0.5);
  backdrop-filter: blur(10px);
}

.agora-view {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 16px 0;
}

.agora-video {
  position: relative;
  margin: 20px;
}
.agora-video_klein {
  position: relative;
  width: 8vw;
  height: 20vh;
}
.agora-video_mittel {
  position: relative;
  width: 20vw;
  height: 25vh;
}
.agora-video_gross {
  position: relative;
  width: 40vw;
  height: 45vh;
}

.agora-video {
  width: 320px;
  height: 240px;
}

.clickcontainer {
  position: absolute;
  cursor: help;
  width: 100%;
  height: 100%;
}

#icon_settings {
  cursor: pointer;
  pointer-events: all;
  width: 34px;
  height: 34px;
  border-radius: 50%;
  // background-color: rgba(255,255,255,0.6);
  position: absolute;
  right: 5px;
  bottom: 5px;
  z-index: 3;
}

#wrapper {
  z-index: 3;
  bottom: 2% !important;
  right: 2%;
}

.tooltip {
  position: absolute;
  padding: 10px;
  background-color: rgba(255, 255, 255, 0.85);
  width: 11vw;
  border-radius: 4px;
  z-index: 3;
  top: 200%;
  right: -1.6vw;
  height: auto;
}

.tooltip:before {
  content: " ";
  position: Absolute;
  width: 0;
  height: 0;
  right: 28px;
  top: -17px;
  border-left: 14px Solid Transparent;
  border-right: 14px Solid Transparent;
  border-bottom: 17px Solid rgba(255, 255, 255, 0.85);
}

#clientSettingsIconImg {
  margin-top: 3px;
  margin-left: 3px;
}

.mobileHeader {
  backdrop-filter: blur(10px);
  background-color: rgba(255, 255, 255, 0.2);
  display: flex;
  width: 100%;
  padding: 0 var(--spacing);
  box-sizing: border-box;
}
.mobileHeaderTitle {
  display: flex;
  flex: 1;
  justify-content: center;
}

.mobileSettingsButton {
  display: flex;
  align-items: center;
  position: absolute;
  align-self: center;
  right: 16px;
}

.mobileSettingsIcon {
  height: 32px;
}

@media screen and (max-width: 600px) {
  #meetingOverlay {
    padding: 44px 16px;
  }

  .mobile-gross {
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: 100% !important;
    top: 0vh !important;
    left: 0;
    z-index: 1;
    background: rgba(255, 255, 255, 0.3);
    backdrop-filter: saturate(180%) blur(10px);
  }

  .mobile-klein {
    display: flex;
    justify-content: flex-start;
    top: 10vh;
    left: 5vw;
  }

  .agora-video_klein {
    display: block;
    width: 20vw;
    height: 20vh;
    margin-right: 2vw;
  }

  .agora-video_gross {
    display: block;
    width: 37vw;
    height: 37vh;
  }
  #meetingSettingsRow {
    height: auto !important;
    display: block;
  }
  #icon_settings {
    cursor: pointer;
    pointer-events: all;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    position: absolute;
    right: 5px;
    bottom: 5px;
    z-index: 3;
  }
  #clientSettingsIconImg {
    margin-top: 1px;
    margin-left: 1px;
  }

  .tooltip {
    position: absolute;
    padding: 10px;
    background-color: rgba(255, 255, 255, 0.85);
    width: 220px;
    text-align: center;
    border-radius: 4px;
    z-index: 3;
    top: 70px;
    left: -42px;
    height: auto;
  }

  .tooltip:before {
    content: " ";
    position: Absolute;
    width: 0;
    height: 0;
    left: 42px;
    top: -36px;
    border-right: 15px Solid Transparent;
    border-bottom: 21px solid rgba(255, 255, 255, 0.85);
    border-top: 15px Solid Transparent;
    border-left: 15px Solid Transparent;
  }
}

.agora-video_aus {
  display: none;
}

.MeetingInit {
  --spacing: 16px;
  --spacingX: var(--spacing);
  --spacingY: var(--spacing);
  --slotWidth: calc(100% / var(--colCount));

  // allow for the site underneath to stay interactive
  pointer-events: none;

  --gap: var(--spacing);

  .workspace {
    background: rgba(99, 99, 99, var(--workspaceBgAlpha, 0));
    gap: var(--gap);
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    flex: 1;
    padding: var(--spacingY, 0%) var(--spacingX, 0%);
    overflow: hidden;
    transition: width 0.3s;
  }

  &:not(.isMobile):not(.isMinimized) .workspace {
    align-items: center;
    margin-bottom: 40px;
  }

  #videoHolder {
    --siteHeaderHeight: 30px;
    --siteFooterHeight: 25px;
    position: absolute;
    inset: calc(var(--siteHeaderHeight) + var(--spacing)) var(--spacing)
      calc(var(--siteFooterHeight) + var(--spacing));
    display: flex;
    align-content: center;
    justify-content: center;

    & > *:not(.videoHolderBody),
    .videoHolderBody > * {
      pointer-events: all;
    }
  }

  .presenterLabel {
    color: white;
    width: min-content;
    margin: 8px auto;
    display: flex;
    height: 24px;
    padding: 2px 14px;
    gap: 10px;
    border-radius: 2px;
    white-space: nowrap;
    align-items: center;
    box-sizing: border-box;

    &:not(.isRemote) {
      background-color: rgba(255, 255, 255, 0.45);
    }
  }

  .presenterLabelIcon {
    height: 100%;
  }

  .screensharingPanel {
    position: absolute;
    right: calc(var(--workspaceWidth) + 16px);
    bottom: 56px;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.65);
    padding: 0 5%;
  }

  .participantSlot {
    --gapsCount: calc(var(--colCount) - 1);
    --allGapsWidth: calc(var(--gap) * var(--gapsCount));

    min-width: calc(
      var(--slotWidth) - calc(var(--allGapsWidth) / var(--colCount))
    );
    flex: 0 1 min-content;

    &.beforeAnimation,
    &--invisible {
      position: absolute;
      right: -1000px;
    }
  }

  .stopScreenSharingButton {
    @extend %IconButton;

    position: absolute;
    left: var(--meetingTools-rightSpacing, 0);
    bottom: 0;
    gap: var(--spacing);

    padding: 4px 16px;

    .stopScreenSharingIcon {
      height: 32px;
      margin: 0;
      box-sizing: border-box;
    }
  }

  .videoHolderBody {
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 100%;
    display: flex;
    align-content: flex-start;
    justify-content: center;
  }

  @media screen and (max-width: 600px) {
    .videoHolderBody {
      flex-direction: column;
    }
  }

  .meetingTools {
    position: absolute;
    right: var(--meetingTools-rightSpacing, 0);
    bottom: 0;
  }

  &[data-hide-feeds] .meetingTools {
    right: 0;
  }

  .stopScreenSharingButton,
  .meetingTools {
    color: #636363;
    font-size: 13px;
    line-height: 18px;
    display: flex;
    background-color: rgba(255, 255, 255, 0.5);
    border-radius: 40px;
    align-items: center;
    backdrop-filter: blur(10px);
  }

  &.isMobile .meetingTools {
    position: absolute;
    opacity: 0;
    backdrop-filter: blur(10px);
    background-color: rgba(255, 255, 255, 0.2);
    border-radius: 0;
  }

  &[data-hide-feeds] {
    .workspace {
      position: absolute;
      right: -100vw;
    }

    .screensharingPanel {
      right: calc(var(--workspaceWidth) / 2);
      left: calc(var(--workspaceWidth) / 2);
    }
  }
}

@media screen and (min-width: 600px) {
  .MeetingInit {
    &[data-slots-count="2"] {
      --colCount: 2;
      --rowsCount: 1;
    }
    &[data-slots-count="3"] {
      --colCount: 3;
      --rowsCount: 1;
    }
    &[data-slots-count="4"] {
      --colCount: 4;
      --rowsCount: 1;
    }
    &[data-slots-count="5"],
    &[data-slots-count="6"] {
      --colCount: 3;
      --rowsCount: 2;
    }
    &[data-slots-count="7"],
    &[data-slots-count="8"] {
      --colCount: 4;
      --rowsCount: 2;
    }
    &[data-slots-count="9"],
    &[data-slots-count="10"] {
      --colCount: 5;
      --rowsCount: 2;
    }

    &:not(.isMinimized) {
      --spacingX: calc(
        calc(100% - calc(var(--slotWidth) * var(--colCount))) / 2
      );

      &[data-slots-count="2"] {
        --spacingX: 10%;
      }

      &[data-slots-count="7"],
      &[data-slots-count="8"] {
        --slotWidth: 22%;
      }

      // This is a special case.
      // typically we auto-calculate based on defined columns count and total container width.
      // However, for 5 and 6 feeds, it's required to have 3 columns layout, which in its turn
      // leads to 2 rows layout at the same time.
      // Since each feed has height equal to `width + 20px(for title)` and we also have MetingTools,
      // ocupying some of the screen height, for landscape mode media feeds quickly grow out of
      // the parent container by height. That's why we need this mahual management of the slot width
      // for this specific case.
      &[data-slots-count="5"],
      &[data-slots-count="6"] {
        @media (min-aspect-ratio: 6/5) {
          --slotWidth: 33%;
        }

        @media (min-aspect-ratio: 5/4) {
          --slotWidth: 31%;
        }

        @media (min-aspect-ratio: 7/5) {
          --slotWidth: 29%;
        }

        @media (min-aspect-ratio: 8/5) {
          --slotWidth: 27%;
        }

        @media (min-aspect-ratio: 9/5) {
          --slotWidth: 25%;
        }
      }
    }

    &.isMinimized {
      --workspaceWidth: 280px;
      --meetingTools-rightSpacing: calc(var(--workspaceWidth) + var(--spacing));

      --colCount: 2;

      &[data-slots-count="2"],
      &[data-slots-count="3"],
      &[data-slots-count="4"] {
        --colCount: 1;
      }

      &[data-slots-count="5"],
      &[data-slots-count="6"] {
        --rowsCount: 3;
      }

      &[data-slots-count="7"],
      &[data-slots-count="8"] {
        --rowsCount: 4;
      }

      &[data-slots-count="9"],
      &[data-slots-count="10"] {
        --rowsCount: 5;
      }

      .workspace {
        --workspaceBgAlpha: 0.5;

        backdrop-filter: blur(10px);
        box-sizing: border-box;
        position: absolute;
        width: var(--workspaceWidth);
        right: 0;
        top: 0;
        bottom: 0;
        align-content: flex-start;
        justify-content: flex-start;
      }

      &[data-slots-count="2"],
      &[data-slots-count="3"] {
        --workspaceWidth: 250px;
      }

      &[data-slots-count="4"] {
        --workspaceWidth: 200px;
      }

      &[data-slots-count="2"],
      &[data-slots-count="3"],
      &[data-slots-count="4"] {
        .workspace {
          align-content: center;
        }
      }
    }

    #meetingOverlay {
      align-items: center;
    }
  }
}

@media screen and (min-width: 600px) and (max-height: 600px) {
  .MeetingInit.isMinimized[data-slots-count="2"] {
    --colCount: 2;
    --workspaceWidth: 280px;
  }
}

@media screen and (min-width: 600px) and (max-height: 856px) {
  .MeetingInit.isMinimized[data-slots-count="3"] {
    --colCount: 1;
    --workspaceWidth: 220px;
  }
}

@media screen and (min-width: 600px) and (max-height: 778px) {
  .MeetingInit.isMinimized[data-slots-count="3"] {
    --colCount: 1;
    --workspaceWidth: 200px;
  }
}

@media screen and (min-width: 600px) and (max-height: 906px) {
  .MeetingInit.isMinimized[data-slots-count="4"] {
    --colCount: 2;
    --workspaceWidth: 280px;
  }
}

@media screen and (max-width: 600px) {
  .MeetingInit {
    #videoHolder {
      display: flex;
      flex-direction: column;
      inset: 0px;
      padding: 0;
      justify-content: flex-start;
    }

    .workspace {
      box-sizing: border-box;
      width: 100%;
      align-content: center;
    }

    .screensharingPanel {
      position: static;
      width: 100%;
      height: 100%;
      background: rgba(99, 99, 99, 0.5);
      backdrop-filter: blur(10px);
      padding: 0;
    }

    --gap: 8px;
    --colCount: 2;
    --slotWidth: calc(100% / var(--colCount)) !important;

    &[data-slots-count="2"] {
      --colCount: 1;
      --rowsCount: 2;
    }

    &[data-slots-count="3"],
    &[data-slots-count="4"] {
      --rowsCount: 2;
    }

    &[data-slots-count="5"],
    &[data-slots-count="6"] {
      --rowsCount: 3;
    }

    &[data-slots-count="7"],
    &[data-slots-count="8"] {
      --rowsCount: 4;
    }

    &[data-slots-count="9"],
    &[data-slots-count="10"] {
      --rowsCount: 5;
    }

    &.isMinimized {
      --rowsCount: 1;
      --colCount: 4;

      #videoHolder {
        display: grid;
        grid-template-rows: min-content 1fr;
        grid-template-columns: 1fr;
        overflow: auto;
      }

      .videoHolderBody {
        justify-content: flex-start;
      }

      /* use attribute to increase selector priority */
      &[data-slots-count] .workspace {
        --workspaceBgAlpha: 0;

        backdrop-filter: unset;
        position: relative;
        justify-content: flex-start;
        width: 100%;
        flex-grow: 0;
        flex-basis: auto;
        flex-shrink: 0;
      }
    }
  }
}

@media screen and (max-width: 600px) and (min-aspect-ratio: 9/17) {
  .MeetingInit[data-slots-count="2"]:not(.isMinimized) {
    --colCount: 2;
  }
}

#moderatorCreateTourButton {
  padding: 5px 1rem;
  cursor: pointer;
}

#meetingBackDrop {
  /*position: absolute;*/
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  backdrop-filter: blur(10px);
  background-color: rgba(255, 255, 255, 0.2);
  z-index: 820;
}

.startExperienceButton {
  outline: none;
  position: absolute;
  bottom: 0;
  cursor: pointer;
  align-self: center;
  margin: 0 auto;
  height: 40px !important;
  box-sizing: border-box;
}

.slideContainer {
  margin-left: 10%;
}

.transparent {
  background-color: transparent;
}
.transluzent {
  background-color: rgba(255, 255, 255, 0.6);
}

#agora-inner-preview {
  position: absolute;
  z-index: 906 !important;
  background-color: rgba(255, 255, 255, 0.65);
}

.meetingTitle {
  padding: 1rem;
  border-bottom: 1px solid rgba(99, 99, 99, 0.2);
  margin: 0;
  font-weight: normal;
}

.screensharingSlot,
.participantSlot,
.videoWin {
  border-radius: 2px;
}

@media screen and (max-width: 600px) {
  .screensharingSlot,
  .participantSlot,
  .videoWin {
    border-radius: 3px;
  }
}

.participantSlot,
.videoWin {
  aspect-ratio: 1;
  padding-bottom: 20px;
  height: min-content;
}

@media screen and (min-width: 600px) {
  .screensharingSlot {
    height: 100%;
  }

  .videoWin {
    width: 60%;
  }
}

@media screen and (max-width: 600px) {
  .screensharingSlot {
    aspect-ratio: 16 / 9;
  }

  #innerContact {
    background: none;
    width: 100%;
  }

  .meetingTitle,
  .meetingTitle + * {
    background-color: rgba(255, 255, 255, 0.25);
  }

  .agora-view {
    justify-content: flex-start;

    .MediaFeed {
      width: 25%;
    }
  }
}

@media (orientation: landscape) {
  #innerContact {
    height: auto !important;
    width: 500px;
  }
  #agora-inner-preview {
    width: 400px;
    height: 400px;
    left: 50%;
    top: 50%;
    margin-top: -200px;
    margin-left: -200px;
  }
  #agora-inner-preview-cam {
    width: 400px;
    height: 225px;
  }
}

.hideMe {
  display: none !important;
}
</style>
