<template>
  <div class="OtherUsersCell" :hide-title="hideTitle">
    <div class="body">
      <span class="title">{{ users.length }} others</span>

      <div class="firstThree">
        <ParticipantLetter
          v-for="user in users.slice(0, 2)"
          :key="user.id"
          :user="user"
        />
      </div>
    </div>
  </div>
</template>

<script>
import ParticipantLetter from "./UserList/ParticipantLetter.vue";

export default {
  props: ["users", "hideTitle"],

  components: {
    ParticipantLetter,
  },
};
</script>

<style lang="scss" scoped>
.OtherUsersCell {
  display: flex;
  background-color: rgba(99, 99, 99, 0.45);
  color: #fff;
  justify-content: center;
  align-items: center;

  &[hide-title] .title {
    opacity: 0;
  }
}

.body {
  display: flex;
  flex-direction: column;
  text-align: center;
  gap: 4px;
}

.firstThree {
  display: flex;
  gap: 2px;
}
</style>
